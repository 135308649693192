import React from 'react'
import { PageHeader, PageHeaderTitle, PageHeaderActions } from './PageHeader'
import SearchField from '../SearchField'
import Pagination from './Pagination'
import { withStyles } from '@material-ui/core/styles'
import MuiButton from '@material-ui/core/Button'

const Button = withStyles({
  root: {
    width: 'auto',
    minWidth: '100px',
  },
})(MuiButton)

export default ({ 
  title, searchTerm, setSearchTerm, 
  actionButtons, searchPlaceholder, paginationSettings
}) => {
  return (
    <PageHeader>
      <PageHeaderTitle>
        {title}
        {paginationSettings &&
          <Pagination paginationSettings={paginationSettings} />
        }
      </PageHeaderTitle>
      <PageHeaderActions>
        {searchPlaceholder &&
          <SearchField
            value={searchTerm}
            onValueChanged={setSearchTerm}
            placeholder={searchPlaceholder}
          />
        }
        {actionButtons && actionButtons.map(btn => {
          return(
            <Button key={`${btn.label}-btn`}
              color="primary"
              variant="outlined"
              disabled={btn.disabled}
              onClick={() => btn.onClick()}
            >
              {btn.icon ? btn.icon : ''}
              {btn.label}
            </Button>
          )
        })}
      </PageHeaderActions>
    </PageHeader>
  )
}