import React, { useState, useEffect } from 'react'
import { API } from 'aws-amplify'
import GenericTable from '../../components/GenericTable/GenericTable'
import LoadingSpinner from '../../components/LoadingSpinner'
import AdminMenuBar from '../AdminMenuBar'
import EditDetectionRuleDialog from './EditDetectionRuleDialog'
import EditDetectionThresholdDialog from './EditDetectionThresholdDialog'

const formatRuleDescription = (rule) => {
  if(rule.values) {
    return `${rule.description}: ${rule.values.join(', ')}`
  } else if(rule.categories) {
    return `${rule.description}: ${rule.categories.join(', ')}`
  } else {
    return rule.description
  }
}

export default () => {

  const [detectionThresholds, setDetectionThresholds] = useState()
  const [detectionRules, setDetectionRules] = useState()
  const [ruleToEdit, setRuleToEdit] = useState()
  const [thresholdToEdit, setThresholdToEdit] = useState()

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    const [detectionThresholds, detectionRules] = await Promise.all([
      API.get("dev-d1-apps-backend", '/detectionThresholds'),
      API.get("dev-d1-apps-backend", '/detectionRules'),
    ])

    const maxPoints = detectionRules.reduce((acc, cur) => acc + cur.points, 0)
    setDetectionThresholds(detectionThresholds)
    setDetectionRules(detectionRules.map(rule => ({
      ...rule,
      formattedDescription: formatRuleDescription(rule),
      weight: Math.round( rule.points / maxPoints * 100 ) / 100,
    })))
  }

  const onSaveRule = async (data) => {
    await API.put("dev-d1-apps-backend", `/detectionRules/${ruleToEdit.ruleId}`, {
      body: data
    })
    setRuleToEdit(null)
    await loadData()
  }

  const onSaveThreshold = async (data) => {
    await API.put("dev-d1-apps-backend", `/detectionThresholds/${thresholdToEdit.thresholdId}`, {
      body: data
    })
    setThresholdToEdit(null)
    await loadData()
  }

  if(!detectionRules || !detectionThresholds) {
    return (
      <div>
        <AdminMenuBar activeTab="Detection rules" />
        <LoadingSpinner />
      </div>
    )
  }

  return (
    <div>
      <AdminMenuBar activeTab="Detection rules" />

      <div style={{margin: 25}}>
        <GenericTable
          title="Detection rules"
          initialSortColumn="ruleId"
          columns={[
            {
              title: 'Rule ID',
              key: 'ruleId',
              type: 'string'
            },
            {
              title: 'Description',
              key: 'formattedDescription',
              type: 'string'
            },
            {
              title: 'Points',
              key: 'points',
              type: 'number',
              step: 1
            },
            {
              title: 'Weight',
              key: 'weight',
              type: 'number',
              step: 0.1
            }
          ]}
          data={detectionRules}
          onItemClick={(row, columnKey) => setRuleToEdit(detectionRules.find(r => r.ruleId === row.ruleId))}
        />
      </div>

      <div style={{margin: 25}}>
        <GenericTable
          title="Detection threshold"
          columns={[
            {
              title: 'Value',
              key: 'value',
              type: 'number',
              step: 0.1
            },
          ]}
          data={detectionThresholds}
          onItemClick={(row, columnKey) => setThresholdToEdit(detectionThresholds.find(t => t.thresholdId === row.thresholdId))}
        />
      </div>

      {ruleToEdit &&
        <EditDetectionRuleDialog
          onClose={() => setRuleToEdit(null)}
          onSave={(data) => onSaveRule(data)}
          rule={ruleToEdit}
        />
      }

      {thresholdToEdit &&
        <EditDetectionThresholdDialog
          onClose={() => setThresholdToEdit(null)}
          onSave={(data) => onSaveThreshold(data)}
          threshold={thresholdToEdit}
        />
      }

    </div>
  )
}
