export const MuiTextField = {
  root: {
    width: '100%',
    '& .MuiOutlinedInput-inputAdornedEnd': {
      padding: '12.1px 15px',
      paddingRight: 0
    },
    '& .MuiOutlinedInput-input': {
      borderRadius: '20px'
    },
    '& .MuiOutlinedInput-root': {
      background: '#f3f3f3',
      borderRadius: '20px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '20px',
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#da9b31'
    }
  }
}