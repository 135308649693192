import React from 'react'
import styled from 'styled-components'
import Avatar from '@material-ui/core/Avatar'

const Line = styled.div`
  margin: 10px 15px;
`

const BotMessage = styled.div`
  margin: 15px;
  margin-right: 50px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  > div {
    color: #AAA;
    background-color: #EEE;
    border-radius: 20px;
  }
`

export default () =>
  <BotMessage>
    <Avatar src="/bot-logo.png" style={{marginRight: '10px'}} />
    <div>
      <Line>
        ●	●	●
      </Line>
    </div>
  </BotMessage>
