import React from 'react'
import { API } from 'aws-amplify'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'

export default class RenameSavedSearchDialog extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      name: props.savedSearch.name
    }
  }

  async onSave() {
    await API.put("dev-d1-apps-backend", '/savedSearches/' + this.props.savedSearch.savedSearchId, {
      body: {
        name: this.state.name
      }
    })
    this.props.onClose(true)
  }

  render() {
    return(
      <Dialog
        open={true}
        onClose={() => this.props.onClose()}
      >
        <DialogTitle>Rename saved search</DialogTitle>
        <DialogContent>

          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            value={this.state.name}
            onChange={(event) => this.setState({name: event.target.value})}
            fullWidth
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.props.onClose()} color="primary">
            Cancel
          </Button>
          <Button onClick={() => this.onSave()} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

}
