import React from 'react'
import { API } from 'aws-amplify'
import CreatableSelect from 'react-select/creatable'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import LoadingSpinner from '../../components/LoadingSpinner'

export default class ResolveErrorDialog extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      action: 'ADD_ITEM',
      existingNames: null,
      selectedName: null
    }
  }

  async componentDidMount() {
    const isPatternError = this.props.targetType.startsWith('PATTERN_')
    if(isPatternError) {
      return
    }

    const synonyms = await API.get("d1-knowledgebase", `/content/synonyms?type=${this.props.targetType}`)
    const existingNames = synonyms.map(s => s.name)
    existingNames.sort()
    this.setState({existingNames})
  }

  onSave() {
    this.props.onSave({
      action: this.state.action,
      type: this.props.targetType,
      name: this.state.selectedName,
      synonym: this.props.synonym
    })
  }

  render() {
    const isSaving = this.props.isSaving
    const isPatternError = this.props.targetType.startsWith('PATTERN_')
    return (
      <Dialog
        open={true}
        onClose={this.props.onClose}
      >
        <DialogTitle>
          Resolve field error
        </DialogTitle>

        <DialogContent style={{height: '380px'}}>
          <FormControl component="fieldset">

            {!this.state.existingNames && <LoadingSpinner />}

            {this.state.existingNames && isPatternError &&
              <>
              <FormControlLabel
                value="ADD_ITEM"
                onChange={() => this.setState({action: "ADD_ITEM"})}
                checked={this.state.action === "ADD_ITEM"}
                control={<Radio />}
                label={`Convert "${this.props.synonym}" into:`}
              />
              <TextField
                value={this.state.newName}
                onChange={event => this.setState({newName: event.target.value})}
                disabled={this.state.action !== "ADD_ITEM"}
                margin="dense"
                variant="outlined"
              />
              </>
            }

            {this.state.existingNames && !isPatternError &&
              <>
                <FormControlLabel
                  value="ADD_ITEM"
                  onChange={() => this.setState({action: "ADD_ITEM"})}
                  checked={this.state.action === "ADD_ITEM"}
                  control={<Radio />}
                  label={`Save "${this.props.synonym}" as synonym for the following ${this.props.targetType}.`}
                />
                <FormControl variant="outlined">
                  <CreatableSelect
                    isClearable
                    onChange={newValue => this.setState({selectedName: newValue && newValue.value})}
                    options={this.state.existingNames.map(name => ({label: name, value: name}))}
                  />
                </FormControl>

                <FormControlLabel
                  style={{marginTop: '15px'}}
                  value="IGNORE_ITEM"
                  onChange={() => this.setState({action: "IGNORE_ITEM"})}
                  checked={this.state.action === "IGNORE_ITEM"}
                  control={<Radio />}
                  label={`Ignore "${this.props.synonym}" as ${this.props.targetType}.`}
                />
              </>
            }

          </FormControl>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={this.props.onClose}
            color="primary"
            disabled={isSaving}
          >
            Cancel
          </Button>
          <Button
            onClick={() => this.onSave()}
            color='primary'
            variant='contained'
            disabled={isSaving || (this.state.action === 'ADD_ITEM' && !this.state.selectedName)}
          >
            {isSaving ? 'Saving ...' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

}
