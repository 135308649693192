import React from 'react'
import Autocomplete from '../../components/Autocomplete'

export default ({columns, groupedField, onChange}) => {
  const suggestions = columns.map(c => ({label: c.name, value: c.name}))
  const value = groupedField && {label: groupedField, value: groupedField}
  return(
    <div style={{marginTop: '20px'}}>
      <div style={{marginBottom: '5px'}}>
        <b>Group by:</b>
      </div>
      <Autocomplete
        value={value}
        onChange={selection => onChange(selection && selection.value)}
        suggestions={suggestions}
        placeholder='Select field'
      />
    </div>
  )
}
