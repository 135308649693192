import React, { Component } from 'react'
import styled from 'styled-components'
import { API } from 'aws-amplify'
import DeleteIcon from '@material-ui/icons/Delete'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import MenuBar from '../components/MenuBar'
import LoadingButton from '../components/LoadingButton'
import uuid from 'uuid/v4'


const Select = styled.select`
  padding: 5px;
  font-size: 12px;
  flex: 1;
  margin-left: 10px;
  width: 100%;
`

const Input = styled.input`
  padding: 5px;
  ${props => props.fullWidth ? 'flex: 1' : 'width: 200px'};
  box-sizing: border-box;
  font-size: 12px;
`

const IntentInput = styled.input`
  padding: 5px;
  font-weight: bold;
  font-size: 16px;
  width: 100%;
  margin-bottom: 20px;
  box-sizing: border-box;
`

const SuccessMessage = styled.div`
  color: green;
  font-weight: bold;
  padding 10px;
`

export default class SettingsPage extends Component {

  constructor(props) {
    super(props)
    this.state = {
      successMessage: null,
      intents: []
    }
  }

  async componentDidMount() {
    const intents = await API.get("dev-d1-apps-backend", '/chatIntents')
    this.setState({intents})
  }

  async handleSave(event) {
    console.log(this.state.intents)
    await API.put("dev-d1-apps-backend", '/chatIntents/ALL', {
      body: {
        intents: this.state.intents,
      }
    })
    this.setState({successMessage: 'Saved!'})
    setTimeout(() => this.setState({successMessage: null}), 2000)
  }

  changeIntent(id, value) {
    const intents = this.state.intents
    intents.forEach(intent => {
      if(intent.id === id) {
        intent.question = value
      }
    })
    this.setState({intents})
  }

  changeAnswer(id, value) {
    const intents = this.state.intents
    intents.forEach(intent => {
      intent.responses && intent.responses.forEach(response => {
        if(response.id === id) {
          response.answer = value
        }
      })
    })
    this.setState({intents})
  }

  changeQuestion(id, value) {
    const intents = this.state.intents
    intents.forEach(intent => {
      intent.responses && intent.responses.forEach(response => {
        if(response.id === id) {
          response.question = value
        }
      })
    })
    this.setState({intents})
  }

  changeOption(id, value) {
    const intents = this.state.intents
    intents.forEach(intent => {
      intent.responses && intent.responses.forEach(response => {
        response.options && response.options.forEach(option => {
          if(option.id === id) {
            option.text = value
          }
        })
      })
    })
    this.setState({intents})
  }

  selectOptionRef(id, refId) {
    const intents = this.state.intents
    intents.forEach(intent => {
      intent.responses && intent.responses.forEach(response => {
        response.options && response.options.forEach(option => {
          if(option.id === id) {
            option.ref = refId
          }
        })
      })
    })
    this.setState({intents})
  }

  addIntent() {
    const intents = this.state.intents
    intents.push({
      id: uuid(),
      question: '',
      responses: []
    })
    this.setState({intents})
  }

  addResponse(intentId) {
    const intents = this.state.intents
    const intent = intents.find(i => i.id === intentId)
    intent.responses.push({
      id: uuid(),
      answer: '',
      question: '',
      options: []
    })
    this.setState({intents})
  }

  addOption(responseId) {
    const intents = this.state.intents
    const response = intents
      .reduce((acc, cur) => acc.concat(cur.responses), [])
      .find(r => r.id === responseId)
    response.options.push({
      id: uuid(),
      text: '',
      ref: ''
    })
    this.setState({intents})
  }

  removeOption(intentId, responseId, optionId) {
    const intents = this.state.intents
    const intent = intents.find(i => i.id === intentId)
    const response = intent.responses.find(r => r.id === responseId)
    response.options = response.options.filter(o => o.id !== optionId)
    this.setState({intents})
  }

  render() {
    return (
      <div>
        <MenuBar title='Settings' />

        <div style={{display: 'flex', margin: '20px', marginBottom: '50px', }}>
          <div style={{width: '100px'}}>
            <LoadingButton
              text="Save"
              disabled={false}
              isLoading={false}
              onClick={() => this.handleSave()}
            />
          </div>
          {this.state.successMessage &&
            <SuccessMessage>
              {this.state.successMessage}
            </SuccessMessage>
          }
        </div>

        {this.state.intents.map(intent =>
          <div key={intent.id} style={{margin: '20px', marginBottom: '50px'}}>

            <IntentInput
              value={intent.question}
              onChange={(event) => this.changeIntent(intent.id, event.target.value)}
            />

            {intent.responses.map(response =>
              <div key={response.id} style={{margin: '0 0 40px 40px'}}>

                <div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
                  <div style={{width: '100px'}}>Answer</div>
                  <Input
                    fullWidth
                    value={response.answer}
                    onChange={(event) => this.changeAnswer(response.id, event.target.value)}
                  />
                </div>

                <div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
                  <div style={{width: '100px'}}>Question</div>
                  <Input
                    fullWidth
                    value={response.question}
                    onChange={(event) => this.changeQuestion(response.id, event.target.value)}
                  />
                </div>

                <div style={{display: 'flex', marginBottom: '10px'}}>
                  <div style={{width: '100px', paddingTop: '5px'}}>Options</div>
                  <div style={{flex: 1}}>

                    {response.options && response.options.map(option =>
                      <div key={option.id} style={{display: 'flex', marginBottom: '10px'}}>
                        <Input
                          value={option.text}
                          onChange={(event) => this.changeOption(option.id, event.target.value)}
                        />
                        <Select onChange={(event) => this.selectOptionRef(option.id, event.target.value)}>
                          <option selected={option.ref === ''} value={''}>--- None ---</option>
                          {intent.responses.map(response =>
                            <>
                              <option selected={option.ref === response.id} value={response.id}>{response.answer}</option>
                              <option disabled value={response.id}>&nbsp;&nbsp;&nbsp;{response.question}</option>
                            </>
                          )}
                        </Select>

                        <IconButton size="small" onClick={() => this.removeOption(intent.id, response.id, option.id)}>
                          <DeleteIcon />
                        </IconButton>

                      </div>
                    )}

                    <Button color="primary" size="small" onClick={() => this.addOption(response.id)}>
                      Add option
                    </Button>

                  </div>
                </div>

              </div>
            )}

            <Button color="primary" size="small" style={{margin: '0 0 40px 40px'}} onClick={() => this.addResponse(intent.id)}>
              Add response
            </Button>

          </div>
        )}

        <Button color="primary" size="small" style={{margin: '20px'}} onClick={() => this.addIntent()}>
          Add intent
        </Button>

      </div>
    )
  }

}
