import React, {useState} from 'react'
import styled from 'styled-components'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Autocomplete from '../../components/Autocomplete'

const ScraperTypeEntry = styled.div`
  margin-top: 20px;
  > input, textarea {
    display: block;
    margin-top: 10px;
    width: 100%;
    padding: 5px;
  }
  > textarea {
    height: 100px;
  }
`

export default ({existingScrapers, scraperTypes, appNames, onClose, onSave}) => {

  const [selectedApp, setSelectedApp] = useState()
  const [selectedScraperTypes, setSelectedScraperTypes] = useState({})

  const saveScraperConfig = () => {
    const scraperConfig = {
      appName: selectedApp.value,
      config: Object.entries(selectedScraperTypes).map(([key, value]) =>({
        scraperType: key,
        url: value.url,
        notes: value.notes
      }))
    }
    onSave(scraperConfig)
  }

  const changeScraperType = (type, value) => {
    const newSelectedScraperTypes = { ...selectedScraperTypes }
    if(value) {
      newSelectedScraperTypes[type] = {url: '', notes: ''}
    } else {
      delete newSelectedScraperTypes[type]
    }
    setSelectedScraperTypes(newSelectedScraperTypes)
  }

  const updateField = (type, field, value) => {
    const newSelectedScraperTypes = {
      ...selectedScraperTypes,
      [type]: {
        ...selectedScraperTypes[type],
        [field]: value
      }
    }
    setSelectedScraperTypes(newSelectedScraperTypes)
  }

  let validScraperTypes = []
  if(selectedApp) {
    validScraperTypes = scraperTypes.filter(type => {
      const existingScraper = existingScrapers.find(s => s.appName === selectedApp.value)
      return !existingScraper || !existingScraper[type.value]
    })
  }

  return(
    <Dialog
      open={true}
      onClose={onClose}
    >
      <DialogTitle>Add scraper</DialogTitle>
      <DialogContent style={{width: '400px', height: '350px'}}>

        <Autocomplete
          value={selectedApp}
          onChange={selectedApp => setSelectedApp(selectedApp)}
          suggestions={appNames.map(app => ({label: app, value: app}))}
          placeholder='Select app'
        />

        {validScraperTypes.map(type => {
          const isEnabled = !!selectedScraperTypes[type.value]
          return (
            <ScraperTypeEntry key={type.value}>

              <label>
                <input
                  type="checkbox"
                  checked={isEnabled}
                  onChange={event => changeScraperType(type.value, event.target.checked)}
                />
                {type.name}
              </label>

              {isEnabled &&
                <input
                  placeholder='URL'
                  value={selectedScraperTypes[type.value].url}
                  onChange={event => updateField(type.value, 'url', event.target.value)}
                />
              }

              {isEnabled &&
                <textarea
                  placeholder='Scraping instructions'
                  onChange={event => updateField(type.value, 'notes', event.target.value)}
                  value={selectedScraperTypes[type.value].notes}
                />
              }
            </ScraperTypeEntry>
          )
        })}

      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => saveScraperConfig()} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )

}
