import { API } from 'aws-amplify'

export const GET_CONVERSATION = 'GET_CONVERSATION'
export const GET_CONVERSATION_SUCCESS = 'GET_CONVERSATION_SUCCESS'
export const GET_CONVERSATION_ERROR = 'GET_CONVERSATION_ERROR'

export const GET_CONVERSATIONS = 'GET_CONVERSATIONS'
export const GET_CONVERSATIONS_SUCCESS = 'GET_CONVERSATIONS_SUCCESS'
export const GET_CONVERSATIONS_ERROR = 'GET_CONVERSATIONS_ERROR'

export const CREATE_CONVERSATION = 'CREATE_CONVERSATION'
export const CREATE_CONVERSATION_SUCCESS = 'CREATE_CONVERSATION_SUCCESS'
export const CREATE_CONVERSATION_ERROR = 'CREATE_CONVERSATION_ERROR'

export const CREATE_MESSAGE = 'CREATE_MESSAGE'
export const CREATE_MESSAGE_SUCCESS = 'CREATE_MESSAGE_SUCCESS'
export const CREATE_MESSAGE_ERROR = 'CREATE_MESSAGE_ERROR'

export const getConversation = (conversationId) => async (dispatch) => {
  dispatch({type: GET_CONVERSATION})
  try {
    const conversation = await API.get("dev-d1-apps-backend", '/chatConversations/' + conversationId)
    dispatch({
      type: GET_CONVERSATION_SUCCESS,
      payload: conversation
    })
  } catch (error) {
    console.error(error)
    dispatch({
      type: GET_CONVERSATION_ERROR,
      payload: error
    })
  }
}

export const getConversations = () => async (dispatch) => {
  dispatch({type: GET_CONVERSATIONS})
  try {
    const conversations = await API.get("dev-d1-apps-backend", '/chatConversations')
    dispatch({
      type: GET_CONVERSATIONS_SUCCESS,
      payload: conversations
    })
  } catch (error) {
    console.error(error)
    dispatch({
      type: GET_CONVERSATIONS_ERROR,
      payload: error
    })
  }
}

export const createConversation = (title) => async (dispatch) => {
  dispatch({type: CREATE_CONVERSATION})
  try {
    const conversation = await API.post("dev-d1-apps-backend", '/chatConversations', {
      body: {
        title
      }
    })
    dispatch({
      type: CREATE_CONVERSATION_SUCCESS,
      payload: conversation
    })

    await createMessage(conversation.conversationId, title)(dispatch)

  } catch (error) {
    console.error(error)
    dispatch({
      type: CREATE_CONVERSATION_ERROR,
      payload: error
    })
  }
}


export const createMessage = (conversationId, text) => async (dispatch) => {
  dispatch({type: CREATE_MESSAGE})
  try {
    const conversation = await API.post("dev-d1-apps-backend", '/chatMessages', {
      body: {
        conversationId,
        text
      }
    })
    dispatch({
      type: CREATE_MESSAGE_SUCCESS,
      payload: conversation
    })
  } catch (error) {
    console.error(error)
    dispatch({
      type: CREATE_MESSAGE_ERROR,
      payload: error
    })
  }
}
