import React from 'react'
import TextField from '@material-ui/core/TextField'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

const Container = styled.div`
  width: 300px;
  margin: 20px;
  fontSize: 16px;
  border-radius: 20px;
`

const List  = withStyles({
  root: {
    padding: 0,
    marginTop: '10px',
    height: '500px',
    overflow: 'auto',
    border: '1px solid #ccc',
    borderRadius: '20px'
  }
})(MenuList)


const Item =  withStyles({
  root: {
    '&:first-child': {
      borderTopLeftRadius: '20px',
      borderTopRightRadius: '20px'
    },
    '&:last-child': {
      borderBottomLeftRadius: '20px',
      borderBottomRightRadius: '20px'
    },
    '&:hover, &:focus': {
      backgroundColor: '#da9b31',
      color: '#fff',
      fontWeight: '500'
    },
  }
})(MenuItem)

export default class AppsList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      keyword: ''
    }
  }

  render() {

    const filteredApps = this.props.apps
      .sort((a, b) => a.Name.localeCompare(b.Name))
      .filter(app => app.Name.toLowerCase().indexOf(this.state.keyword.toLowerCase()) >= 0 )
      .filter(app => app.Name !== '_IGNORE_')

    return(
      <Container>
        <TextField
          placeholder='Type to filter list'
          value={this.state.keyword}
          onChange={event => this.setState({keyword: event.target.value})}
          variant="outlined"
        />

        <List>
          {filteredApps.map(app =>
            <Item
              key={app.Name}
              onClick={() => this.props.handleSelectApp(app.Name)}
            >
              {app.Name}
            </Item>
          )}
        </List>
      </Container>
    )
  }

}
