import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { API } from 'aws-amplify'
import LoadingSpinner from '../components/LoadingSpinner'
import MenuBar from '../components/MenuBar'
import { getQueryParameterByName } from './components/util'

export default withRouter(({history}) => {

  useEffect(() => {
    const authorize = async () => {
      const oauthCode = getQueryParameterByName('code')
      if(oauthCode) {
        try {
          await API.post("default", '/appStackQBOImports/authorize', {
            body: {
              oauthRedirectUrl: window.location.href,
            }
          })
        } catch (error) {
          console.error(error)
          alert('Failed to connect new QBO organisation.')
        }
      }
      history.push('/appStackReports/import')
    }

    authorize()
  }, [history])

  return(
    <div>
      <MenuBar title='Book of Apps' activeTab="Import" />
      <LoadingSpinner />
    </div>
  )
})
