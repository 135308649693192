import { useEffect, useState } from 'react';

const loadWidget = (appID) => {
  (function(w, d, id, s, x) {
    function i() {
        i.c(arguments);
    }
    i.q = [];
    i.c = function(args) {
        i.q.push(args);
    };
    w.Intercom = i;
    s = d.createElement('script');
    s.async = 1;
    s.src = 'https://widget.intercom.io/widget/' + id;
    d.head.appendChild(s);
  })(window, document, appID);
}

export default (props) => {
  const [isInit, setIsInit] = useState(false)

  const { appID, ...otherProps } = props;
  if (!window.Intercom) {
    loadWidget(appID)
  }
  window.intercomSettings = { ...otherProps, app_id: appID };

  useEffect(() => {
    if (window.Intercom) {
      if (isInit) {
        window.Intercom('update', otherProps);
      } else {
        window.Intercom('boot', otherProps);
        setIsInit(true)
      }
    }
  }, [props.page, isInit, otherProps])

  return null
}
