import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { API } from 'aws-amplify'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { withStyles } from '@material-ui/core/styles'
import QuestionPanel from './QuestionPanel'
import BucketPanel from './BucketPanel'
import MenuBar from '../components/MenuBar'
import LoadingSpinner from '../components/LoadingSpinner'
import MakeCopyOfAnswersDialog from '../dialogs/MakeCopyOfAnswersDialog'
import SetSavedSearchNameDialog from '../dialogs/SetSavedSearchNameDialog'
import MenuBarComponent from './MenuBarComponent'
import IntercomWidget from '../IntercomWidget'
import { getAppName } from '../AppConfig'
import { setSidePanelContent } from '../actions/sidePanelActions'
import renderHTMLContent from './renderHTMLContent'
import { connect } from 'react-redux'

const Container = styled.div`
  position: relative;
  overflow: hidden;
`

const QuestionTabs = withStyles({
  root: {
    backgroundColor: '#ddd',
    '& button': {
      fontSize: '14px'
    }
  },
  indicator: {
    display: 'none'
  }
})(Tabs)

const QuestionTab = withStyles(theme => ({
  root: {
    borderBottom: '8px solid',
    borderRight: '1px solid #bbb',
    textTransform: 'capitalize',
    textAlign: 'left',
    maxWidth: '1000px',
    '&:last-child': {
      borderRight: 0,
    },
    '&$selected': {
      backgroundColor: '#fff'
    }
  },
  selected: {},
}))(Tab)

const ColoredQuestionTab = styled(QuestionTab)`
  width: calc( 100% / ${props => props.noOfTabs} ) !important;
  border-bottom-color: ${props => props.color} !important;
`

const AUTO_SAVE_INTERVAL = 3000

const getDownloadFilename = (client) => {
  const clientName = `${client.firstName} ${client.lastName}`
  const today = moment().format('YYYY-MM-DD')
  return `${today} - ${clientName} - App Advisory report.docx`
}

// TODO use map/reduce instead of forEach
const getQuestionsById = (config) => {
  const result = {}
  config.sections.forEach(section => {
    section.questions.forEach(question => {
      result[question.id] = question
    })
  })
  return result
}

// TODO use map/reduce instead of forEach
const getAnswersById = (config) => {
  const result = {}
  config.sections.forEach(section => {
    section.questions.forEach(question => {
      question.answers.forEach(answer => {
        result[answer.id] = answer
      })
    })
  })
  return result
}

class QuestionsPage extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      config: null,
      questionsById: null,
      answersById: null,
      selectedSection: null,
      answers: {},
      isMakeCopyOfAnswersDialogOpen: false,
      isSetSavedSearchNameDialogOpen: false,
      client: null,
      savedSearch: null,
      isSaving: false,
      intercomReportCount: 0
    }
  }

  async componentDidUpdate(prevProps) {
    if(prevProps.appId !== this.props.appId || prevProps.clientId !== this.props.clientId || prevProps.savedSearchId !== this.props.savedSearchId) {
      window.clearInterval(this.autoSaveIntervalId)
      await this.componentDidMount()
    }
  }

  async componentDidMount() {
    try {
      const app = await API.get("dev-d1-apps-backend", '/appConfig/' + this.props.appId)
      const config = JSON.parse(app.config)
      const questionsById = getQuestionsById(config)
      const answersById = getAnswersById(config)
      const selectedSection = config.sections[0].id

      let answers = {}
      let clientId = this.props.clientId
      let savedSearch = null
      if(this.props.savedSearchId) {
        try {
          savedSearch = await API.get("dev-d1-apps-backend", '/savedSearches/' + this.props.savedSearchId)
          answers = JSON.parse(savedSearch.content)
          clientId = savedSearch.clientId
        } catch (err) {
          console.error(err)
          // do nothing
        }
      }

      const client = await API.get("dev-d1-apps-backend", '/clients/' + clientId)

      this.setState({
        config,
        questionsById,
        answersById,
        selectedSection,
        answers,
        client,
        savedSearch
      })
    } catch (err) {
      console.error(err)
    }

    this.autoSaveIntervalId = window.setInterval(() => this.autoSave(), AUTO_SAVE_INTERVAL)
  }

  componentWillUnmount() {
    window.clearInterval(this.autoSaveIntervalId)
  }

  async autoSave() {
    let doSave = false
    if(this.state.savedSearch) {
      if(this.state.savedSearch.content !== JSON.stringify(this.state.answers)) {
        doSave = true
      }
    } else {
      if(Object.keys(this.state.answers).length > 0) {
        doSave = true
      }
    }

    if(doSave) {
      const name = (this.state.savedSearch && this.state.savedSearch.name) || 'Untitled'
      this.handleRename(name)
    }
  }

  async handleRename(name) {
    this.setState({isSaving: true})
    let savedSearch = this.state.savedSearch
    if(savedSearch) {
      savedSearch = await API.put("dev-d1-apps-backend", '/savedSearches/' + this.state.savedSearch.savedSearchId, {
        body: {
          name,
          content: JSON.stringify(this.state.answers)
        }
      })
    } else {
      savedSearch = await API.post("dev-d1-apps-backend", '/savedSearches', {
        body: {
          clientId: this.state.client.clientId,
          appId: this.props.appId,
          name,
          content: JSON.stringify(this.state.answers)
        }
      })
    }
    this.setState({savedSearch})
    window.setTimeout(() => this.setState({isSaving: false}), 500)
  }

  handleChangeAnswer = ({questionId, answerIds}) => {
    const answers = this.state.answers
    answers[questionId] = answerIds
    this.setState({answers})
  }

  async openWikiPage(wikiPageId) {
    const wikiPage = await API.get("dev-d1-apps-backend", '/wikiPages/' + wikiPageId)
    const content = wikiPage.content && renderHTMLContent(wikiPage.content, (wikiPageId) => this.openWikiPage(wikiPageId))
    this.props.setSidePanelContent([
      {
        title: wikiPage.name,
        content
      }
    ])
  }

  async downloadReport(bucketId) {
    // TODO duplicate code, see below
    const allBalls = this.calculateBalls()
    const hiddenSections = allBalls
      .map(ball => ball.answer.hideSection)
      .filter(sectionId => !!sectionId)
    const visibleBalls = allBalls.filter(ball => (hiddenSections.indexOf(ball.section.id) < 0))

    const answerSections = this.state.config.sections
      .filter(section => (hiddenSections.indexOf(section.id) < 0))
      .map(section => {
        const notes = visibleBalls
          .filter(ball => ball.section.id === section.id && !!ball.answer.noteForReport)
          .map(ball => {
            if(ball.question.topics && ball.question.topics.length > 0) {
              return (ball.question.topics[0] + ': ' + ball.answer.noteForReport)
            } else {
              return ball.answer.noteForReport
            }
          })
        return {
          heading: section.title,
          intro: section.intro || '',
          notes
        }
      })

    const client = this.state.client
    const downloadFilename = getDownloadFilename(client)
    const preparedFor = client.firstName + ' ' + client.lastName + ', ' + client.companyName
    try {
      const result = await API.post("dev-d1-apps-backend", '/reports', {
        body: {
          appId: this.props.appId,
          preparedFor,
          answerSections,
          bucketId,
          clientId: client.clientId,
          comments: this.state.config.comments || {},
          downloadFilename
        }
      })
      window.open(result.signedUrl, '_blank')
      this.setState({intercomReportCount: result.reportCount})
    } catch (err) {
      console.log(err)
      alert('Error: Cannot generate report.')
    }

    if(!this.state.savedSearch || this.state.savedSearch.name === 'Untitled') {
      this.setState({isSetSavedSearchNameDialogOpen: true})
    }
  }

  calculateBalls() {
    return Object.keys(this.state.answers).reduce((acc, questionId) => {
      const question = this.state.questionsById[questionId]
      if(question) {

        // TODO improve performance of finding ball color without iterating over all sections/questions
        const section = this.state.config.sections.find(
          section => !!section.questions.find(question => question.id === questionId)
        )

        this.state.answers[questionId].forEach(answerId => {
          const answer = this.state.answersById[answerId]
          if(answer) {
            acc.push({section, question, answer})
          }
        })
      }
      return acc
    }, [])
  }

  getSelectedSectionName() {
    const section = this.state.config && this.state.config.sections.find(section => section.id === this.state.selectedSection)
    return (section && section.title)
  }

  render() {

    if(!this.state.config) {
      return <LoadingSpinner />
    }

    const { config, selectedSection } = this.state

    // TODO duplicate code, see above
    const allBalls = this.calculateBalls()
    const hiddenSections = allBalls
      .map(ball => ball.answer.hideSection)
      .filter(sectionId => !!sectionId)
    const visibleBalls = allBalls.filter(ball => (hiddenSections.indexOf(ball.section.id) < 0))

    const activeSections = config.sections.filter(section => (hiddenSections.indexOf(section.id) < 0))
    const activeSectionIndex = activeSections.findIndex(section => section.id === selectedSection)
    const questionsTotal = activeSections.reduce((acc, cur) => acc + cur.questions.length, 0)

    const activeQuestionIds = activeSections.reduce((acc, cur) => {
      cur.questions.forEach(question => acc.push(question.id))
      return acc
    }, [])

    const questionsAnswered = Object.keys(this.state.answers)
      .filter(questionId => !!this.state.answers[questionId].length)
      .filter(questionId => (activeQuestionIds.indexOf(questionId) >= 0))
      .length

    return (
      <Container>

        <MenuBar
          title={this.props.appName}
          component={
            <MenuBarComponent
              client={this.state.client}
              savedSearch={this.state.savedSearch}
              isSaving={this.state.isSaving}
              handleRename={name => this.handleRename(name)}
            />
          }
        />

        <QuestionTabs
          value={selectedSection}
          onChange={(event, selectedSection) => this.setState({ selectedSection })}
        >
          {activeSections.map(section =>
            <ColoredQuestionTab
              key={section.id}
              value={section.id}
              color={section.color}
              label={section.title}
              noOfTabs={activeSections.length}
            />
          )}
        </QuestionTabs>

        <QuestionPanel
          title={config.sections.find(section => section.id === selectedSection).title}
          questions={config.sections.find(section => section.id === selectedSection).questions}
          answers={this.state.answers}
          handleChangeAnswer={this.handleChangeAnswer}
          nextButton={(activeSectionIndex < (activeSections.length - 1)) && {
            color: activeSections[activeSectionIndex + 1].color,
            onClick: () => this.setState({ selectedSection: activeSections[activeSectionIndex + 1].id})
          }}
          prevButton={(activeSectionIndex > 0) && {
            color: activeSections[activeSectionIndex - 1].color,
            onClick: () => this.setState({ selectedSection: activeSections[activeSectionIndex - 1].id})
          }}
          openWikiPage={(wikiPageId) => this.openWikiPage(wikiPageId)}
        />

        <BucketPanel
          buckets={config.buckets}
          balls={visibleBalls}
          changeTab={(sectionId) => this.setState({ selectedSection: sectionId })}
          questionsAnswered={questionsAnswered}
          questionsTotal={questionsTotal}
          hasHiddenSections={(hiddenSections.length > 0)}
          saveAnswers={() => this.setState({isMakeCopyOfAnswersDialogOpen: true})}
          downloadReport={(bucketId) => this.downloadReport(bucketId)}
        />

        {this.state.isMakeCopyOfAnswersDialogOpen && <MakeCopyOfAnswersDialog
          onClose={() => this.setState({isMakeCopyOfAnswersDialogOpen: false})}
          open={true}
          savedSearch={this.state.savedSearch}
          clientId={this.state.client.clientId}
          appId={this.props.appId}
          content={this.state.answers}
        />}

        {this.state.isSetSavedSearchNameDialogOpen &&
          <SetSavedSearchNameDialog
            onClose={() => this.setState({isSetSavedSearchNameDialogOpen: false})}
            onSubmit={name => {
              this.handleRename(name)
              this.setState({isSetSavedSearchNameDialogOpen: false})
            }}
          />
        }

        <IntercomWidget
          page={getAppName(this.props.appId)}
          section={this.getSelectedSectionName()}
          reportCount={this.state.intercomReportCount}
        />

      </Container>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  setSidePanelContent: tabs => dispatch(setSidePanelContent(tabs))
})

export default connect(null, mapDispatchToProps)(QuestionsPage)
