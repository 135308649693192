import React, { useState } from 'react'
import { API } from 'aws-amplify'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default ({onClose, user}) => {

  const [firstName, setFirstName] = useState(user.firstName)
  const [lastName, setLastName] = useState(user.lastName)
  const [isOrgAdmin, setOrgAdmin] = useState(user.isOrgAdmin)
  const [errorMessage, setErrorMessage] = useState()

  const updateUser = async () => {
    try {
      await API.put("dev-d1-apps-backend", '/users/' + user.userId, {
        body: {
          firstName,
          lastName,
          isOrgAdmin,
        }
      })
      onClose({
        ...user,
        firstName,
        lastName,
        isOrgAdmin,
      })
    } catch (err) {
      setErrorMessage(err.response.data.errorMessage)
    }
  }

  return(
    <Dialog
      open
      onClose={onClose}
    >
      <DialogTitle>
        Update user details
      </DialogTitle>

      <DialogContent>

        <div style={{color: 'red'}}>
          {errorMessage}
        </div>

        <form
          method="post"
          onSubmit={event => { event.preventDefault(); updateUser(); } }
        >
          <TextField
            margin="dense"
            label="First name"
            value={firstName}
            onChange={(event) => setFirstName(event.target.value)}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Last name"
            value={lastName}
            onChange={(event) => setLastName(event.target.value)}
            fullWidth
          />
          <FormControlLabel
            style={{padding: '20px 10px'}}
            control={
              <input type="checkbox"
                checked={isOrgAdmin}
                onChange={event => setOrgAdmin(event.target.checked)}
                value={`${isOrgAdmin}`}
              />
            }
            label="This user is admin within their company"
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => updateUser()}
          disabled={(firstName.length === 0) || (lastName.length === 0)}
          color="primary"
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )

}
