import React from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import ArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import * as Dialog from '../../../components/Dialog'
import { withStyles } from '@material-ui/core/styles'
import styled from 'styled-components'
import { setErrorNotification } from '../../../actions/notificationActions'
import { connect } from 'react-redux'

const Category = styled.div`
  display: flex;
`

const ArrowIcons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 26px;
  padding-right: 5px;
`

const CategoryButton = withStyles({
  root: {
    margin: '25px 0 30px'
  }
})(Button)

const IconBtnContainer = withStyles({
  root: {
    padding: '3px',
    color: '#da9b31',
    '& svg': {
      width: '20px',
      height: '20px'
    }
  }
})(IconButton)

class BandDialog extends React.Component {

  constructor(props) {
    super(props)
    let state
    if(props.band !== undefined) {
      const { band: { primarySection, secondarySection } } = props
      state = {
        primaryName: primarySection.name,
        categories: primarySection.categories.map(category => ({
          value: category.name,
          label: category.name
        })),
        defaultApps: primarySection.categories.map(category => category.defaultApp),
        defaultAppNames: primarySection.categories.map(category => ({
          value: category.defaultApp.name,
          label: category.defaultApp.name
        })),
        secondaryName: secondarySection ? secondarySection.name : '',
        secondaryCategories: secondarySection ?
          secondarySection.categories.map(category => ({
            value: category.name,
            label: category.name
          }))
          : [''],
        secondaryDefaultApps: secondarySection ?
          secondarySection.categories.map(category => category.defaultApp)
          : [{name: '', logoURL: null}],
        secondaryDefaultAppNames: secondarySection ?
          secondarySection.categories.map(category => ({
            value: category.defaultApp.name,
            label: category.defaultApp.name
          }))
          : [''],
        benchmarkPercentage: props.band.benchmarkPercentage
      }
    } else {
      // FIXME: Why do we have all these empty strings here ?
      state= {
        primaryName: '',
        categories: [''],
        defaultApps: [{name: '', logoURL: null}],
        defaultAppNames: [''],
        secondaryName: '',
        secondaryCategories: [''],
        secondaryDefaultApps: [{name: '', logoURL: null}],
        secondaryDefaultAppNames: [''],
        benchmarkPercentage: 0
      }
    }

    this.state = state

    this.addNewBand = this.addNewBand.bind(this)
    this.editBand = this.editBand.bind(this)
    this.removeEmptyValues = this.removeEmptyValues.bind(this)
    this.refineInputValues = this.refineInputValues.bind(this)
    this.moveCategory = this.moveCategory.bind(this)
  }

  removeEmptyValues({categories, defaultApps, defaultAppNames}) {
    const categoryList = categories.filter((c) => c !== null && c !== '' && c.value !== '')
    const defaultAppList = defaultApps.filter((d) => d && d.name.length > 0)
    const defaultAppNameList = defaultAppNames.filter((d) => d && d.value.length > 0)

    return { categoryList, defaultAppList, defaultAppNameList }
  }

  refineInputValues() {
    const {
      categories, defaultApps,
      secondaryCategories, secondaryDefaultApps,
      defaultAppNames, secondaryDefaultAppNames
    } = this.state
    const primary = this.removeEmptyValues({categories, defaultApps, defaultAppNames})
    const secondary = this.removeEmptyValues({
      categories: secondaryCategories,
      defaultApps: secondaryDefaultApps,
      defaultAppNames: secondaryDefaultAppNames
    })

    if(primary.categoryList.length !== primary.defaultAppList.length) {
      this.props.setErrorNotification({
        title: 'Input Error',
        message: 'Please enter both a category and a default app in your primary section.'
      })
    } else if(secondary.categoryList.length !== secondary.defaultAppList.length) {
      this.props.setErrorNotification({
        title: 'Input Error',
        message: 'Please enter both a category and a default app in your secondary section.'
      })
    } else {
      this.setState({
        categories: primary.categoryList,
        defaultApps: primary.defaultAppList,
        defaultAppNames: primary.defaultAppNameList,
        secondaryCategories: secondary.categoryList,
        secondaryDefaultApps: secondary.defaultAppList,
        secondaryDefaultAppNames: secondary.defaultAppNameList,
      }, () => {
        // FIXME Don't use 'Add Band' here to figure out if we should add or edit a band.
        // Use another flag for this.
        if (this.props.title === 'Add Band') {
          return this.addNewBand()
        } else {
          return this.editBand()
        }
      })
    }
  }

  addNewBand() {
    const {
      primaryName, categories, defaultApps,
      secondaryName, secondaryCategories, secondaryDefaultApps,
      benchmarkPercentage
    } = this.state

    let categoryList = categories.map((category, i) => {
      return {
        name: category.value || '',
        defaultApp: defaultApps[i],
        apps: []
      }
    })

    const band = {
      primarySection: {
        name: primaryName,
        categories: categoryList
      },
      annualRevenue: 0,
      annualRevenuePercentage: 0,
      benchmarkPercentage
    }

    if(secondaryName !== '') {
      const secondaryList = secondaryCategories.map((category, i) => {
        return {
          name: category.value || '',
          defaultApp: secondaryDefaultApps[i],
          apps: []
        }
      })

      band.secondarySection = {
        name: secondaryName,
        categories: secondaryList
      }
    }

    this.props.addBand(band)
  }

  editBand() {
    const {
      primaryName, categories, defaultApps,
      secondaryName, secondaryCategories, secondaryDefaultApps,
      benchmarkPercentage
    } = this.state
    const { band } = this.props

    let categoryList = categories.map((category, i) => {
      const item = {
        ...band.primarySection.categories[i],
        name: category.value || '',
        defaultApp: defaultApps[i] || {name: '', logoURL: ''},
      }
      if (!item.apps) {
        item.apps = []
      }
      return item
    })

    const newBand = {
      ...band,
      primarySection: {
        name: primaryName,
        categories: categoryList
      },
      benchmarkPercentage
    }

    if(secondaryName !== '') {
      const secondaryList = secondaryCategories.map((category, i) => {
        if (band.secondarySection) {

          const newCategory = {
            ...band.secondarySection.categories[i],
            name: category.value || '',
            defaultApp: secondaryDefaultApps[i]
          }

          if (!newCategory.apps) {
            newCategory.apps = []
          }

          return newCategory
        } else {
          return {
            name: category.value,
            defaultApp: secondaryDefaultApps[i],
            apps: []
          }
        }
      })

      newBand.secondarySection = {
        name: secondaryName,
        categories: secondaryList
      }
    }

    this.props.editBand(newBand)
  }

  moveCategory({categoryLevel, currentCategory, i, direction}) {
    let newCategories, newDefaultApps, newDefaultAppNames
    let categoryIndex = direction === 'up' ? i - 1 : i + 1

    if(categoryLevel === 'primary') {
      let { categories, defaultApps, defaultAppNames } = this.state
      newCategories = categories
      newDefaultApps = defaultApps
      newDefaultAppNames = defaultAppNames
    } else {
      let { secondaryCategories, secondaryDefaultApps, secondaryDefaultAppNames } = this.state
      newCategories = secondaryCategories
      newDefaultApps = secondaryDefaultApps
      newDefaultAppNames = secondaryDefaultAppNames
    }

    let affectedCategory = newCategories[categoryIndex]
    newCategories[categoryIndex] = currentCategory
    newCategories[i] = affectedCategory

    let affectedDefaultApp = newDefaultApps[categoryIndex]
    newDefaultApps[categoryIndex] = newDefaultApps[i]
    newDefaultApps[i] = affectedDefaultApp

    let affectedDefaultAppName = newDefaultAppNames[categoryIndex]
    newDefaultAppNames[categoryIndex] = newDefaultAppNames[i]
    newDefaultAppNames[i] = affectedDefaultAppName

    if(categoryLevel === 'primary') {
      this.setState({
        categories: newCategories,
        defaultApps: newDefaultApps,
        defaultAppNames: newDefaultAppNames
      })
    } else {
      this.setState({
        secondaryCategories: newCategories,
        secondaryDefaultApps: newDefaultApps,
        secondaryDefaultAppNames: newDefaultAppNames
      })
    }
  }

  render() {
    const {
      isDialogOpen, toggleDialog,
      allApps, allCategories,
      title, icon
     } = this.props
    const {
      primaryName, categories, defaultApps, defaultAppNames,
      secondaryName, secondaryCategories, secondaryDefaultApps, secondaryDefaultAppNames,
      benchmarkPercentage
    } = this.state

    return (
      <Dialog.CenteredDialog
          open={isDialogOpen}
          onClose={() => toggleDialog()}
          aria-labelledby="form-dialog-title"
        >
          <Dialog.FormTitle id="form-dialog-title">{icon} {title}</Dialog.FormTitle>

          <Dialog.DialogContentContainer>
            <p><strong>When you select a category, select a default app for it too.</strong></p>

            <Grid item xs={12} md={6}>
              <Dialog.TextfieldContainer>
                <label htmlFor="primaryName">Band Primary Name (shown on the left side)</label>
                <TextField id="primaryName"
                  value={primaryName}
                  onChange={(e) => this.setState({primaryName: e.target.value})}
                  placeholder="Enter primary name"
                  variant="outlined" autoFocus fullWidth
                />
              </Dialog.TextfieldContainer>
            </Grid>

            {categories.map((currentCategory, i) => {
              return (
                <Category key={`category-${i}`}>
                  <ArrowIcons>
                    <IconBtnContainer title="Move up category"
                      disabled={i === 0 ? true : false}
                      onClick={() => this.moveCategory({
                        categoryLevel: 'primary', currentCategory, i, direction: 'up'
                      })}
                    >
                      <ArrowUpIcon />
                    </IconBtnContainer>
                    <IconBtnContainer title="Move down category"
                      disabled={(i + 1) === categories.length ? true : false}
                      onClick={() => this.moveCategory({
                        categoryLevel: 'primary', currentCategory, i, direction: 'down'
                      })}
                    >
                      <ArrowDownIcon />
                    </IconBtnContainer>
                  </ArrowIcons>
                  <Grid container>
                    <Grid item xs={12} md={6}>
                      <Dialog.TextfieldContainer>
                        <label htmlFor="category">Category</label>
                        <Dialog.IndustryAutocomplete
                          id="category"
                          value={currentCategory}
                          onChange={category => {
                            const list = categories
                            list[i] = category
                            return this.setState({ categories: list })
                          }}
                          suggestions={allCategories.map(category => ({ value: category, label: category}))}
                          placeholder='Search a category'
                        />
                      </Dialog.TextfieldContainer>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Dialog.TextfieldContainer>
                        <label htmlFor="defaultApp">Default App</label>
                          <Dialog.IndustryAutocomplete
                            id="defaultApp"
                            value={defaultAppNames[i]}
                            onChange={app => {
                              const selected = app !== null ? allApps.filter(item => item.name === app.value) : {name: '', logoURL: ''}
                              const appNames = defaultAppNames
                              appNames[i] = app
                              const apps = defaultApps
                              apps[i] = selected[0]
                              return this.setState({
                                defaultApps: apps,
                                defaultAppNames: appNames
                              })
                            }}
                            suggestions={allApps.map(app => ({ value: app.name, label: app.name}))}
                            placeholder='Search an app'
                          />
                      </Dialog.TextfieldContainer>
                    </Grid>
                  </Grid>
                </Category>
              )
            })}

            <div>
              <CategoryButton color="primary" variant="outlined" onClick={() => {
                return this.setState({
                  categories: [...categories, ''],
                  defaultApps: [...defaultApps, {name: '', logoURL: null}],
                  defaultAppNames: [...defaultAppNames, '']
                })
              }}>
                Add Category
              </CategoryButton>
            </div>

            <Grid item xs={12} md={6}>
              <Dialog.TextfieldContainer>
                <label htmlFor="secondaryName">Band Secondary Name (shown on the right side)</label>
                <TextField id="secondaryName"
                  value={secondaryName}
                  onChange={(e) => this.setState({secondaryName: e.target.value})}
                  placeholder="Enter secondary name"
                  variant="outlined" fullWidth
                />
              </Dialog.TextfieldContainer>
            </Grid>

            {secondaryCategories.map((currentCategory, i) => {
              return (
                <Category key={`secondaryCategory-${i}`}>
                  <ArrowIcons>
                    <IconBtnContainer title="Move up category"
                      disabled={i === 0 ? true : false}
                      onClick={() => this.moveCategory({
                        categoryLevel: 'secondary', currentCategory, i, direction: 'up'
                      })}
                    >
                      <ArrowUpIcon />
                    </IconBtnContainer>
                    <IconBtnContainer title="Move down category"
                      disabled={(i + 1) === secondaryCategories.length ? true : false}
                      onClick={() => this.moveCategory({
                        categoryLevel: 'secondary', currentCategory, i, direction: 'down'
                      })}
                    >
                      <ArrowDownIcon />
                    </IconBtnContainer>
                  </ArrowIcons>
                  <Grid container>
                    <Grid item xs={12} md={6}>
                      <Dialog.TextfieldContainer>
                        <label htmlFor="category">Category</label>
                        <Dialog.IndustryAutocomplete
                          id="category"
                          value={currentCategory}
                          onChange={category => {
                            const list = secondaryCategories
                            list[i] = category
                            return this.setState({ secondaryCategories: list })
                          }}
                          suggestions={allCategories.map(category => ({ value: category, label: category}))}
                          placeholder='Search a category'
                        />
                      </Dialog.TextfieldContainer>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Dialog.TextfieldContainer>
                        <label htmlFor="defaultApp">Default App</label>
                          <Dialog.IndustryAutocomplete
                            id="defaultApp"
                            value={secondaryDefaultAppNames[i]}
                            onChange={app => {
                              const selected = app !== null ? allApps.filter(item => item.name === app.value) : {name: '', logoURL: ''}
                              const appNames = secondaryDefaultAppNames
                              appNames[i] = app
                              const apps = secondaryDefaultApps
                              apps[i] = selected[0]
                              return this.setState({
                                secondaryDefaultApps: apps,
                                secondaryDefaultAppNames: appNames
                              })
                            }}
                            suggestions={allApps.map(app => ({ value: app.name, label: app.name}))}
                            placeholder='Search an app'
                          />
                      </Dialog.TextfieldContainer>
                    </Grid>
                  </Grid>
                </Category>
              )
            })}

            <div>
              <CategoryButton color="primary" variant="outlined" onClick={() => {
                return this.setState({
                  secondaryCategories: [...secondaryCategories, ''],
                  secondaryDefaultApps: [...secondaryDefaultApps, {name: '', logoURL: null}],
                  secondaryDefaultAppNames: [...secondaryDefaultAppNames, '']
                })
              }}>
                Add Category
              </CategoryButton>
            </div>

            <Grid item xs={12} md={6}>
              <Dialog.TextfieldContainer>
                <label htmlFor="benchmark">Benchmark Percentage</label>
                <TextField id="benchmark"
                  value={benchmarkPercentage}
                  onChange={(e) => this.setState({benchmarkPercentage: e.target.value})}
                  placeholder="Enter benchmark value (ex. 0.223)"
                  variant="outlined" fullWidth
                />
              </Dialog.TextfieldContainer>
            </Grid>

            <Dialog.Actions>
              <Dialog.CancelButton variant="outlined" onClick={() => toggleDialog()}>
                Cancel
              </Dialog.CancelButton>
              <Dialog.SaveButton variant="outlined"
                onClick={this.refineInputValues}
              >
                {title}
              </Dialog.SaveButton>
            </Dialog.Actions>

          </Dialog.DialogContentContainer>

        </Dialog.CenteredDialog>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  setErrorNotification: (error) => dispatch(setErrorNotification(error))
})

export default connect(null, mapDispatchToProps)(BandDialog)
