import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import reduxStore from './reduxStore'
import EnvMessage from './EnvMessage'
import App from './App'
import Amplify from 'aws-amplify'
import AmplifyConfig from './AmplifyConfig'
import './index.css'
import 'fixed-data-table-2/dist/fixed-data-table.css'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { theme } from './styles'

Amplify.configure(AmplifyConfig)

ReactDOM.render(
  <Router>
    <MuiThemeProvider theme={theme}>
      <Provider store={reduxStore}>
        {(process.env.REACT_APP_ENV !== 'prod') && <EnvMessage />}
        <App />
      </Provider>
    </MuiThemeProvider>
  </Router>,
  document.getElementById("root")
)
