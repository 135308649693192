import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Checkbox from '@material-ui/core/Checkbox'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Slider from '@material-ui/core/Slider'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import FilterIcon from '@material-ui/icons/FilterList'
import { TableContainer, TableHeaderCell, TableStandardCell } from '../Table'
import { formatNumber } from '../../appStackReports/components/util'
import useDebounce from '../../useDebounce'

import Header from './Header'
import BodyRows from './BodyRows'

import Autocomplete from '../Autocomplete'
import styled from 'styled-components'
import { withStyles } from '@material-ui/core/styles'

const DATE_FORMAT = 'DD.MM.YYYY'
const TH = TableHeaderCell
const TD = TableStandardCell

const Content = styled.div`
  display: flex;
  flex-direction: row;

  .genericTable {
    width: 100%;
  }
`

const CellWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    display: flex;
    align-items: center;
  }

  .sortIcon {
    font-size: 2.5rem;
    position: relative;
    top: -1px;
  }

  .filterIcon {
    visibility: hidden;
  }

  &:hover .filterIcon,
  .filterIcon.active {
    visibility: visible;
  }
`

const CategoryInput = styled.div`
  margin-top: 8px;

  .valueLabels {
    text-align: center;
  }

  .fromDatePicker {
    margin-bottom: 10px;
  }
`

const NumberSlider = withStyles({
  root: {
    color: '#da9b31'
  },
  thumb: {
    color: '#da9b31',
    '&:focus,&:hover': {
      boxShadow: 'none'
    }
  },
  valueLabel: {
    left: "calc(-50% + 11px)",
    top: -25,
    '& *': {
      background: 'transparent',
      color: '#fff'
    },
    '& > span span': {
      backgroundColor: '#da9b31',
      padding: '5px',
      fontSize: '0.875rem'
    }
  },
})(Slider)

const NoRowsTag = styled.em`
  display: block;
  text-align: center;
`

const sortRows = (column, sortOrder, rows) => {
  if(column.length > 0) {
    const sortedRows = [ ...rows ].sort((a, b) => {
      const aValue = a[column].value
      const bValue = b[column].value
      if(typeof aValue === 'number' && sortOrder) {
        return bValue - aValue
      } else if (typeof aValue === 'number' && !sortOrder) {
        return aValue - bValue
      } else if (sortOrder) {
        return bValue.localeCompare(aValue)
      }
      return aValue.localeCompare(bValue)
    })
    return sortedRows
  } else {
    return rows
  }
}

const formatRawData = (data) => {
  return data.map(row => {
    return Object.entries(row).reduce((acc, keyValuePair) => {
      const [key, value] = keyValuePair
      if(typeof value === 'object' && value !== null) {
        acc[key] = value
      } else {
        acc[key] = {
          value: value === null ? '' : value,
          component: value === null ? '' : value
        }
      }
      return acc
    }, {})
  })
}

const filterRow = ({row, allFilters}) => {
  return Object.keys(allFilters).every(field => {
    const item = allFilters[field]
    if(typeof item[0] === 'string') {
      const stringHolder = String(row[field].value).toLowerCase()
      for(let filterValue of item) {
        if(stringHolder === filterValue.toLowerCase()) {
          return true
        }
      }
    } else if (typeof item === 'object' && !isNaN(item)) {
      const filterDate = moment(item).format(DATE_FORMAT)
      const rowDate = moment(row[field].value).format(DATE_FORMAT)
      if(rowDate === filterDate) {
        return true
      }
    } else {
      const numberHolder = Number(row[field].value)
      if(numberHolder >= item[0] && numberHolder <= item[1]) {
        return true
      }
    }
    return false
  })
}

const searchRow = ({row, searchTerm}) => {
  // const prepositions = [
  //   'a', 'an', 'in', 'on', 'at', 'to', 'of', 'the', 'by', 'with', 'it', 'for', '&', 'and', 'her', 'his'
  // ]
  // const searchTerms = searchTerm.split(' ').filter(term => !prepositions.includes(term))
  const searchTerms = searchTerm.split(' ')

  return Object.keys(row).some(field => {
    return searchTerms.every((term) => {
      const stringHolder = String(row[field].value).toLowerCase()
      if(stringHolder.includes(term.toLowerCase())) {
        return true
      }
      return false
    })
  })
}

export default ({
  title, columns, data, onItemClick,
  actionButtons, searchPlaceholder,
  initialSortColumn, initialSortOrder,
  sideContent,
  isAllChecked, onAllChecked,
  pageSize, initialData
}) => {
  const [rawData, setRawData] = useState([])
  const [sortColumn, setSortColumn] = useState(initialSortColumn || columns[0].key)
  const [isReverse, setSortOrder] = useState(initialSortOrder || false)
  const [visibleFilters, setVisibleFilters] = useState([])
  const [filters, setFilters] = useState({})
  const [sliderValues, setSliderValues] = useState({})
  const debouncedSliderValues = useDebounce(sliderValues, 500)
  const [searchTerm, setSearchTerm] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [filteredRows, setFilteredRows] = useState([])

  useEffect(() => {
    const rawData = formatRawData(data || initialData)
    setRawData(rawData)
  }, [initialData, data])

  useEffect(() => {
    const rows = rawData.filter(row => {
      const allFilters = {...filters, ...debouncedSliderValues}
      const isFilter = filterRow({row, allFilters})
      const isSearch = (searchTerm.length === 0) ? true : searchRow({row, searchTerm})
      return (isFilter && isSearch)
    })
    const filteredRows = sortRows(sortColumn, isReverse, rows)
    setFilteredRows(filteredRows)
    setCurrentPage(1)
  }, [sortColumn, isReverse, filters, debouncedSliderValues, searchTerm, rawData])

  const setSortOrderAndColumn = (column) => {
    if(column.type !== 'selector') {
      let order = isReverse
      if(sortColumn === column.key) {
        order = !isReverse
        setSortOrder(order)
      } else {
        order = false
        setSortOrder(order)
        setSortColumn(column.key)
      }
    }
    setCurrentPage(1)
  }

  const setInitialFilterDates = (column) => {
    const dates = rawData.map(d => moment(new Date(d[column].value)))
    setFilters({
      ...filters,
      [column]: [moment.min(dates), moment.max(dates)]
    })
  }

  // sets up / removes initial values for slider filters
  const handleVisibleSliderFilterChange = (column, columnIndex) => {
    if(visibleFilters.includes(columnIndex)) {
      const newSliderValues = { ...sliderValues }
      delete newSliderValues[column.key]
      setSliderValues(newSliderValues)
    } else {
      const newSliderValues = {
        ...sliderValues,
        [column.key]: [numberColumnSliders[column.key][0], numberColumnSliders[column.key][1]]
      }
      setSliderValues(newSliderValues)
    }
  }

  // handles WHEN filters will be shown/hidden
  const handleVisibleFilterChange = (column, columnIndex, e) => {
    e.stopPropagation()
    if(visibleFilters.includes(columnIndex)) {
      const newVisibleFilters = visibleFilters.filter(filterIndex => filterIndex !== columnIndex)
      const newFilters = { ...filters }
      delete newFilters[column.key]
      setFilters(newFilters)
      setVisibleFilters(newVisibleFilters)
    } else {
      setVisibleFilters([...visibleFilters, columnIndex])
    }

    if(column.type === 'number' || column.type === 'currency') {
      handleVisibleSliderFilterChange(column, columnIndex)
    }

    if(column.type === 'date') {
      setInitialFilterDates(column.key)
    }
  }

  const handleOnFilterChange = (selection, column) => {
    let newFilters = { ...filters }

    if(selection === null || selection.length === 0) {
      delete newFilters[column]
    } else {
      newFilters[column] = selection && selection.map(s => s.value)
    }
    setFilters(newFilters)
  }

  const handleOnSliderFilterChange = (columnKey, sliderValue) => {
    const newSliderValues = { ...sliderValues }
    newSliderValues[columnKey] = sliderValue
    setSliderValues(newSliderValues)
  }

  const handleDateChange = (date, columnKey, period) => {
    const dateRange = filters[columnKey] && period === 'from' ? [date, filters[columnKey][1]] : [filters[columnKey][0], date]
    if(date !== null) {
      setFilters({
        ...filters,
        [columnKey]: dateRange
      })
    }
  }

  const numberColumnSliders = {}
  const sliderStep = {}
  const numberColumns = columns.filter(d => d.type === 'number' || d.type === 'currency')

  for(let column of numberColumns) {
    const numbers = rawData.map(d => d[column.key].value)
    const minNumber = Math.min(...numbers)
    const maxNumber = Math.max(...numbers)

    if(!column.step) {
      numberColumnSliders[column.key] = [minNumber, maxNumber]
      sliderStep[column.key] = Math.round((maxNumber - minNumber) / 10)
    } else {
      sliderStep[column.key] = column.step

      const step = column.step

      // get the smallest multiple of step closest to step that is lower than minNumber
      let smallestMultiple = ( step === 0 ) ? minNumber : Math.floor( ( minNumber / step ) - 1) * step
      smallestMultiple = smallestMultiple >= 0 ? smallestMultiple : 0
      // get the biggest multiple of step closest to step that is higher than maxNumber
      const biggestMultiple = ( step === 0 ) ? maxNumber : Math.floor( ( maxNumber / step ) + 1 ) * step

      numberColumnSliders[column.key] = [smallestMultiple, biggestMultiple]
    }
  }

  let paginationSettings = null
  let rows = filteredRows
  let notifMessage = 'No rows found'

  if(pageSize) {
    const lastPost = currentPage * pageSize
    const firstPost = lastPost - pageSize
    const toBeLastPageNumber = Math.ceil(filteredRows.length / pageSize)
    const lastPage = toBeLastPageNumber === 0 ? 1 : toBeLastPageNumber

    paginationSettings = {
      setCurrentPage: (page) => setCurrentPage(page),
      currentPage,
      indexOfFirstItem: firstPost + 1,
      indexOfLastItem: lastPost,
      isDataLoading: !data,
      totalItems: filteredRows.length,
      lastPage
    }

    rows = rows.slice(firstPost, lastPost)
  }

  if(rows.length === 0 && data && data.length === 0) {
    notifMessage = 'Empty table'
  }

  return(
    <div>
      <Header
        title={title}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        actionButtons={actionButtons}
        searchPlaceholder={searchPlaceholder}
        paginationSettings={paginationSettings}
      />

      <Content>
        <TableContainer className="genericTable">
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column, columnIndex) => {
                  return (
                    <TH key={`th-${column.key}`}>
                      <CellWrapper onClick={() => setSortOrderAndColumn(column)}>
                        <span>
                          {column.title}
                          {column.type && column.type !== 'selector' &&
                            <span className="sortIcon">&nbsp;{(column.key === sortColumn) && (isReverse ? '▾' : '▴')}</span>
                          }
                          {column.type === 'selector' &&
                            <Checkbox
                              checked={isAllChecked}
                              onClick={onAllChecked}
                              color="primary"
                            />
                          }
                        </span>
                        {column.type && column.type !== 'selector' &&
                          <FilterIcon
                            className={`${visibleFilters.includes(columnIndex) ? 'active filterIcon' : 'filterIcon'}`}
                            onClick={(e) => handleVisibleFilterChange(column, columnIndex, e)}
                          />
                        }
                      </CellWrapper>

                      {visibleFilters.includes(columnIndex) && column.type === 'string' &&
                        <CategoryInput>
                          <Autocomplete
                            value={filters[column.key] && filters[column.key].map(filter => ({ value: filter, label: filter}))}
                            onChange={selection => handleOnFilterChange(selection, column.key)}
                            suggestions={[...new Set(rawData.map(d => d[column.key].value).sort())].map(d => ({ value: d, label: d}))}
                            placeholder='Select'
                            isMulti={true} fullWidth
                          />
                        </CategoryInput>
                      }

                      {visibleFilters.includes(columnIndex) && (column.type === 'number' || column.type === 'currency') &&
                        <CategoryInput>
                          <NumberSlider
                            value={sliderValues[column.key] || []}
                            onChange={(e, value) => handleOnSliderFilterChange(column.key, value)}
                            valueLabelDisplay="auto"
                            min={numberColumnSliders[column.key][0]}
                            max={numberColumnSliders[column.key][1]}
                            step={sliderStep[column.key]}
                          />
                          <div className="valueLabels">
                            {column.type === 'currency' &&
                              <span>{formatNumber(sliderValues[column.key][0])} - {formatNumber(sliderValues[column.key][1])}</span>
                            }
                            {column.type === 'number' &&
                              <span>{sliderValues[column.key][0]} - {sliderValues[column.key][1]}</span>
                            }
                          </div>
                        </CategoryInput>
                      }

                      {visibleFilters.includes(columnIndex) && column.type === 'date' &&
                        <CategoryInput>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                              label="From"
                              inputVariant="outlined"
                              format="DD.MM.YYYY"
                              value={filters[column.key][0]}
                              onChange={(date) => handleDateChange(date, column.key, 'from')}
                              KeyboardButtonProps={{'aria-label': 'change date',}}
                              className="fromDatePicker"
                            />
                            <KeyboardDatePicker
                              label="To"
                              inputVariant="outlined"
                              format="DD.MM.YYYY"
                              value={filters[column.key][1]}
                              onChange={(date) => handleDateChange(date, column.key, 'to')}
                              KeyboardButtonProps={{'aria-label': 'change date',}}
                            />
                          </MuiPickersUtilsProvider>
                        </CategoryInput>
                      }
                    </TH>
                  )
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {rows.length === 0 &&
                <TableRow>
                  <TD colSpan={columns.length}><NoRowsTag>{notifMessage}</NoRowsTag></TD>
                </TableRow>
              }
              {(rows.length > 0) &&
                <BodyRows rows={rows} columns={columns} onItemClick={onItemClick} />
              }
            </TableBody>
          </Table>
        </TableContainer>
        {sideContent !== null &&
          sideContent
        }
      </Content>
    </div>
  )
}