import React, {useState, useEffect} from 'react'
import { API } from 'aws-amplify'
import styled from 'styled-components'
import moment from 'moment'
import transformConfig from './components/transformConfig'
import AppStackReport from './components/AppStackReport'
import AppSummary from './components/AppSummary'
import { getUrlParameter } from '../utils'
import { findApps, getConfigFromFoundApps } from './components/util'

const DATE_FORMAT = 'DD.MM.YYYY'

const ReportInfoLine = styled.div`
  display: flex;
  margin: 20px 0;
`

const ReportInfoLabel = styled.div`
  font-weight: bold;
  width: 200px;
`

const ReportInfoValue = styled.div`

`

export default ({match}) => {

  const [content, setContent] = useState()
  const datasetId = match.params.datasetId

  useEffect(() => {
    const loadData = async () => {
      const authToken = getUrlParameter('authToken')
      const fetchedContent = await API.get("dev-d1-apps-backend", `/appStackReports/${datasetId}/downloadData`,
        {
          headers: {
            'Authorization': `Bearer ${authToken}`
          }
        }
      )
  
      const allApps = fetchedContent.allApps.map(a => ({
        name: a.Name,
        category: a.MainCategory,
        svgLogoUrl: a.SVGLogoURL,
      }))
  
      const selectedTransactionLines = fetchedContent.transactionLines.reduce((acc, cur) => {
        if(cur.appName && cur.includeInAppStack) {
          acc[cur.id] = cur.appName
        }
        return acc
      }, {})
  
      const foundAppsByCategory = findApps({
        allApps,
        transactionLines: fetchedContent.transactionLines,
        selectedTransactionLines
      })
  
      const allAppsConfig = transformConfig({
        config: getConfigFromFoundApps({foundAppsByCategory, allCategories: fetchedContent.allCategories}),
        foundAppsByCategory,
        totalIncome: fetchedContent.dataset.totalIncome
      })
  
      setContent({
        ...fetchedContent,
        selectedTransactionLines,
        allApps,
        allAppsConfig,
        foundAppsByCategory
      })
    }

    loadData()
  }, [datasetId])

  if(!content) {
    return null
  }

  const startDate = moment(content.dataset.createdAt)
    .startOf('month')
    .subtract(1, 'years')
    .format(DATE_FORMAT)

  const endDate = moment(content.dataset.createdAt)
    .subtract(1, 'months')
    .endOf('month')
    .format(DATE_FORMAT)

  return(
    <div>

      <h1 style={{textAlign: 'center'}} className='pageheading'>
        {`App Stack for ${content.dataset.nickname}`}
      </h1>

      <ReportInfoLine>
        <ReportInfoLabel>Analysed timeframe</ReportInfoLabel>
        <ReportInfoValue>{startDate} - {endDate}</ReportInfoValue>
      </ReportInfoLine>

      <ReportInfoLine>
        <ReportInfoLabel>Owner</ReportInfoLabel>
        <ReportInfoValue>{content.dataset.userName} / {content.dataset.companyName}</ReportInfoValue>
      </ReportInfoLine>

      <ReportInfoLine>
        <ReportInfoLabel>Report prepared</ReportInfoLabel>
        <ReportInfoValue>{moment().format(DATE_FORMAT)}</ReportInfoValue>
      </ReportInfoLine>

      <AppStackReport
        config={content.allAppsConfig.config}
        isEditing={false}
        showDefaults={false}
        allApps={content.allApps}
        hideTitle
      />

      <h2 className="pagebreak">
        Summary of Apps
      </h2>
      <AppSummary
        foundApps={content.foundAppsByCategory}
      />

    </div>
  )
}
