import React from 'react'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'
import StarIcon from '@material-ui/icons/Star'

const Name = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  span {
    font-weight: normal;
    color: #606060;
    font-size: 12px;
  }
`

const BotMessage = styled.div`
  position: relative;
  padding: 5px;
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  :hover {
    background-color: #F1F1F1;
    .ActionButton {
      display: block;
    }
  }
`

const Content = styled.div`
  p {
    margin: 0;
  }
  a {
    color: #0084ff;
  }
`

const ActionButton = styled.div`
  position: absolute;
  top: -7px;
  right: 10px;
  border: 1px solid #BBB;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
  display: none;
`


const TextLine = ({line}) =>
  <Content>
    <ReactMarkdown
      source={line.text}
      linkTarget='_blank'
    />
  </Content>

const LinkLine = ({line}) =>
  <Content>
    <a href={line.link.startsWith('http') ? line.link : 'http://' + line.link} target='_blank' rel="noopener noreferrer" style={{display: 'block'}}>
      {line.text}
    </a>
  </Content>

const CardLine = ({line}) =>
  <div style={{display: 'flex', flexDirection: 'row', overflow: 'auto'}}>
    {(line.cards.length > 2) && line.cards.map((card, index) => <Card key={index} {...card} /> )}
    {(line.cards.length <= 2) && line.cards.map((card, index) => <BigCard key={index} {...card} /> )}
  </div>

const Card = ({text, link, image}) =>
  <div style={{marginRight: '15px', backgroundColor: '#eee', borderRadius: '20px', border: '1px solid #eee'}}>
    <img alt='' src={image} style={{width: '120px', height: '100px', objectFit: 'cover', borderTopRightRadius: '20px', borderTopLeftRadius: '20px'}} />
    <div style={{textAlign: 'center', padding: '10px'}}>
      {text}
    </div>
  </div>

const BigCard = ({text, link, image}) =>
  <div style={{marginRight: '15px', backgroundColor: '#eee', borderRadius: '20px', border: '1px solid #eee'}}>
    <img alt='' src={image} style={{minWidth: '120px', maxWidth: '180px', minHeight: '100px', maxHeight: '180px', objectFit: 'cover', borderTopRightRadius: '20px', borderTopLeftRadius: '20px'}} />
    <div style={{textAlign: 'center', padding: '10px'}}>
      {text}
    </div>
  </div>


export default ({message, onClick, saveLine}) =>
<>
  {message.lines.filter(line => line.text || line.cards).map((line, index, arr) =>
    <BotMessage key={index}>

      <ActionButton className='ActionButton' onClick={() => saveLine(line)} >
        <StarIcon style={{fontSize: '14px', width: '20px'}}/>
      </ActionButton>

      <div style={{width: '36px', marginRight: '10px'}}>
        {(index === 0) &&
          <img alt="" src="/bot-logo.png" style={{height: '36px', width: '36px', borderRadius: '4px'}} />
        }
      </div>

      <div style={{flex: 1}}>
        {(index === 0) &&
          <Name>
            ChampionBot <span>12:32 PM</span>
          </Name>
        }

        {line.cards && <CardLine line={line} />}
        {line.text && line.link && <LinkLine line={line} />}
        {line.text && !line.link && <TextLine line={line} />}
      </div>

    </BotMessage>
  )}
</>
