import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { 
  clearErrorNotification, 
  clearInfoNotification, 
  clearWarningNotification
} from '../actions/notificationActions'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import * as Dialog from './Dialog'
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles'

const DialogContainer = withStyles(theme => ({
  paper: {
    [theme.breakpoints.up('md')]: {
      top: '0'
    }
  }
}))(Dialog.DialogContainer)

const CloseButton = withStyles({
  root: {
    padding: '6px'
  }
})(IconButton)

const ViewButton = withStyles({
  root: {
    color: '#fff'
  }
})(Button)

const NotifDialog = ({ isDialogOpen, toggleDialog, title, msg }) => {
  return (
    <DialogContainer
      open={isDialogOpen}
      onClose={() => toggleDialog()}
      aria-labelledby="form-dialog-title"
    >

      <Dialog.FormTitle id="form-dialog-title">{title}</Dialog.FormTitle>
      <Dialog.DialogContentContainer>
        <div>{msg}</div>
        <Dialog.UnstyledActions>
          <Dialog.CancelButton variant="outlined" onClick={() => toggleDialog()}>
            Close
          </Dialog.CancelButton>
        </Dialog.UnstyledActions>
      </Dialog.DialogContentContainer>
        
    </DialogContainer>
  )
}

const Notification = ({ 
  error, warning, info, 
  enqueueSnackbar, closeSnackbar,
  clearErrorNotification, clearInfoNotification, 
  clearWarningNotification
}) => {

  const [isDialogOpen, setDialogOpen] = useState(false)
  const [notifMessage, setNotifMessage] = useState({title: '', message: ''})

  const setSnackbarOptions = useCallback((notif, variant) => {
    return {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      },
      persist: notif.timeout > 0 ? false : true ,
      autoHideDuration: notif.timeout,
      message: notif.title,
      key: new Date().getTime() + Math.random(),
      action: (key) => (
        <>
          <ViewButton onClick={() => {
            setNotifMessage({
              title: notif.title,
              message: notif.message
            })
            closeSnackbar(key)
            return setDialogOpen(!isDialogOpen)
          }}>
            View
          </ViewButton>
          <CloseButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {closeSnackbar(key)}}
          >
            <CloseIcon  />
          </CloseButton>
        </>
      ),
      variant
    }
  }, [closeSnackbar, isDialogOpen])

  useEffect(() => {
    if (error.message.length > 0) {
      const options = setSnackbarOptions(error, 'error')
      enqueueSnackbar(error.title, options)
    }
    return () => clearErrorNotification()
  }, [error, error.message.length, setSnackbarOptions, clearErrorNotification, enqueueSnackbar])

  useEffect(() => {
    if (warning.message.length > 0) {
      const options = setSnackbarOptions(warning, 'warning')
      enqueueSnackbar(warning.title, options)
    }
    return () => clearWarningNotification()
  }, [warning, warning.message.length, setSnackbarOptions, clearWarningNotification, enqueueSnackbar])

  useEffect(() => {
    if (info.message.length > 0) {
      const options = setSnackbarOptions(info, 'info')
      enqueueSnackbar(info.title, options)
    }
    return () => clearInfoNotification()
  }, [info, info.message.length, setSnackbarOptions, clearInfoNotification, enqueueSnackbar])

  return (
    <div>
      {isDialogOpen && notifMessage && notifMessage.message.length > 0 &&
        <NotifDialog 
          isDialogOpen={isDialogOpen} 
          toggleDialog={setDialogOpen} 
          title={notifMessage.title} 
          msg={notifMessage.message}
        />
      }
    </div>
  )
}

const mapStateToProps = (state) => ({
  error: state.notif.error,
  warning : state.notif.warning,
  info: state.notif.info
})

const mapDispatchToProps = (dispatch) => ({
  clearErrorNotification: () => dispatch(clearErrorNotification()),
  clearWarningNotification: () => dispatch(clearWarningNotification()),
  clearInfoNotification: () => dispatch(clearInfoNotification())
})

export default connect(mapStateToProps, mapDispatchToProps)((withSnackbar(Notification)))