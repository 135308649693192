import React from 'react'
import styled from 'styled-components'
import {formatUpdatedAt, formatUpdatedBy, formatContent} from './util'

const UpdateEventDiv = styled.div`
  background-color: #eee;
  padding: 10px;
  border: 1px solid #da9b31;
  margin-bottom: 10px;

  p {
    margin-top: 0;
    overflow-wrap: break-word;
    word-wrap: break-word;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

const UpdateEventsList = ({ events }) =>
  <>
  {events.map(event =>
    <UpdateEventDiv key={event.ID}>
      <p>
        <strong>Update At: </strong>
        {formatUpdatedAt(event.UpdatedAt)}
      </p>
      <p>
        <strong>Update By: </strong>
        {formatUpdatedBy(event.UpdatedBy)}
      </p>
      <p>
        <strong>Content: </strong>
        <ul>
          {Object.entries(event.Content).map(([key, value]) =>
            <li>{formatContent(key, value)}</li>
          )}
        </ul>
      </p>
    </UpdateEventDiv>
  )}
  </>

export default UpdateEventsList
