import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

const UserMessage = styled.div`
  cursor: pointer;
  font-size: 16px;
  padding: 10px;
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  background-color: ${props => props.selected ? '#F1F1F1' : 'transparent'}
  :hover {
    background-color: #F1F1F1;
  }
`

const Name = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  span {
    font-weight: normal;
    color: #606060;
    font-size: 12px;
  }
`

export default ({message, onClick, selected}) =>
  <UserMessage onClick={onClick} selected={selected}>

    <div style={{height: '36px', width: '36px', marginRight: '10px'}}>
      <img alt="" src="/profile-picture.jpg" style={{height: '36px', width: '36px', borderRadius: '4px'}} />
    </div>

    <div style={{flex: 1}}>
      <Name>
        Mathias <span>{moment(message.createdAt).fromNow()}</span>
      </Name>

      {message.lines.map((line, lineIndex) =>
        <div key={lineIndex}>
          {line.text}
        </div>
      )}
    </div>

  </UserMessage>
