import uuid from 'uuid/v4'

export const addBucket = () => ({
  type: 'ADD_BUCKET',
  payload: {
    id: uuid()
  }
})

export const receiveBuckets = buckets => ({
  type: 'RECEIVE_BUCKETS',
  payload: {
    buckets
  }
})

export const updateBucket = (id, title, templateFile) => ({
  type: 'UPDATE_BUCKET',
  payload: {
    id, title, templateFile
  }
})

export const deleteBucket = id => ({
  type: 'DELETE_BUCKET',
  payload: {
    id
  }
})
