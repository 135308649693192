import React from 'react'
//import Sockette from 'sockette'
import { connect } from 'react-redux'
import MenuBar from '../components/MenuBar'
import BotPanel from './BotPanel'

class BotPage extends React.Component {

/*
  componentDidMount() {
    console.log('WSS', this.props.currentUser.accessToken.jwtToken)
    const ws = new Sockette('wss://67lpr05xle.execute-api.ap-southeast-2.amazonaws.com/dev?token='+this.props.currentUser.accessToken.jwtToken, {
      timeout: 5e3,
      maxAttempts: 1,
      onopen: e => console.log('WSS Connected!', e),
      onmessage: e => console.log('WSS Received:', e),
      onreconnect: e => console.log('WSS Reconnecting...', e),
      onmaximum: e => console.log('WSS Stop Attempting!', e),
      onclose: e => console.log('WSS Closed!', e),
      onerror: e => console.log('WSS Error:', e)
    })
    this.ws = ws
//    ws.send('Hello, world!')
//    ws.json({type: 'ping'})
//    ws.close()

  }
*/

  render() {
    return(
      <div>
        <MenuBar title='Bot' />
        <BotPanel currentUser={this.props.currentUser} />
      </div>
    )
  }

}

const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser
})

export default connect(mapStateToProps)(BotPage)
