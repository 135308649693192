import React, { Component } from 'react'
import ConfigEditor from './ConfigEditor'
import Grid from '@material-ui/core/Grid'

class App extends Component {
  render () {
    return (
      <Grid
        alignItems='center'
        justify='center'
        container spacing={10}
      >
        <Grid item xs={11} md={10}>
          <ConfigEditor
            config={this.props.config}
            appId={this.props.appId}
          />
        </Grid>
      </Grid>
    )
  }
}

export default App
