import React, { Component } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import SaveSurveyButton from './editor/SaveSurveyButton'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { Button } from '@material-ui/core'
import AdminMenuBar from '../AdminMenuBar'
import LoadingSpinner from '../../components/LoadingSpinner'
import ConfigEditorApp from './editor/App'
import VersionsDialog from './VersionsDialog'
import {
  loadAppConfig,
  loadAppConfigVersions,
  updateAppConfig
} from './editor/actions/savedConfigActions'
import {
  APP_ID_ARCHITECTURE_SELECTOR,
  APP_ID_INVENTORY_APP_SELECTOR,
  APP_ID_POS_APP_SELECTOR
} from '../../AppConfig'

const DATE_FORMAT = 'DD.MM.YYYY HH:mm'

class AdminAppConfigEditorPage extends Component {

  constructor(props) {
    super(props)
    this.state = {
      showVersionsDialog: false
    }
  }

  componentDidMount() {
    this.props.loadAppConfig(APP_ID_ARCHITECTURE_SELECTOR)
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.savedConfig.saving && !this.props.savedConfig.saving && this.state.showVersionsDialog) {
      this.props.loadAppConfig(this.props.savedConfig.appId)
      this.setState({showVersionsDialog: false})
    }
  }

  onSave(config, comment) {
    const appId = this.props.savedConfig.appId
    this.props.updateAppConfig({appId, config, comment})
  }

  onRestore(createdAt) {
    const comment = `Restore version ${moment(Number(createdAt)).format(DATE_FORMAT)}`
    const appId = this.props.savedConfig.appId
    this.props.updateAppConfig({appId, createdAt, comment})
  }

  showVersionsDialog() {
    const appId = this.props.savedConfig.appId
    this.props.loadAppConfigVersions(appId)
    this.setState({showVersionsDialog: true})
  }

  render() {
    return (
      <div>
        <AdminMenuBar activeTab="App config" />

        {!this.props.savedConfig.config && <LoadingSpinner />}

        {(this.props.savedConfig.config) &&
          <div>
            <div style={{display: 'flex', justifyContent: 'space-between', padding: '10px', borderBottom: '1px solid #CCC', backgroundColor: '#EEE'}}>
              <div style={{display: 'flex'}}>
                <Select
                  style={{margin: '0 20px'}}
                  value={this.props.savedConfig.appId || APP_ID_ARCHITECTURE_SELECTOR}
                  onChange={event => this.props.loadAppConfig(event.target.value)}
                  variant='filled'
                >
                  <MenuItem value={APP_ID_ARCHITECTURE_SELECTOR}>Architecture Selector</MenuItem>
                  <MenuItem value={APP_ID_INVENTORY_APP_SELECTOR}>Inventory App Selector</MenuItem>
                  <MenuItem value={APP_ID_POS_APP_SELECTOR}>POS App Selector</MenuItem>
                </Select>
                <SaveSurveyButton
                  onSave={(config, comment) => this.onSave(config, comment)}
                />
              </div>
              <div>
                <Button
                  color={'primary'}
                  variant='contained'
                  onClick={() => this.showVersionsDialog()}
                >
                  Versions
                </Button>
              </div>
            </div>

            <div style={{padding: '25px'}}>
              <ConfigEditorApp
                key={this.props.savedConfig.appId}
                appId={this.props.savedConfig.appId}
                config={this.props.savedConfig.config}
              />
            </div>
          </div>
        }

      {this.state.showVersionsDialog &&
        <VersionsDialog
          versions={this.props.savedConfig.versions}
          users={this.props.savedConfig.versionsUsers}
          onClose={() => this.setState({showVersionsDialog: false})}
          onRestore={(selectedVersion) => this.onRestore(selectedVersion)}
          saving={this.props.savedConfig.saving}
        />
      }

      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  savedConfig: state.appConfig.savedConfig
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadAppConfig: (appId) => dispatch(loadAppConfig(appId)),
  loadAppConfigVersions: (appId) => dispatch(loadAppConfigVersions(appId)),
  updateAppConfig: ({appId, createdAt, config, comment}) => dispatch(updateAppConfig({appId, createdAt, config, comment})),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminAppConfigEditorPage)
