import React, { Component } from 'react'
import { ContentState, EditorState, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import Toolbar from './Toolbar'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

export default class AdminWikiPage extends Component {
  constructor (props) {
    super(props)
    const contentBlock = htmlToDraft(this.props.html)
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
    const editorState = EditorState.createWithContent(contentState)
    this.state = {
      editorState
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.wikiPageId !== this.props.wikiPageId) {
      const contentBlock = htmlToDraft(this.props.html)
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
      const editorState = EditorState.createWithContent(contentState)
      this.setState({ editorState })
    }
  }

  onEditorStateChange (editorState) {
    this.setState({
      editorState
    })
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    this.props.onHTMLChange(html)
  }

  render () {
    return (
      <Editor
        toolbar={Toolbar}
        editorState={this.state.editorState}
        disabled={this.props.disabled}
        editorStyle={{ padding: '10px', paddingTop: 0, overflow: 'visible' }}
        toolbarStyle={{}}
        onEditorStateChange={state => this.onEditorStateChange(state)}
      />
    )
  }
}
