import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'

export default ({params, onClose, onSave}) =>
  <Dialog open={true} onClose={onClose}>
    <DialogTitle>
      Bulk import
    </DialogTitle>
    <DialogContent>
      <div>
        <b>Add following items as names for an {params.type}:</b>
      </div>
      {params.names.map((name, index) => <div key={index}>{name}</div>)}
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Cancel
      </Button>
      <Button onClick={onSave} color="primary" variant="contained">
        Save
      </Button>
    </DialogActions>
  </Dialog>
