import React, {useState} from 'react'
import styled from 'styled-components'
import { withStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import SaveIcon from '@material-ui/icons/Save'
import DownloadIcon from '@material-ui/icons/GetApp';
//import ReplayIcon from '@material-ui/icons/Replay';
import PollOutlinedIcon from '@material-ui/icons/PollOutlined';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import TextField from '@material-ui/core/TextField'
import * as Dialog from '../../components/Dialog'
import NumberTextField from './NumberTextField'
import { roundAndFormatNumber } from './util'
import VisibilityToggle from './VisibilityToggle'
import AnonymiseDatasetDialog from './AnonymiseDatasetDialog'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`

const KeyValueItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  > label {
    font-weight: bold;
    width: 150px;
  }
  > div {
    flex: 1
  }
`

const IconButtonContainer = withStyles({
  root: {
    padding: '5px',
    color: '#999',
    '& svg': {
      width: '20px',
      height: '20px'
    }
  }
})(IconButton)

export default ({dataset, onUpdate, onDelete, onAnonymise, onDownload, onRerunImport, onRerunAnalysis, isAdminArea, hasWritePermission}) => {

  const [visibility, setVisibility] = useState(dataset.visibility)
  const [nickname, setNickname] = useState(dataset.nickname)
  const [totalIncome, setTotalIncome] = useState(dataset.totalIncome)
  const [lineOfBusiness, setLineOfBusiness] = useState(dataset.lineOfBusiness)
  const [isEditing, setEditing] = useState(false)
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [isAnonymiseDialogOpen, setAnonymiseDialogOpen] = useState(false)

  if(isEditing) {
    return(
      <Container>
        <div style={{flex: 1}}>
          <KeyValueItem>
            <label>Nickname: </label>
            <div>
              <TextField
                type="text"
                variant="outlined"
                placeholder="Nickname"
                value={nickname}
                onChange={event => setNickname(event.target.value)}
                fullWidth
              />
            </div>
          </KeyValueItem>
          <KeyValueItem>
            <label>YTD Revenue: </label>
            <div>
              <NumberTextField
                numberValue={totalIncome}
                setNumberValue={(value) => setTotalIncome(value)}
                placeholder="YTD Revenue"
              />
            </div>
          </KeyValueItem>
          <KeyValueItem>
            <label>Line of Business: </label>
            <div>
              <TextField
                type="text"
                variant="outlined"
                placeholder="Line of Business"
                value={lineOfBusiness}
                onChange={event => setLineOfBusiness(event.target.value)}
                fullWidth
              />
            </div>
          </KeyValueItem>
          {isAdminArea &&
            <KeyValueItem>
              <label>Visibility: </label>
              <VisibilityToggle
                type='DATASET'
                visibility={visibility}
                onChange={visibility => setVisibility(visibility)}
              />
            </KeyValueItem>
          }
        </div>

        <div>
          <Tooltip placement='bottom' title='Save'>
            <IconButtonContainer title="Save" onClick={() => {
                setEditing(false)
                onUpdate({nickname, totalIncome, lineOfBusiness, visibility})
              }}>
              <SaveIcon />
            </IconButtonContainer>
          </Tooltip>
        </div>

      </Container>
    )
  } else {
    return(
      <Container>
        <div>
          <KeyValueItem>
            <label>YTD Revenue: </label>
            <div>
              {roundAndFormatNumber(totalIncome)}
            </div>
          </KeyValueItem>
          <KeyValueItem>
            <label>Line of Business: </label>
            <div>
              {lineOfBusiness}
            </div>
          </KeyValueItem>
          <KeyValueItem>
            <label>Data source: </label>
            <div>
              {dataset.dataSource}
              {dataset.anonymised && <i> (this dataset was anonymised)</i>}
            </div>
          </KeyValueItem>
        </div>

        <div>
          {hasWritePermission &&
            <>
              <Tooltip placement='bottom' title='Edit'>
                <IconButtonContainer onClick={() => setEditing(true)}>
                  <EditIcon />
                </IconButtonContainer>
              </Tooltip>
              <Tooltip placement='bottom' title='Delete'>
                <IconButtonContainer onClick={() => setDeleteDialogOpen(true)}>
                  <DeleteIcon />
                </IconButtonContainer>
              </Tooltip>
              {!dataset.anonymised &&
                <Tooltip placement='bottom' title='Anonymise'>
                  <IconButtonContainer onClick={() => setAnonymiseDialogOpen(true)}>
                    <VisibilityOffIcon />
                  </IconButtonContainer>
                </Tooltip>
              }
            </>
          }
          <Tooltip placement='bottom' title='Download'>
            <IconButtonContainer onClick={() => onDownload()}>
              <DownloadIcon />
            </IconButtonContainer>
          </Tooltip>

          {isAdminArea &&
            <>
              {/*
                <Tooltip placement='bottom' title='Rerun import'>
                  <IconButtonContainer onClick={() => onRerunImport()}>
                    <ReplayIcon />
                  </IconButtonContainer>
                </Tooltip>
              */}
              <Tooltip placement='bottom' title='Rerun analysis'>
                <IconButtonContainer onClick={() => onRerunAnalysis()}>
                  <PollOutlinedIcon />
                </IconButtonContainer>
              </Tooltip>
            </>
          }

        </div>

        <Dialog.DialogContainer
          open={isDeleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
        >
          <Dialog.FormTitle>
            <DeleteIcon />Are you sure to delete this dataset?
          </Dialog.FormTitle>
          <Dialog.DialogContentContainer>
            <Dialog.Actions>
              <Dialog.CancelButton variant="outlined" onClick={() => setDeleteDialogOpen(false)}>
                Cancel
              </Dialog.CancelButton>
              <Dialog.SaveButton variant="outlined" onClick={() => onDelete()}>
                Delete
              </Dialog.SaveButton>
            </Dialog.Actions>
          </Dialog.DialogContentContainer>
        </Dialog.DialogContainer>


        <AnonymiseDatasetDialog
          open={isAnonymiseDialogOpen}
          onClose={() => setAnonymiseDialogOpen(false)}
          onAnonymise={() => onAnonymise()}
        />

      </Container>
    )
  }
}
