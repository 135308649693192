import React from 'react'
import styled from 'styled-components'

const EnvMessage = styled.div`
  left: 0;
  right: 0;
  position: absolute;
  z-index: 999;
  div {
    background: yellow;
    border: 1px solid #CCC;
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: 10px;
    margin: 0 auto;
    text-align: center;
    width: 200px;
  }
`

export default () =>
  <EnvMessage>
    <div>
      This is a test environment.
    </div>
  </EnvMessage>
