import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Routes from './navigation/Routes'
import LoadingSpinner from './components/LoadingSpinner'
import { fetchCurrentUser } from './actions/authActions'
import SidePanel from './components/SidePanel'
import Notification from './components/Notification'
import { SnackbarProvider } from 'notistack';

class App extends Component {

  async componentDidMount() {
    this.props.fetchCurrentUser()
  }

  render() {
    return (this.props.isInitialized) ?
      <>
        <Routes />
        <SidePanel />
        <SnackbarProvider>
          <Notification />
        </SnackbarProvider>
      </>
     :
     <LoadingSpinner />
  }

}

const mapStateToProps = (state) => ({
  isInitialized: state.auth.isInitialized
})

const mapDispatchToProps = (dispatch) => ({
  fetchCurrentUser: () => dispatch(fetchCurrentUser())
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
