import { combineReducers } from 'redux'
import buckets from './buckets'
import comments from './comments'
import sections from './sections'
import questions from './questions'
import answers from './answers'
import savedConfig from './savedConfig'

const rootReducer = combineReducers({
  buckets,
  comments,
  sections,
  questions,
  answers,
  savedConfig
})

export default rootReducer
