import styled from 'styled-components'

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const PageHeaderTitle = styled.h1`
  font-size: 40px;
  font-weight: normal;
  color: #2e314e;
`

export const PageHeaderActions = styled.div`
  text-align: right;
  display: flex;
  > * {
    margin-left: 10px !important;
  }
`
