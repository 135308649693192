import React from 'react'
import { connect } from 'react-redux'
import Answer from './Answer'
import { setQuestionAnswersOrder } from './actions/questionActions'
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Grid from '@material-ui/core/Grid'

const SortableItem = SortableElement(({ id, questionId, onClick, expanded, expandedAll }) =>
  <Answer
    id={id}
    questionId={questionId}
    onClick={onClick}
    expanded={expanded}
    expandedAll={expandedAll}
  />
)

const SortableList = SortableContainer(({ answers, questionId, onClick, expanded, expandedAll }) => {
  return (
    <div>
      {answers.map((id, index) => (
        <SortableItem
          key={`item-${index}`}
          questionId={questionId}
          index={index}
          onClick={onClick}
          expanded={expanded}
          expandedAll={expandedAll}
          id={id}
        />
      ))}
    </div>
  )
})

class AnswersEditor extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      expanded: null,
      expandedAll: true
    }
  }

  toggleExpandedAll = () => {
    const expandedAll = !this.state.expandedAll
    this.setState({ expandedAll })
  }

  handleClick = (questionId) => {
    const newExpanded = this.state.expanded === questionId ? null : questionId
    this.setState({ expanded: newExpanded })
  }

  handleAnswersSort = ({ oldIndex, newIndex }) => {
    const { questionId, answers, dispatch } = this.props
    console.log('need to write handler to handleAnswersSort')
    dispatch(setQuestionAnswersOrder(questionId,
      arrayMove(answers, oldIndex, newIndex)))
  }

  render () {
    return (
      <Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={this.state.expandedAll}
                onChange={this.toggleExpandedAll}
                color='primary'
              />
            }
            label='Expand all'
          />
        </Grid>
        <Grid item xs={12}>
          <SortableList
            {...this.props}
            onSortEnd={this.handleAnswersSort}
            distance={20}
            expanded={this.state.expanded}
            expandedAll={this.state.expandedAll}
            onClick={this.handleClick}
          />
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const questionId = ownProps.questionId
  const question = state.appConfig.questions.byId[questionId]
  return { answers: question.answers }
}

export default connect(mapStateToProps)(AnswersEditor)
