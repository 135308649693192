import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'
import Paper from '@material-ui/core/Paper'
import SettingsIcon from '@material-ui/icons/Settings'
import Avatar from '@material-ui/core/Avatar'
import PersonIcon from '@material-ui/icons/Person'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import styled from 'styled-components'

const UsernameButtonText = styled.span`
  padding-right: 10px;
  text-transform: none;
`

const MenuBarDropDown = ({history, authState, doLogout}) => {
  const [ anchorEl, setAnchorEl ] = useState(null)
  const { currentUser, currentCompany, currentRole } = authState
  return (
    <>

      <Button
        color="inherit"
        onClick={event => setAnchorEl(event.currentTarget)}
        aria-owns={Boolean(anchorEl) ? 'simple-popper' : undefined}
        aria-haspopup="true"
      >
        <UsernameButtonText data-hj-suppress>
          Welcome back, <strong>{`${currentUser.firstName}`}</strong>!
        </UsernameButtonText>
        <Avatar>
          <PersonIcon />
        </Avatar>
      </Button>

      <Popover
        id="simple-popper"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Paper>
          <List>
            <ListItem>
              <Avatar style={{marginRight: 15}}>
                <PersonIcon />
              </Avatar>
              <ListItemText
                primary={`${currentUser.firstName} ${currentUser.lastName}`}
                secondary={
                  <>
                    <div>{currentCompany.name}</div>
                    <div>{currentUser.email}</div>
                  </>
                }
                data-hj-suppress
              />
            </ListItem>
          </List>
          <Divider />
          <List component="nav">

            {currentUser.isAdmin &&
              <ListItem button onClick={() => history.push('/selectRole') }>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary={`Role: ${currentRole}`} />
              </ListItem>
            }

            <ListItem button onClick={() => history.push('/settings') }>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItem>

            <ListItem button onClick={() => doLogout()}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Sign out" />
            </ListItem>

          </List>
        </Paper>
      </Popover>

    </>
  )
}

export default withRouter(MenuBarDropDown)
