import React, { useState } from 'react'
import { denormalize, schema } from 'normalizr'
import { connect } from 'react-redux'
import { Button } from '@material-ui/core'
import SaveCommentDialog from '../SaveCommentDialog'

const SaveSurveyButton = ({ appConfig, onSave }) => {
  const [showSaveCommentDialog, setShowSaveCommentDialog] = useState(false)

  const handleSubmit = (comment) => {
    const bucket = new schema.Entity('buckets')
    const answer = new schema.Entity('answers', { bucket })
    const question = new schema.Entity('questions', { answers: [answer] })
    const section = new schema.Entity('sections', { questions: [question] })

    const entities = {
      buckets: appConfig.buckets.byId,
      sections: appConfig.sections.byId,
      questions: appConfig.questions.byId,
      answers: appConfig.answers.byId
    }

    const sectionsIds = appConfig.sections.allIds
    const bucketsIds = appConfig.buckets.allIds
    const mySchema = { buckets: [ bucket ], sections: [ section ] }
    const result = denormalize({ buckets: bucketsIds, sections: sectionsIds }, mySchema, entities)

    result.comments = appConfig.comments
    onSave(result, comment)
    setShowSaveCommentDialog(false)
  }

  const buttonText = appConfig.savedConfig.saving
    ? 'Saving ...'
    : (appConfig.savedConfig.hasChanges ? 'Save config changes' : 'Save (no changes)')

  return(
    <div>
      <Button
        disabled={!appConfig.savedConfig.hasChanges || appConfig.savedConfig.saving}
        color={'primary'}
        variant='contained'
        onClick={() => setShowSaveCommentDialog(true)}
      >
        {buttonText}
      </Button>

      {showSaveCommentDialog &&
        <SaveCommentDialog
          onClose={() => setShowSaveCommentDialog(false)}
          onSave={(comment) => handleSubmit(comment)}
        />
      }

    </div>
  )
}

const mapStateToProps = state => ({
  appConfig: state.appConfig
})

export default connect(mapStateToProps)(SaveSurveyButton)
