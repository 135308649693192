import reduxStore from './reduxStore'

export const getHeadersForUserRole = () => {
  const currentRole = reduxStore.getState().auth.currentRole
  return {
    'x-boa-role': currentRole
  }
}

export const getUrlParameter = (name) => {
  const pairs = window.location.search.substring(1).split('&')
  for (let i = 0; i < pairs.length; i++) {
    const [key, value] = pairs[i].split('=', 2)
    if (key === name) {
      return value
    }
  }
  return null
}

export const pickBy = (item = {}, isTrue) => {
  return Object.entries(item).reduce((acc, [prop, value]) => {
    if(isTrue(value)) {
      acc[prop] = value
    }
    return acc
  }, {})
}

export const omit = (item = {}, propsToBeRemoved = []) => {
  let filteredItem = { ...item }
   
  propsToBeRemoved.forEach((prop, i) => {
    if(filteredItem[prop]) {
      delete filteredItem[prop]
    }
  })

 return filteredItem
}
