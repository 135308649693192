import React from 'react'
import * as Dialog from '../../../components/Dialog'
import DeleteIcon from '@material-ui/icons/Delete'

export default ({
  isDialogOpen, toggleDialog,
  deleteItem, idToBeDeleted
}) => {
  return (
    <Dialog.DialogContainer
      open={isDialogOpen}
      onClose={() => toggleDialog()}
      aria-labelledby="form-dialog-title"
    >
      <Dialog.FormTitle id="form-dialog-title"><DeleteIcon />Are you sure?</Dialog.FormTitle>
      <Dialog.DialogContentContainer>

        <Dialog.Actions>
          <Dialog.CancelButton variant="outlined" onClick={() => toggleDialog()}>
            Cancel
          </Dialog.CancelButton>
          <Dialog.SaveButton variant="outlined"
            onClick={() => deleteItem(idToBeDeleted)}
          >
            Delete
          </Dialog.SaveButton>
        </Dialog.Actions>

      </Dialog.DialogContentContainer>

    </Dialog.DialogContainer>
  )
}
