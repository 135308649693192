import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { API } from 'aws-amplify'
import { setErrorNotification } from '../../actions/notificationActions'

import TextField from '@material-ui/core/TextField'
import DialogContent from '@material-ui/core/DialogContent'

import { UnstyledActions, SmallDialog, FormTitle, CancelButton, SaveButton } from '../../components/Dialog'
import GenericTable from '../../components/GenericTable/GenericTable'
import LoadingSpinner from '../../components/LoadingSpinner'
import AdminMenuBar from '../AdminMenuBar'

const AdminUserCompaniesPage = ({ setErrorNotification }) => {
  const [companies, setCompanies] = useState(null)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [newCompanyName, setNewCompanyName] = useState('')

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    const companies = await API.get("dev-d1-apps-backend", '/userCompanies')
    companies.sort((a, b) => a.name.localeCompare(b.name))
    setCompanies(companies)
  }

  const createCompany = async () => {
    const newCompanies = [...companies]
    const companyIndex = newCompanies.findIndex((company) => {
      return company.name.toLowerCase() === newCompanyName.toLowerCase()
    })
    if(companyIndex === -1) {
      const result = await API.post("dev-d1-apps-backend", '/userCompanies', {
        body: {
          name: newCompanyName
        }
      })
      newCompanies.push(result)
      newCompanies.sort((a, b) => a.name.localeCompare(b.name))
      setCompanies(newCompanies)
      setIsDialogOpen(false)
      setNewCompanyName('')
    } else {
      setErrorNotification({
        title: 'Input Error',
        message: 'Company already exists.'
      })
    }
  }

  if(!companies) {
    return (
      <div>
        <AdminMenuBar activeTab="Companies" />
        <LoadingSpinner />
      </div>
    )
  }

  return (
    <div>
      <AdminMenuBar activeTab="Companies" />

      <div style={{margin: 25}}>
        <GenericTable
          title="Companies"
          initialSortColumn="name"
          columns={[
            {
              title: 'Name',
              key: 'name',
              type: 'string'
            }
          ]}
          data={companies}
          onItemClick={(row, columnKey) => {}}
          actionButtons={[
            {
              label: 'Create company',
              onClick: () => setIsDialogOpen(true)
            }
          ]}
          searchPlaceholder={'Search company'}
        />


        <SmallDialog
          open={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
        >
          <FormTitle>
            Create a new company
          </FormTitle>
          <DialogContent>
            <form
              method="post"
              onSubmit={event => { 
                event.preventDefault()
                createCompany()
              }}
            >
              <label htmlFor="companyName">Name</label>
              <TextField
                color="primary" variant="outlined"
                id="companyName"
                placeholder="Enter company name"
                value={newCompanyName}
                onChange={(event) => setNewCompanyName(event.target.value)}
                fullWidth
                autoFocus
              />
            </form>
            <UnstyledActions>
              <CancelButton variant="outlined" onClick={() => setIsDialogOpen(false)}>
                Cancel
              </CancelButton>
              <SaveButton variant="outlined"
                onClick={createCompany}
                disabled={!newCompanyName}
              >
                Save
              </SaveButton>
            </UnstyledActions>
          </DialogContent>
        </SmallDialog>

      </div>

    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  setErrorNotification: (error) => dispatch(setErrorNotification(error))
})

export default connect(null, mapDispatchToProps)(AdminUserCompaniesPage)
