import React, { Component } from 'react'
import { API } from 'aws-amplify'
import styled from 'styled-components'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import LoadingSpinner from '../../components/LoadingSpinner'
import AdminMenuBar from '../AdminMenuBar'
import Tabs from './Tabs'
import Button from '@material-ui/core/Button'
import {Table, Column, Cell} from 'fixed-data-table-2'
import Measure from 'react-measure'
import AddScraperDialog from './AddScraperDialog'
import ScraperConfigDialog from './ScraperConfigDialog'
import ScraperErrorDialog from './ScraperErrorDialog'

const SCRAPER_TYPES = [
  {name: 'Integrations', value:'integrations'},
  {name: 'Partners', value:'partners'},
  {name: 'Support pages', value:'supportPages'},
  {name: 'Release notes', value:'releaseNotes'}
]

const getBackgroundColorForStatus = (status) => {
  switch (status) {
    case 'ERROR':
      return '#ff6347'
    case 'WARNING':
      return '#ffb533'
    case 'SUCCESS':
      return '#99ff00'
    case 'NOT_MAPPED':
      return '#ffb533'
    case 'NO_RUN_YET':
      return '#66ccff'
    default:
      return 'transparent'
  }
}

const TimeAgo = styled.div`
  color: #555;
  font-size: 80%;
`

const Content = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  top: 105px;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
`

const InfoBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
`

const InfoIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 12px;
  height: 12px;
  font-size: 12px;
  line-height: 12px;
  border-radius: 10px;
  border: 1px solid black;
  text-align: center;
  display: none;
`

const CellValueContainer = styled.div`
  position: relative;
  :hover > div {
    display: block
  }
`

const CellValue = ({config, onInfoIconClick}) => {
  if(!config) {
    return null
  }

  let message = null
  switch (config.status) {
    case 'ERROR':
      message = <div>Error running scraper</div>
      break
    case 'NO_RUN_YET':
      message = <div>Requested, but no scraper run yet.</div>
      break
    case 'NOT_MAPPED':
      message = (
        <>
          <div>{`${config.noOfEntries} entries`}</div>
          <div>No mapping found.</div>
        </>
      )
      break
    default:
      message = (
        <>
          <div>{`${config.noOfEntries} entries`}</div>
          <div>{`${config.linesWithErrors} warnings`}</div>
        </>
      )
  }

  const onClick = (event) => {
    event.stopPropagation()
    onInfoIconClick()
  }

  return(
    <CellValueContainer>
      <InfoIcon onClick={onClick}>i</InfoIcon>
      {message}
      {config.datetime &&
        <TimeAgo>
          {moment.utc(config.datetime).fromNow()}
        </TimeAgo>
      }
    </CellValueContainer>
  )
}

class KnowledgebaseScrapersPage extends Component {

  constructor(props) {
    super(props)
    this.state = {
      configDialogContent: null,
      errorDialogContent: null,
      scrapers: null,
      notSuccessfulScrapers: 0,
      isAddScraperDialogOpen: false,
      dimensions: {
        width: -1,
        height: -1,
      }
    }
  }

  async componentDidMount() {
    await this.loadScrapers()
  }

  async loadScrapers() {
    let notSuccessfulScrapers = 0
    const [scraperConfig, scraperRuns, mappings, apps] = await Promise.all([
      API.get("d1-knowledgebase", '/scraperConfig'),
      API.get("d1-knowledgebase", '/scraperRuns'),
      API.get("d1-knowledgebase", '/mappings'),
      API.get("d1-knowledgebase", '/content/synonyms?type=App')
    ])
    const scrapersObject = scraperConfig.reduce((acc, cur) => {
      if(!acc[cur.appName]) {
        acc[cur.appName] = {
          appName: cur.appName
        }
      }
      const run = scraperRuns.find(run => run.scraperId === `${cur.appName}_${cur.scraperType}`)

      if(run) {
        const mapping = mappings.find(mapping => mapping.scraperId === `${cur.appName}_${cur.scraperType}`)
        const isMapped = mapping && mapping.config && Object.keys(mapping.config).length > 0
        const hasErrors = !!run.errors
        const hasWarnings = (run.linesWithErrors > 0)

        let status
        if(hasErrors) {
          status = 'ERROR'
        } else if(!isMapped) {
          status = 'NOT_MAPPED'
        } else if(hasWarnings) {
          status = 'WARNING'
        } else {
          status = 'SUCCESS'
        }

        acc[cur.appName][cur.scraperType] = { ...run, status}
        if(status !== 'SUCCESS') {
          notSuccessfulScrapers++
        }

      } else {
        acc[cur.appName][cur.scraperType] = { status: 'NO_RUN_YET' }
      }

      acc[cur.appName][cur.scraperType].url = cur.url
      acc[cur.appName][cur.scraperType].notes = cur.notes
      return acc
    }, {})

    const appNames = apps.map(app => app.name)
    appNames.sort()

    const scrapers = Object.values(scrapersObject)
    scrapers.sort((a, b) => a.appName.localeCompare(b.appName))
    this.setState({scrapers, notSuccessfulScrapers, appNames})
  }

  async addScraper(scraperConfig) {
    await API.post("d1-knowledgebase", '/scraperConfig', {
      body: scraperConfig
    })
    this.setState({isAddScraperDialogOpen: false})
    await this.loadScrapers()
  }

  handleCellClick(config) {
    switch (config.status) {
      case 'ERROR':
        this.setState({errorDialogContent: config.errors})
        break
      case 'WARNING':
      case 'SUCCESS':
      case 'NOT_MAPPED':
        this.props.history.push(`/admin/knowledgebase/mappings/${config.scraperRunId}`)
        break
      default:
        // do nothing
    }
  }

  render() {
    const scrapers = this.state.scrapers

    if(!scrapers) {
      return (
        <div>
          <AdminMenuBar activeTab="Knowledgebase" />
          <Tabs activeTab="Scrapers" />
          <LoadingSpinner />
        </div>
      )
    }

    return (
      <div>
        <AdminMenuBar activeTab="Knowledgebase" />
        <Tabs activeTab='Scrapers' />
        <Content>

          <InfoBar>
            <div>
              {`${this.state.notSuccessfulScrapers} scrapers not successful.`}
            </div>
            <Button size='small' variant="contained" color="primary" onClick={() => this.setState({isAddScraperDialogOpen: true})}>
              Add scraper
            </Button>
          </InfoBar>

          <Measure bounds onResize={contentRect => this.setState({dimensions: contentRect.bounds})}>
            {({ measureRef }) => (
              <div ref={measureRef} style={{flex: 1}}>
                <Table
                  rowHeight={80}
                  rowsCount={scrapers.length}
                  width={this.state.dimensions.width}
                  height={this.state.dimensions.height}
                  headerHeight={50}
                  isColumnResizing={false}
                >
                <Column
                  columnKey='appName'
                  header={<Cell>App name</Cell>}
                  width={200}
                  cell={({rowIndex, width, height}) => (
                    <Cell
                      width={width}
                      height={height}
                    >
                      {scrapers[rowIndex].appName}
                     </Cell>
                  )}
                />
                {SCRAPER_TYPES.map(type =>
                  <Column
                    key={type.value}
                    columnKey={type.value}
                    header={<Cell>{type.name}</Cell>}
                    width={200}
                    cell={({rowIndex, width, height}) => {
                      const config = scrapers[rowIndex][type.value]
                      if(!config) {
                        return(
                          <Cell width={width} height={height} />
                        )
                      }
                      const backgroundColor = getBackgroundColorForStatus(config.status)
                      return (
                        <Cell
                          style={{backgroundColor, cursor: 'pointer'}}
                          width={width}
                          height={height}
                          onClick={() => this.handleCellClick(config)}
                        >
                          <CellValue
                            config={config}
                            onInfoIconClick={
                              () => this.setState({configDialogContent: config.scraperId})
                            }
                          />
                        </Cell>
                      )
                    }}
                  />
                )}
                </Table>
              </div>
            )}
          </Measure>

        </Content>

        {this.state.isAddScraperDialogOpen &&
          <AddScraperDialog
            existingScrapers={scrapers}
            scraperTypes={SCRAPER_TYPES}
            appNames={this.state.appNames}
            onClose={() => this.setState({isAddScraperDialogOpen: false})}
            onSave={scraperConfig => this.addScraper(scraperConfig)}
          />
        }

        {this.state.configDialogContent &&
          <ScraperConfigDialog
            scraperId={this.state.configDialogContent}
            onClose={() => this.setState({configDialogContent: null})}
          />
        }

        {this.state.errorDialogContent &&
          <ScraperErrorDialog
            content={this.state.errorDialogContent}
            onClose={() => this.setState({errorDialogContent: null})}
          />
        }

      </div>
    )
  }

}

export default withRouter(KnowledgebaseScrapersPage)
