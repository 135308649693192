import uuid from 'uuid/v4'

export const addAnswer = (questionId) => ({
  type: 'ADD_ANSWER',
  payload: {
    answerId: uuid(),
    questionId
  }
})

export const receiveAnswers = answers => ({
  type: 'RECEIVE_ANSWERS',
  payload: {
    answers
  }
})

export const updateAnswer = (id, title, tooltip, ballTooltip, noteForReport, buckets) => ({
  type: 'UPDATE_ANSWER',
  payload: {
    id, title, tooltip, ballTooltip, noteForReport, buckets
  }
})

export const deleteAnswer = id => ({
  type: 'DELETE_ANSWER',
  payload: { id }
})
