import React from 'react'
import Table from './Table'
import { roundAndFormatNumber } from './util'

export default ({foundApps, handleAppNameClick}) => {
  return (
    <>
    {foundApps.map(category =>
      <div key={category.name}>
        <div style={{fontWeight: 'bold', marginTop: '10px'}}>
          {category.name}
        </div>
        <div style={{paddingLeft: '10px'}}>
          <Table>
            <tbody>
              {category.apps.map(app =>
                <tr key={app.name}>
                  <td
                    onClick={() => handleAppNameClick(app.name)}
                    style={{cursor: 'pointer'}}
                  >
                    {app.name} ({app.transactions})
                  </td>
                  <td>{roundAndFormatNumber(app.amount)}</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    )}
    </>
  )
}
