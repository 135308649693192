import React from 'react'
import styled from 'styled-components'
import Bucket from './Bucket'
import ProgressPanel from './ProgressPanel'

const Container = styled.div`
  background-color: #171820;
  color: #fff;
  margin-top: 20px;
  padding: 20px 0;
  width: 100%;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 1145px;
    margin: 0 auto;
  }
`

export default ({buckets, balls, changeTab, questionsAnswered, questionsTotal, hasHiddenSections, saveAnswers, downloadReport}) =>
  <Container>
    <Content>
      {buckets.map(bucket =>
        <Bucket
          key={bucket.id}
          title={bucket.title}
          balls={balls.filter(ball => !!ball.answer.buckets.find(b => b === bucket.id))}
          changeTab={changeTab}
        />
      )}

      <ProgressPanel
        questionsAnswered={questionsAnswered}
        questionsTotal={questionsTotal}
        hasHiddenSections={hasHiddenSections}
        saveAnswers={saveAnswers}
        downloadReport={downloadReport}
        buckets={buckets}
      />

    </Content>
  </Container>
