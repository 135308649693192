import React from 'react'
import styled from 'styled-components'
import PersonIcon from '@material-ui/icons/Person'
import StarIcon from '@material-ui/icons/Star'
import SettingsIcon from '@material-ui/icons/Settings'
import ReactMarkdown from 'react-markdown'


const Tabbar = styled.div`
  height: 35px;
  background-color: #F1F1F1;
  border-bottom: 1px solid #BBB;
  display: flex;
`

const TabbarItem = styled.div`
  cursor: pointer;
  flex: 1;
  text-align: center;
  border-right: 1px solid #BBB;
  padding-top: 5px;
  ${props => props.active && `
    border-bottom: 1px solid #1164A3;
  `}
`

const TextLineContent = styled.div`
  p {
    margin: 10px;
  }
  a {
    color: #0084ff;
  }
`


const TextLine = ({line}) =>
  <TextLineContent>
    <ReactMarkdown
      source={line.text}
      linkTarget='_blank'
    />
  </TextLineContent>



export default class Sidepanel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0
    }
  }

  selectTab(index) {
    this.setState({activeTab: index})
  }

  render() {
    return(
      <>
        <Tabbar>
          <TabbarItem onClick={() => this.selectTab(0)} active={this.state.activeTab === 0}>
            <StarIcon />
          </TabbarItem>
          <TabbarItem onClick={() => this.selectTab(1)} active={this.state.activeTab === 1}>
            <SettingsIcon />
          </TabbarItem>
          <TabbarItem onClick={() => this.selectTab(2)} active={this.state.activeTab === 2}>
            <PersonIcon />
          </TabbarItem>
        </Tabbar>

        <div style={{flex: 1, overflow: 'auto'}}>

          {this.state.activeTab === 0 && this.props.savedLines.map(line =>
            <TextLine line={line} />
          )}

        </div>
      </>
    )
  }
}
