import React from 'react'
import moment from 'moment'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button'
import Radio from '@material-ui/core/Radio'
import LoadingSpinner from '../../components/LoadingSpinner'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

const DATE_FORMAT = 'DD.MM.YYYY HH:mm'

export default class CreateClientDialog extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      selectedVersion: null
    }
  }

  getName(userId) {
    /* INFO: We assume that everyone who edits the app config belongs to the same company */
    const user = this.props.users.find(user => user.userId === userId)
    return user ? `${user.firstName} ${user.lastName}` : ' - '
  }

  render() {
    return(
      <Dialog
        open={true}
        onClose={() => this.props.onClose()}
      >
        <DialogTitle>
          App config versions
        </DialogTitle>
        <DialogContent>

          {!this.props.versions &&
            <LoadingSpinner />
          }

          {this.props.versions &&
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell> </TableCell>
                  <TableCell>Created</TableCell>
                  <TableCell>User</TableCell>
                  <TableCell>Comment</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.versions.map((version, index) =>
                  <TableRow key={version.createdAt}>
                    <TableCell>
                      {index > 0 &&
                        <Radio
                          color="primary"
                          checked={this.state.selectedVersion === `${version.createdAt}`}
                          onChange={event => this.setState({selectedVersion: event.target.value})}
                          value={version.createdAt}
                          name="radio-button-createdAt"
                        />
                      }
                    </TableCell>
                    <TableCell>
                      {moment(version.createdAt).format(DATE_FORMAT)}
                    </TableCell>
                    <TableCell>
                      {this.getName(version.userId)}
                    </TableCell>
                    <TableCell>
                      {version.comment}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          }

        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.props.onClose()} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => this.props.onRestore(this.state.selectedVersion)}
            color="primary"
            variant="contained"
            disabled={!this.state.selectedVersion || this.props.saving}
          >
            {this.props.saving ? 'Restoring ...' : 'Restore'}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

}
