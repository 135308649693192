import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Grid from '@material-ui/core/Grid'
import Section from './Section'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

const SortableItem = SortableElement((props) => <Section {...props} />)

const SortableList = SortableContainer(({ sections, onClick, expanded, expandedAll }) => {
  return (
    <div>
      {sections.map((id, index) => (
        <SortableItem
          key={`item-${index}`}
          index={index}
          onClick={onClick}
          expanded={expanded}
          expandedAll={expandedAll}
          id={id}
        />
      ))}
    </div>
  )
})

class SectionsEditor extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      expanded: null,
      expandedAll: true
    }
  }

  toggleExpandedAll = () => {
    const expandedAll = !this.state.expandedAll
    this.setState({ expandedAll })
  }

  handleClick = (sectionId) => {
    const newExpanded = this.state.expanded === sectionId ? null : sectionId
    this.setState({ expanded: newExpanded })
  }

  render () {
    return (
      <Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={this.state.expandedAll}
                onChange={this.toggleExpandedAll}
                color='primary'
              />
            }
            label='Expand all'
          />
        </Grid>
        <Grid item xs={12}>
          <SortableList
            {...this.props}
            expanded={this.state.expanded}
            expandedAll={this.state.expandedAll}
            distance={20}
            onClick={this.handleClick}
          />
        </Grid>
      </Grid>
    )
  }
}

export default SectionsEditor
