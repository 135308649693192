
const INITIAL_STATE = {
  appId: null,
  config: null,
  hasChanges: false,
  saving: false,
  versions: null,
  versionsUsers: null
}

export default (state = INITIAL_STATE, action) => {

  switch (action.type) {
    case 'LOAD_APP_CONFIG':
      return INITIAL_STATE
    case 'LOAD_APP_CONFIG_SUCCESS':
      return { ...state, ...action.payload}
    case 'LOAD_APP_CONFIG_ERROR':
      return { ...state, ...action.payload}

    case 'LOAD_APP_CONFIG_VERSIONS':
      return { ...state, versions: null }
    case 'LOAD_APP_CONFIG_VERSIONS_SUCCESS':
      return { ...state, ...action.payload}
    case 'LOAD_APP_CONFIG_VERSIONS_ERROR':
      return { ...state, ...action.payload}

    case 'UPDATE_APP_CONFIG':
      return { ...state, saving: true}
    case 'UPDATE_APP_CONFIG_SUCCESS':
      return { ...state, saving: false, hasChanges: false, ...action.payload}
    case 'UPDATE_APP_CONFIG_ERROR':
      return { ...state, saving: false}

    case 'UPDATE_ANSWER':
    case 'DELETE_ANSWER':
    case 'ADD_ANSWER':
    case 'UPDATE_BUCKET':
    case 'DELETE_BUCKET':
    case 'ADD_BUCKET':
    case 'UPDATE_COMMENTS':
    case 'UPDATE_QUESTION':
    case 'DELETE_QUESTION':
    case 'REMOVE_ANSWER_FROM_QUESTION':
    case 'SET_QUESTION_ANSWERS_ORDER':
    case 'ADD_QUESTION':
    case 'UPDATE_SECTION':
    case 'DELETE_SECTION':
    case 'ADD_SECTION':
    case 'REMOVE_QUESTION_FROM_SECTION':
    case 'ADD_QUESTION_TO_SECTION':
    case 'SET_SECTION_QUESTIONS_ORDER':
    case 'SET_SECTIONS_ORDER':
      return { ...state, hasChanges: true}

    default:
      return state
  }
}
