import React from 'react'
import { updateComments } from './actions/commentsActions'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import CommentsForm from './CommentsForm'

const CommentsEditor = ({ comments, onSave }) => {
  if(!comments) {
    return null
  }
  return (
    <div>
      <Typography variant='h5' style={{marginTop: '30px', marginBottom: '5px'}}>
        Comments for report
      </Typography>
      <Paper style={{padding: 20}}>
        <CommentsForm
          comments={comments}
          onSave={onSave}
        />
      </Paper>
    </div>
  )
}

const mapStateToProps = (state) => ({
  comments: state.appConfig.comments
})

const mapDispatchToProps = (dispatch) => ({
  onSave: (noInfoForSection) => dispatch(updateComments(noInfoForSection))
})

export default connect(mapStateToProps, mapDispatchToProps)(CommentsEditor)
