import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import IconButton from '@material-ui/core/IconButton'

const IconBtnContainer = withStyles({
  root: {
    color: '#da9b31',
    marginLeft: '15px'
  }
})(IconButton)

export default ({onClick}) =>
  <IconBtnContainer onClick={onClick}>
    <AddIcon />
  </IconBtnContainer>
