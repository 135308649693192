import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import LoadingSpinner from '../../components/LoadingSpinner'

export default () =>
  <Dialog open={true}>
    <DialogTitle>
      Import running ...
    </DialogTitle>
    <DialogContent>
      <LoadingSpinner />
    </DialogContent>
  </Dialog>
