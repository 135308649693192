export default {
  Auth: {
    mandatorySignIn: true,
    region: 'ap-southeast-2',
    userPoolId: 'ap-southeast-2_KdudNuI2F',
    identityPoolId: 'ap-southeast-2:03b22c82-813c-414f-9a5c-0f12808ab05c',
    userPoolWebClientId: '5jo21lgmoleolkml673jn2hnlm'
  },
  API: {
    endpoints: [
      {
        name: 'dev-d1-apps-backend',
        endpoint: 'https://tfn48pb0ei.execute-api.ap-southeast-2.amazonaws.com/prod/v1',
        region: 'ap-southeast-2'
      },
      {
        name: 'd1-knowledgebase',
        endpoint: 'https://tfn48pb0ei.execute-api.ap-southeast-2.amazonaws.com/prod/knowledgebase',
        region: 'ap-southeast-2'
      },
      {
        name: 'default',
        endpoint: 'https://tfn48pb0ei.execute-api.ap-southeast-2.amazonaws.com/prod',
        region: 'ap-southeast-2'
      }
    ]
  },
  Interactions: {
    bots: {
      "BOA": {
        "name": "BOA",
        "alias": "$LATEST",
        "region": "us-east-1",
      },
    }
  }
}
