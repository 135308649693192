import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import UserMessage from './UserMessage'
import {
  getConversation,
  getConversations,
  createConversation
} from '../../actions/chatConversationsActions'

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const Searchbox = styled.div`
  padding: 10px;
  background-color: #F1F1F1;
  border-bottom: 1px solid #BBB;
  display: flex;
  input {
    margin-right: 10px;
    box-sizing: border-box;
    width: 100%;
    font-size: 14px;
    padding: 8px;
    outline: none;
    border: 1px solid #BBB;
    background-color: #FFF;
    border-radius: 5px;
    ::placeholder {
      color: #AAA;
    }
  }
`

const ChatFooter = styled.div`
  display: flex;
  border-top: 1px solid #CCC;
  padding: 5px;

  input {
    flex: 1;
    font-size: 16px;
    padding: 10px;
    outline: none;
    border: none;
    ::placeholder {
      color: #AAA;
    }
  }
`

const List = styled.div`
  flex: 1;
  overflow: auto;
`


const MenuItem = styled.div`
  color: #0084ff;
  background-color: white;
  border-top: 1px solid #CCC;
  cursor: pointer;
  padding: 15px;
`

const MENU_ITEMS = [
  "Pin to client record",
  "Add company guidelines",
  "Forward chat to firm champion",
  "Forward chat to D1 expert",
  "Email conversation"
]

class ConversationsList extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      searchText: '',
      isMenuOpen: false,
      currentText: '',
      lastText: ''
    }
  }

  openMenu() {
    this.setState({isMenuOpen: !this.state.isMenuOpen})
  }

  closeMenu() {
    this.setState({isMenuOpen: false})
  }

  showPreviousText() {
    this.setState({currentText: this.state.lastText})
  }

  componentDidMount() {
    this.props.getConversations()
  }

  postConversation() {
    this.props.createConversation(this.state.searchText)
    this.setState({searchText: ''})
  }

  async send({event, text}) {
    if(event) {
      event.preventDefault()
    }

    const currentText = text || this.state.currentText
    this.props.createConversation(this.state.currentText)

    this.setState({
      lastText: currentText,
      currentText: ''
    })
  }

  render() {

    const conversations = this.props.chatConversations.data
    conversations.sort((a, b) => (a.createdAt < b.createdAt) ? -1 : 1)

    return(
      <Container>

        {false && <Searchbox>
          <input
            placeholder='Search'
            value={this.state.searchText}
            onChange={event => this.setState({searchText: event.target.value})}
          />
          <Button variant="contained" size="small" onClick={() => this.postConversation()}>
            Post
          </Button>
        </Searchbox>
        }

        <List>
          {conversations.map(c =>
            <UserMessage
              key={c.conversationId}
              message={{createdAt: c.createdAt, lines: [{text: c.title}]}}
              onClick={() => this.props.getConversation(c.conversationId)}
              selected={this.props.chatConversation.data && this.props.chatConversation.data.conversationId === c.conversationId}
            />
          )}
        </List>




        <form onSubmit={event => this.send({event})}>
          <ChatFooter>

            <div style={{position: 'relative'}}>
              <ClickAwayListener onClickAway={() => this.closeMenu()}>
                <div>
                  <IconButton onClick={() => console.log('do nothing') /*this.openMenu()*/}>
                    <MenuIcon />
                  </IconButton>
                  {this.state.isMenuOpen &&
                    <Paper elevation={10} style={{position: 'absolute', bottom: '50px', left: '5px', width: '300px', border: '1px solid #CCC'}}>
                      <div style={{padding: '15px', fontWeight: 'bold', textAlign: 'center'}}>Menu</div>
                      {MENU_ITEMS.map(item =>
                        <MenuItem onClick={() => this.closeMenu()}>
                          {item}
                        </MenuItem>
                      )}
                      <MenuItem onClick={() => {this.closeMenu(); this.showInitialSuggestions()}}>
                        Show questions you can ask
                      </MenuItem>
                    </Paper>
                  }
                </div>
              </ClickAwayListener>
            </div>

            <input
              autoFocus
              placeholder='Create new conversation'
              value={this.state.currentText}
              onChange={event => this.setState({currentText: event.target.value})}
              onKeyDown={event => event.keyCode === 38 && this.showPreviousText()}
            />
          </ChatFooter>
        </form>


      </Container>
    )
  }

}


const mapStateToProps = (state) => ({
  chatConversations: state.chatConversations,
  chatConversation: state.chatConversation,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getConversation: (conversationId) => dispatch(getConversation(conversationId)),
  createConversation: (title) => dispatch(createConversation(title)),
  getConversations: () => dispatch(getConversations())
})

export default connect(mapStateToProps, mapDispatchToProps)(ConversationsList)
