import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { API } from 'aws-amplify'

import TableRowActionsDropDown from '../components/TableRowActionsDropDown'
import LoadingSpinner from '../components/LoadingSpinner'
import GenericTable from '../components/GenericTable/GenericTable'
import CreateUserDialog from './CreateUserDialog'
import UpdateUserDialog from './UpdateUserDialog'
import UserStatus from './UserStatus'

const sortUsers = (users) => {
  const result = [ ...users ]
  result.sort((u1, u2) => {
    return `${u1.lastName} ${u1.firstName}`.toLowerCase().localeCompare(`${u2.lastName} ${u2.firstName}`.toLowerCase())
  })
  return result
}

const UserList = ({isAdminArea, currentUserCompanyId, currentUserRole}) => {

  const [users, setUsers] = useState()
  const [companies, setCompanies] = useState()
  const [isCreateDialogOpen, setCreateDialogOpen] = useState(false)
  const [updateDialogSelectedUser, setUpdateDialogSelectedUser] = useState()

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    const [usersResult, companies] = await Promise.all([
      API.get("dev-d1-apps-backend", '/users'),
      API.get("dev-d1-apps-backend", '/userCompanies')
    ])
    const users = usersResult.map(user => {
      const company = companies.find(company => company.companyId === user.companyId)
      return ({
        ...user,
        companyName: company.name
      })
    })
    setUsers(sortUsers(users))
    setCompanies(companies)
  }

  const onDeactivate = async (userId) => {
    await API.del("dev-d1-apps-backend", '/users/' + userId)
    const newUsers = users.map(u => {
      if(u.userId === userId) {
        u.enabled = false
      }
      return u
    })
    setUsers(newUsers)
  }

  const onResendInvitation = async (email) => {
    await API.post("dev-d1-apps-backend", '/users', {
      body: {
        email,
        resendInvitation: true
      }
    })
  }

  const onCloseCreateDialog = async (userData) => {
    if(userData) {
      const newUser = {
        ...userData,
        companyName: companies.find(c => c.companyId === userData.companyId).name
      }
      const newUsers = sortUsers([...users, newUser])
      setUsers(newUsers)
    }
    setCreateDialogOpen(false)
  }

  const onCloseUpdateDialog = (updatedUser) => {
    if(updatedUser) {
      const newUsers = users.map(u => {
        if(u.userId === updatedUser.userId) {
          u.firstName = updatedUser.firstName
          u.lastName = updatedUser.lastName
        }
        return u
      })
      setUsers(sortUsers(newUsers))
    }
    setUpdateDialogSelectedUser(null)
  }

  const getAdminStatus = (user) => {
    if(user.isAdmin) {
      return 'super admin'
    } else if(user.isOrgAdmin) {
      return 'yes'
    } else {
      return 'no'
    }
  }

  const getActionsForUser = (user) => {
    const actions = []
    if(user.userStatus === 'FORCE_CHANGE_PASSWORD') {
      actions.push({
        label: 'Resend invitation email',
        onClick: () => onResendInvitation(user.email)
      })
    }
    actions.push({
      label: 'Edit',
      onClick: () => setUpdateDialogSelectedUser(user)
    })
    if(user.enabled) {
      actions.push({
        label: 'Deactivate',
        onClick: () => onDeactivate(user.userId)
      })
    }
    return actions
  }

  if(!users || !companies) {
    return (
      <LoadingSpinner />
    )
  }

  let columns = [
    {
      title: 'First Name',
      key: 'firstName',
      type: 'string'
    },
    {
      title: 'Last Name',
      key: 'lastName',
      type: 'string'
    },
    {
      title: 'Email',
      key: 'email',
      type: 'string'
    },
    {
      title: 'Company',
      key: 'companyName',
      type: 'string'
    },
    {
      title: 'Status',
      key: 'status',
      type: 'string'
    },
    {
      title: 'Is Admin?',
      key: 'role',
      type: 'string'
    },
    {
      title: 'Actions',
      key: 'actions',
    }
  ]

  const tableData = users.map(user => {
    let newUser = {
      ...user,
      status: {
        value: user.enabled ? user.userStatus : 'DEACTIVATED',
        component: <UserStatus enabled={user.enabled} status={user.userStatus} />
      },
      role: getAdminStatus(user),
      actions: {
        value: 'Actions',
        component: <TableRowActionsDropDown actions={getActionsForUser(user)} />
      }
    }

    if(currentUserRole === 'USER') {
      delete newUser.companyName
      columns = columns.filter(column => column.title !== 'Company')
    }
    return newUser
  })

  return (
    <div>
      <GenericTable
        title="Users"
        initialSortColumn="lastName"
        columns={columns}
        data={tableData}
        onItemClick={(row, columnKey) => {}}
        actionButtons={[
          {
            label: 'Create user',
            onClick: () => setCreateDialogOpen(true),
            disabled: false
          }
        ]}
        searchPlaceholder={'Search user'}
      />

      <CreateUserDialog
        isOpen={isCreateDialogOpen}
        onClose={(userData) => onCloseCreateDialog(userData)}
        companies={companies}
        fixedCompanyId={!isAdminArea && currentUserCompanyId}
      />

      {updateDialogSelectedUser &&
        <UpdateUserDialog
          onClose={(updatedUser) => onCloseUpdateDialog(updatedUser)}
          user={updateDialogSelectedUser}
        />
      }

    </div>
  )
}

const mapStateToProps = (state) => ({
  currentUserCompanyId: state.auth.currentUser.companyId,
  currentUserRole: state.auth.currentRole
})

export default connect(mapStateToProps)(UserList)
