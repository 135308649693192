import React, { useState } from 'react'
import { API } from 'aws-amplify'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Select from '@material-ui/core/Select'

export default ({isOpen, onClose, companies, fixedCompanyId}) => {

  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [companyId, setCompanyId] = useState(fixedCompanyId || null)
  const [isOrgAdmin, setOrgAdmin] = useState(false)
  const [errorMessage, setErrorMessage] = useState()

  const createUser = async () => {
    try {
      const result = await API.post("dev-d1-apps-backend", '/users', {
        body: {
          email: email.trim(),
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          companyId,
          isOrgAdmin,
        }
      })
      onClose(result)
      setEmail('')
      setFirstName('')
      setLastName('')
      setOrgAdmin(false)
      setErrorMessage()
    } catch (err) {
      setErrorMessage(err.response.data.errorMessage)
    }
  }

  const isFormValid = (email.length > 0) && (firstName.length > 0) && (lastName.length > 0) && !!companyId

  return(
    <Dialog
      open={isOpen}
      onClose={() => onClose()}
    >
      <DialogTitle>
        Create a new user
      </DialogTitle>

      <DialogContent>

        <div style={{color: 'red'}}>
          {errorMessage}
        </div>

        <form
          method="post"
          onSubmit={event => {
            event.preventDefault()
            createUser()
          }}
        >
          <TextField
            margin="dense"
            label="Email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            fullWidth
            autoFocus
          />
          <TextField
            margin="dense"
            label="First name"
            value={firstName}
            onChange={(event) => setFirstName(event.target.value)}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Last name"
            value={lastName}
            onChange={(event) => setLastName(event.target.value)}
            fullWidth
          />

        {!fixedCompanyId &&
            <FormControl style={{width: '100%'}}>
              <InputLabel htmlFor="company-id">Company</InputLabel>
              <Select
                value={companyId}
                onChange={(event) => setCompanyId(event.target.value)}
                inputProps={{
                  name: 'companyId',
                  id: 'company-id',
                }}
              >
                {companies.map(company =>
                  <MenuItem key={company.companyId} value={company.companyId}>
                    {company.name}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          }

          <FormControlLabel
            style={{padding: '20px 10px'}}
            control={
              <input type="checkbox"
                checked={isOrgAdmin}
                onChange={event => setOrgAdmin(event.target.checked)}
                value={`${isOrgAdmin}`}
              />
            }
            label="This user is admin within their company"
          />

        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => createUser()}
          disabled={!isFormValid}
          color="primary"
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
