import React from 'react'
import styled from 'styled-components'
import Filters from './Filters'
import GroupBy from './GroupBy'

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  transition: right 0.5s ease-in-out;
  width: 250px;
  background-color: #EEE;
  border: 1px solid #CCC;
  padding: 5px;
  z-index: 100;
`

const CloseLink = styled.div`
  position: absolute;
  top: 5px;
  left: -20px;
  width: 18px;
  height: 30px;
  line-height: 30px;
  background-color: #EEE;
  border: 1px solid #CCC;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
`

export default (props) => {

  const {
    visible,
    onClose,
    synonyms,
    schema,
    selectedTable,
    filters,
    onChangeFilters,
    groupedField,
    onChangeGroupedField
  } = props

  return(
    <Container style={{right: visible ? '0': '-300px'}}>
      <CloseLink onClick={onClose}>▶</CloseLink>

      <Filters
        synonyms={synonyms}
        schema={schema}
        selectedTable={selectedTable}
        filters={filters}
        onChange={onChangeFilters}
      />

      <GroupBy
        columns={schema.find(x => x.table === selectedTable).columns}
        groupedField={groupedField}
        onChange={onChangeGroupedField}
      />

    </Container>
  )
}
