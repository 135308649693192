import { combineReducers } from 'redux'
import { omit } from '../../../../utils'

const answersById = (state = {}, action) => {
  switch (action.type) {
    case 'RECEIVE_ANSWERS':
      return action.payload.answers
    case 'UPDATE_ANSWER':
      return {
        ...state,
        [action.payload.id]: {
          id: action.payload.id,
          title: action.payload.title,
          tooltip: action.payload.tooltip,
          ballTooltip: action.payload.ballTooltip,
          noteForReport: action.payload.noteForReport,
          buckets: action.payload.buckets
        }
      }
    case 'DELETE_ANSWER':
      return omit(state, [action.payload.id])
    case 'ADD_ANSWER':
      return {
        ...state,
        [action.payload.answerId]: {
          id: action.payload.answerId,
          title: '',
          tooltip: '',
          ballTooltip: '',
          noteForReport: '',
          buckets: []
        }
      }
    default:
      return state
  }
}
export default combineReducers({
  byId: answersById
})
