import React, { useState } from 'react'
import DialogContent from '@material-ui/core/DialogContent'
import * as Dialog from '../../components/Dialog'
import { withStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'

const CheckboxLabel = withStyles({
  root: {
    marginLeft: 0,
    verticalAlign: 'baseline',
    '& span': {
      fontSize: '16px',
      paddingLeft: '10px'
    }
  }
})(FormControlLabel)

export default ({isOpen, onClose, onSave, detectionKeyword}) => {

  const [unassignFromSameDescription, setUnassignFromSameDescription] = useState(false)
  const [addKeywordToIgnore, setAddKeywordToIgnore] = useState(false)
  const [manualOverrideReason, setManualOverrideReason] = useState('')

  return (
    <Dialog.CenteredDialog
      open={isOpen}
      onClose={() => onClose()}
      aria-labelledby="form-dialog-title"
    >
      <Dialog.FormTitle id="form-dialog-title">
        Deselect app from this transaction
      </Dialog.FormTitle>
      <DialogContent>

        <p>
          <TextField
            type="text"
            variant="outlined"
            placeholder="Reason for deselecting this transaction"
            value={manualOverrideReason}
            onChange={event => setManualOverrideReason(event.target.value)}
            fullWidth
          />
        </p>

        <p>
          <CheckboxLabel
            label="Deselect this app also from transactions with the same description in this account."
            control={
              <input type="checkbox"
                checked={unassignFromSameDescription}
                onChange={event => setUnassignFromSameDescription(event.target.checked)}
              />
            }
          />
        </p>

        {detectionKeyword &&
          <p>
            <CheckboxLabel
              label={`Don't link transactions containing "${detectionKeyword}" to an app in future data imports.`}
              control={
                <input type="checkbox"
                  checked={addKeywordToIgnore}
                  onChange={event => setAddKeywordToIgnore(event.target.checked)}
                />
              }
            />
          </p>
        }

        <Dialog.UnstyledActions>
          <Dialog.CancelButton
            variant="outlined"
            onClick={() => onClose()}
          >
            Cancel
          </Dialog.CancelButton>
          <Dialog.SaveButton variant="outlined"
            onClick={() => onSave({unassignFromSameDescription, addKeywordToIgnore, manualOverrideReason})}
          >
            Save
          </Dialog.SaveButton>
        </Dialog.UnstyledActions>

      </DialogContent>

    </Dialog.CenteredDialog>
  )
}
