import { OTHER_CATEGORY_NAME } from './util'

export default ({config, foundAppsByCategory, totalIncome}) => {

  // TODO optimize this, remove this foundApps and filter by categories later
  const foundApps = foundAppsByCategory.reduce((acc, cur) => {
    return acc.concat(cur.apps)
  }, [])

  let hasEmptyCategories = false
  const resultConfig = JSON.parse(JSON.stringify(config))
  resultConfig.bands.forEach(band => {
    let annualRevenue = 0
    // TODO refactor this app-category-filter, because same logic is used for `secondarySection`
    band.primarySection && band.primarySection.categories.forEach(c => {
      c.apps = foundApps
        .filter(app => {
          const isMatch = (app.category === c.name) || (!app.category && c.name === OTHER_CATEGORY_NAME)
          if(isMatch) {
            annualRevenue = annualRevenue + app.amount
          }
          return isMatch
        })
      if(c.apps.length === 0) {
        hasEmptyCategories = true
      }
    })
    band.secondarySection && band.secondarySection.categories.forEach(c => {
      c.apps = foundApps
        .filter(app => {
          const isMatch = (app.category === c.name) || (!app.category && c.name === OTHER_CATEGORY_NAME)
          if(isMatch) {
            annualRevenue = annualRevenue + app.amount
          }
          return isMatch
        })
      if(c.apps.length === 0) {
        hasEmptyCategories = true
      }
    })
    band.annualRevenue = annualRevenue
    band.annualRevenuePercentage = (totalIncome > 0) ? (annualRevenue * 100 / totalIncome) : null
  })

  return {
    config: resultConfig,
    hasEmptyCategories
  }
}
