
import Paper from '@material-ui/core/Paper'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

import { withStyles } from '@material-ui/core/styles'

export const TableContainer = withStyles({
  root: {
    boxShadow: 'none',
    border: '1px solid #e0e0e0',
    borderRadius: '20px',
    fontSize: '14px',
    marginTop: '10px',
    marginBottom: '20px'
  }
})(Paper)

export const ClickableTableRow = withStyles({
  root: {
    cursor: 'pointer',
    '&:last-of-type td': {
      borderBottom: 'none'
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  }
})(TableRow)

export const TableHeaderCell = withStyles({
  root: {
    cursor: 'pointer',
    fontSize: '14px',
    padding: '12px 10px',
    '&:last-child': {
      paddingRight: '10px'
    }
  }
})(TableCell)

export const TableStandardCell = withStyles({
  root: {
    fontSize: '16px',
    padding: '12px 10px',
    '& a': {
      color: '#000',
      textDecoration: 'none',
      '&.contentLink': {
        color: '-webkit-link',
        textDecoration: 'underline',
      },
    },
    '&:last-child': {
      paddingRight: '10px'
    }
  }
})(TableCell)