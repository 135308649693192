import React from 'react'
import { API } from 'aws-amplify'
import styled from 'styled-components'
import Dropzone from 'react-dropzone'
import axios from 'axios'

const DOCX_CONTENT_TYPE = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'

const DropArea = styled.p`
  background-color: #3f51b5;
  color: white;
  cursor: pointer;
  padding: 10px;
  text-align: center;
`

export default class DocxUpload extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      uploadProgress: null
    }
  }

  handleUploadProgress(event) {
    // We do "-1" here, so that it stays at 99% for a bit.
    // Because there is a delay between the "100%" event and when the "axios.put" call returns.
    // If we show 99%, then the user has the impression that it is not yet finihsed,
    // even it is actually at 100%. (But the axios call has not yet returned).
    const uploadProgress = Math.round(event.loaded * 100 / event.total) - 1
    this.setState({uploadProgress})
  }

  async onDrop(files) {
    this.setState({uploadProgress: 1})
    const file = files[0]
    const result = await API.post("dev-d1-apps-backend", '/reportTemplates', {
      body: {
        appName: this.props.appName,
        bucketIndex: this.props.bucketIndex
      }
    })

    const signedUrl = result.signedUrl
    const options = {
      headers: {
        'Content-Type': DOCX_CONTENT_TYPE
      },
      onUploadProgress: (event) => this.handleUploadProgress(event)
    }

    await axios.put(signedUrl, file, options)
    this.props.onUploaded(result.filename)
    this.setState({uploadProgress: 100})
  }

  getDropAreaText() {
    if(!this.state.uploadProgress) {
      return 'Click or drop file'
    } else if(this.state.uploadProgress === 100) {
      return 'Done'
    } else {
      return (this.state.uploadProgress + '% uploaded')
    }
  }

  render() {
    return (
      <Dropzone
        onDrop={files => this.onDrop(files)}
        accept={DOCX_CONTENT_TYPE}
      >
        {({getRootProps, getInputProps, isDragActive}) =>
          <div {...getRootProps()} >
            <input {...getInputProps()} />
            <DropArea>
              {this.getDropAreaText()}
            </DropArea>
          </div>
        }
      </Dropzone>
    )
  }

}
