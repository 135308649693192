import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import styled, { css } from 'styled-components'
import PersonIcon from '@material-ui/icons/PersonOutlined'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import AutoSaveMessage from './AutoSaveMessage'
import EditIcon from '@material-ui/icons/EditOutlined'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'

const lightGreenIcon = css`
  color: #a9cf54;
  margin-right: 10px;
`;

const BgIconButton = withStyles({
  root: {
    width: '80px',
    backgroundColor: '#292c46',
    borderRadius: 0,
    height: '100%',
    color: '#fff',
    '&:hover': {
      color: '#da9b31',
    }
  }
})(IconButton)

const PersonOutlinedIcon = styled(PersonIcon)`
  ${lightGreenIcon}
`

const RightArrowIcon = styled(KeyboardArrowRightIcon)`
  ${lightGreenIcon}
  margin-right: 0;
`

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  p {
    cursor: pointer;
  }
`

const FlexParentStretch = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 18px;
  align-items:stretch;

  > div {
    width: 32.5%;
    padding-left: 20px;
    background-color: #292c46;

    &:nth-child(3) {
      width: 13%;
      padding: 0;
      background-color: transparent;
    }

    &:last-child {
      justify-content: flex-end;
      width: 22%;
      background-color: transparent;
    }

  }

  @media (min-width: 1280px) {
    margin-left: 90px;
  }
`


const Container = styled.div`
  flex: 1;
  overflow: hidden;
`

const Title = styled.div`
  border: 1px solid transparent;
  cursor: text;
  font-size: 18px;
  padding: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  :hover {
    border: 1px solid white;
  }
`

const Input = styled.input`
  border: none;
  font-size: 18px;
  padding: 5px;
  width: 100%;
  outline: none;
`

export default withRouter(({client, savedSearch, isSaving, handleRename, history}) => {

  const [editing, setEditing] = useState(false)
  const [name, setName] = useState((savedSearch && savedSearch.name) || 'Untitled')

  const handleSubmit = () => {
    setEditing(false)
    handleRename(name)
    return false
  }

  return(
    <FlexParentStretch>
      <FlexDiv>
        <PersonOutlinedIcon />
        <p onClick={() => history.push(`/clients/${client.clientId}`)}>
          {`${client.firstName} ${client.lastName}`}
        </p>
      </FlexDiv>
      <FlexDiv>
        <RightArrowIcon />
          <Container>
            {!editing &&
              <Title onClick={() => setEditing(true)}>
                {name}
              </Title>
            }
            {editing &&
              <form onSubmit={() => handleSubmit()}>
                <Input
                  autoFocus
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  onBlur={() => handleSubmit()}
                />
              </form>
            }
          </Container>
      </FlexDiv>
      <FlexDiv>
        {!editing &&
          <BgIconButton
            color="inherit"
            aria-label="Edit"
            onClick={() => setEditing(true)}
          >
            <EditIcon />
          </BgIconButton>
        }
      </FlexDiv>
      <FlexDiv>
        {savedSearch && <AutoSaveMessage isSaving={isSaving} />}
      </FlexDiv>
    </FlexParentStretch>
  )
})
