import React from 'react'
import { SidePanelTabTitle } from '../components/SidePanel'
import styled from 'styled-components'

const Section = styled.div`
  margin-top: 10px;
`

const getWebsite = (value) => {
  if(value.indexOf('http') === 0) {
    return value
  } else {
    return 'http://' + value
  }
}

export const ProfileTab = ({app}) => {
  return(
    <>
      {app.logo && app.logo.length && <img src={app.logo[0].thumbnails.small.url} alt='logo' />}

      <SidePanelTabTitle>{app.Name}</SidePanelTabTitle>

      {app.website && (
        <div>
          <a href={getWebsite(app.Website)} target="_blank" rel="noopener noreferrer">
            {app.website}
          </a>
        </div>
      )}

      {app.Tagline && (
        <Section>
          {app.Tagline}
        </Section>
      )}

      {(app.Categories.length > 0) && (
        <Section>
          <strong>Category:</strong> {app.Categories.join(', ')}
        </Section>
      )}

    </>
  )
}

export const CompanyTab = ({app}) => {
  return(
    <>
      <SidePanelTabTitle >{app.Name}</SidePanelTabTitle>

      {app.founded && (
        <Section>
          <b>Founded:</b> {app.founded}
        </Section>
      )}

      {app.founders && (
        <Section>
          <b>Founders:</b> {app.founders}
        </Section>
      )}

      {app.headquarters && (
        <Section>
          <b>Headquarter:</b> {app.headquarters}
        </Section>
      )}

    </>
  )
}
