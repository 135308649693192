import React, { useState, useEffect, useMemo } from 'react'
import { withRouter } from 'react-router-dom'
import { API } from 'aws-amplify'
import LoadingSpinner from '../../components/LoadingSpinner'
import AppStackReport from './AppStackReport'
import {
  findApps,
  getConfigFromFoundApps,
  getSelectedTransactionLines,
} from './util'
import transformConfig from './transformConfig'

const AppStackReportsReportsContainer = ({datasetId}) => {

  const [isLoading, setIsLoading] = useState(true)
  const [content, setContent] = useState()
  const [selectedDataset, setSelectedDataset] = useState()
  const [accounts, setAccounts] = useState([])

  useEffect(() => {
    const loadAllData = async () => {
      setIsLoading(true)
      const [apps, allCategories, dataset, accounts, transactionLines] = await Promise.all([
        API.get("d1-knowledgebase", `/content/apps?type=minimalist`),
        API.get("d1-knowledgebase", `/content/categories`),
        API.get("dev-d1-apps-backend", `/appStackReports/${datasetId}`),
        API.get("dev-d1-apps-backend", `/appStackReports/${datasetId}/accounts`),
        API.get("dev-d1-apps-backend", `/appStackReports/${datasetId}/transactionLines?hasAppName`),
      ])
  
      const allApps = apps
        .map(a => ({name: a.Name, category: a.MainCategory}))
        .sort((a, b) => a.name.localeCompare(b.name))
      setContent({ allApps, allCategories })
      setSelectedDataset(dataset)
      
      transactionLines.reduce((acc, line) => {
        const account = acc.find(a => a.name === line.accountName)
        if(account) {
          if(account.items) {
            account.items.push(line)
          } else {
            account.items = [ line ]
          }
        }
        return acc
      }, accounts)
  
      setAccounts(accounts.map(a => ({ ...a, loaded: false })))
      setIsLoading(false)
    }

    loadAllData()
  }, [datasetId])

  const foundAppsByCategory = useMemo(() => {
    return !!content && !isLoading && findApps({
      allApps: content.allApps,
      selectedTransactionLines: getSelectedTransactionLines({accounts}),
      transactionLines: accounts.reduce((acc, cur) => {
        return acc.concat(cur.items || [])
      }, [])
    })
  }, [content, isLoading, accounts])

  const allAppsConfig = useMemo(() => {
    return !!content && !isLoading && transformConfig({
      config: getConfigFromFoundApps({foundAppsByCategory, allCategories: content.allCategories}),
      foundAppsByCategory,
      totalIncome: selectedDataset.totalIncome
    })
  }, [content, isLoading, foundAppsByCategory, selectedDataset])

  if(!content || isLoading) {
    return <LoadingSpinner />
  } else {
    return(
      <AppStackReport
        config={allAppsConfig.config}
        isEditing={false}
        showDefaults={false}
        allApps={content.allApps}
        title={selectedDataset.nickname}
        hideRevenueNumbers={true}
      />
    )
    }
}

export default withRouter(AppStackReportsReportsContainer)
