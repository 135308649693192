import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

const AdminRoute = ({ component: C, currentUser, ...rest }) =>
  <Route
    {...rest}
    render={props => {
      if(currentUser) {
        return currentUser.isAdmin ? <C {...props} /> : <Redirect to={'/'} />
      } else {
        return <Redirect to={`/login?redirect=${props.location.pathname}${props.location.search}`} />
      }
    }}
  />

const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser
})

export default connect(mapStateToProps)(AdminRoute)
