import React from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import Autocomplete from '../../components/Autocomplete'

const Input = styled.input`
  width: 100%;
  padding: 5px;
  font-size: 18px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 0;
`

export default ({synonyms, schema, selectedTable, filters, onChange}) => {
  const columns = schema.find(x => x.table === selectedTable).columns

  const setFilter = (index, filter) => {
    const newFilters = [ ...filters ]
    newFilters[index] = filter
    onChange(newFilters)
  }

  const removeFilter = (index) => {
    const newFilters = [ ...filters ]
    newFilters.splice(index, 1)
    onChange(newFilters)
  }

  return(
    <>
    {filters.map((filter, index) => {

      const type = filter.field && columns.find(c => c.name === filter.field).type

      let Selector = null
      if(type) {
        if(type.startsWith('PATTERN_')) {
          Selector = PatternSelector
        } else if(type === 'App' && selectedTable !== 'App' ) {
          Selector = AppSelector
        } else {
          Selector = SynonymSelector
        }
      }

      const suggestions = columns.map(c => {
        if(selectedTable === 'App') {
          return {label: c.name.replace('appName', 'app'), value: c.name}
        } else {
          return {label: c.name, value: c.name}
        }
      })

      return(
        <div key={index} style={{marginBottom: '20px'}}>

          <div style={{fontWeight: 'bold', display: 'flex', justifyContent: 'space-between', marginBottom: '5px'}}>
            <span>Filter {index+1}:</span>
            <span
              style={{cursor: 'pointer', padding: '0 5px'}}
              onClick={event => removeFilter(index)}
            >
              X
            </span>
          </div>

          <div style={{marginBottom: '5px'}}>
            <Autocomplete
              value={filter.field && {label: filter.field, value: filter.field}}
              onChange={selection => setFilter(index, {field: selection && selection.value, subfield: null, value: null})}
              suggestions={suggestions}
              placeholder='Select field'
            />
          </div>

          {Selector &&
            <Selector
              schema={schema}
              filter={filter}
              type={type}
              synonyms={synonyms}
              onUpdateFilter={newFilter => setFilter(index, newFilter)}
            />
          }

        </div>
      )
    })}

    <div style={{textAlign: 'center', marginTop: '20px'}}>
      <Button
        size='small'
        variant="contained"
        color="primary"
        onClick={() => onChange([...filters, {}])}
      >
        Add filter
      </Button>
    </div>

    </>
  )
}

const PatternSelector = ({filter, onUpdateFilter}) => {
  return(
    <div>
      <Input
        value={filter.value || ''}
        onChange={event => onUpdateFilter({ ...filter, value: event.target.value })}
      />
    </div>
  )
}

const SynonymSelector = ({type, filter, synonyms, onUpdateFilter}) => {
  const suggestions = [
    {label: '-- NO VALUE --', value: '-- NO VALUE --'},
    ...synonyms[type].map(s => ({label: s, value: s}))
  ]
  return(
    <Autocomplete
      value={filter.value && {label: filter.value, value: filter.value}}
      onChange={selection => onUpdateFilter({ ...filter, value: selection && selection.value })}
      suggestions={suggestions}
      placeholder='Select value'
    />
  )
}

const AppSelector = ({type, schema, filter, synonyms, onUpdateFilter}) => {
  const columns = schema.find(x => x.table === 'App').columns
  const type2 = filter.subfield && columns.find(c => c.name === filter.subfield).type
  return(
    <>

      <div style={{marginBottom: '5px'}}>
        <Autocomplete
          value={{label: filter.subfield, value: filter.subfield}}
          onChange={selection => onUpdateFilter({ ...filter, subfield: selection && selection.value, value: null })}
          suggestions={columns.map(c => ({label: c.name, value: c.name}))}
          placeholder='Select app field'
        />
      </div>

      {type2 && type2.startsWith('PATTERN_') &&
        <PatternSelector
          filter={filter}
          onUpdateFilter={newFilter => onUpdateFilter(newFilter)}
        />
      }

      {type2 && !type2.startsWith('PATTERN_') &&
        <SynonymSelector
          type={type2}
          filter={filter}
          synonyms={synonyms}
          onUpdateFilter={newFilter => onUpdateFilter(newFilter)}
        />
      }

    </>
  )
}
