import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button'

export default class SaveCommentDialog extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      comment: ''
    }
  }

  render() {
    return(
      <Dialog
        open={true}
        onClose={() => this.props.onClose()}
      >
        <DialogTitle>
          Comment for saving changes
        </DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Comment"
            value={this.state.comment}
            onChange={(event) => this.setState({comment: event.target.value})}
            autoFocus
            style={{width: '400px'}}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.props.onClose()} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => this.props.onSave(this.state.comment)}
            color="primary"
            variant="contained"
            disabled={this.state.comment.length === 0}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

}
