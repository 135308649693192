import { combineReducers } from 'redux'
import { omit } from '../../../../utils'

const bucketsById = (state = {}, action) => {
  switch (action.type) {
    case 'RECEIVE_BUCKETS':
      return action.payload.buckets
    case 'UPDATE_BUCKET':
      const old = Object.assign({}, state[action.payload.id])
      return {
        ...state,
        [action.payload.id]: {
          ...old,
          id: action.payload.id,
          title: action.payload.title,
          templateFile: action.payload.templateFile
        }
      }
    case 'DELETE_BUCKET':
      return omit(state, [action.payload.id])
    case 'ADD_BUCKET':
      return {
        ...state,
        [action.payload.id]: {
          id: action.payload.id,
          title: ''
        }
      }
    default:
      return state
  }
}

const allBuckets = (state = [], action) => {
  switch (action.type) {
    case 'RECEIVE_BUCKETS':
      return Object.keys(action.payload.buckets)
    case 'DELETE_BUCKET':
      return state.filter(id => id !== action.payload.id)
    case 'ADD_BUCKET':
      return [...state, action.payload.id]
    default:
      return state
  }
}

export default combineReducers({
  byId: bucketsById,
  allIds: allBuckets
})
