export const SET_SIDEPANEL_CONTENT = 'SET_SIDEPANEL_CONTENT'
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB'
export const SET_IS_LOADING = 'SET_IS_LOADING'

export const setSidePanelContent = (tabs=[]) => {
  return {
    type: SET_SIDEPANEL_CONTENT,
    payload: tabs
  }
}

export const setActiveTab = (value) => {
  return {
    type: SET_ACTIVE_TAB,
    payload: value
  }
}

export const setIsLoading = () => {
  return {
    type: SET_IS_LOADING
  }
}
