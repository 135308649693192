import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

const AuthenticatedRoute = ({ component: C, currentUser, ...rest }) =>
  <Route
    {...rest}
    render={props =>
      currentUser
        ? <C {...props} />
        : <Redirect to={`/login?redirect=${props.location.pathname}${props.location.search}`} />
    }
  />

const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser
})

export default connect(mapStateToProps)(AuthenticatedRoute)
