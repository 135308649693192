import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { doLogout } from '../actions/authActions'
import AppBar from '@material-ui/core/AppBar'
import MenuBarDropDown from '../components/MenuBarDropDown'
import { NavToolbar } from '../components/MenuBar'
import { NavButton } from '../components/Basic'
import styled from 'styled-components'

const FlexGrowDiv = styled.div`
  flex-grow: 1;
`

const MenuBarButton = styled(NavButton)`
  font-weight: ${props => props.active === 'true' ? `bold !important;` : ''}
  color: ${props => props.active === 'true' ? `#da9b31 !important;` : ''}
`

const AdminMenuBar = ({history, doLogout, activeTab, authState}) => {
  return(
    <AppBar position="static">
      <NavToolbar>

        <MenuBarButton size="small" active={String(activeTab === 'Users')}
          color="inherit" onClick={() => history.push('/admin/users')}
        >
          Users
        </MenuBarButton>

        <MenuBarButton size="small" active={String(activeTab === 'Companies')}
          color="inherit" onClick={() => history.push('/admin/userCompanies')}
        >
          Companies
        </MenuBarButton>

        <MenuBarButton size="small" active={String(activeTab === 'Wiki pages')}
          color="inherit" onClick={() => history.push('/admin/wiki')}
        >
          Wiki pages
        </MenuBarButton>

        <MenuBarButton size="small" active={String(activeTab === 'App config')}
          color="inherit" onClick={() => history.push('/admin/appConfigEditor')}
        >
          App config
        </MenuBarButton>

        <MenuBarButton size="small" active={String(activeTab === 'Knowledgebase')}
          color="inherit" onClick={() => history.push('/admin/knowledgebase')}
        >
          Knowledgebase
        </MenuBarButton>

        <MenuBarButton size="small" active={String(activeTab === 'Detection rules')}
          color="inherit" onClick={() => history.push('/admin/detectionRules')}
        >
          Detection rules
        </MenuBarButton>

        <FlexGrowDiv />

        <MenuBarButton size="small" active={String(activeTab === 'Help')}
          color="inherit" onClick={() => history.push('/admin/help')}
        >
          Help
        </MenuBarButton>

        <MenuBarDropDown
          authState={authState}
          doLogout={doLogout}
        />

      </NavToolbar>
    </AppBar>
  )
}

const mapStateToProps = (state) => ({
  authState: state.auth
})

const mapDispatchToProps = (dispatch) => ({
  doLogout: () => dispatch(doLogout())
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminMenuBar))
