import React from 'react'
import { connect } from 'react-redux'
import { updateSection, deleteSection } from './actions'
import SectionForm from './SectionForm'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const Section = ({ id, section, onSectionSave, onDelete, expanded, expandedAll, index, onClick }) => {
  return (
    <ExpansionPanel expanded={expandedAll || expanded === section.id}
      onChange={() => onClick(section.id)}
    >
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography >{section.title}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <SectionForm
          onSectionSave={onSectionSave}
          key={section.id}
          onDelete={onDelete}
          section={section}
        />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

const mapStateToProps = (state, ownProps) => ({
  section: state.appConfig.sections.byId[ownProps.id]
})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSectionSave: (title, intro, color) => dispatch(updateSection(ownProps.id, title, intro, color)),
    onDelete: () => dispatch(deleteSection(ownProps.id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Section)
