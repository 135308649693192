import React from 'react'
import { Switch } from 'react-router-dom'

import LoginPage from '../auth/LoginPage'
import ResetPasswordPage from '../auth/ResetPasswordPage'

import DashboardPage from '../DashboardPage'
import ClientDetailsPage from '../ClientDetailsPage'

import AppStackReportsIndexPage from '../appStackReports/AppStackReportsIndexPage'
import AppStackReportsDatasetPage from '../appStackReports/AppStackReportsDatasetPage'
import AppStackReportsTemplatesPage from '../appStackReports/AppStackReportsTemplatesPage'
import AppStackReportsKeywordsPage from '../appStackReports/AppStackReportsKeywordsPage'
import AppStackReportsDownloadPage from '../appStackReports/AppStackReportsDownloadPage'
import AppStackReportsImportPage from '../appStackReports/AppStackReportsImportPage'
import AppStackReportsImportStatusPage from '../appStackReports/AppStackReportsImportStatusPage'

import AppStackReportsXeroCallbackPage from '../appStackReports/AppStackReportsXeroCallbackPage'
import AppStackReportsQBOCallbackPage from '../appStackReports/AppStackReportsQBOCallbackPage'
import AppStackReportsMYOBCallbackPage from '../appStackReports/AppStackReportsMYOBCallbackPage'


import Slack1BotPage from '../bot/slack1/SlacklikeBotPage'
import Slack2BotPage from '../bot/slack2/SlacklikeBotPage'

import BotPage from '../bot/BotPage'
import BotSettingsPage from '../bot/BotSettingsPage'
import BotHardCodedPage from '../bot/BotHardCodedPage'
import BotDevPage from '../bot/BotDevPage'
import AppPage from '../AppPage'
import SettingsPage from '../SettingsPage'
import SelectRolePage from '../SelectRolePage'

import AdminIndexPage from '../admin/AdminIndexPage'
import AdminHelpPage from '../admin/AdminHelpPage'
import AdminWikiPage from '../admin/wiki/AdminWikiPage'
import AdminDetectionRulesPage from '../admin/detectionRules/AdminDetectionRulesPage'
import AdminAppConfigPage from '../admin/appConfig/AdminAppConfigPage'
import AdminAppConfigEditorPage from '../admin/appConfig/AdminAppConfigEditorPage'
import AdminUserCompaniesPage from '../admin/users/AdminUserCompaniesPage'
import AdminUsersPage from '../admin/users/AdminUsersPage'
import UsersPage from '../users/UsersPage'

import KnowledgebaseIndexPage from '../admin/knowledgebase/KnowledgebaseIndexPage'
import KnowledgebaseScrapersPage from '../admin/knowledgebase/KnowledgebaseScrapersPage'
import KnowledgebaseMappingsPage from '../admin/knowledgebase/KnowledgebaseMappingsPage'
import KnowledgebaseSynonymsPage from '../admin/knowledgebase/KnowledgebaseSynonymsPage'
import KnowledgebaseContentPage from '../admin/knowledgebase/KnowledgebaseContentPage'

import AuthenticatedRoute from './AuthenticatedRoute'
import AdminRoute from './AdminRoute'
import UnauthenticatedRoute from './UnauthenticatedRoute'

export default () =>
  <Switch>
    <UnauthenticatedRoute path="/login" exact component={LoginPage} />
    <UnauthenticatedRoute path="/resetPassword" exact component={ResetPasswordPage} />

    <AuthenticatedRoute path="/" exact component={AppStackReportsIndexPage} />
    <AuthenticatedRoute path="/appStackReports/datasets/:datasetId" exact component={AppStackReportsDatasetPage} />
    <AuthenticatedRoute path="/appStackReports/import" exact component={AppStackReportsImportPage} />
    <AuthenticatedRoute path="/appStackReports/importStatus" exact component={AppStackReportsImportStatusPage} />
    <AuthenticatedRoute path="/appStackReports/templates" exact component={AppStackReportsTemplatesPage} />
    <AuthenticatedRoute path="/appStackReports/templates/:templateId" exact component={AppStackReportsTemplatesPage} />
    <AuthenticatedRoute path="/appStackReports/keywords" exact component={AppStackReportsKeywordsPage} />

    <AuthenticatedRoute path="/appStackReports/xeroCallback" exact component={AppStackReportsXeroCallbackPage} />
    <AuthenticatedRoute path="/appStackReports/qboCallback" exact component={AppStackReportsQBOCallbackPage} />
    <AuthenticatedRoute path="/appStackReports/myobCallback" exact component={AppStackReportsMYOBCallbackPage} />

    <UnauthenticatedRoute path="/appStackReports/downloads/:datasetId" exact component={AppStackReportsDownloadPage} />


    <AuthenticatedRoute path="/dashboard" exact component={DashboardPage} />
    <AuthenticatedRoute path="/clients/:clientId" exact component={ClientDetailsPage} />

    <AuthenticatedRoute path="/settings" exact component={SettingsPage} />
    <AuthenticatedRoute path="/selectRole" exact component={SelectRolePage} />

    <AuthenticatedRoute path="/slack1" exact component={Slack1BotPage} />
    <AuthenticatedRoute path="/slack2" exact component={Slack2BotPage} />

    <AuthenticatedRoute path="/bot" exact component={BotPage} />
    <AuthenticatedRoute path="/bot-settings" exact component={BotSettingsPage} />
    <AuthenticatedRoute path="/bot-hardcoded" exact component={BotHardCodedPage} />
    <AuthenticatedRoute path="/botdev" exact component={BotDevPage} />

    <AuthenticatedRoute path="/apps/:appId" exact component={AppPage} />
    <AuthenticatedRoute path="/apps/:appId/clients/:clientId" exact component={AppPage} />
    <AuthenticatedRoute path="/apps/:appId/savedSearches/:savedSearchId" exact component={AppPage} />
    <AuthenticatedRoute path="/users" exact component={UsersPage} />

    <AdminRoute path="/admin/knowledgebase" exact component={KnowledgebaseIndexPage} />
    <AdminRoute path="/admin/knowledgebase/scrapers" exact component={KnowledgebaseScrapersPage} />
    <AdminRoute path="/admin/knowledgebase/mappings/:scraperRunId" exact component={KnowledgebaseMappingsPage} />
    <AdminRoute path="/admin/knowledgebase/synonyms" exact component={KnowledgebaseSynonymsPage} />
    <AdminRoute path="/admin/knowledgebase/content" exact component={KnowledgebaseContentPage} />
    <AdminRoute path="/admin/detectionRules" exact component={AdminDetectionRulesPage} />

    <AdminRoute path="/admin" exact component={AdminIndexPage} />
    <AdminRoute path="/admin/help" exact component={AdminHelpPage} />
    <AdminRoute path="/admin/wiki" exact component={AdminWikiPage} />
    <AdminRoute path="/admin/appConfig" exact component={AdminAppConfigPage} />
    <AdminRoute path="/admin/appConfigEditor" exact component={AdminAppConfigEditorPage} />
    <AdminRoute path="/admin/userCompanies" exact component={AdminUserCompaniesPage} />
    <AdminRoute path="/admin/users" exact component={AdminUsersPage} />

  </Switch>
