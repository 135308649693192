import React, { useState } from 'react'
import NumberFormat from 'react-number-format'
import TextField from '@material-ui/core/TextField'

const NumberFormatCustom = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
    />
  )
}

export default ({ numberValue, setNumberValue, placeholder }) => {
  const [value, setValue] = useState(numberValue)

  const handleChange = (event) => {
    setNumberValue(event.target.value)
    setValue(event.target.value)
  }

  return (
    <TextField
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      id="formatted-numberformat-input"
      variant="outlined"
      InputProps={{
        inputComponent: NumberFormatCustom
      }}
    />
  );
}