import {
  SET_SIDEPANEL_CONTENT,
  SET_ACTIVE_TAB,
  SET_IS_LOADING
} from '../actions/sidePanelActions'

const INITIAL_STATE = {
  tabs: [],
  activeTab: 0,
  isLoading: false
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SIDEPANEL_CONTENT:
      const tabs = action.payload
      const activeTab = tabs.findIndex(t => !!t.active)
      return {
        ...state,
        tabs,
        activeTab: (activeTab >= 0) ? activeTab : 0,
        isLoading: false
      }
    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload
      }
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: true
      }
    default:
      return state
  }
}
