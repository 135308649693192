import React from 'react'
import { selectTopicsSuggestions } from './reducers/questions'
import { connect } from 'react-redux'
import QuestionForm from './QuestionForm.js'
import { updateQuestion, deleteQuestion } from './actions/questionActions'
import { removeQuestionFromSection } from './actions'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { pickBy } from '../../../utils'

const Question = ({ id, topicsSuggestions, sectionId, question, answersById, onQuestionSave, onDelete, expanded, expandedAll, index, onClick }) => {
  return (
    <ExpansionPanel expanded={expandedAll || expanded === question.id}
      onChange={() => onClick(question.id)}
    >
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography >{question.title}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <QuestionForm
          onQuestionSave={onQuestionSave}
          onDelete={onDelete}
          key={question.id}
          topicsSuggestions={topicsSuggestions}
          sectionId={sectionId}
          question={question}
          answersById={answersById}
        />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

const mapStateToProps = (state, ownProps) => {
  const topicsSuggestions = selectTopicsSuggestions(state.appConfig.questions)
  return {
    question: state.appConfig.questions.byId[ownProps.id],
    topicsSuggestions,
    answersById: pickBy(state.appConfig.answers.byId, (value, key) => {
      return state.appConfig.questions.byId[ownProps.id].answers.includes(key)
    })
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onQuestionSave: (form) => {
      const title = form.title
      const tooltip = form.tooltip
      const questionType = form.questionType
      const answers = form.answers
      const topics = form.topics
      dispatch(updateQuestion(ownProps.id, title, tooltip, questionType, answers, topics))
    },
    onDelete: () => {
      dispatch(removeQuestionFromSection(ownProps.id, ownProps.sectionId))
      dispatch(deleteQuestion(ownProps.id))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Question)
