import React from 'react'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import Tooltip from '@material-ui/core/Tooltip'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import { withStyles } from '@material-ui/core/styles'
import { ControlLabel } from '../components/Basic'
import renderHTMLContent from './renderHTMLContent'
import styled from 'styled-components'

const InfoOutlinedIcon = styled(InfoIcon)`
  font-size: 24px;
  position: relative;
  top: 6.5px;
`

const AnswerInfoIcon = styled(InfoIcon)`
  position: absolute;
  top: -2.5px;
  margin-left: 2px;
`

const QuestionFormControl = withStyles({
  root: {
    width: '230px', 
    margin: '20px', 
    marginBottom: 0,
    fontSize: '20px'
  }
})(FormControl)

const InfoIconContainer = styled.span`
  display: inline-block;
  position: relative;
  height: 14px;
`

const AnswerTooltip = withStyles({
  tooltip: {
    fontSize: '14px'
  }
})(Tooltip)

const AnswerLabel = ({answer, openWikiPage}) =>
  <div>
    {renderHTMLContent(answer.title, openWikiPage)}
    {(answer.tooltip || answer.noteForReport) && (
      <AnswerTooltip title={answer.tooltip || answer.noteForReport} placement="right">
        <InfoIconContainer><AnswerInfoIcon /></InfoIconContainer>
      </AnswerTooltip>
    )}
  </div>

const CheckBoxes = ({question, handleChangeAnswer, answerIds, openWikiPage}) =>
  <div>
    {question.answers.map(answer =>
      <div key={answer.id}>
        <ControlLabel
        control={
            <input type="checkbox"
            value={answer.id}
            checked={!!(answerIds && !!answerIds.find(answerId => answerId === answer.id))}
            onChange={event => {
              if(event.target.checked) {
                handleChangeAnswer({
                  questionId: question.id,
                  answerIds: answerIds ? [...answerIds, answer.id] : [answer.id]
                })
              } else {
                const newAnswerIds = [...answerIds]
                const index = newAnswerIds.indexOf(answer.id)
                newAnswerIds.splice(index, 1)
                handleChangeAnswer({
                  questionId: question.id,
                  answerIds: newAnswerIds
                })
              }
            }
            }
          />
        }
        label={<AnswerLabel answer={answer} openWikiPage={openWikiPage} />}
       />
      </div>
    )}
  </div>

const RadioButtons = ({question, handleChangeAnswer, answerIds, openWikiPage}) =>
  <RadioGroup
    value={answerIds && answerIds[0]}
    onChange={event => handleChangeAnswer({
      questionId: question.id,
      answerIds: [event.target.value]
    })}
  >
    {question.answers.map(answer =>
      <ControlLabel
      key={answer.id}
        value={answer.id}
        control={<input type="radio" />}
        label={<AnswerLabel answer={answer} openWikiPage={openWikiPage} />}
      />
    )}
  </RadioGroup>

export default ({question, handleChangeAnswer, answerIds, openWikiPage}) =>
  <QuestionFormControl component="fieldset">
    <div>
      <strong>{renderHTMLContent(question.title, openWikiPage)}</strong>
      {question.tooltip && (
        <AnswerTooltip title={question.tooltip} placement="right">
          <InfoOutlinedIcon
            onClick={() => {
              if(question.wikiPageId) {
                openWikiPage(question.wikiPageId)
              }
            }}
          />
        </AnswerTooltip>
      )}
    </div>

    {(question.questionType === 'checkbox') && <CheckBoxes
      question={question}
      handleChangeAnswer={handleChangeAnswer}
      answerIds={answerIds}
      openWikiPage={openWikiPage}
    />}

    {(!question.questionType || question.questionType === 'radiobutton') && <RadioButtons
      question={question}
      handleChangeAnswer={handleChangeAnswer}
      answerIds={answerIds}
      openWikiPage={openWikiPage}
    />}

  </QuestionFormControl>
