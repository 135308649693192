import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { API } from 'aws-amplify'
import styled from 'styled-components'
import MenuBar from '../components/MenuBar'
import AppInfoTab from './components/AppInfoTab'
import NewTemplateForm from './components/NewTemplateForm'
import LoadingSpinner from '../components/LoadingSpinner'
import { MainContent } from '../components/Basic'
import { setErrorNotification } from '../actions/notificationActions'
import { setIsLoading, setSidePanelContent } from '../actions/sidePanelActions'
import transformConfig from './components/transformConfig'
import AppStackReport from './components/AppStackReport'
import DeleteDialog from './components/band/DeleteDialog'
import { cleanUpConfig } from './components/util'
import { getHeadersForUserRole } from '../utils'
import ListOfTemplates from './ListOfTemplates'
import VisibilityToggle from './components/VisibilityToggle'
import IntercomWidget from '../IntercomWidget'

const Right = styled.div`
  flex: 1;
  padding-top: 10px;
  padding-left: 10px;
`

const AppStackReportsTemplatesPage = (props) => {
  const {
    currentRole,
    myCompanyId,
    setErrorNotification,
    setSidePanelContent,
    setSidePanelLoading
  } = props

  let rightContentTemplate = null
  const [isLoading, setIsLoading] = useState(true)
  const [selectedTemplate, setSelectedTemplate] = useState()
  const [currentConfig, setCurrentConfig] = useState()
  const [isEditing, setIsEditing] = useState(false)
  const [templates, setTemplates] = useState([])
  const [allApps, setAllApps] = useState([])
  const [allIndustries, setAllIndustries] = useState([])
  const [allCategories, setAllCategories] = useState([])
  const [isDeleteDialogOpen, toggleDeleteDialog] = useState(false)

  const loadData = async () => {
    const [templates, allIndustries, allCategories, apps] = await Promise.all([
      API.get("dev-d1-apps-backend", '/appStackTemplates', { headers: getHeadersForUserRole() }),
      API.get("d1-knowledgebase", `/content/synonyms?type=Industry`),
      API.get("d1-knowledgebase", `/content/categories`),
      API.get("d1-knowledgebase", `/content/apps?type=minimalist`),
    ])
    setTemplates(templates)
    setAllIndustries(allIndustries.map(i => i.name))
    setAllCategories(allCategories.map(c => c.Name))
    const allApps = apps
      .map(a => ({name: a.Name, category: a.MainCategory}))
      .sort((a, b) => a.name.localeCompare(b.name))
    setAllApps(allApps)
    setIsLoading(false)
  }

  useEffect(() => {
    loadData()
  }, [])

  const selectTemplate = useCallback((templateId) => {
    setIsEditing(false)
    const template = templateId && templates.find(t => t.templateId === templateId)
    setSelectedTemplate(template)
    setCurrentConfig(template && template.config)
  }, [templates])

  useEffect(() => {
    selectTemplate(props.match.params.templateId)
  }, [templates, props.match.params.templateId, selectTemplate])

  const updateTemplateConfig = async () => {
    if (selectedTemplate.industry === null) {
      setErrorNotification({
        title: 'Input Error',
        message: 'Industry is required. Enter an industry.'
      })
    } else if (selectedTemplate.maxRevenue === '') {
      setErrorNotification({
        title: 'Input Error',
        message: 'Max Revenue is required. Enter a max revenue.'
      })
    } else {
      const selectedTemplateDetails = {
        templateName: selectedTemplate.templateName,
        industry: selectedTemplate.industry,
        maxRevenue: selectedTemplate.maxRevenue,
        visibility: selectedTemplate.visibility
      }
      await API.put("dev-d1-apps-backend", `/appStackTemplates/${selectedTemplate.templateId}`, {
        body: {
          ...selectedTemplateDetails,
          config: cleanUpConfig(currentConfig)
        }
      })
      const newTemplates = templates.map(t => {
        if(t.templateId === selectedTemplate.templateId) {
          return {
            ...t,
            ...selectedTemplateDetails,
            config: currentConfig
          }
        } else {
          return t
        }
      })
      setTemplates(newTemplates)
      setIsEditing(false)
    }
  }

  const addTemplate = async () => {
    const newTemplate = await API.post("dev-d1-apps-backend", '/appStackTemplates', {
      body: {
        templateName: 'A New Template',
        industry: 'Test',
        maxRevenue: '1000000',
        config: {
          bands: []
        }
      }
    })

    setTemplates([...templates, newTemplate])
    setSelectedTemplate(newTemplate)
    setCurrentConfig(newTemplate.config)
    setIsEditing(true)
  }

  const deleteTemplate = async (templateId) => {
    if(templateId) {
      await API.del("dev-d1-apps-backend", `/appStackTemplates/${templateId}`)
      setTemplates(templates.filter(t => t.templateId !== templateId))
      setSelectedTemplate(null)
      setCurrentConfig(null)
    }
    toggleDeleteDialog(!isDeleteDialogOpen)
  }

  const handleAppIconClick = async (appName) => {
    setSidePanelLoading()
    const appInfo = await API.get("d1-knowledgebase", `/content/apps/${appName}`)
    const { Published, IgnoreInTextAnalysis, ...info } = appInfo
    setSidePanelContent([
      {
        title: 'App Info',
        content: <AppInfoTab appInfo={info} />
      }
    ])
  }

  if(isLoading) {
    return(
      <div>
        <MenuBar title='Book of Apps' activeTab="Templates" />
        <LoadingSpinner />
      </div>
    )
  }

  if(selectedTemplate && currentConfig) {
    rightContentTemplate = (
      <Right>
        {isEditing &&
          <>
            <NewTemplateForm
              selectedTemplate={selectedTemplate}
              updateTemplate={(template) => setSelectedTemplate(template)}
              allIndustries={allIndustries}
            />
            <VisibilityToggle
              type='TEMPLATE'
              visibility={selectedTemplate.visibility}
              onChange={visibility => setSelectedTemplate({...selectedTemplate, visibility})}
            />
          </>
        }

        <AppStackReport
          config={transformConfig({config: currentConfig, foundAppsByCategory: [], totalIncome: 0}).config}
          onConfigChange={config => setCurrentConfig(config)}
          isEditing={isEditing}
          showDefaults={true}
          allApps={allApps}
          allIndustries={allIndustries}
          allCategories={allCategories}
          handleAppIconClick={handleAppIconClick}
          showBenchmarks={true}
          title={selectedTemplate.templateName}
          onClose={() => {
            selectTemplate(null)
            return props.history.push(`/appStackReports/templates`)
          }}
        />
      </Right>
    )
  }

  let actionButtons = []

  if (!isEditing && (selectedTemplate && selectedTemplate.companyId === myCompanyId)) {
    actionButtons = actionButtons.concat([
      {
        label: 'Edit',
        onClick: () => setIsEditing(!isEditing),
        disabled: !selectedTemplate
      },
      {
        label: 'Delete',
        onClick: () => toggleDeleteDialog(!isDeleteDialogOpen),
        disabled: !selectedTemplate
      }
    ])
  } else if (isEditing && selectedTemplate && currentConfig) {
    actionButtons = [
      {
        label: 'Save',
        onClick: () => updateTemplateConfig(),
        disabled: !selectedTemplate
      }
    ]
  } else if (!isEditing) {
    actionButtons = [{
      label: 'New',
      onClick: () => addTemplate(),
      disabled: false
    }]
  }

  return(
    <div>
      <MenuBar title='Book of Apps' activeTab="Templates" />
      <MainContent>
        <ListOfTemplates
          currentRole={currentRole}
          templates={templates}
          selectedTemplate={selectedTemplate}
          onSelect={(templateId) => {
            props.history.push(`/appStackReports/templates/${templateId}`)
            selectTemplate(templateId)
          }}
          small={!!selectedTemplate && !!currentConfig}
          actionButtons={actionButtons}
          rightContentTemplate={rightContentTemplate}
        />

        {isDeleteDialogOpen &&
          <DeleteDialog
            isDialogOpen={isDeleteDialogOpen}
            toggleDialog={() => toggleDeleteDialog(!isDeleteDialogOpen)}
            deleteItem={deleteTemplate}
            idToBeDeleted={selectedTemplate && selectedTemplate.templateId}
          />
        }
      </MainContent>

      <IntercomWidget page='APPSTACK_REPORTS_TEMPLATES' />
    </div>
  )
}

const mapStateToProps = (state) => ({
  currentRole: state.auth.currentRole,
  myCompanyId: state.auth.currentCompany.companyId
})

const mapDispatchToProps = (dispatch) => ({
  setErrorNotification: (error) => dispatch(setErrorNotification(error)),
  setSidePanelContent: tabs => dispatch(setSidePanelContent(tabs)),
  setSidePanelLoading: () => dispatch(setIsLoading())
})

export default connect(mapStateToProps, mapDispatchToProps)(AppStackReportsTemplatesPage)
