import React from 'react'
import styled from 'styled-components'
import Ball from './Ball'

const Container = styled.div`
  border-right: 1px solid #44454b;
  flex: 1;
  width: 250px;
  padding: 0 20px;
`

const Bucket = styled.div`
  display: flex;
`

const BucketContent = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const BucketName = styled.div`
  text-align: center;
  font-weight: bold;
  margin: 10px;
`

const ShadowBall = styled.div`
  width: 34px;
  height: 34px;
  margin: 5px;
  border: 3px solid #44454b;
  border-radius: 50%;
  background-color: transparent;
`

const getTooltipTitle = (questionTitle, answerTitle) =>
  <div>
    <div>
      <b>{questionTitle}</b>
    </div>
    <div>
      <i>{answerTitle}</i>
    </div>
  </div>

const ShadowBalls = ({num}) => {
  const shadowBalls = [];
  for(let i = 0; i < num; i++) {
    shadowBalls.push(<ShadowBall key={i} />);
  }
  return shadowBalls;
}

export default ({title, balls, changeTab}) => {
  return (
    <Container>
      <BucketName>
        {title}
      </BucketName>

      <Bucket>
        <BucketContent>
          {balls.map(ballConfig =>
            <Ball
              key={ballConfig.answer.id}
              tooltip={getTooltipTitle(ballConfig.question.title, ballConfig.answer.title)}
              color={ballConfig.section.color}
              onClick={() => changeTab(ballConfig.section.id)}
            />
          )}
          <ShadowBalls num={30 - balls.length || 30} />
        </BucketContent>
      </Bucket>
    </Container>
  );
}
