import React from 'react'
import { API } from 'aws-amplify'
import moment from 'moment'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/EditOutlined'
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEyeOutlined'
import ViewCompactIcon from '@material-ui/icons/ViewCompactOutlined'
import DashboardIcon from '@material-ui/icons/DashboardOutlined'
import AddCircleIcon from '@material-ui/icons/AddCircleOutline'
import { ReactComponent as DatabaseSVG } from './assets/database.svg'
import styled from 'styled-components'
import { withStyles } from '@material-ui/core/styles'

import IntercomWidget from './IntercomWidget'
import RenameSavedSearchDialog from './dialogs/RenameSavedSearchDialog'
import UpdateClientDialog from './dialogs/UpdateClientDialog'
import MenuBar from './components/MenuBar'
import LoadingSpinner from './components/LoadingSpinner'
import * as ClientDetails from './components/Table'
import * as Basic from './components/Basic'
import {
  APP_ID_ARCHITECTURE_SELECTOR,
  APP_ID_INVENTORY_APP_SELECTOR,
  APP_ID_APPSTACK_DESIGNER
} from './AppConfig'

const ClientDetailsContainer = styled.div`
  margin: 20px;
`

const AppSectionContainer = styled.div`
  @media (min-width: 1280px) {
    margin-right: ${props => props.heading === "Appstack Designer" ? 0 : '20px'};
  }
`

const ClientInfo = styled.p`
  font-size: 18px;
  font-weight: 500;
  color: #2e314e;
  margin: 0 0 10px;

  span {
    font-weight: 400;
    color: #44454b;
  }

  @media (min-width: 960px) {
    margin-bottom: 0;
  }
`

const EditContainer = styled.div`
  margin-bottom: 20px;
  @media(min-width: 960px) {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
  }
`

const TableTitle = styled.div`
  display: flex;
  justify-content: space-between;

  div:first-child {
    display: flex;
    align-items: center;
    color: #a9cf54;

    > span {
      position: relative;
      display: inline-block;
      width: 24px;
      height: 14px;

      svg {
        position: absolute;
        top: -4.5px;
        stroke: #a9cf54;
      }
    }
  }

  h2 {
    font-weight: 400;
    color: #000;
    margin: 0 5px;
  }
`

const IconBtnContainer = withStyles({
  root: {
    padding: '4px',
    color: '#da9b31',
  }
})(IconButton)

const NoContentMessage = styled.div`
  font-style: italic;
  margin: 30px 0 30px 30px;
`

export default class ClientDetailsPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      client: null,
      savedSearches: null,
      industries: [],
      isUpdateClientDialogOpen: false,
      savedSearchForRename: null
    }
  }

  async closeUpdateClientDialog(clientUpdated) {
    if(clientUpdated) {
      const client = await API.get("dev-d1-apps-backend", '/clients/' + this.props.match.params.clientId)
      this.setState({
        client,
        isUpdateClientDialogOpen: false
      })
    } else {
      this.setState({
        isUpdateClientDialogOpen: false
      })
    }
  }

  async componentDidMount() {
    const [client, savedSearches, industries] = await Promise.all([
      API.get("dev-d1-apps-backend", '/clients/' + this.props.match.params.clientId),
      API.get("dev-d1-apps-backend", '/savedSearches'),
      API.get("d1-knowledgebase", '/content/synonyms?type=Industry')
    ])
    this.setState({
      client,
      savedSearches,
      industries
    })
  }

  async reloadSavedSearches() {
    const savedSearches = await API.get("dev-d1-apps-backend", '/savedSearches')
    this.setState({savedSearches})
  }

  openAppPage({appId, clientId, savedSearchId}) {
    if(savedSearchId) {
      this.props.history.push(`/apps/${appId}/savedSearches/${savedSearchId}`)
    } else {
      this.props.history.push(`/apps/${appId}/clients/${clientId}`)
    }
  }

  render() {

    if(!this.state.savedSearches) {
      return <LoadingSpinner />
    }

    const savedSearchesMap = this.state.savedSearches
      .filter(savedSearch => savedSearch.clientId === this.props.match.params.clientId)
      .reduce(
        (acc, cur) => {
          if(acc[cur.appId]) {
            acc[cur.appId].push(cur)
          } else {
            acc[cur.appId] = [cur]
          }
          return acc
        },
        {}
      )

    return(
      <div>
        <MenuBar title="Client Details" />

        <ClientDetailsContainer>

          <Grid container alignItems="center">
            <Grid item xs={12} md={4}>
              <Basic.Heading1>{this.state.client.firstName} {this.state.client.lastName}</Basic.Heading1>
            </Grid>

            <Grid item xs={12} md={3}>
              <ClientInfo>Company <span>{this.state.client.companyName}</span></ClientInfo>
            </Grid>

            <Grid item xs={12} md={3}>
              <ClientInfo>Industry <span>{this.state.client.industry}</span></ClientInfo>
            </Grid>

            <Grid item xs={12} md={2}>
              <EditContainer>
                <Button color="primary" variant="outlined" onClick={() => this.setState({isUpdateClientDialogOpen: true})}>
                  <EditIcon />
                  Edit
                </Button>
              </EditContainer>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} lg={4}>
              <AppSection
                heading='Architecture Selector'
                savedSearches={savedSearchesMap[APP_ID_ARCHITECTURE_SELECTOR]}
                onRename={(savedSearchForRename) => this.setState({savedSearchForRename})}
                openAppPage={(savedSearchId) => this.openAppPage({
                  appId: APP_ID_ARCHITECTURE_SELECTOR,
                  clientId: this.state.client.clientId,
                  savedSearchId
                })}
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <AppSection
                heading='Inventory App Selector'
                savedSearches={savedSearchesMap[APP_ID_INVENTORY_APP_SELECTOR]}
                onRename={(savedSearchForRename) => this.setState({savedSearchForRename})}
                openAppPage={(savedSearchId) => this.openAppPage({
                  appId: APP_ID_INVENTORY_APP_SELECTOR,
                  clientId: this.state.client.clientId,
                  savedSearchId
                })}
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <AppSection
                heading='Appstack Designer'
                savedSearches={savedSearchesMap[APP_ID_APPSTACK_DESIGNER]}
                onRename={(savedSearchForRename) => this.setState({savedSearchForRename})}
                openAppPage={(savedSearchId) => this.openAppPage({
                  appId: APP_ID_APPSTACK_DESIGNER,
                  clientId: this.state.client.clientId,
                  savedSearchId
                })}
              />
            </Grid>
          </Grid>
        </ClientDetailsContainer>

        <UpdateClientDialog
          onClose={clientUpdated => this.closeUpdateClientDialog(clientUpdated)}
          open={this.state.isUpdateClientDialogOpen}
          industries={this.state.industries}
          client={this.state.client}
        />

        {this.state.savedSearchForRename &&
          <RenameSavedSearchDialog
              savedSearch={this.state.savedSearchForRename}
              onClose={(updated) => {
                this.setState({savedSearchForRename: null})
                this.reloadSavedSearches()
              }}
            />
        }

        <IntercomWidget page='CLIENT_DETAILS' />

      </div>
    )
  }

}


const AppSection = ({heading, savedSearches, openAppPage, onRename}) =>
  <AppSectionContainer heading={heading || ''}>
    <TableTitle>
      <div>
        { heading === 'Architecture Selector' ?
        <ViewCompactIcon /> : heading === 'Appstack Designer' ?
        <DashboardIcon /> : <span><DatabaseSVG /></span> }
        <h2>{heading}</h2>
      </div>
      <IconBtnContainer>
        <AddCircleIcon onClick={() => openAppPage()} />
      </IconBtnContainer>
    </TableTitle>

    {!savedSearches && <NoContentMessage>No saved searches yet.</NoContentMessage>}
    {savedSearches &&
      <ClientDetails.TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <ClientDetails.TableHeaderCell>Name</ClientDetails.TableHeaderCell>
              <ClientDetails.TableHeaderCell>Created</ClientDetails.TableHeaderCell>
              <ClientDetails.TableHeaderCell>Updated</ClientDetails.TableHeaderCell>
              <ClientDetails.TableHeaderCell>Actions</ClientDetails.TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {savedSearches
            .sort((a, b) => b.updatedAt - a.updatedAt)
            .map(item =>
              <ClientDetails.ClickableTableRow key={item.savedSearchId}>
                <ClientDetails.TableStandardCell onClick={() => openAppPage(item.savedSearchId)}>
                  {item.name}
                </ClientDetails.TableStandardCell>
                <ClientDetails.TableStandardCell onClick={() => openAppPage(item.savedSearchId)}>
                  {moment(item.createdAt).format('DD.MM.YYYY HH:mm')}
                </ClientDetails.TableStandardCell>
                <ClientDetails.TableStandardCell onClick={() => openAppPage(item.savedSearchId)}>
                  {moment(item.updatedAt).format('DD.MM.YYYY HH:mm')}
                </ClientDetails.TableStandardCell>
                <ClientDetails.TableStandardCell>
                  <RowActions
                    onOpen={() => openAppPage(item.savedSearchId)}
                    onRename={() => onRename(item)}
                  />
                </ClientDetails.TableStandardCell>
              </ClientDetails.ClickableTableRow>
            )}
          </TableBody>
        </Table>
      </ClientDetails.TableContainer>
    }
  </AppSectionContainer>


class RowActions extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      anchorEl: null
    }
  }

  showMenu = event => {
     this.setState({ anchorEl: event.currentTarget })
   }

   hideMenu = () => {
      this.setState({ anchorEl: null })
    }

   handleOpen = () => {
     this.hideMenu()
     this.props.onOpen()
   }

   handleRename = () => {
     this.hideMenu()
     this.props.onRename()
   }

   render() {
     return (
       <>
         {/* <IconButton onClick={this.showMenu}>
           <MoreVertIcon />
         </IconButton>
         <Menu
           id="long-menu"
           anchorEl={this.state.anchorEl}
           open={!!this.state.anchorEl}
           onClose={this.hideMenu}
           PaperProps={{
             style: {
               maxHeight: 48 * 4.5,
               width: 200,
             }
           }}
         >
           <MenuItem onClick={this.handleOpen}>Open</MenuItem>
           <MenuItem onClick={this.handleRename}>Rename</MenuItem>
         </Menu> */}
        <IconBtnContainer onClick={this.handleOpen}><RemoveRedEyeIcon /></IconBtnContainer>
        <IconBtnContainer onClick={this.handleRename}><EditIcon /></IconBtnContainer>
       </>
     )
   }

}
