import React from 'react'
import { API } from 'aws-amplify'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { setSidePanelContent } from '../../actions/sidePanelActions'
import renderHTMLContent from '../../app-advisor/renderHTMLContent'
import { connect } from 'react-redux'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  padding-left: 30px;
  background-color: #EEE;
  border-bottom: 1px solid #CCC;
`

const Left = styled.div`
  display: flex;
  flex-direction: row;
`

const Link = styled.div`
  color: #3f51b5;
  font-size: 16px;
  cursor: pointer;
  margin-right: 20px;
  ${props => props.active && `font-weight: bold;`}
`

const Tabs = withRouter(({activeTab, history, setSidePanelContent}) => {

  // TODO We have exactly the same function in QuestionPage.js.
  // Let's refactor to avoid duplicate code.
  const openWikiPage = async (wikiPageId) => {
    const wikiPage = await API.get("dev-d1-apps-backend", '/wikiPages/' + wikiPageId)
    const content = wikiPage.content && renderHTMLContent(wikiPage.content, (wikiPageId) => this.openWikiPage(wikiPageId))
    setSidePanelContent([
      {
        title: wikiPage.name,
        content
      }
    ])
  }

  return(
    <Container>

      <Left>

        <Link
          onClick={() => history.push('/admin/knowledgebase/scrapers')}
          active={activeTab === 'Scrapers'}
        >
          Scrapers
        </Link>

        <Link
          onClick={() => history.push('/admin/knowledgebase/synonyms')}
          active={activeTab === 'Synonyms'}
        >
          Synonyms
        </Link>

        <Link
          onClick={() => history.push('/admin/knowledgebase/content')}
          active={activeTab === 'Content'}
        >
          Content
        </Link>

      </Left>

      <Link onClick={() => openWikiPage('89f5c4da-c47b-4807-9950-acc80abb2064')}>
        Mapping instructions
      </Link>
    </Container>
  )
})


const mapDispatchToProps = (dispatch) => ({
  setSidePanelContent: tabs => dispatch(setSidePanelContent(tabs))
})

export default connect(null, mapDispatchToProps)(Tabs)
