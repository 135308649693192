import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save'

const CommentsForm = ({ comments, onSave }) => {
  const [mode, setMode] = useState('view') // or 'edit'
  const [noInfoForSection, setNoInfoForSection] = useState(comments.noInfoForSection)

  const handleChange = (e) => {
    const value = e.target.value
    if (e.target.name === 'noInfoForSection') {
      setNoInfoForSection(value)
    }
  }

  const handleSubmit = () => {
    toggleMode()
    onSave(noInfoForSection)
  }

  const toggleMode = () => {
    const newMode = mode === 'view' ? 'edit' : 'view'
    setMode(newMode)
  }

  return (
    <Grid container spacing={16}>

      <TextField
        label='No info for section'
        margin='normal'
        disabled={mode === 'view'}
        name='noInfoForSection'
        fullWidth
        value={noInfoForSection || ''}
        onChange={handleChange}
        variant='outlined'
        multiline
      />

      { mode === 'view' &&
        <IconButton onClick={toggleMode} aria-label='Edit'> <EditIcon /> </IconButton>}
      { mode === 'edit' &&
        <IconButton onClick={handleSubmit} aria-label='Save'> <SaveIcon /> </IconButton>}
    </Grid>
  )
}

export default CommentsForm
