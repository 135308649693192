import {
  GET_CONVERSATIONS,
  GET_CONVERSATIONS_SUCCESS,
  GET_CONVERSATIONS_ERROR,
  CREATE_CONVERSATION,
  CREATE_CONVERSATION_SUCCESS,
  CREATE_CONVERSATION_ERROR,
} from '../actions/chatConversationsActions'

const INITIAL_STATE = {
  data: [],
  getting: false,
  creating: false,
  error: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case GET_CONVERSATIONS:
      return { ...state, getting: true, error: null }

    case GET_CONVERSATIONS_SUCCESS:
      return { ...state, data: action.payload, getting: false, error: null }

    case GET_CONVERSATIONS_ERROR:
      return { ...state, getting: false, error: action.payload }

    case CREATE_CONVERSATION:
      return { ...state, creating: true, error: null }

    case CREATE_CONVERSATION_SUCCESS:
      return { ...state, data: [...state.data, action.payload], creating: false, error: null }

    case CREATE_CONVERSATION_ERROR:
      return { ...state, creating: false, error: action.payload }

    default:
      return state
  }
}
