import React from 'react'
import styled from 'styled-components'
import { API } from 'aws-amplify'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'

import BotMessage from './BotMessage'
import UserMessage from './UserMessage'
import SuggestionPanel from './SuggestionPanel'
import TypingIndicator from './TypingIndicator'

const Chatbox = styled.div`
  position: fixed;
  bottom: 30px;
  top: 90px;
  right: 30px;
  width: 500px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const ChatFooter = styled.div`
  display: flex;
  border-top: 1px solid #CCC;
  padding: 5px;

  input {
    flex: 1;
    font-size: 16px;
    padding: 10px;
    outline: none;
    border: none;
    ::placeholder {
      color: #AAA;
    }
  }
`

const MenuItem = styled.div`
  color: #0084ff;
  background-color: white;
  border-top: 1px solid #CCC;
  cursor: pointer;
  padding: 15px;
`

const MENU_ITEMS = [
  "Pin to client record",
  "Add company guidelines",
  "Forward chat to firm champion",
  "Forward chat to D1 expert",
  "Email conversation"
]

const getStartMessage = (currentUser) => ({
  lines: [
    {
      text: `Hello ${currentUser.firstName}, how can I help you?`
    }
  ],
  isBot: true
})

const getStartButtons = () => ({
  lines: [
    {
      buttons: [
        {text: 'Information about an app', value: 'Information about an app'},
        {text: 'Find an expert', value: 'Find an expert'},
        {text: 'Integration between apps', value: 'Integration between apps'},
        {text: 'Feature of an app', value: 'Feature of an app'},
      ]
    }
  ],
  isBot: true
})

const replacePlaceholder = (lines) => {
  let companyName = 'KPMG'
  if(document.cookie) {
    const cookieCompanyName = document.cookie
      .split(';')
      .map(x => x.trim())
      .find(x => x.startsWith('botSettingsCompanyName='))
    if(cookieCompanyName) {
      companyName = cookieCompanyName.replace('botSettingsCompanyName=', '')
    }
  }

  lines.forEach(line => {
    if(line.text) {
      line.text = line.text.replace('{{botSettingsCompanyName}}', companyName)
    }
  })
  return lines
}

export default class BotPanel extends React.Component {

  constructor(props) {
    super(props)
    this.textInput = React.createRef()
    this.state = {
      isMenuOpen: false,
      messages: [
        getStartMessage(props.currentUser),
        getStartButtons()
      ],
      currentText: '',
      lastText: '',
      isTyping: false
    }
  }

  scrollToEnd() {
    window.setTimeout(() => this.messagesEnd.scrollIntoView({ behavior: "smooth" }), 300)
  }

  async send({event, text}) {
    if(event) {
      event.preventDefault()
    }
    this.textInput.current.focus()

    const currentText = text || this.state.currentText
    const messages = [ ...this.state.messages ]
    messages.push({
      lines: [
        {text: currentText}
      ]
    })

    this.setState({
      lastText: currentText,
      currentText: '',
      messages,
      isTyping: true
    }, () => this.scrollToEnd())


    let newMessage
    let response
    try {
      // response = await Interactions.send("BOA", currentText)
      response = await API.post("dev-d1-apps-backend", '/chatBot', {
        body: {
          text: currentText,
          intentId: this.state.lastIntentId,
          responseId: this.state.lastResponseId
        }
      })

      console.log('bot response', response)
      const lines = (response.messageFormat === 'CustomPayload')
        ? JSON.parse(response.message)
        : [{text: response.message}]

      newMessage = {
        lines: replacePlaceholder(lines),
        isBot: true
      }

    } catch (err) {
      console.error(err)
      newMessage = {lines: [{text: 'Oops, something went wrong. There was an error finding the answer.'}], isBot: true}
    }

    messages.push(newMessage)

    if(response && response.messageFormat === 'PlainText' && response.dialogState === 'ElicitIntent') {
      messages.push(getStartButtons())
    }


    this.setState({
      lastIntentId: response && response.sessionAttributes && response.sessionAttributes.intentId,
      lastResponseId: response && response.sessionAttributes && response.sessionAttributes.responseId,
      messages,
      isTyping: false
    }, () => this.scrollToEnd())

  }

  showInitialSuggestions() {
    const messages = [ ...this.state.messages ]
    messages.push(getStartButtons())
    this.setState({
      messages
    }, () => this.scrollToEnd())
  }

  openMenu() {
    this.setState({isMenuOpen: !this.state.isMenuOpen})
  }

  closeMenu() {
    this.setState({isMenuOpen: false})
  }

  showPreviousText() {
    this.setState({currentText: this.state.lastText})
  }

  render() {

    const messages = this.state.messages
    const lastMessage = messages[messages.length - 1]
    const lastLine = lastMessage.isBot && lastMessage.lines[lastMessage.lines.length - 1]
    const suggestButtons = lastLine && lastLine.buttons

    return(
      <Chatbox>
        <Paper elevation={10} style={{height: '100%'}}>
          <Content>
            <div style={{flex: 1, overflow: 'auto'}}>

              {messages.map((message, index) => {
                if(message.isBot) {
                  return(
                    <BotMessage key={index} message={message} onClick={text => this.send({text})}/>
                  )
                } else {
                  return(
                    <UserMessage key={index} message={message} />
                  )
                }
              })}

              {this.state.isTyping && <TypingIndicator />}

              <div ref={(el) => this.messagesEnd = el }> </div>

            </div>

            <SuggestionPanel
              buttons={suggestButtons}
              onClick={text => this.send({text})}
            />


            <form onSubmit={event => this.send({event})}>
              <ChatFooter>

                <div style={{position: 'relative'}}>
                  <ClickAwayListener onClickAway={() => this.closeMenu()}>
                    <div>
                      <IconButton onClick={() => this.openMenu()}>
                        <MenuIcon />
                      </IconButton>
                      {this.state.isMenuOpen &&
                        <Paper elevation={10} style={{position: 'absolute', bottom: '50px', left: '5px', width: '300px', border: '1px solid #CCC'}}>
                          <div style={{padding: '15px', fontWeight: 'bold', textAlign: 'center'}}>Menu</div>
                          {MENU_ITEMS.map(item =>
                            <MenuItem onClick={() => this.closeMenu()}>
                              {item}
                            </MenuItem>
                          )}
                          <MenuItem onClick={() => {this.closeMenu(); this.showInitialSuggestions()}}>
                            Show questions you can ask
                          </MenuItem>
                        </Paper>
                      }
                    </div>
                  </ClickAwayListener>
                </div>

                <input
                  autoFocus
                  ref={this.textInput}
                  placeholder='Type a message'
                  value={this.state.currentText}
                  onChange={event => this.setState({currentText: event.target.value})}
                  onKeyDown={event => event.keyCode === 38 && this.showPreviousText()}
                />
              </ChatFooter>
            </form>


        </Content>
        </Paper>

      </Chatbox>
    )
  }

}
