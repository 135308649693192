import React, { useState, useEffect } from 'react'
import { API } from 'aws-amplify'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import LoadingSpinner from '../components/LoadingSpinner'
import { FormSection, WideForm } from '../components/Basic'
import styled from 'styled-components'
import IntercomWidget from '../IntercomWidget'
import MenuBar from '../components/MenuBar'
import { getQueryParameterByName } from './components/util'

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: -10px;

  a {
    text-decoration: none;
  }

  &.withTopMargin {
    margin-top: 50px;
  }
`

const MainContent = styled.main`
  margin: 20px;
`

export default () => {

  const [dataset, setDataset] = useState()
  const datasetId = getQueryParameterByName('datasetId')

  useEffect(() => {
    const checkForUpdates = async () => {
      const dataset = await API.get("dev-d1-apps-backend", `/appStackReports/${datasetId}`)
      setDataset(dataset)
      if(dataset.importStatus !== 'DONE') {
        window.setTimeout(() => checkForUpdates(), 5000)
      }
    }

    checkForUpdates()
  }, [datasetId])

  return(
    <div>
      <MenuBar title='Book of Apps' />
      <MainContent>
        <WideForm>
          <FormSection>
            <pre>
              {dataset && dataset.importMessage}
            </pre>
          </FormSection>
          {dataset && dataset.importStatus === 'DONE' &&
            <ButtonContainer className="withTopMargin">
              <Link to={`/appStackReports/datasets/${dataset.datasetId}`}>
                <Button color="primary" variant="outlined">
                  View Report
                </Button>
              </Link>
            </ButtonContainer>
          }
          {(!dataset || (dataset.importStatus !== 'DONE' && dataset.importStatus !== 'ERROR') ) &&
            <LoadingSpinner />
          }
        </WideForm>
      </MainContent>
      <IntercomWidget page='APPSTACK_REPORTS_IMPORT' />
    </div>
  )
}
