import styled from 'styled-components'

export default styled.table`
  width: 100%;
  border-collapse: collapse;
  th, td {
    border-top: 1px solid #CCC;
    font-size: 12px;
    padding: 3px;
  }
  td:last-child {
    text-align: right;
  }
`
