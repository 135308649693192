export const SET_ERROR_NOTIFICATION = 'SET_ERROR_NOTIFICATION'
export const SET_WARNING_NOTIFICATION = 'SET_WARNING_NOTIFICATION'
export const SET_INFO_NOTIFICATION = 'SET_INFO_NOTIFICATION'
export const CLEAR_ERROR_NOTIFICATION = 'CLEAR_ERROR_NOTIFICATION'
export const CLEAR_WARNING_NOTIFICATION = 'CLEAR_WARNING_NOTIFICATION'
export const CLEAR_INFO_NOTIFICATION = 'CLEAR_INFO_NOTIFICATION'

export const setErrorNotification = (error) => {
  console.error(error.message)
  return {
    type: SET_ERROR_NOTIFICATION,
    payload: error
  }
}

export const setWarningNotification = (warning) => {
  console.warn(warning.message)
  return {
    type: SET_WARNING_NOTIFICATION,
    payload: warning
  }
}

export const setInfoNotification = (info) => {
  console.info(info.message)
  return {
    type: SET_INFO_NOTIFICATION,
    payload: info
  }
}

export const clearErrorNotification = () => {
  return {
    type: CLEAR_ERROR_NOTIFICATION
  }
}

export const clearWarningNotification = () => {
  return {
    type: CLEAR_WARNING_NOTIFICATION
  }
}

export const clearInfoNotification = () => {
  return {
    type: CLEAR_INFO_NOTIFICATION
  }
}