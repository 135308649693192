import React from 'react'
import DialogContent from '@material-ui/core/DialogContent'
import * as Dialog from '../../components/Dialog'
import LoadingSpinner from '../../components/LoadingSpinner'

export default ({isOpen, message}) => {
  return (
    <Dialog.CenteredDialog open={isOpen}>
      <Dialog.FormTitle>
        Preparing report for download ...
      </Dialog.FormTitle>
      <DialogContent>
        <div>
          {message}
        </div>
        <LoadingSpinner />
      </DialogContent>
    </Dialog.CenteredDialog>
  )
}
