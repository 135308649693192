import React from 'react'
import EditIcon from '@material-ui/icons/EditOutlined'
import DeleteIcon from '@material-ui/icons/Delete'
import ArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import { transformedNameAt180Degree } from '../../../components/Basic'
import styled, { css } from 'styled-components'
import { roundAndFormatNumber } from '../util'
import AmplifyConfig from '../../../AmplifyConfig.js'

const endpoint = AmplifyConfig.API.endpoints.find(endpoint => {
  return endpoint.name === 'd1-knowledgebase'
}).endpoint

const nameTD = css`
  font-weight: bold;
  position: relative;
  padding: 0 10px;
  width:1%;
  white-space:nowrap;
`

const nameStylesInTheBand = css`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  padding-top: 3px;
`

const PrimaryNameTD = styled.td`
  ${nameTD}
  .primaryName {
    ${transformedNameAt180Degree}
  }
`

const SecondaryNameTD = styled.td`
  ${nameTD}
  .secondaryName {
    ${transformedNameAt180Degree}
    transform: rotate(360deg);
  }
`

const NumbersTD = styled.td`
  width: 80px;
  border-right: 1px solid #ccc;
  padding: 0 10px;

  &:last-of-type {
    border-right: 0;
  }
`

const BandTD = styled.td`
  background-color: ${props => props.color};
  flex-grow: 1;
  border-right: 0;
  padding: 0;
  display: flex;
  min-height: 70px;

  p {
    margin: 0;
  }

  .categoryName {
    ${nameStylesInTheBand}
  }
`
const PrimarySection = styled.div`
  margin-left: 10px;
  padding: 25px 2px 0 25px;
  position: relative;
  box-sizing: border-box;
  flex-grow: 1;
`

const SecondarySection = styled.div`
  padding: 25px 10px 10px;
  background-color: rgba(255,255,255, 0.5);
  box-sizing: border-box;
  text-align: center;
  display: flex;

  > div {
    position: relative;
    margin: 0 5px;
    > div {
      flex-direction: column;
      align-items: center;
    }
  }
`

const ParentIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 5px;
  left: -6px;
`

const IconBtnContainer = withStyles({
  root: {
    padding: '3px',
    color: '#fff',
    '& svg': {
      width: '20px',
      height: '20px'
    }
  }
})(IconButton)

const CategoryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .category {
    margin-right: 25px;
    margin-bottom: 25px;
  }

  .appName {
    ${nameStylesInTheBand}
    padding-top: 0;
    padding-bottom: 3px;
    margin-right: 8px;
  }
`

const EmptyApp = styled.div`
  background-color: #eee;
  width: 58px;
  height: 58px;
  border: 1px dashed #5e5e5e;
  padding: 7px;
`

const Logo = styled.img`
  width: 58px;
  height: 58px;
  border: ${props => props.highlight ? '3px solid #3f51b5' : '1px solid #AAA'};
  background-color: #CCC;
  padding: ${props => props.highlight ? '5px' : '7px'};
  border-radius: 4px;
  cursor: pointer;
  margin-right: 8px;
`

const getAppLogoURL = ({appName, allApps}) => {
  const foundApp = allApps.find(app => app.name === appName)
  if(foundApp) {
    if(foundApp.svgLogoUrl) {
      return foundApp.svgLogoUrl
    } else {
      const encodedAppName = encodeURIComponent(appName.trim())
      return `${endpoint}/content/appLogos/${encodedAppName}`
    }
  } else {
    return null
  }
}

const AppLogoContainer = ({category, showDefaults, highlightDefaults, i, allApps, handleAppIconClick }) => {
  return(
    <>
      {category && category.apps &&
        <CategoryContainer>
          {category.apps.length === 0 && showDefaults &&
            <div key={`defaultApp-${i}`}>
              <p className="appName">{category.defaultApp && category.defaultApp.name}</p>
              <Logo
                src={getAppLogoURL({appName: category.defaultApp.name, allApps})}
                alt=""
                onClick={() => handleAppIconClick(category.defaultApp.name)}
                highlight={highlightDefaults}
              />
            </div>
          }
          {category.apps.length === 0 && !showDefaults &&
            <div key={`emptyApp-${i}`}>
              <p className="appName">&nbsp;</p>
              <EmptyApp  />
            </div>
          }
          {category.apps.map((app, i) => {
            return(
              <div key={`bandApp-${i}`}>
                <p className="appName">{app && app.name}</p>
                <Logo
                  src={getAppLogoURL({appName: app.name, allApps})}
                  alt=""
                  onClick={() => handleAppIconClick(app.name)}
                />
              </div>
            )
          })}
        </CategoryContainer>
      }
    </>
  )
}

export default ({
  bandConfig, allBands, color,
  toggleEditDialog, toggleDeleteDialog,
  setCurrentBandIndex, setCurrentBand,
  showDefaults, highlightDefaults, isEditing,
  allApps, bandIndex,
  handleAppIconClick,
  moveBand, showBenchmarks,
  bandsWithSecondarySection,
  hideRevenueNumbers,
 }) => {

  const openEditDialog = () => {
    setCurrentBandIndex(bandIndex)
    setCurrentBand(bandConfig)
    toggleEditDialog()
  }

  const openDeleteDialog = () => {
    setCurrentBandIndex(bandIndex)
    toggleDeleteDialog()
  }

  const moveCurrentBand = (direction) => {
    moveBand({bandIndex, bandConfig, direction})
  }

  const getAnnualRevenuePercentage = (value) => {
    return value ? `${Math.round(value * 10) / 10}%` : 'N/A'
  }

  return (
    <tr>
        {bandConfig && bandConfig.primarySection &&
          <>
            <PrimaryNameTD>
              <div className="primaryName">{bandConfig.primarySection.name}</div>
            </PrimaryNameTD>

            <BandTD color={color}>

              <PrimarySection>
                {isEditing &&
                  <ParentIconContainer>
                    <IconBtnContainer title="Edit band" onClick={() => openEditDialog()}><EditIcon /></IconBtnContainer>
                    <IconBtnContainer title="Delete band" onClick={() => openDeleteDialog()}><DeleteIcon /></IconBtnContainer>
                    <IconBtnContainer
                      disabled={bandIndex === 0 ? true : false}
                      title="Move up band" onClick={() => moveCurrentBand('up')}
                    >
                      <ArrowUpIcon />
                    </IconBtnContainer>
                    <IconBtnContainer
                      disabled={bandIndex + 1 === allBands ? true : false}
                      title="Move down band" onClick={() => moveCurrentBand('down')}
                    >
                      <ArrowDownIcon />
                    </IconBtnContainer>
                  </ParentIconContainer>
                }
                <CategoryContainer>
                  {bandConfig.primarySection.categories.map((category, i) => {
                    return(
                      <div className="category" key={`bandCategory-${i}`}>
                        <AppLogoContainer
                          allApps={allApps}
                          category={category}
                          showDefaults={showDefaults}
                          highlightDefaults={highlightDefaults}
                          handleAppIconClick={handleAppIconClick}
                          i={i}
                        />
                        <p className="categoryName">{category.name}</p>
                      </div>
                    )
                  })}
                </CategoryContainer>
              </PrimarySection>

              {bandConfig.secondarySection &&
                <SecondarySection>
                  {bandConfig.secondarySection.categories.map((category, i) => {
                    return(
                      <div key={`bandSecondaryCategory-${i}`}>
                        <AppLogoContainer
                          allApps={allApps}
                          category={category}
                          showDefaults={showDefaults}
                          highlightDefaults={highlightDefaults}
                          handleAppIconClick={handleAppIconClick}
                          i={i}
                        />
                        <p className="categoryName">{category.name}</p>
                      </div>
                    )
                  })}
                </SecondarySection>
              }

            </BandTD>
            {bandsWithSecondarySection > 0 &&
              <SecondaryNameTD>
                <div className="secondaryName">
                  {bandConfig.secondarySection && bandConfig.secondarySection.name}
                  {!bandConfig.secondarySection && ''}
                </div>
              </SecondaryNameTD>
            }

            {!hideRevenueNumbers &&
              <>
              <NumbersTD>{roundAndFormatNumber(bandConfig.annualRevenue)}</NumbersTD>
              <NumbersTD>{getAnnualRevenuePercentage(bandConfig.annualRevenuePercentage)}</NumbersTD>
              </>
            }
          </>
        }

      {showBenchmarks &&
        <NumbersTD>{`${Math.round((bandConfig.benchmarkPercentage || 0) * 10) / 10}%`}</NumbersTD>
      }
    </tr>
  )
}
