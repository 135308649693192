import React from 'react'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import styled from 'styled-components'
import { withStyles } from '@material-ui/core/styles'

const Page = styled.span`
  padding-left: 1rem;
  font-size: 1rem;
`

const IconBtn = withStyles({
  root: {
    padding: '4px',
    marginLeft: '8px'
  }
})(IconButton)

const ColoredCircularProgress = withStyles({
  root: {
    color: '#2e314e',
    marginLeft: '8px'
  },
})(CircularProgress);

export default ({ paginationSettings }) => {
  const {   indexOfFirstItem, indexOfLastItem,
    isDataLoading, totalItems,
    setCurrentPage, currentPage,
    lastPage 
  } = paginationSettings
  const firstItem = totalItems === 0 ? totalItems : indexOfFirstItem
  const lastItem = currentPage === lastPage ? totalItems : indexOfLastItem
  let isPreviousDisabled = currentPage === 1 ? true : false
  let isNextDisabled = currentPage === lastPage ? true : false

  return (
    <Page>
      {firstItem} - {lastItem} of {isDataLoading ? <ColoredCircularProgress size={16} /> : totalItems}
      {!isDataLoading &&
        <>
          <IconBtn 
            disabled={isPreviousDisabled} 
            onClick={() => setCurrentPage(currentPage - 1)}
          > 
            <KeyboardArrowLeftIcon /> 
          </IconBtn>
          <IconBtn 
            disabled={isNextDisabled} 
            onClick={() => setCurrentPage(currentPage + 1)}
          > 
            <KeyboardArrowRightIcon /> 
          </IconBtn>
        </>
      }
    </Page>
  )
}