import React, { useState } from 'react'
import {Table, Column, Cell} from 'fixed-data-table-2'
import styled from 'styled-components'
import ActionCellContent from './ActionCellContent'

const ArrayValue = styled.span`
  margin-right: 5px;
  background-color: #EEE;
  padding: 3px;
  border-radius: 3px;
  ${props => props.error && `
    background-color: #ffb533;
    cursor: pointer;
  `}
`

const SingleValue = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${props => props.error && `
    background-color: #ffb533;
    cursor: pointer;
  `}
`

const NoValue = styled.span`
  font-style: italic;
  cursor: text;
`

export default (props) => {
  const [columnWidths, setColumnWidths] = useState({})

  const {
    width,
    height,
    isEditingMapping,
    tableSchema,
    mappedFields,
    updateMappedField,
    columns,
    data,
    handleClickOnError,
    onAppBulkImportClick
  } = props

  const onColumnResizeEndCallback = (newColumnWidth, columnKey) => {
    setColumnWidths({
      ...columnWidths,
      [columnKey]: newColumnWidth,
    })
   }

  return(
    <Table
      rowHeight={40}
      rowsCount={data.length}
      width={width}
      height={height}
      headerHeight={50}
      onColumnResizeEndCallback={onColumnResizeEndCallback}
      isColumnResizing={false}
    >

    {columns.map((column, columnIndex) =>
      <Column
        key={columnIndex}
        columnKey={columnIndex}
        header={
          <HeaderCell
            column={column}
            tableSchema={tableSchema}
            mappedFields={mappedFields}
            updateMappedField={updateMappedField}
            isEditingMapping={isEditingMapping}
            onAppBulkImportClick={onAppBulkImportClick}
          />
        }
        width={columnWidths[columnIndex] || 150}
        isResizable={true}
        fixed={columnIndex === 0}
        cell={<TextCell data={data} handleClickOnError={handleClickOnError} />}
      />
    )}

    </Table>
  )

}


const HeaderCell = ({column, tableSchema, mappedFields, updateMappedField, isEditingMapping, onAppBulkImportClick}) => {
  return(
    <ActionCellContent
      column={column}
      tableSchema={tableSchema}
      mappedFields={mappedFields}
      updateMappedField={fieldMapping => updateMappedField(fieldMapping)}
      isEditingMapping={isEditingMapping}
      onAppBulkImportClick={onAppBulkImportClick}
    />
  )
}


const TextCell = ({data, rowIndex, columnKey, handleClickOnError}) => {
  const row = data[rowIndex]
  const cell = row.cells[columnKey]
  if(Array.isArray(cell.value)) {
    return(
      <Cell>
        <SingleValue>
          {cell.value.map((x, index) => {
            const hasError = cell.error && cell.error.indexes && cell.error.indexes.indexOf(index) >= 0
            return(
              <ArrayValue
                key={index}
                error={hasError}
                onClick={() => hasError && handleClickOnError(row.entryId, cell, index)}
              >
               {x}
              </ArrayValue>
            )
          })}
        </SingleValue>
      </Cell>
    )
  } else {
    return(
      <Cell>
        <SingleValue
          error={!!cell.error}
          onClick={() => cell.value && cell.error && handleClickOnError(row.entryId, cell)}
        >
          {cell.value}
          {!cell.value && cell.error && <NoValue>NO VALUE</NoValue>}
        </SingleValue>
      </Cell>
    )
  }
}
