
const INITIAL_STATE = {
  currentRole: 'USER',
  currentUser: null,
  currentCompany: null,
  isInitialized: false
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case 'SET_AUTHENTICATED':
      const currentUser = action.payload.currentUser
      return {
        ...state,
        currentRole: (currentUser && currentUser.isAdmin) ? 'ADMIN' : 'USER',
        currentUser,
        currentCompany: action.payload.currentCompany,
        isInitialized: true
      }

    case 'SET_USER_ROLE':
      return {
        ...state,
        currentRole: action.payload.role
      }

    default:
      return state
  }
}
