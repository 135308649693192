import React, {useState, useEffect} from 'react'
import { API } from 'aws-amplify'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import LoadingSpinner from '../../components/LoadingSpinner'

export default ({scraperId, onClose}) => {

  const [isEditing, setEditing] = useState(false)
  const [config, setConfig] = useState()

  useEffect(() => {
    const fetchScraperConfig = async () => {
      const scraperConfig = await API.get("d1-knowledgebase", `/scraperConfig/${scraperId}`)
      setConfig(scraperConfig)
    }
    fetchScraperConfig()
  }, [scraperId])

  const onSave = async () => {
    await API.put("d1-knowledgebase", `/scraperConfig/${scraperId}`, {
      body: {
        ...config
      }
    })
    setEditing(false)
  }

  return(
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>
        {config ? `${config.appName} / ${config.scraperType}` : ''}
      </DialogTitle>
      <DialogContent style={{width: '400px', height: '250px'}}>
        {!config && <LoadingSpinner />}

        {config && isEditing &&
          <div>
            <div>
              <input
                placeholder='URL'
                value={config.url}
                onChange={event => setConfig({...config, url: event.target.value})}
                style={{width: '100%', marginBottom: '20px', padding: '5px'}}
              />
            </div>
            <div>
              <textarea
                placeholder='Scraping instructions'
                value={config.notes}
                onChange={event => setConfig({...config, notes: event.target.value})}
                style={{height: '100px', width: '100%', marginBottom: '20px', padding: '5px'}}
              />
            </div>
          </div>
        }

        {config && !isEditing &&
          <div>
            <div style={{paddingBottom: '20px'}}>
              <a href={config.url} target='_blank' rel='noopener noreferrer'>
                {config.url}
              </a>
            </div>
            <div>
              {config.notes && config.notes.split('\n').map((item, key) =>
                <span key={key}>{item}<br/></span>
              )}
            </div>
          </div>
        }

      </DialogContent>
      <DialogActions>
        {isEditing &&
          <Button onClick={onSave} color="primary" variant="contained">
            Save
          </Button>
        }
        {!isEditing &&
          <>
          <Button onClick={() => setEditing(true)} color="primary">
            Edit
          </Button>
          <Button onClick={onClose} color="primary" variant="contained">
            OK
          </Button>
          </>
        }
      </DialogActions>
    </Dialog>
  )
}
