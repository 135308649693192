import React, { useState } from 'react'
import Paper from '@material-ui/core/Paper'
import HtmlField from './components/HtmlField'
import { connect } from 'react-redux'
import Checkbox from '@material-ui/core/Checkbox'
import FormGroup from '@material-ui/core/FormGroup'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import LaunchIcon from '@material-ui/icons/Launch'
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import DeleteDialog from './DeleteDialog'

const AnswerForm = ({ answer, allBuckets, onAnswerSave, onDelete }) => {
  const [mode, setMode] = useState('view') // or 'edit'
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const [form, setValues] = useState({
    title: answer.title,
    tooltip: answer.tooltip,
    ballTooltip: answer.ballTooltip,
    noteForReport: answer.noteForReport,
    buckets: answer.buckets
  })

  const updateField = e => {
    setValues({
      ...form,
      [e.target.name]: e.target.value
    })
  }

  const { title, tooltip, ballTooltip, noteForReport, buckets } = form

  const handleBucketItemChange = name => event => {
    const bucketsCopy = buckets.slice()
    if (event.target.checked) {
      bucketsCopy.push(event.target.value)
    } else {
      bucketsCopy.splice(bucketsCopy.indexOf(event.target.value), 1)
    }
    const e = { target: { name: 'buckets', value: bucketsCopy } }
    updateField(e)
  }

  const handleSubmit = () => {
    toggleMode()
    onAnswerSave(form)
  }

  const toggleMode = () => {
    const newMode = mode === 'view' ? 'edit' : 'view'
    setMode(newMode)
  }

  return (
    <Grid container spacing={16}>

      {showDeleteDialog &&
        <DeleteDialog
          title='Delete answer?'
          onClose={() => setShowDeleteDialog(false)}
          onSubmit={onDelete}
        />
      }

      { mode === 'edit' &&
      <Paper style={{ minHeight: '100px', marginTop: '10px' }}>
        <HtmlField
          html={form.title || ''}
          disabled={mode === 'view'}
          onHTMLChange={html => setValues({ ...form, title: html })}
        />
      </Paper>
      }
      { mode === 'view' &&
      <TextField
        label='Title'
        margin='normal'
        disabled={mode === 'view'}
        name='title'
        fullWidth
        value={title}
        onChange={updateField}
        variant='outlined'
      />}

      <TextField
        label='Tooltip'
        margin='normal'
        disabled={mode === 'view'}
        name='tooltip'
        multiline
        fullWidth
        rowsMax='2'
        value={tooltip}
        onChange={updateField}
        variant='outlined'
      />

      <TextField
        label='Ball tooltip'
        margin='normal'
        disabled={mode === 'view'}
        name='ballTooltip'
        multiline
        fullWidth
        rowsMax='2'
        value={ballTooltip}
        onChange={updateField}
        variant='outlined'
      />

      <TextField
        label='Note for report'
        margin='normal'
        disabled={mode === 'view'}
        name='noteForReport'
        multiline
        fullWidth
        rowsMax='5'
        value={noteForReport}
        onChange={updateField}
        variant='outlined'
      />

      <a
        href='https://docs.google.com/document/d/1EPc0uVXCmhxuvN-wFFINkEsZGQ8WLoawEudaTeJrllU'
        rel="noopener noreferrer"
        target='_blank'
        style={{marginBottom: 20}}
      >
        Style guide for "Note for report"
        <LaunchIcon style={{fontSize: '16px'}} />
      </a>

      <Grid container spacing={16} >
        <Grid item xs={12}>
          <FormControl component='fieldset'>
            <FormLabel component='legend'>Buckets</FormLabel>
            <FormGroup>
              {allBuckets.map(bucket => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={buckets.includes(bucket.id)}
                      onChange={handleBucketItemChange(bucket.id)}
                      disabled={mode === 'view'}
                      value={bucket.id}
                    />
                  }
                  key={bucket.id}
                  label={bucket.title}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>

      { mode === 'view' &&
        <IconButton onClick={toggleMode} aria-label='Edit'> <EditIcon /> </IconButton>}
      { mode === 'view' &&
        <IconButton onClick={() => setShowDeleteDialog(true)} aria-label='Delete'> <DeleteIcon /> </IconButton>}
      { mode === 'edit' &&
        <IconButton onClick={handleSubmit} aria-label='Save'> <SaveIcon /> </IconButton>}
    </Grid>
  )
}

const mapStateToProps = state => ({
  allBuckets: state.appConfig.buckets.allIds.map(bucketId => {
    return state.appConfig.buckets.byId[bucketId]
  })
})

export default connect(mapStateToProps)(AnswerForm)
