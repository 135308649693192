export default {
  options: [
    "inline",
    "blockType",
    "fontSize",
    "list",
    "textAlign",
    "link",
    "embedded",
    "image",
    "remove",
  ],

  inline: {
    options: [
      "bold",
      "italic",
      "underline"
    ],
  },

  list: {
    options: [
      "unordered",
      "ordered"
    ]
  }

}
