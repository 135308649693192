import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import TextField from '@material-ui/core/TextField'
import LoadingButton from '../components/LoadingButton'
import { fetchCurrentUser } from '../actions/authActions'
import { FormContainer, FormSection, Form, FormTitle, ErrorMessage } from '../components/Basic'
import styled from 'styled-components'

const ForgotPasswordSection = styled.div`
  margin-top: 50px;
  text-align: center;
  a {
    color: #069;
  }
`

class LoginPage extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      errorMessage: null,
      email: '',
      password: '',
      newPassword: '',
      mustChangePassword: false,
      currentUser: null
    }
  }

  isLoginFormValid() {
    return (this.state.email.length > 0 && this.state.password.length > 0)
  }

  isChangePasswordFormValid() {
    return (this.state.newPassword.length > 0)
  }

  async handleSubmitLogin(event) {
    event.preventDefault()
    if(!this.isLoginFormValid()) {
      return
    }

    this.setState({ isLoading: true })
    const email = this.state.email.trim()
    const password = this.state.password

    try {
      const currentUser = await Auth.signIn(email, password)
      if(currentUser.challengeName) {
        // user must perform a challenge before getting access
        if(currentUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
          this.setState({
            mustChangePassword: true,
            currentUser,
            isLoading: false,
            errorMessage: null
          })
        } else {
          // INFO add other challenges here if neccessary (e.g. MFA)
          throw new Error('Error occured. Cannot log in.')
        }
      } else {
        // user has logged in successfully
        this.props.fetchCurrentUser()
      }
    } catch (err) {
      this.setState({
        isLoading: false,
        errorMessage: (err && err.message) || err
      })
    }
  }

  async handleSubmitChangePassword(event) {
    event.preventDefault()
    if(!this.isChangePasswordFormValid()) {
      return
    }

    this.setState({ isLoading: true })
    const newPassword = this.state.newPassword
    try {
      await Auth.completeNewPassword(this.state.currentUser, newPassword)
      this.props.fetchCurrentUser()
    } catch (err) {
      this.setState({
        isLoading: false,
        errorMessage: (err && err.message) || err
      })
    }
  }

  renderLoginForm() {
    return (
      <form onSubmit={event => this.handleSubmitLogin(event)}>

        <FormSection>
          <TextField
            name="email"
            placeholder="Email"
            value={this.state.email}
            onChange={event => this.setState({email: event.target.value})}
            variant="outlined"
            fullWidth
          />
        </FormSection>

        <FormSection>
          <TextField
            name="password"
            placeholder="Password"
            value={this.state.password}
            onChange={event => this.setState({password: event.target.value})}
            variant="outlined"
            type="password"
            fullWidth
          />
        </FormSection>

        <FormSection>
          <LoadingButton
            text="Log In"
            disabled={!this.isLoginFormValid()}
            isLoading={this.state.isLoading}
            onClick={event => this.handleSubmitLogin(event)}
          />
        </FormSection>

        <ForgotPasswordSection>
          <Link to={'/resetPassword'}>
            Forgot password?
          </Link>
        </ForgotPasswordSection>

      </form>
    )
  }

  renderChangePasswordForm() {
    return (
      <form onSubmit={event => this.handleSubmitChangePassword(event)}>

        <div>
          This is your first login. You need to change the password for your account.
        </div>

        <FormSection>
          <TextField
            name="newPassword"
            placeholder="New password"
            value={this.state.newPassword}
            onChange={event => this.setState({newPassword: event.target.value})}
            variant="outlined"
            type="password"
            fullWidth
          />
        </FormSection>

        <FormSection>
          <LoadingButton
            text="Submit"
            disabled={!this.isChangePasswordFormValid()}
            isLoading={this.state.isLoading}
            onClick={event => this.handleSubmitChangePassword(event)}
          />
        </FormSection>

      </form>
    )
  }

  render() {
    return (
      <FormContainer>
        <Form>
          <FormTitle>Log In</FormTitle>

          {this.state.errorMessage &&
            <ErrorMessage>
              {this.state.errorMessage}
            </ErrorMessage>
          }

          {this.state.mustChangePassword
            ? this.renderChangePasswordForm()
            : this.renderLoginForm()
          }

        </Form>
      </FormContainer>
    )
  }
}


const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
  fetchCurrentUser: () => dispatch(fetchCurrentUser())
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
