import React from 'react'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'
import * as Dialog from '../components/Dialog'

export default class SetSavedSearchNameDialog extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      name: ''
    }
  }

  render() {
    return(
      <Dialog.SmallDialog
        open={true}
        onClose={() => this.props.onClose()}
      >
        <Dialog.FormTitle>Save the Selected Answers</Dialog.FormTitle>
        <DialogContent>

          <label htmlFor="name">Name</label>
          <TextField id="name" variant="outlined"
            value={this.state.name} placeholder="Enter name"
            onChange={(event) => this.setState({name: event.target.value})}
            fullWidth
            autoFocus
          />

          <Dialog.UnstyledActions>
            <Dialog.CancelButton onClick={() => this.props.onClose()} variant="outlined">
              Cancel
            </Dialog.CancelButton>
            <Dialog.SaveButton
              disabled={this.state.name.length < 1}
              onClick={() => this.props.onSubmit(this.state.name)}
              variant="outlined"
            >
              Save
            </Dialog.SaveButton>
          </Dialog.UnstyledActions>

        </DialogContent>
      </Dialog.SmallDialog>
    )
  }

}
