import React, { useState } from 'react'
import DialogContent from '@material-ui/core/DialogContent'
import * as Dialog from '../../components/Dialog'
import Autocomplete from '../../components/Autocomplete'
import { withStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'

const CheckboxLabel = withStyles({
  root: {
    marginLeft: 0,
    verticalAlign: 'baseline',
    '& span': {
      fontSize: '16px',
    }
  }
})(FormControlLabel)

export default ({isOpen, onClose, onSave, allApps}) => {

  const [appName, setAppName] = useState(null)
  const [assignToSameContactNames, setAssignToSameContactNames] = useState(true)
  const [manualOverrideReason, setManualOverrideReason] = useState('')

  return (
    <Dialog.CenteredDialog
      open={isOpen}
      onClose={() => onClose()}
      aria-labelledby="form-dialog-title"
    >
      <Dialog.FormTitle id="form-dialog-title">
        Select app for this transaction
      </Dialog.FormTitle>
      <DialogContent>

        <p>
          <TextField
            type="text"
            variant="outlined"
            placeholder="Reason for selecting this transaction"
            value={manualOverrideReason}
            onChange={event => setManualOverrideReason(event.target.value)}
            fullWidth
          />
        </p>

        <p>
          <Autocomplete
            value={{label: appName, value: appName}}
            onChange={app => setAppName(app && app.value)}
            suggestions={allApps.map(a => ({label: a.name, value: a.name}))}
          />
        </p>

        <p>
          <CheckboxLabel
            style={{marginTop: '20px'}}
            label="Select this app also for transactions with the same contact name in this account"
            control={
              <input type="checkbox"
                checked={assignToSameContactNames}
                onChange={event => setAssignToSameContactNames(event.target.checked)}
              />
            }
          />
        </p>

        <Dialog.UnstyledActions>
          <Dialog.CancelButton
            variant="outlined"
            onClick={() => onClose()}
          >
            Cancel
          </Dialog.CancelButton>
          <Dialog.SaveButton variant="outlined"
            onClick={() => onSave({appName, assignToSameContactNames, manualOverrideReason})}
            disabled={!appName}
          >
            Save
          </Dialog.SaveButton>
        </Dialog.UnstyledActions>

      </DialogContent>

    </Dialog.CenteredDialog>
  )
}
