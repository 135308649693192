import React from 'react'
import AdminMenuBar from './AdminMenuBar'
import styled from 'styled-components'

const VIDEOS = [
  {
    title: 'How to reset my password',
    href: 'reset-password',
    embedId: 'W2fOQIBQCj4'
  },
  {
    title: 'How to change my password',
    href: 'change-password',
    embedId: 'yKjy0WzaMC8'
  },
  {
    title: 'How to create a new user account',
    href: 'create-new-user',
    embedId: 'wFodRnwfa84'
  },
  {
    title: 'How to give a user access to the admin section',
    href: 'make-user-admin',
    embedId: '1BckUwnQcP0'
  },
  {
    title: 'How to deactivate and reactivate a user account',
    href: 'deactivate-user-account',
    embedId: 'kXQfjK7mE8A'
  },
  {
    title: 'How to customize the AWS Cognito emails',
    href: 'customize-cognito-emails',
    embedId: 'ZSF1tKKMblU'
  },
  {
    title: 'Where are uploaded template files stored',
    href: 'location-uploaded-template-files',
    embedId: 'vsC_sISi6wc'
  },
]

export const Container = styled.div`
  padding: 20px;
  display: flex;
`

const HelpLinkContainer = styled.div`
  width: 450px;
`

const Link = styled.div`
  color: #3f51b5;
  cursor: pointer;
  padding: 5px;
  ${props => props.active && 'font-weight: bold;'}
  ${props => props.active && `color: #da9b31;`}
`

export default class AdminHelpPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedVideo: null
    }
  }

  render() {
    const { selectedVideo } = this.state
    return (
      <div>
        <AdminMenuBar activeTab="Help" />

        <Container>
          <HelpLinkContainer>
            {VIDEOS.map(video =>
              <Link
                active={(this.state.selectedVideo && this.state.selectedVideo.embedId === video.embedId )}
                onClick={() => this.setState({selectedVideo: video})}
              >
                {video.title}
              </Link>
            )}
          </HelpLinkContainer>

          {selectedVideo &&
            <div>
              <iframe
                title={selectedVideo.title}
                width="750"
                height="435"
                src={"https://www.youtube.com/embed/" + selectedVideo.embedId}
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          }
        </Container>

      </div>
    )
  }
}





  /*
        {VIDEOS.map(video =>
          <Paper elevation={3} style={{width: 560, padding: 20, marginTop: 30}}>
            <h2 id={video.href} style={{margin: 0}}>
              {video.title}
            </h2>
            <p>
              <iframe
                title={video.title}
                width="560"
                height="315"
                src={"https://www.youtube.com/embed/" + video.embedId}
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </p>
          </Paper>
        )}
  */
