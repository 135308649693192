export const MuiButton = {
  root: {
    fontSize: '1.125rem',
    borderRadius: '20px',
    textTransform: 'unset'
  },
  sizeSmall: {
    textTransform: 'uppercase',
    fontSize: '0.875rem'
  },
  containedPrimary: {
    color: '#fff',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#e8a331'
    }
  },
  outlined: {
    '& svg': {
      marginRight: '8px'
    },
    '&$disabled': {
      backgroundColor: '#eee',
      border: '0.5px solid #ddd'
    }
  },
  contained: {
    '&$disabled': {
      backgroundColor: '#eee',
      border: '0.5px solid #ddd'
    },
    '&:hover': {
      backgroundColor: '#eda834'
    }
  },
  disabled: {}
}
