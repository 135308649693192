import React from 'react'
import styled from 'styled-components'
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/InfoOutlined'

const VisibilityToggle = styled.div`
  padding-top: 10px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  > * {
    margin-right: 10px;
  }
`

export default ({type, visibility, onChange}) => {

  let typeName
  if(type === 'DATASET') {
    typeName = 'dataset'
  } else if(type === 'TEMPLATE') {
    typeName = 'template'
  } else {
    console.error('VisibilityToggle must have a prop type with a value either TEMPLATE or DATASET.')
    return null
  }

  return(
    <VisibilityToggle>
      <input
        type="checkbox"
        checked={visibility === 'PUBLIC'}
        onChange={event => onChange(event.target.checked ? 'PUBLIC' : null)}
        value={`${visibility}`}
      />
      <span>Public {typeName}</span>
      <Tooltip placement="right" title={`A public ${typeName} can be seen by all Book of Apps users. They will see that you contributed it. Users from your company will also be able to edit it. If you do not set the dataset to public, it will only be visible and editable to users from your company.`}>
        <InfoIcon />
      </Tooltip>
    </VisibilityToggle>
  )
}
