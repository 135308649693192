import React from 'react'
import moment from 'moment'
import { ClickableTableRow, TableStandardCell } from '../Table'
import { formatNumber } from '../../appStackReports/components/util'

const TD = TableStandardCell
const TR = ClickableTableRow
const DATE_FORMAT = 'DD.MM.YYYY'

export default ({ 
  rows, columns, onItemClick 
}) => {
  return (
    <>
      {rows.map((row, i) => {
        return (
          <TR key={`row-${i}`}>
            {columns.map((column, j) => {
              const rawCellValue = row[column.key].value
              let cellValue
              if(column.type === 'date') {
                cellValue = moment(rawCellValue).format(DATE_FORMAT)
              } else if(column.type === 'currency') {
                cellValue = formatNumber(rawCellValue)
              } else if(rawCellValue !== row[column.key].component) {
                cellValue = row[column.key].component
              } else {
                cellValue = rawCellValue
              }

              return (
                <TD key={`bodyRow-${j}`}
                  onClick={(e) => {
                    e.preventDefault()
                    // TODO maybe there is a better way to get the
                    // item back, basically what was passed in as a prop
                    // at the beginning
                    const resultItem = Object.entries(row).reduce((acc, cur) => {
                      const [key, value] = cur
                      acc[key] = value.value
                      return acc
                    }, {})
                    onItemClick(resultItem, column.key)
                  }}
                >
                  {cellValue}
                </TD>
              )
            })}
          </TR>
        )
      })}
    </>
  )
}