import uuid from 'uuid/v4'

export const addQuestion = (sectionId) => ({
  type: 'ADD_QUESTION',
  payload: {
    questionId: uuid(),
    sectionId
  }
})

export const receiveQuestions = questions => ({
  type: 'RECEIVE_QUESTIONS',
  payload: {
    questions
  }
})

export const updateQuestion = (id, title, tooltip, questionType, answers, topics) => ({
  type: 'UPDATE_QUESTION',
  payload: {
    id, title, tooltip, questionType, answers, topics
  }
})

export const deleteQuestion = id => ({
  type: 'DELETE_QUESTION',
  payload: { id }
})

export const setQuestionAnswersOrder = (id, answers) => ({
  type: 'SET_QUESTION_ANSWERS_ORDER',
  payload: {
    id, answers
  }
})

export const removeAnswerFromQuestion = (answerId, questionId) => ({
  type: 'REMOVE_ANSWER_FROM_QUESTION',
  payload: { answerId, questionId }
})
