import React from 'react'
import { connect } from 'react-redux'
import IntercomWidget from '../IntercomWidget'
import MenuBar from '../components/MenuBar'
import AppStackReportsDatasetContainer from './components/AppStackReportsDatasetContainer'
import AppStackReportsDatasetContainerIframe from './components/AppStackReportsDatasetContainerIframe'
import { getQueryParameterByName } from './components/util'

const AppStackReportsDatasetPage = ({isAdmin, match}) => {
  const datasetId = match.params.datasetId
  const isInIframe = getQueryParameterByName('iframe') === 'true'

  if(isInIframe) {
    return(
      <AppStackReportsDatasetContainerIframe
        datasetId={datasetId}
      />
    )
  } else {
    return(
      <div>
        <MenuBar title='Book of Apps' />
        <AppStackReportsDatasetContainer
          isAdminArea={isAdmin}
          datasetId={datasetId}
        />
        <IntercomWidget page='APPSTACK_REPORTS_DATASET' />
      </div>
    )
  }

}

const mapStateToProps = (state) => ({
  isAdmin: (state.auth.currentRole === 'ADMIN')
})

export default connect(mapStateToProps)(AppStackReportsDatasetPage)
