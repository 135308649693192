import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

const Container = styled.div`
  text-align: center;
  padding: ${props => `${props.size}px`}
`

const ColoredCircularProgress = withStyles({
  root: {
    color: '#2e314e',
  },
})(CircularProgress);

export default ({size=100, padding}) =>
  <Container size={padding || size}>
    <ColoredCircularProgress size={size} />
  </Container>
