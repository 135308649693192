import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { API } from 'aws-amplify'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import PersonAddIcon from '@material-ui/icons/PersonAddOutlined'
import ViewCompactIcon from '@material-ui/icons/ViewCompactOutlined'
import DashboardIcon from '@material-ui/icons/DashboardOutlined'
import AddCircleIcon from '@material-ui/icons/AddCircleOutline'
import { ReactComponent as DatabaseSVG } from './assets/database.svg'

import Autocomplete from './components/Autocomplete'
import IntercomWidget from './IntercomWidget'
import CreateClientDialog from './dialogs/CreateClientDialog'
import MenuBar from './components/MenuBar'
import LoadingSpinner from './components/LoadingSpinner'
import {
  TableContainer, ClickableTableRow, TableHeaderCell, TableStandardCell
} from './components/Table'
import { Heading1, MainContent } from './components/Basic'
import {
  APP_ID_ARCHITECTURE_SELECTOR,
  APP_ID_INVENTORY_APP_SELECTOR,
  APP_ID_APPSTACK_DESIGNER
} from './AppConfig'
import styled from 'styled-components'
import { withStyles } from '@material-ui/core/styles'

const FilterGrid = styled(Grid)`
  @media (min-width: 1280px) {
    display: flex;
    justify-content: flex-end;
  }
`

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column

  > label, > div {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  @media (min-width: 990px) {
    flex-direction: row;
    align-items: center;

    > label, > div {
      margin-bottom: 0;
    }
  }
`

const Filter = styled.div`
  width: 340px;
`

const HeaderCell = withStyles({
  root: {
    '& span': {
      position: 'relative',
      display: 'inline-block',
      width: '24px',
      height: '14px'
    },
    '& svg': {
      position: 'absolute',
      top: '-3.5px'
    }
  }
})(TableHeaderCell)

const StandardCell = withStyles({
  root: {
    lineHeight: '2'
  }
})(TableStandardCell)

const AddButton = withStyles({
  root: {
    color: '#da9b31',
    padding: '4px',
    position: 'relative',
    top: '-1.75px'
  }
})(IconButton)

const IndustryTag = styled.span`
  font-size: 12px;
  color: #a9cf54;
  padding: 8px 15px;
  border: 1px solid #a9cf54;
  border-radius: 20px;
`

const getOwnerFilterItem = (user) => user && ({ value: user.userId, label: `${user.firstName} ${user.lastName}`})

class DashboardPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      filterType: null,
      coworkers: [],
      clients: null,
      savedSearches: null,
      industries: [],
      isCreateClientDialogOpen: false,
      selectedOwner: null,
      selectedIndustry: null
    }
  }

  async componentDidMount() {
    const [coworkers, clients, savedSearches, industries] = await Promise.all([
      API.get("dev-d1-apps-backend", '/coworkers'),
      API.get("dev-d1-apps-backend", '/clients'),
      API.get("dev-d1-apps-backend", '/savedSearches'),
      API.get("d1-knowledgebase", '/content/synonyms?type=Industry')
    ])
    this.setState({
      coworkers,
      clients,
      savedSearches,
      industries
    })
  }

  async closeCreateClientDialog(newClientCreated) {
    if(newClientCreated) {
      const clients = await API.get("dev-d1-apps-backend", '/clients')
      const savedSearches = await API.get("dev-d1-apps-backend", '/savedSearches')
      this.setState({
        clients,
        savedSearches,
        isCreateClientDialogOpen: false
      })
    } else {
      this.setState({
        isCreateClientDialogOpen: false
      })
    }
  }

  showClientsDetails(client) {
    this.props.history.push('/clients/' + client.clientId)
  }

  getSavedSearchesText(appId, clientId) {
    const count = this.state.savedSearches
      .filter(item => item.appId === appId && item.clientId === clientId)
      .length
    if(count > 0) {
      return count + ' records found'
    } else {
      return null
    }
  }

  render() {

    const ownerFilterSuggestions = this.state.coworkers
      .filter(x => !!x.userId)
      .map(x => getOwnerFilterItem(x))
      .sort((a, b) => a.label.localeCompare(b.label))

    const industryFilterSuggestions = this.state.industries && this.state.industries
      .map(x => ({value: x.name, label: x.name}))
      .sort((a, b) => a.label.localeCompare(b.label))

    const filterTypeValue = this.state.filterType && this.state.filterType.value

    return(
      <div>

        <MenuBar title='Book of Apps' />

        <MainContent>

          <Grid container alignItems="center">

            <Grid item xs={12} lg={3}>
              <Heading1>My Clients</Heading1>
            </Grid>

            <FilterGrid item xs={12} lg={9}>
              <FilterContainer>
                  <label htmlFor="autocomplete">Filter by</label>

                  <Filter>
                    <Autocomplete
                      value={this.state.filterType}
                      onChange={filterType => this.setState({filterType, selectedIndustry: null, selectedOwner: null})}
                      suggestions={[{value: 'OWNER', label: 'Owner'}, {value: 'INDUSTRY', label: 'Industry'}]}
                      placeholder='Select type (i.e. Industry, Owner, etc.)'
                    />
                  </Filter>

                  {filterTypeValue === null &&
                    <Filter>
                      <Autocomplete
                        value={null}
                        suggestions={[]}
                        placeholder='Select filter type first to enable'
                        disabled={true}
                      />
                    </Filter>
                  }

                  {filterTypeValue === 'OWNER' &&
                    <Filter>
                      <Autocomplete
                        value={ownerFilterSuggestions.filter(item => item.value === (this.state.selectedOwner && this.state.selectedOwner.value))}
                        onChange={selectedOwner => this.setState({selectedOwner})}
                        suggestions={ownerFilterSuggestions}
                        placeholder='Select an owner' disabled={false}
                      />
                    </Filter>
                  }

                  {filterTypeValue === 'INDUSTRY' &&
                    <Filter>
                      <Autocomplete
                        value={industryFilterSuggestions.filter(item => item.value === (this.state.selectedIndustry && this.state.selectedIndustry.value))}
                        onChange={selectedIndustry => this.setState({selectedIndustry})}
                        suggestions={industryFilterSuggestions}
                        placeholder='Select an industry' disabled={false}
                      />
                    </Filter>
                  }

                  <Button color="primary" variant="outlined" onClick={() => this.setState({isCreateClientDialogOpen: true})}>
                    <PersonAddIcon />
                    Create Client
                  </Button>
              </FilterContainer>
            </FilterGrid>

          </Grid>

          {!this.state.clients && <LoadingSpinner />}

          {this.state.clients && this.state.clients.length === 0 && <i>No clients yet.</i> }

          {this.state.clients && this.state.clients.length > 0 &&
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <HeaderCell>Name</HeaderCell>
                    <HeaderCell>Company</HeaderCell>
                    <HeaderCell>Industry</HeaderCell>
                    <HeaderCell><span><ViewCompactIcon /></span> Architecture Selector</HeaderCell>
                    <HeaderCell><span><DatabaseSVG /></span> Inventory App Selector</HeaderCell>
                    <HeaderCell><span><DashboardIcon /></span> Appstack Designer</HeaderCell>
                    <HeaderCell>Owner</HeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.clients.map(client => {

                    if(this.state.selectedOwner && this.state.selectedOwner.value !== client.userId) {
                      return null
                    }

                    if(this.state.selectedIndustry && this.state.selectedIndustry.value !== client.industry) {
                      return null
                    }

                    const coworker = this.state.coworkers.find(coworker => coworker.userId === client.userId)
                    const owner = coworker && `${coworker.firstName} ${coworker.lastName}`
                    return(
                      <ClickableTableRow
                        key={client.clientId}
                      >
                        <StandardCell onClick={() => this.showClientsDetails(client)} >{client.firstName + ' ' + client.lastName}</StandardCell>
                        <StandardCell onClick={() => this.showClientsDetails(client)} >{client.companyName}</StandardCell>
                        <StandardCell onClick={() => this.showClientsDetails(client)} ><IndustryTag>{client.industry}</IndustryTag></StandardCell>

                        <StandardCell>
                          <AddButton aria-label="Add"
                            onClick={() => this.props.history.push('/apps/' + APP_ID_ARCHITECTURE_SELECTOR + '/clients/' + client.clientId)}
                          >
                            <AddCircleIcon />
                          </AddButton>
                          <Link to={'/clients/' + client.clientId}>
                            {this.getSavedSearchesText(APP_ID_ARCHITECTURE_SELECTOR, client.clientId)}
                          </Link>
                        </StandardCell>

                        <StandardCell>
                          <AddButton
                            onClick={() => this.props.history.push('/apps/' + APP_ID_INVENTORY_APP_SELECTOR + '/clients/' + client.clientId)}
                          >
                            <AddCircleIcon />
                          </AddButton>
                          <Link to={'/clients/' + client.clientId}>
                            {this.getSavedSearchesText(APP_ID_INVENTORY_APP_SELECTOR, client.clientId)}
                          </Link>
                        </StandardCell>

                        <StandardCell>
                          <AddButton
                            onClick={() => this.props.history.push('/apps/' + APP_ID_APPSTACK_DESIGNER + '/clients/' + client.clientId)}
                          >
                            <AddCircleIcon />
                          </AddButton>
                          <Link to={'/clients/' + client.clientId}>
                            {this.getSavedSearchesText(APP_ID_APPSTACK_DESIGNER, client.clientId)}
                          </Link>
                        </StandardCell>

                        <StandardCell>
                          {owner}
                        </StandardCell>

                      </ClickableTableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          }

          <CreateClientDialog
            onClose={newClientCreated => this.closeCreateClientDialog(newClientCreated)}
            open={this.state.isCreateClientDialogOpen}
            industries={this.state.industries}
          />
        </MainContent>

        <IntercomWidget page='DASHBOARD' />

      </div>
    )
  }

}

export default withRouter(DashboardPage)
