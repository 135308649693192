import React from 'react'
import styled from 'styled-components'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import QuestionItem from './QuestionItem'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'

const GridContainer = styled(Grid)`
  padding: 0 20px;
`

const Heading1 = styled.h1`
  font-size: 40px;
  font-weight: normal;
  text-transform: capitalize;
  color: #2e314e;
`

const ButtonsContainer = styled.div`
  @media (min-width: 600px) {
    display: flex;
    justify-content: flex-end;
  }
`

const ChangeButton = withStyles({
  root: {
    width: '140px',
    justifyContent: 'space-between',
    '& svg': {
      marginRight: 0
    }
  }
})(Button)

const ArrowButton = styled(({ color, ...otherProps }) => <ChangeButton {...otherProps} />)`
  border-color: ${props => props.color} !important;
  color: ${props => props.color} !important;

  &:first-child {
    margin-right: 20px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export default ({title, questions, answers, handleChangeAnswer, nextButton, prevButton, openWikiPage}) =>
  <div>
    <GridContainer container alignItems="center">
      <Grid item xs={12} sm={6} md={8}>
        <Heading1>{title}</Heading1>
      </Grid>
      <Grid item xs={12} sm={6} md={4} direction="row">
        <ButtonsContainer>
          {prevButton &&
            <ArrowButton
              variant="outlined"
              color={prevButton.color}
              onClick={prevButton.onClick}
            >
              <KeyboardArrowLeftIcon />
              Prev
            </ArrowButton>
          }
          {nextButton &&
            <ArrowButton
              variant="outlined"
              color={nextButton.color}
              onClick={nextButton.onClick}
            >
              Next
              <KeyboardArrowRightIcon />
            </ArrowButton>
          }
        </ButtonsContainer>
      </Grid>
    </GridContainer>
    <Container>
      {questions.map(question =>
        <QuestionItem
          key={question.id}
          question={question}
          answerIds={answers[question.id]}
          handleChangeAnswer={handleChangeAnswer}
          openWikiPage={openWikiPage}
        />
      )}
    </Container>
  </div>
