import React, { useState, useEffect } from 'react'
import { API } from 'aws-amplify'
import styled from 'styled-components'
import MenuBar from '../components/MenuBar'
import LoadingSpinner from '../components/LoadingSpinner'
import { MainContent } from '../components/Basic'
import { getHeadersForUserRole } from '../utils'
import IntercomWidget from '../IntercomWidget'
import TextField from '@material-ui/core/TextField'
import * as Dialog from '../components/Dialog'
import { TallAutocomplete } from '../components/Basic'
import TableRowActionsDropDown from '../components/TableRowActionsDropDown'
import GenericTable from '../components/GenericTable/GenericTable'

const KeywordAppPair = styled.div`
  display: flex;
  > * {
    flex: 1;
    margin: 0 10px;
  }
`

export default () => {
  const [isLoading, setIsLoading] = useState(true)
  const [selectedDetectionOverride, setSelectedDetectionOverride] = useState()
  const [detectionOverrides, setDetectionOverrides] = useState([])
  const [allApps, setAllApps] = useState([])

  const loadData = async () => {
    const [detectionOverrides, apps] = await Promise.all([
      API.get("dev-d1-apps-backend", '/appStackDetectionOverrides', { headers: getHeadersForUserRole() }),
      API.get("d1-knowledgebase", `/content/apps?type=minimalist`),
    ])

    const allApps = apps
      .map(a => ({name: a.Name, category: a.MainCategory}))
      .sort((a, b) => a.name.localeCompare(b.name))
    setAllApps(allApps)
    setDetectionOverrides(detectionOverrides)
    setIsLoading(false)
  }

  useEffect(() => {
    loadData()
  }, [])


  const saveDetectionOverride = async () => {
    const { keyword, reason, appName } = selectedDetectionOverride
    const data = { keyword, reason, appName }
    if(selectedDetectionOverride.id) {
      await API.put("dev-d1-apps-backend", `/appStackDetectionOverrides/${selectedDetectionOverride.id}`, {
        body: data
      })
      const newDetectionOverrides = detectionOverrides.map(d => {
        return (d.id === selectedDetectionOverride.id) ? selectedDetectionOverride: d
      })
      setDetectionOverrides(newDetectionOverrides)
    } else {
      const result = await API.post("dev-d1-apps-backend", `/appStackDetectionOverrides`, {
        body: data
      })
      setDetectionOverrides([...detectionOverrides, {...selectedDetectionOverride, id: result.id}])
    }
    setSelectedDetectionOverride(null)
  }

  const deleteDetectionOverride = async (id) => {
    await API.del("dev-d1-apps-backend", `/appStackDetectionOverrides/${id}`)
    setDetectionOverrides(detectionOverrides.filter(d => d.id !== id))
    setSelectedDetectionOverride(null)
  }

  if(isLoading) {
    return(
      <div>
        <MenuBar title='Book of Apps' activeTab="Keywords" />
        <LoadingSpinner />
      </div>
    )
  }

  const columns = [
    {
      title: 'Keyword',
      key: 'keyword',
      type: 'string'
    },
    {
      title: 'Linked to app',
      key: 'appName',
      type: 'string'
    },
    {
      title: '',
      key: 'actions',
    }
  ]

  const tableData = detectionOverrides.map(d => {
    const entry = {
      ...d,
      appName: {
        value: d.appName || '-- Ignore --',
        component: d.appName || <i>{`Transactions containing "${d.keyword}" will be ignored.`}</i>
      },
      actions: {
        value: '',
        component: (
          <TableRowActionsDropDown
            actions={[
              {
                label: 'Edit',
                onClick: () => setSelectedDetectionOverride(d)
              },
              {
                label: 'Delete',
                onClick: () => deleteDetectionOverride(d.id)
              }
            ]}
          />
        )
      }
    }
    return entry
  })

  return(
    <div>
      <MenuBar title='Book of Apps' activeTab="Keywords" />
      <MainContent>
        {detectionOverrides.length === 0 && <i>No keywords defined.</i> }

        {detectionOverrides.length > 0 &&
          <GenericTable
            title="Keywords"
            initialSortColumn="keyword"
            columns={columns}
            data={tableData}
            onItemClick={(row, columnKey) => console.log(row)}
            actionButtons={[
              {
                label: 'New',
                onClick: () => setSelectedDetectionOverride({keyword:''}),
                disabled: false
              }
            ]}
            searchPlaceholder={'Search keyword'}
          />
        }

        <Dialog.DialogContainer
          open={!!selectedDetectionOverride}
          onClose={() => setSelectedDetectionOverride(null)}
        >
          <Dialog.FormTitle>
            Set up keyword
          </Dialog.FormTitle>
          <Dialog.DialogContentContainer>
            <KeywordAppPair>
              <TextField
                type="text"
                variant="outlined"
                placeholder="Keyword"
                value={selectedDetectionOverride && selectedDetectionOverride.keyword}
                onChange={(e) => setSelectedDetectionOverride({
                  ...selectedDetectionOverride,
                  keyword: e.target.value
                })}
                fullWidth
              />
              <TallAutocomplete
                value={{
                  label: selectedDetectionOverride && selectedDetectionOverride.appName,
                  value: selectedDetectionOverride && selectedDetectionOverride.appName
                }}
                onChange={(app) => setSelectedDetectionOverride({...selectedDetectionOverride, appName: app && app.value})}
                suggestions={allApps.map(a => ({label: a.name, value: a.name}))}
                placeholder="Select app"
                fullWidth
              />
            </KeywordAppPair>

            <p>
              If you don't select and app, then all transactions containing the keyword will be ignored for the analysis.
            </p>

            <Dialog.Actions>
              <Dialog.CancelButton variant="outlined" onClick={() => setSelectedDetectionOverride(null)}>
                Cancel
              </Dialog.CancelButton>
              <Dialog.SaveButton variant="outlined"
                onClick={() => saveDetectionOverride()}
              >
                Save
              </Dialog.SaveButton>
            </Dialog.Actions>

          </Dialog.DialogContentContainer>
        </Dialog.DialogContainer>



      </MainContent>

      <IntercomWidget page='APPSTACK_REPORTS_DETECTION_OVERRIDES' />
    </div>
  )

}
