import React, { Component } from 'react'
import styled from 'styled-components'
import { API } from 'aws-amplify'
import Table from './Table'
import ResolveErrorDialog from './ResolveErrorDialog'
import ImportRunningDialog from './ImportRunningDialog'
import BulkImportDialog from './BulkImportDialog'
import AdminMenuBar from '../AdminMenuBar'
import LoadingSpinner from '../../components/LoadingSpinner'
import Button from '@material-ui/core/Button'
import Measure from 'react-measure'
import Tabs from './Tabs'

const Content = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  top: 105px;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
`

const InfoHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: 65px;
`

const InfoItem = styled.div`
  display: flex;
  margin-bottom: 10px;
`

const Key = styled.div`
  width: 100px;
`

const Value = styled.div`
  font-weight: bold;
`

const ActionButtons = styled.div`
  button {
    margin-left: 10px;
  }
`

const ActionButtonsMessage = styled.div`
  color: red;
  padding-top: 5px;
`

const extractCellValue = (value) => {
  switch (typeof value) {
    case 'string':
      return value
    case 'number':
      return value
    case 'object':
      if(Array.isArray(value)) {
        return value.map(x => extractCellValue(x)).filter(x => !!x)
      } else {
        return ''
      }
    default:
      return ''
  }
}

export default class KnowledgebaseMappingsPage extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isEditingMapping: false,
      isImporting: false,
      errorToResolve: null,
      isSavingResolveErrorDialog: false,
      bulkImportParams: null,
      showOnlyErrorLines: false,
      schema: null,
      mapping: null,
      selectedTargetTable: null,
      scraperRun: null,
      scraperResults: [],
      dimensions: {
        width: -1,
        height: -1,
      }
    }
  }

  async componentDidMount() {
    const scraperRunId = this.props.match.params.scraperRunId
    const [scraperRun, scraperResults, schema] = await Promise.all([
      API.get("d1-knowledgebase", '/scraperRuns/' + scraperRunId),
      API.get("d1-knowledgebase", '/scraperResults/' + scraperRunId),
      API.get("d1-knowledgebase", '/schemas')
    ])
    const mappingResult = await API.get("d1-knowledgebase", '/mappings/' + scraperRun.scraperId)
    const mapping = (mappingResult && mappingResult.config) || {}
    const mappedTables = Object.keys(mapping)
    const selectedTargetTable = (mappedTables.length > 0 && mappedTables[0]) || schema[0].table

    this.setState({
      scraperRun,
      scraperResults,
      schema,
      mapping,
      selectedTargetTable
    })
  }

  async saveMapping() {
    const config = Object.keys(this.state.mapping).reduce((acc, targetTable) => {
      const fields = this.state.mapping[targetTable].filter(field => field.targetField !== 'IGNORE')
      if(fields.length > 0) {
        acc[targetTable] = fields
      }
      return acc
    }, {})
    await API.put("d1-knowledgebase", `/mappings/${this.state.scraperRun.scraperId}`, {
      body: {
        config
      }
    })

    this.setState({isEditingMapping: false})
    this.startImport({targetTable: this.state.selectedTargetTable})
  }

  updateMappedField({sourceField, targetField, fixedValue}) {
    const targetTable = this.state.selectedTargetTable
    const mapping = { ...this.state.mapping }
    mapping[targetTable] = mapping[targetTable] ? [ ...mapping[targetTable] ] : []

    if(fixedValue) {
      const field = mapping[targetTable].find(f => f.fixedValue === fixedValue)
      if(field) {
        field.targetField = targetField
      } else {
        mapping[targetTable].push({fixedValue, targetField})
      }
    } else {
      const field = mapping[targetTable].find(f => f.sourceField === sourceField)
      if(field) {
        field.targetField = targetField
      } else {
        mapping[targetTable].push({sourceField, targetField})
      }
    }
    this.setState({mapping})
  }

  async startImport({targetTable, entryId, additionalFields}) {
    this.setState({isImporting: true})
    const scraperRunId = this.props.match.params.scraperRunId
    try {
      await API.put("d1-knowledgebase", '/scraperImports/' + scraperRunId, {
        body: {
          targetTable,
          entryId,
          additionalFields
        }
      })
      const scraperRun = await API.get("d1-knowledgebase", '/scraperRuns/' + scraperRunId)
      const scraperResults = await API.get("d1-knowledgebase", '/scraperResults/' + scraperRunId)
      this.setState({scraperRun, scraperResults, isImporting: false})
    } catch (error) {
      this.setState({isImporting: false})
      console.log(error)
      window.location.reload()
    }
  }

  async addToSynonyms({type, name, synonym, entryId}) {
    await API.post("d1-knowledgebase", '/synonyms', {
      body: {
        type,
        name,
        synonym
      }
    })
    this.startImport({entryId})
  }

  async addMultipleToSynonyms({type, names}) {
    const items = names.map(name => ({type, name, synonym: name}))
    await API.post("d1-knowledgebase", '/synonyms', {
      body: items
    })
    this.setState({bulkImportParams: null})
    this.startImport({targetTable: this.state.selectedTargetTable})
  }

  async updateScraperResultItem({scraperRunId, entryId, status}) {
    await API.put("d1-knowledgebase", '/scraperResults/' + scraperRunId, {
      body: {
        entryId,
        status
      }
    })
    this.startImport({entryId})
  }

  showResolveErrorDialog(entryId, cell, index) {
    const column = this.findTargetColumnBySourceColumnName(cell.name)
    if(!column) {
      return
    }
    const errorToResolve = {
      entryId,
      targetType: column.type,
      synonym: (index != null) ? cell.value[index] : cell.value
    }
    this.setState({errorToResolve, isSavingResolveErrorDialog: false})
  }

  async resolveError({action, type, name, synonym}) {
    this.setState({isSavingResolveErrorDialog: true})
    const entryId = this.state.errorToResolve.entryId
    switch (action) {
      case 'ADD_ITEM':
      case 'ADD_SYNONYME':
        await this.addToSynonyms({type, name, synonym, entryId})
        break;
      case 'IGNORE_ITEM':
        await this.addToSynonyms({type, name: '_IGNORE_', synonym, entryId})
        break;
      case 'IGNORE_LINE':
        const scraperRunId = this.props.match.params.scraperRunId
        await this.updateScraperResultItem({scraperRunId, entryId, status: 'IGNORE'})
        break;
      default:
        // do nothing
    }
    this.setState({errorToResolve: null, isSavingResolveErrorDialog: false})
  }

  findTargetColumnBySourceColumnName(name) {
    const mappedFields = (this.state.mapping[this.state.selectedTargetTable]) || []
    const mappedField = mappedFields.find(f => f.sourceField === name)
    const tableSchema = this.state.schema.find(entry => entry.table === this.state.selectedTargetTable)
    const column = tableSchema.columns.find(c => c.name === mappedField.targetField)
    return column
  }

  async onAppBulkImportClick(sourceColumnName) {
    const column = this.findTargetColumnBySourceColumnName(sourceColumnName)
    const names = this.state.scraperResults
      .filter(entry => entry.errors[this.state.selectedTargetTable] && entry.errors[this.state.selectedTargetTable].find(err => err.field === column.name))
      .map(entry => entry.content[sourceColumnName])
      .filter(value => !!value)
    const bulkImportParams = {
      type: column.type,
      names
    }
    this.setState({bulkImportParams})
  }

  render() {
    if(!this.state.schema) {
      return(
        <div>
          <AdminMenuBar activeTab="Knowledgebase" />
          <LoadingSpinner />
        </div>
      )
    }

    const columns = this.state.scraperResults
      .reduce((acc, cur) => {
        Object.keys(cur.content).forEach(key => {
          if(acc.indexOf(key) < 0) {
            acc.push(key)
          }
        })
        return acc
      }, [])
      .map(key => ({name: key}))

    const tableSchema = this.state.schema.find(entry => entry.table === this.state.selectedTargetTable)
    const mappedFields = (this.state.mapping[this.state.selectedTargetTable]) || []

    const notMappedKeyColumnNames = tableSchema.columns
      .filter(c => c.isKey)
      .filter(c => {
        const mappedKeyField = mappedFields.find(f => f.targetField === c.name)
        return !mappedKeyField
      })
      .map(c => c.name)

    const data = this.state.scraperResults
      .filter(row => !this.state.showOnlyErrorLines || (row.errors && row.errors[this.state.selectedTargetTable] && row.errors[this.state.selectedTargetTable].length))
      .map(row => ({
        entryId: row.entryId,
        status: row.status,
        cells: columns.map(col => {
          const mappedField = mappedFields.find(f => f.sourceField === col.name)
          const error = row.errors
            && row.errors[this.state.selectedTargetTable]
            && mappedField
            && row.errors[this.state.selectedTargetTable].find(e => e.field === mappedField.targetField)

          return {
            name: col.name,
            value: extractCellValue(row.content[col.name]),
            error
          }
        })
      }))

    data.sort((a, b) => Number(a.entryId) - Number(b.entryId))

    const [appName] = this.state.scraperRun.scraperId.split('_')
    const appNameTargetFieldValue = mappedFields
      .filter(f => f.fixedValue === appName)
      .map(f => f.targetField)
      .reduce((acc, cur) => cur, 'IGNORE')

    return (
      <div>
        <AdminMenuBar activeTab="Knowledgebase" />
        <Tabs activeTab='Scrapers' />
        <Content>

          <InfoHeader>

            <div>
              <InfoItem>
                <Key>Scraper:</Key>
                <Value>{this.state.scraperRun.scraperId.replace('_', ' / ')}</Value>
              </InfoItem>
              <InfoItem>
                <Key>Date/Time:</Key>
                <Value>{this.state.scraperRun.datetime}</Value>
              </InfoItem>
            </div>

            <div>
              <InfoItem>
                <Key>Target tables:</Key>
                <Value>
                  <select
                    value={this.state.selectedTargetTable}
                    onChange={(event) => this.setState({selectedTargetTable: event.target.value})}
                  >
                    {this.state.schema.map(entry => {
                      const tableIsMapped = Object.keys(this.state.mapping).indexOf(entry.table) >= 0
                      return(
                        <option key={entry.table} value={entry.table}>
                          {entry.table} {tableIsMapped ? '(mapped)' : ''}
                        </option>
                      )
                    })}
                  </select>
                </Value>
              </InfoItem>
            </div>

            <div>
              <div>
                <b>Fixed values</b>
              </div>
              <b>{appName}</b>
              {` into field `}
              {this.state.isEditingMapping
                ? <select value={appNameTargetFieldValue} onChange={event => this.updateMappedField({fixedValue: appName, targetField: event.target.value})}>
                    <option value='IGNORE'>IGNORE</option>
                    {tableSchema.columns.map(column =>
                      <option key={column.name} value={column.name}>
                        {column.name}
                      </option>
                    )}
                  </select>
                : <b>{appNameTargetFieldValue}</b>
              }
            </div>

            <div>
              <InfoItem>
                <Key>Errors:</Key>
                <Value>{this.state.scraperRun.linesWithErrors}</Value>
              </InfoItem>
              <label>
                <input
                  type="checkbox"
                  checked={this.state.showOnlyErrorLines}
                  onChange={event => this.setState({showOnlyErrorLines: event.target.checked})}
                /> Show error lines only
              </label>
            </div>

            <ActionButtons>
              <div>
                {this.state.isEditingMapping
                  ? <Button size='small' onClick={() => this.saveMapping()} variant='contained'>Save mapping</Button>
                  : <Button size='small' onClick={() => this.setState({isEditingMapping: true})} variant='contained'>Edit mapping</Button>
                }
                {this.state.isImporting
                  ? <Button size='small' disabled={true} variant='contained'>Import running ...</Button>
                  : <Button size='small' disabled={this.state.isEditingMapping || (notMappedKeyColumnNames.length > 0)} onClick={() => this.startImport({targetTable: this.state.selectedTargetTable})} variant='contained'>Import all</Button>
                }
              </div>
              <ActionButtonsMessage>
                {(notMappedKeyColumnNames.length > 0) && `Not mapped keys: ${notMappedKeyColumnNames.join(', ')}`}
              </ActionButtonsMessage>
            </ActionButtons>

          </InfoHeader>


          <Measure bounds onResize={contentRect => this.setState({dimensions: contentRect.bounds})}>
            {({ measureRef }) => (
              <div ref={measureRef} style={{flex: 1}}>

                <Table
                  width={this.state.dimensions.width}
                  height={this.state.dimensions.height}
                  isEditingMapping={this.state.isEditingMapping}
                  tableSchema={tableSchema}
                  mappedFields={(this.state.mapping[this.state.selectedTargetTable]) || []}
                  updateMappedField={({sourceField, targetField}) => this.updateMappedField({sourceField, targetField})}
                  columns={columns}
                  data={data}
                  handleClickOnError={(entryId, cell, index) => this.showResolveErrorDialog(entryId, cell, index)}
                  onAppBulkImportClick={(columnName) => this.onAppBulkImportClick(columnName)}
                />

              </div>
            )}
          </Measure>


        </Content>

        {this.state.errorToResolve &&
          <ResolveErrorDialog
            targetType={this.state.errorToResolve.targetType}
            synonym={this.state.errorToResolve.synonym}
            onClose={() => this.setState({errorToResolve: null})}
            onSave={(event) => this.resolveError(event)}
            isSaving={this.state.isSavingResolveErrorDialog}
          />
        }

        {this.state.isImporting &&
          <ImportRunningDialog />
        }

        {this.state.bulkImportParams &&
          <BulkImportDialog
            params={this.state.bulkImportParams}
            onClose={() => this.setState({bulkImportParams: null})}
            onSave={() => this.addMultipleToSynonyms(this.state.bulkImportParams)}
          />
        }

      </div>
    )
  }

}
