import React from 'react'
import styled from 'styled-components'

const Panel = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`

const Button = styled.div`
  color: #0084ff;
  background-color: white;
  border: 1px solid #0084ff;
  border-radius: 20px;
  min-width: 50px;
  text-align: center;
  cursor: pointer;
  padding: 10px 15px;
  margin: 5px;
`

export default ({buttons, onClick}) => {
  if(!buttons) {
    return null
  } else {
    return(
      <Panel>
        {buttons.map((button, index) =>
          <Button key={index} onClick={() => onClick(button.value)}>
            {button.text}
          </Button>
        )}
      </Panel>
    )
  }
}
