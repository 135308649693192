import React from 'react'
import styled from 'styled-components'
import { Parser, ProcessNodeDefinitions } from 'html-to-react'

const WikiLink = styled.span`
  color: #069;
  text-decoration: underline;
  cursor: pointer;
`

const isValidNode = () => true

const getProcessingInstructions = (handleWikiLink) => [
  {
    shouldProcessNode: (node) => {
      return (
        node.name === 'a' && node.attribs &&
        (node.attribs['class'] === 'wysiwyg-mention' || node.attribs['href'].startsWith('wiki#'))
      )
    },
    processNode: (node, children, index) => {
      let parts = node.attribs['href'].split('/')
      parts = parts[parts.length - 1].split('#')
      const pageId = parts[parts.length - 1]
      const text = node.attribs['data-value'] || children
      return(
        <WikiLink key={index} onClick={() => handleWikiLink(pageId)}>
          {text}
        </WikiLink>
      )
    }
  },
  {
    shouldProcessNode: (node) => true,
    processNode: new ProcessNodeDefinitions(React).processDefaultNode
  }
]

export default (content, handleWikiLink) => {
  const processingInstructions = getProcessingInstructions(handleWikiLink)
  const parser = new Parser()
  const result = parser.parseWithInstructions(content, isValidNode, processingInstructions)
  return result
}
