import React from 'react'
import styled from 'styled-components'

const UserMessage = styled.div`
  margin: 15px;
  margin-left: 50px;
  display: flex;
  flex-direction: row-reverse;

  > div {
    color: white;
    background-color: #0084ff;
    border-radius: 20px;
    min-width: 50px;
    text-align: center;
  }
`

export default ({message}) =>
  <UserMessage>
    <div>
      {message.lines.map((line, lineIndex) =>
        <div key={lineIndex} style={{margin: '10px 15px'}}>
          {line.text}
        </div>
      )}
    </div>
  </UserMessage>
