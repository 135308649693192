import React from 'react'
import { connect } from 'react-redux'
import Tooltip from '@material-ui/core/Tooltip'
import VisibilityIcon from '@material-ui/icons/Visibility'

const VisibilityTooltip = ({dataset, template, myCompanyId}) => {

  let props
  if(dataset) {
    props = {...dataset, typeName: 'dataset'}
  } else if(template) {
    props = {...template, typeName: 'template'}
  } else {
    console.error('VisibilityTooltip must have a prop either template or dataset.')
    return null
  }

  if(props.visibility === 'PUBLIC') {
    const ownedByMyCompany = (props.companyId === myCompanyId)
    const color = ownedByMyCompany ? 'green' : 'black'
    const title = ownedByMyCompany
      ? `Your company has set this ${props.typeName} to be public. That means any Book of Apps user can see this ${props.typeName}. But only users from your company can edit it.`
      : `This is a public ${props.typeName} contributed by ${props.userName} (${props.companyName}). You will only be able to view this ${props.typeName}, but you cannot edit it.`
    return(
      <Tooltip title={title} placement="bottom">
        <VisibilityIcon style={{color, verticalAlign: 'middle'}} />
      </Tooltip>
    )
  } else {
    return null
  }
}

const mapStateToProps = (state) => ({
  myCompanyId: state.auth.currentCompany.companyId
})

export default connect(mapStateToProps)(VisibilityTooltip)
