import React, { useState, useEffect, useCallback } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { API } from 'aws-amplify'
import LoadingSpinner from '../components/LoadingSpinner'
import IntercomWidget from '../IntercomWidget'
import MenuBar from '../components/MenuBar'
import DataUsageIcon from '@material-ui/icons/DataUsage'
import CloseIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'
import { MainContent } from '../components/Basic'
import GenericTable from '../components/GenericTable/GenericTable'
import Grid from '@material-ui/core/Grid';
import * as Dialog from '../components/Dialog'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'

const DATE_AND_TIME_FORMAT = 'DD.MM.YYYY - HH:mm'

const Link = styled.div`
  color: blue;
  cursor: pointer;
  font-size: 12px;
`

const DataProvider = styled.div`
  display: flex;
  justify-content: center;
  margin: 35px 0;
`

const DialogTitle = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;

  > div:first-of-type {
    padding: 0;
  }
`

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 10px;
  border: 1px solid #da9b31;
  border-radius: 4px;
  margin-right: 25px;

  img {
    height: 100px;
    padding-bottom: 10px;
    cursor: pointer;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &:hover {
    box-shadow: 0px 0px 19px -4px rgba(0,0,0,0.32);
  }
`

const P = styled.p`
  margin: 0
`

export default withRouter(({history}) => {
  const [isLoading, setLoading] = useState(true)
  const [organisations, setOrganisations] = useState([])
  const [selectedOrgForImport, setSelectedOrgForImport] = useState(null)
  const [selectedDatasetWithError, setSelectedDatasetWithError] = useState(null)
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const mergeOrganistions = useCallback(({organisations, statusList}) => {

    const statusMap = statusList.reduce((acc, cur) => {
      acc[cur.dataSourceTenantId] = cur
      return acc
    }, {})
  
    const result = organisations.map(org => {
  
      let lastImportLink = null
      let actionComponent = (
        <Button
          color="primary"
          variant="outlined"
          onClick={() => setSelectedOrgForImport(org)}
        >
          Start Import
        </Button>
      )
      
      const status = statusMap[org.tenantId]
      if(status) {
        if(status.importStatus === 'DONE') {
          lastImportLink = (
            <Link onClick={() => history.push(`/appStackReports/datasets/${status.datasetId}`)}>
              {moment(status.createdAt).format(DATE_AND_TIME_FORMAT)}
            </Link>
          )
        } else if(status.importStatus === 'ERROR') {
          lastImportLink = (
            <Link onClick={() => setSelectedDatasetWithError(status.datasetId)}>
              Error on last import
            </Link>
          )
        } else if(status.importStatus === 'NEW' || status.importStatus === 'RUNNING') {
          actionComponent = (
            <>
              Import running <LoadingSpinner size={25} padding={5} />
            </>
          )
          
        }
      }

      return {
        ...org,
        lastImportLink: {
          value: '',
          component: lastImportLink
        },
        actions: {
          value: '',
          component: (
            <Grid container={true} direction="row-reverse">
              {actionComponent}
            </Grid>
          )
        }
      }
    })
  
    return result
  }, [history])

  useEffect(() => {
    let intervalId = null

    const loadData = async () => {
      const [organisations, statusList] = await Promise.all([
        API.get("dev-d1-apps-backend", '/xeroOrganisations'),
        API.get("dev-d1-apps-backend", '/appStackReportsStatus'),
      ])

      const mergedOrganistions =  mergeOrganistions({organisations, statusList})
      setOrganisations(mergedOrganistions)
      setLoading(false)

      intervalId = setInterval(() => {
        API.get("dev-d1-apps-backend", '/appStackReportsStatus').then(statusList => {
          const mergedOrganistions = mergeOrganistions({organisations, statusList})
          setOrganisations(mergedOrganistions)
        })
      }, 10000)
    }

    loadData()
    return () => clearInterval(intervalId)
  }, [mergeOrganistions])

  const onProviderSelected = async (providerName) => {
    setLoading(true)
    setIsDialogOpen(false)
    const authorizeUrl = await API.get("default", `/appStack${providerName}Imports/authorizeUrl`)
    window.location.href = authorizeUrl
  }

  const startImport = async ({nickname}) => {
    const tenantId = selectedOrgForImport.tenantId
    const datasets = await API.post("dev-d1-apps-backend", '/appStackXeroImports', {
      body: {
        organisations: [{nickname, tenantId}]
      }
    })
    history.push(`/appStackReports/importStatus?datasetId=${datasets[0].datasetId}`)
  }

  const columns = [
    {
      title: 'Organisation',
      key: 'name',
      type: 'string'
    },
    {
      title: 'Data source',
      key: 'dataSource',
      type: 'string'
    },
    {
      title: 'Last import',
      key: 'lastImportLink',
    },
    {
      title: '',
      key: 'actions',
    }
]

  const actionButtons = [
    {
      label: 'Connect new organisation',
      onClick: () => setIsDialogOpen(true),
      icon: <DataUsageIcon />
    }
  ]

  return (
    <div>
      <MenuBar title='Book of Apps' activeTab="Import" />
      <MainContent>
        {isLoading
          ? <LoadingSpinner />
          : <GenericTable
              title="Connected organisations"
              initialSortColumn="name"
              columns={columns}
              data={organisations}
              actionButtons={actionButtons}
              searchPlaceholder={'Search'}
              onItemClick={() => {}}
            />
        }
      </MainContent>

      {selectedOrgForImport &&
        <StartImportDialog
          organisation={selectedOrgForImport}
          onClose={() => setSelectedOrgForImport(null)}
          onStartImport={(nickname) => startImport({nickname})}
        />
      }

      {selectedDatasetWithError &&
        <ErrorDialog
          datasetId={selectedDatasetWithError}
          onClose={() => setSelectedDatasetWithError(null)}
        />
      }

      {isDialogOpen &&
        <DataProviderDialog
          onProviderSelected={onProviderSelected}
          onClose={() => setIsDialogOpen(false)}
        />
      }

      <IntercomWidget page='APPSTACK_REPORTS_IMPORT' />
    </div>
  )
})

const StartImportDialog = ({organisation, onClose, onStartImport}) => {
  const [nickname, setNickname] = useState(organisation.name)

  return (
    <Dialog.DialogContainer open={true} onClose={onClose}>
      <Dialog.FormTitle>
        Import data for: {organisation.name}
      </Dialog.FormTitle>
      <Dialog.DialogContentContainer>
        <b>
          You are about to import data from your Xero file to the
          Book of Apps database for analysis.
          This is the information that will be brought across:
        </b>
        <ul>
          <li>
            All bill lines for the last 12 months
          </li>
          <li>
            All Spend money transactions for the last 12 months
          </li>
          <li>
            The names of all accounts
          </li>
          <li>
            The total income for the last 12 months as per the P&amp;L report
          </li>
          <li>
            {`The business' Line of Business`}
          </li>
        </ul>

        <b>
          Please select a name for the dataset:
        </b>
        <TextField
          name="Name"
          placeholder="Nickname"
          value={nickname}
          onChange={event => setNickname(event.target.value)}
          variant="outlined"
          fullWidth
        />

        <Dialog.Actions>
          <Dialog.CancelButton variant="outlined" onClick={onClose}>
            Cancel
          </Dialog.CancelButton>
          <Dialog.SaveButton
            variant="outlined"
            disabled={nickname.trim().length === 0}
            onClick={() => onStartImport(nickname)}
          >
            Import
          </Dialog.SaveButton>
        </Dialog.Actions>

      </Dialog.DialogContentContainer>
    </Dialog.DialogContainer>
  )
}

const ErrorDialog = ({datasetId, onClose}) => {
  const [errorMessage, setErrorMessage] = useState()

  useEffect(() => {
    API.get("dev-d1-apps-backend", `/appStackReports/${datasetId}`).then(result => {
      setErrorMessage(result.importMessage)
    })
  }, [datasetId])

  return (
    <Dialog.DialogContainer open={true} onClose={onClose}>
      <Dialog.FormTitle>
        Error
      </Dialog.FormTitle>
      <Dialog.DialogContentContainer>
        {errorMessage
          ? <pre>{errorMessage}</pre>
          : <LoadingSpinner />
        }
        <Dialog.Actions>
          <Dialog.CancelButton variant="outlined" onClick={onClose}>
            Close
          </Dialog.CancelButton>
        </Dialog.Actions>
      </Dialog.DialogContentContainer>
    </Dialog.DialogContainer>
  )
}

const DataProviderDialog = ({ onProviderSelected, onClose }) => {
  return (
    <Dialog.DialogContainer open={true} onClose={onClose}>
      <DialogTitle>
        <Dialog.FormTitle>
          Connect a new organisation
        </Dialog.FormTitle>
        <IconButton onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <Dialog.DialogContentContainer>
        <P>Select one of the data provider from which you would like to import the accounting data for an organisation.</P>
        <DataProvider>
          <LogoContainer>
            <img src={'/images/xeroLogo.png'} alt="Connect new Xero org" onClick={() => onProviderSelected('Xero')}/>
            <Button color="primary" variant="outlined" size="small" onClick={() => onProviderSelected('Xero')}>Xero</Button>
          </LogoContainer>
          <LogoContainer>
            <img src={'/images/qboLogo.png'} alt="Connect new QBO org" onClick={() => onProviderSelected('QBO')}/>
            <Button color="primary" variant="outlined" size="small" onClick={() => onProviderSelected('QBO')}>QBO</Button>
          </LogoContainer>
          <LogoContainer>
            <img src={'/images/myobLogo.png'} alt="Connect new MYOB org" onClick={() => onProviderSelected('MYOB')}/>
            <Button color="primary" variant="outlined" size="small" onClick={() => onProviderSelected('MYOB')}>MYOB</Button>
          </LogoContainer>
        </DataProvider>
      </Dialog.DialogContentContainer>
    </Dialog.DialogContainer>
  )
}
