import React from 'react'
import { removeAnswerFromQuestion } from './actions/questionActions'
import { connect } from 'react-redux'
import AnswerForm from './AnswerForm.js'
import { updateAnswer, deleteAnswer } from './actions/answerActions'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const Answer = ({ id, answer, questionId, onAnswerSave, onDelete, expanded, expandedAll, index, onClick }) => {
  return (
    <ExpansionPanel expanded={expandedAll || expanded === answer.id}
      style={{ backgroundColor: 'rgba(0,0,0,.03)', boxShadow: 'none' }}
      onChange={() => onClick(answer.id)}
    >
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography >{answer.title}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <AnswerForm
          onAnswerSave={onAnswerSave}
          onDelete={onDelete}
          key={answer.id}
          answer={answer}
        />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

const mapStateToProps = (state, ownProps) => ({
  answer: state.appConfig.answers.byId[ownProps.id]
})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onAnswerSave: (form) => {
      const { title, tooltip, ballTooltip, noteForReport, buckets } = form
      dispatch(updateAnswer(ownProps.id, title, tooltip, ballTooltip, noteForReport, buckets))
    },
    onDelete: () => {
      dispatch(removeAnswerFromQuestion(ownProps.id, ownProps.questionId))
      dispatch(deleteAnswer(ownProps.id))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Answer)
