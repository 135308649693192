import { API, Auth } from 'aws-amplify'

const getCurrentUser = async () => {
  //const authUser = await Auth.currentAuthenticatedUser()
  //const accessToken = authUser.signInUserSession.accessToken
  const user = await API.get("dev-d1-apps-backend", '/users/me')
  return ({
    ...user,
    //accessToken
  })
}

const getCurrentCompany = async (companyId) => {
  const company = await API.get("dev-d1-apps-backend", '/userCompanies/' + companyId)
  return company
}

export const fetchCurrentUser = () => async (dispatch) => {
  try {
    await Auth.currentSession()
    const currentUser = await getCurrentUser()
    // TODO return company details with the previous call,
    // getCurrentUser, so "/users/me"
    const currentCompany = await getCurrentCompany(currentUser.companyId)
    if(!currentUser || !currentCompany) {
      const message = 'Cannot find a user profile.'
      alert(message)
      throw new Error(message)
    }
    dispatch({
      type: 'SET_AUTHENTICATED',
      payload: {
        currentUser,
        currentCompany
      }
    })
  } catch(err) {
    // there is no current session active
    // or the is no user profile for this cognito user
    console.warn(err)
    dispatch({
      type: 'SET_AUTHENTICATED',
      payload: {
        currentUser: null,
        currentCompany: null
      }
    })
  }
}

export const doLogout = () => async (dispatch) => {
  await Auth.signOut()
  dispatch({
    type: 'SET_AUTHENTICATED',
    payload: {
      currentUser: null,
      currentCompany: null
    }
  })
}

export const setUserRole = (role) => (dispatch) => {
  dispatch({
    type: 'SET_USER_ROLE',
    payload: {
      role
    }
  })
}
