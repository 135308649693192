const APP_ID_ARCHITECTURE_SELECTOR = '9dd27568-85eb-4be2-925d-a15e6a2b3ef4'
const APP_ID_INVENTORY_APP_SELECTOR = 'daf3c019-6e63-477a-a639-2b53510ab3a9'
const APP_ID_POS_APP_SELECTOR = '38c6a595-7133-4372-93e0-734351f6f40e'
const APP_ID_APPSTACK_DESIGNER = '356198e0-7a40-4a31-9f45-11f4be12f8aa'

const getAppName = (appId) => {
  switch (appId) {
    case APP_ID_ARCHITECTURE_SELECTOR:
      return 'ARCHITECTURE_SELECTOR'
    case APP_ID_INVENTORY_APP_SELECTOR:
      return 'INVENTORY_APP_SELECTOR'
    case APP_ID_POS_APP_SELECTOR:
      return 'POS_APP_SELECTOR'
    case APP_ID_APPSTACK_DESIGNER:
      return 'APPSTACK_DESIGNER'
    default:
      return null
  }
}

export {
  APP_ID_ARCHITECTURE_SELECTOR,
  APP_ID_INVENTORY_APP_SELECTOR,
  APP_ID_POS_APP_SELECTOR,
  APP_ID_APPSTACK_DESIGNER,
  getAppName
}
