import React, { useState, useEffect } from 'react'
import { API } from 'aws-amplify'
import DialogContent from '@material-ui/core/DialogContent'
import * as Dialog from '../../components/Dialog'
import { ControlLabel } from '../../components/Basic'
import NewTemplateForm from './NewTemplateForm'
import styled from 'styled-components'

const CheckboxContainer = styled.div`
  margin-bottom: 12px;

  &:first-of-type {
    margin-top: 25px;
  }

  label {
    display: inline-block;
    margin-right: 12px;

    &:first-child {
      display: block;
      margin-bottom: 8px;
    }
  }
`

const getMaxRevenue = (totalIncome) => {
  const str = `${Math.round(totalIncome) || 0}`
  let result = Number(str[0]) + 1
  if(str.length > 1) {
    result = result * Math.pow(10, str.length - 1)
  }
  return result
}

export default ({isOpen, onClose, onSave, foundAppsByCategory, totalIncome}) => {

  if(!isOpen) {
    return null
  }

  const [allIndustries, setAllIndustries] = useState([])
  const [newTemplate, setNewTemplate] = useState({
    templateName: '',
    industry: '',
    maxRevenue: getMaxRevenue(totalIncome)
  })
  const [addButtonStatus, setAddButtonStatus] = useState(true)

  const selectedCategories = foundAppsByCategory && foundAppsByCategory.reduce((acc, item) => {
    acc[item.name] = item.apps[0].name
    return acc
  }, {})

  const [selectedCategoriesAndApps, setCategoriesAndApps] = useState(selectedCategories)

  const loadData = async () => {
    const allIndustries = await API.get("d1-knowledgebase", `/content/synonyms?type=Industry`)
    setAllIndustries(allIndustries.map(i => i.name))
  }

  useEffect(() => {
    loadData()
  }, [])

  useState(() => {
    setCategoriesAndApps(selectedCategoriesAndApps)
  }, [selectedCategoriesAndApps])

  useEffect(() => {
    setNewTemplate({
      templateName: '',
      industry: '',
      maxRevenue: getMaxRevenue(totalIncome)
    })
  }, [totalIncome]);

  const areAllFieldsValid = (template) => {
    const { templateName, industry, maxRevenue } = template

    if (templateName.length > 0 && (industry && industry.length > 0) && Number(maxRevenue) > 0) {
      setAddButtonStatus(false)
    } else {
      setAddButtonStatus(true)
    }
    setNewTemplate(template)
  }

  return (
    <Dialog.CenteredDialog
      open={isOpen}
      onClose={() => onClose()}
      aria-labelledby="form-dialog-title"
    >
      <Dialog.FormTitle id="form-dialog-title">
        Create template from dataset
      </Dialog.FormTitle>
      <DialogContent>

        <div>
          <h3>Please fill out all these fields.</h3>
          <NewTemplateForm className="responsive-templateForm"
            selectedTemplate={newTemplate}
            updateTemplate={(template) => areAllFieldsValid(template)}
            allIndustries={allIndustries}
          />
        </div>

        <div>
          <h3>Select a default app for each category.</h3>
          <p>To leave a category out, untick all the apps in it.</p>
          {foundAppsByCategory && foundAppsByCategory.map((item, i) => {
            return(
              <CheckboxContainer key={`categoryAndApp-${item.name}-${i}`}>
                <label htmlFor={`checkbox-${item.name}-${i}`}><strong>{item.name}</strong></label>
                {item.apps &&
                  item.apps.map(app =>
                    <ControlLabel className="noTopPadding"
                      id={`checkbox-${item.name}-${app.name}-${i}`}
                      key={`${item.name}-${app.name}`}
                      control={
                        <input type="checkbox"
                          checked={selectedCategoriesAndApps[item.name]
                            &&
                            selectedCategoriesAndApps[item.name] === app.name ? true : false
                          }
                          key={`${item.name}-${app.name}`}
                          onChange={(e) => {
                            if(!e.target.checked &&
                              selectedCategoriesAndApps[item.name] &&
                              selectedCategoriesAndApps[item.name] === app.name
                            ) {
                              const categoriesAndApps = { ...selectedCategoriesAndApps }
                              delete categoriesAndApps[item.name]
                              return setCategoriesAndApps(categoriesAndApps)
                            }
                            return setCategoriesAndApps({
                              ...selectedCategoriesAndApps,
                              [item.name]: app.name
                            })
                          }}
                        />
                      }
                      label={app.name}
                    />
                  )
                }
              </CheckboxContainer>
            )
          })

          }
        </div>

        <Dialog.UnstyledActions>
          <Dialog.CancelButton variant="outlined" onClick={() => onClose()}>
            Cancel
          </Dialog.CancelButton>
          <Dialog.SaveButton variant="outlined"
            onClick={() => onSave({
              ...newTemplate,
              config: selectedCategoriesAndApps
            })}
            disabled={addButtonStatus}
          >
            Add Template
          </Dialog.SaveButton>
        </Dialog.UnstyledActions>

      </DialogContent>

    </Dialog.CenteredDialog>
  )
}
