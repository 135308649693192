import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import * as Dialog from '../../components/Dialog'

export default ({onClose, onSave, threshold}) => {

  const [value, setValue] = useState(threshold.value || 0.5)

  const handleSave = () => {
    onSave({value})
  }

  return(
    <Dialog.DialogContainer
      open={true}
      onClose={onClose}
    >
      <Dialog.FormTitle>
        Edit threshold
      </Dialog.FormTitle>
      <Dialog.DialogContentContainer>

        <h4>
          Value
        </h4>
        <TextField
          type="number"
          variant="outlined"
          placeholder="Value"
          value={value}
          onChange={(e) => setValue(parseFloat(e.target.value))}
          fullWidth
        />

        <Dialog.Actions>
          <Dialog.CancelButton variant="outlined" onClick={onClose}>
            Cancel
          </Dialog.CancelButton>
          <Dialog.SaveButton variant="outlined" onClick={() => handleSave()}
          >
            Save
          </Dialog.SaveButton>
        </Dialog.Actions>

      </Dialog.DialogContentContainer>
    </Dialog.DialogContainer>
  )
}
