import React from 'react';
import AccountInfoPanel from './AccountInfoPanel'

export default ({accounts, ...props}) => {
  return(
    <div>
      {accounts.map(account =>
        <AccountInfoPanel
          key={account.name}
          account={account}
          {...props}
        />
      )}
    </div>
  )
}
