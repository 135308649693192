import React, {useState, useEffect} from 'react'
import { API } from 'aws-amplify'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import LoadingSpinner from '../../components/LoadingSpinner'
import { ControlLabel, TallAutocomplete } from '../../components/Basic'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import { withStyles } from '@material-ui/core/styles'
import styled from 'styled-components'
import AppBar from '@material-ui/core/AppBar'
import { SidePanelTab, SidePanelTabs } from '../../components/SidePanel'
import * as Dialog from '../../components/Dialog'
import UpdateEventsList from './updateEvents/UpdateEventsList'
import UpdateEventsTable from './updateEvents/UpdateEventsTable'


const DialogTabTitle = withStyles({
  root: {
    padding: 0
  }
})(DialogTitle)

const InputDiv = styled.div`
  margin: 15px 0;

  label {
    display: block;
    margin-bottom: 8px;
  }
`

const CategoryInput = styled.div`
  display: flex;
  margin-top: 15px;
  > div {
    width: 75%;
  }

  button {
    margin-left: 15px;
    width: calc(25% - 15px)
  }
`

const IconBtnContainer = withStyles({
  root: {
    padding: '3px',
    color: '#000'
  }
})(IconButton)

const Actions = withStyles({
  root: {
    padding: '8px 20px',
    '& .saveButton': {
      marginLeft: '8px'
    }
  }
})(DialogActions)

const ENTITY_PATHS = {
  'App': 'apps',
  'Integration': 'integrations',
  'Company': 'companies',
  'Category': 'categories'
}

export default ({name, entityType, onClose, synonyms, columns, updateSynonyms}) => {

  const entityPath = ENTITY_PATHS[entityType]
  const [entity, setEntity] = useState()
  const [updateEvents, setUpdateEvents] = useState()
  const [isCategoryInputVisible, showCategoryInput] = useState(false)
  const [newCategory, setNewCategory] = useState('')
  const [activeTab, setActiveTab] = useState(0)

  useEffect(() => {
    API.get("d1-knowledgebase", `/content/${entityPath}/${name}`)
      .then(entity => setEntity(entity))
    if(entityPath === 'apps') {
      API.get("d1-knowledgebase", `/content/${entityPath}/${name}/events`)
        .then(events => setUpdateEvents(events))
    }
    }, [name, entityPath])

  const setEntityField = ({field, value}) => {
    const val = (value === '') ? null : value
    const newEntity = {
      ...entity,
      [field]: val
    }
    setEntity(newEntity)
  }

  const saveEntity = async () => {
    const body = Object.keys(entity).reduce((acc, cur) => {
      const value = entity[cur]
      if((value && value.length > 0) || (value === true) || (value === false)) {
        acc[cur] = value
      }
      return acc
    }, {})

    const result = await API.put("d1-knowledgebase", `/content/${entityPath}/${name}`, {
      body
    })
    if(result.validationErrors && result.validationErrors.length > 0) {
      alert(JSON.stringify(result))
    } else {
      onClose({updatedEntity: body})
    }
  }

  const addNewCategory = async () => {
    if(newCategory.length > 0) {
      API.post("d1-knowledgebase", '/synonyms', {
        body: {
          type: 'Category',
          synonym: newCategory.toLowerCase(),
          name: newCategory
        }
      })
      updateSynonyms({
        ...synonyms,
        Category: [...synonyms.Category, newCategory]
      })
      showCategoryInput(!isCategoryInputVisible)
      setNewCategory('')
    }
  }

  return (
    <Dialog.SquareDialog open={true} onClose={onClose}>
      <DialogTabTitle>
        <AppBar position="static">
          <SidePanelTabs
            value={activeTab} variant="fullWidth"
            onChange={(event, value) => setActiveTab(value)}
            scrollButtons="auto"
          >
            <SidePanelTab label="Edit" key={"Edit"} />
            <SidePanelTab label="History" key={"History"} />
            <SidePanelTab label="History Table" key={"History Table"} />
          </SidePanelTabs>
        </AppBar>
      </DialogTabTitle>

      <Dialog.DialogContentContainer>
        {!entity && <LoadingSpinner />}
        {entity &&
          <>
            <div
              role="tabpanel"
              hidden={activeTab !== 0}
              id={`simple-tabpanel-${0}`}
              aria-labelledby={`simple-tab-${0}`}
            >
              <h2>{entityType}: {entity && <b>{entity.Name}</b>}</h2>

              {columns.filter(col => col.name !== 'Name').map(col =>
                <InputDiv key={col.name}>
                  <label>
                    <strong>{col.name}</strong>
                    {col.name === 'MainCategory' &&
                      <IconBtnContainer onClick={() => {showCategoryInput(!isCategoryInputVisible)}}>
                        <AddIcon title="Add new category" />
                      </IconBtnContainer>
                    }
                  </label>

                  {col.type.startsWith('PATTERN_') &&
                    <PatternInput
                      type={col.type}
                      value={entity[col.name]}
                      onChange={value => setEntityField({field: col.name, value})}
                    />
                  }

                  {!col.type.startsWith('PATTERN_') &&
                    <SynonymInput
                      synonyms={synonyms[col.type]}
                      value={entity[col.name]}
                      onChange={value => setEntityField({field: col.name, value})}
                      isMulti={col.isArray}
                    />
                  }

                  {col.name === 'MainCategory' &&
                    <>
                      {isCategoryInputVisible &&
                        <CategoryInput>
                          <TextField
                            type="text"
                            variant="outlined"
                            placeholder="Enter new category"
                            value={newCategory}
                            onChange={(e) => setNewCategory(e.target.value)}
                          />
                          <Button variant="contained" color="primary" size="small" onClick={() => addNewCategory()}>Add</Button>
                        </CategoryInput>
                      }
                    </>
                  }
                </InputDiv>
              )}
            </div>
            <div
              role="tabpanel"
              hidden={activeTab !== 1}
              id={`simple-tabpanel-${1}`}
              aria-labelledby={`simple-tab-${1}`}
            >
              <h2>{entityType}: {entity && <b>{entity.Name}</b>}</h2>
              {updateEvents && <UpdateEventsList events={updateEvents} />}
            </div>
            <div
              role="tabpanel"
              hidden={activeTab !== 2}
              id={`simple-tabpanel-${2}`}
              aria-labelledby={`simple-tab-${2}`}
            >
              <h2>{entityType}: {entity && <b>{entity.Name}</b>}</h2>
              {updateEvents && <UpdateEventsTable events={updateEvents} />}
            </div>
          </>
        }
      </Dialog.DialogContentContainer>

      <Actions>
        {activeTab === 0 &&
          <>
            <Dialog.SimpleCancelButton variant="outlined" onClick={onClose}>
              Cancel
            </Dialog.SimpleCancelButton>
            <Dialog.SimpleSaveButton className="saveButton" variant="outlined" onClick={() => saveEntity()}>
              Save
            </Dialog.SimpleSaveButton>
          </>
        }
        {activeTab > 0 &&
          <Dialog.SimpleCancelButton variant="outlined" onClick={onClose}>
            Close
          </Dialog.SimpleCancelButton>
        }
      </Actions>
    </Dialog.SquareDialog>
  )
}

const PatternInput = ({type, value, onChange}) => {
  if(type === 'PATTERN_BOOLEAN') {
    return(
      <ControlLabel className="noTopPadding"
        control={
          <input type="checkbox"
            checked={value}
            onChange={event => onChange(event.target.checked)}
            value={`${value}`}
          />
        }
        label="Ignore this app name when analyzing text"
      />
    )
  } else {
    return(
      <TextField
        type="text"
        variant="outlined"
        value={value || ''}
        onChange={event => onChange(event.target.value)}
        fullWidth
      />
    )
  }
}

const SynonymInput = ({synonyms, value, onChange, isMulti}) => {
  const suggestions = synonyms.map(s => ({label: s, value: s}))
  const selectedValue = isMulti
    ? suggestions.filter(s => value && value.indexOf(s.value) >= 0)
    : suggestions.find(s => s.value === value)

  return(
    <TallAutocomplete
      value={selectedValue}
      onChange={selection => onChange(selection && (isMulti ? selection.map(s => s.value) : selection.value))}
      suggestions={suggestions}
      placeholder='Select'
      isMulti={isMulti}
    />
  )
}
