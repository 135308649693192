import React, {useState} from 'react'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import MenuItem from '@material-ui/core/MenuItem';

export default ({actions}) => {
  const [anchorEl, setAnchorEl] = useState()
  return (
    <div style={{textAlign: 'right'}}>
      <IconButton 
        onClick={event => {
          event.stopPropagation()
          return setAnchorEl(event.currentTarget)
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={(event) => {
          event.stopPropagation()
          return setAnchorEl(null)
        }}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: 200,
          }
        }}
      >
        {actions.map(action =>
          <MenuItem onClick={(event) => {
              event.stopPropagation()
              setAnchorEl(null)
              action.onClick()
            }}>
            {action.label}
          </MenuItem>
        )}
      </Menu>
    </div>
  )
}
