import React, { Component } from 'react'
import styled from 'styled-components'
import { API } from 'aws-amplify'
import AdminMenuBar from '../AdminMenuBar'
import LoadingSpinner from '../../components/LoadingSpinner'
import Tabs from './Tabs'
import {Table, Column, Cell} from 'fixed-data-table-2'
import Measure from 'react-measure'
import EditEntityDialog from './EditEntityDialog'
import FilterPanel from './FilterPanel'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  top: 105px;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  overflow: hidden;
`

const Select = styled.select`
  font-size: 14px;
  margin-bottom: 20px;
  width: 250px;
`

const HeaderInfo = styled.div`
  line-height: 16px;
  font-size: 10px;
  color: #777;
`

const Link = styled.div`
  color: #3f51b5;
  font-size: 16px;
  cursor: pointer;
`

const groupData = ({data, groupedField, visibleGroupItems}) => {
  if(groupedField) {
    const result = []

    const allValuesSet = data
      .reduce((acc, cur) => {
        const value = cur[groupedField]
        if(Array.isArray(value)) {
          value.forEach(item => acc.add(item))
        } else {
          acc.add(value)
        }
        return acc
      }, new Set())
      .values()

    const allValues = Array.from(allValuesSet)
    allValues.sort((a, b) => `${a}`.localeCompare(`${b}`))
    allValues.forEach(groupedValue => {

      const newData = data.filter(item => {
        const value = item[groupedField]
        if(Array.isArray(value)) {
          return (value.indexOf(groupedValue) >= 0)
        } else {
          // FIME improve this. I think we cannot do a === on the actual values,
          // because they don't have the same data type
          return (`${value}` === `${groupedValue}`)
        }
      })

      const isExpanded = (visibleGroupItems.indexOf(`${groupedValue}`) >= 0)
      if(isExpanded) {
        newData.forEach(item => {
          result.push({
            ...item,
            [`GROUP_BY_${groupedField}`]: groupedValue
          })
        })
      } else {
        result.push({
          [`GROUP_BY_${groupedField}`]: `${groupedValue} (${newData.length})`
        })
      }

    })

    return result
  } else {
    return data
  }
}

const filterData = ({content, filters, selectedTable}) => {
  let result = content[selectedTable]

  for (let i = 0; i < filters.length; i++) {
    const filter = filters[i]
    if(filter.value) {
      result = result.filter(item => {
        let itemValue
        // FIXME lookup by field type and not name
        if((filter.field === 'App1' || filter.field === 'App2') && selectedTable !== 'App') {
          const app = content['App'].find(a => a.Name === item[filter.field])
          itemValue = app[filter.subfield]
        } else {
          itemValue = item[filter.field]
        }

        if(filter.value === '-- NO VALUE --') {
          if(Array.isArray(itemValue)) {
            return itemValue.length === 0
          } else {
            return !itemValue
          }
        } else {
          if(Array.isArray(itemValue)) {
            return itemValue.indexOf(filter.value) >= 0
          } else {
            let filterValue = filter.value === 'true' ? true
            : filter.value === 'false' ? false
            : filter.value
            return itemValue === filterValue
          }
        }

      })
    }
  }

  return result
}

const getAllSynonyms = async () => {
  const synonymsResult = await API.get("d1-knowledgebase", '/content/synonyms')
  const synonyms = Object.entries(synonymsResult).reduce((acc, cur) => {
    const [key, value] = cur
    acc[key] = value.map(v => v.name)
    return acc
  }, {})
  return synonyms
}

const getSchema = async () => {
  const schemaResult = await API.get("d1-knowledgebase", '/schemas')
  const schema = schemaResult.map(item => {
    const columns = item.columns.reduce((acc, cur) => {
      if(cur.mainItemName) {
        acc.push({name: cur.mainItemName, type: cur.type})
      }
      acc.push(cur)
      return acc
    }, [])
    return {
      ...item,
      columns
    }
  })
  return schema
}



export default class KnowledgebaseContentPage extends Component {

  constructor(props) {
    super(props)
    this.state = {
      schema: null,
      selectedTable: null,
      content: null,
      filterPanelVisible: false,
      filters: [],
      groupedField: null,
      visibleGroupItems: [],
      sortColumn: null,
      sortReverse: false,
      dimensions: {
        width: -1,
        height: -1,
      },
      columnWidths: {}
    }
  }

  async componentDidMount() {

    const [synonyms, schema] = await Promise.all([
      getAllSynonyms(),
      getSchema()
    ])

    const [apps, integrations, companies, categories] = await Promise.all([
      API.get("d1-knowledgebase", `/content/apps`),
      API.get("d1-knowledgebase", `/content/integrations`),
      API.get("d1-knowledgebase", `/content/companies`),
      API.get("d1-knowledgebase", `/content/categories`),
    ])

    const content = {
      'App': apps,
      'Integration': integrations,
      'Company': companies,
      'Category': categories,
    }

    const selectedTable = schema[0].table
    this.setState({content, schema, synonyms, selectedTable})
  }

  async setSelectedTable(selectedTable) {
    this.setState({selectedTable, filters: [], groups: []})
  }

  toggleVisibleGroupValue(groupValue) {
    // THIS IS A HACK.
    // FIXME refactor this to make it better to not have string manipulation here
    const cleanGroupValue = `${groupValue}`.split(' (')[0]

    const visibleGroupItems = [ ...this.state.visibleGroupItems ]
    const index = visibleGroupItems.indexOf(cleanGroupValue)
    if(index < 0) {
      visibleGroupItems.push(cleanGroupValue)
    } else {
      visibleGroupItems.splice(index, 1)
    }
    this.setState({visibleGroupItems})
  }

  onColumnResizeEndCallback(newColumnWidth, columnName) {
    const columnWidths = {
      ...this.state.columnWidths,
      [`${this.state.selectedTable}-${columnName}`]: newColumnWidth,
    }
    this.setState({columnWidths})
   }

  onEditEntityClick(entityNameForEditing) {
    this.setState({entityNameForEditing})
  }

  onCloseEditEntityDialog({updatedEntity}) {
    if(updatedEntity) {
      const content = {
        ...this.state.content,
        [this.state.selectedTable]: this.state.content[this.state.selectedTable].map(item => (item.Name === this.state.entityNameForEditing) ? ({ ...item, ...updatedEntity }) : item)
      }
      this.setState({content, entityNameForEditing: null})
    } else {
      this.setState({entityNameForEditing: null})
    }
  }

  setSortColumn(sortColumn) {
    if(sortColumn === this.state.sortColumn) {
      this.setState({sortReverse: !this.state.sortReverse})
    } else {
      this.setState({sortColumn, sortReverse: false})
    }
  }

  render() {
    const {
      content,
      filters,
      groupedField,
      selectedTable,
      visibleGroupItems,
      schema,
      synonyms
    } = this.state

    if(!content) {
      return(
        <div>
          <AdminMenuBar activeTab="Knowledgebase" />
          <LoadingSpinner />
        </div>
      )
    }

    const groupColumns = groupedField ? [{name: `GROUP_BY_${groupedField}`, type: 'GROUP BY'}] : []
    const regularColumns = schema.find(x => x.table === selectedTable).columns
    const columns = [...groupColumns, ...regularColumns]

    let data = filterData({content, filters, selectedTable})
    data = groupData({data, groupedField, visibleGroupItems})

    const sortColumn = this.state.sortColumn || columns[0].name
    data.sort((item1, item2) => {
      let value1 = item1[sortColumn] || ''
      if(Array.isArray(value1)) {
        value1 = item1[sortColumn].join()
      }
      let value2 = item2[sortColumn] || ''
      if(Array.isArray(value2)) {
        value2 = item2[sortColumn].join()
      }
      if(this.state.sortReverse) {
        return `${value2}`.localeCompare(`${value1}`)
      } else {
        return `${value1}`.localeCompare(`${value2}`)
      }
    })

    return (
      <div>
        <AdminMenuBar activeTab="Knowledgebase" />
        <Tabs activeTab='Content' />
        <Content>

          <div style={{display: 'flex', justifyContent: 'space-between', height: '25px', padding: '10px'}}>
            <div>
              <Select value={selectedTable} onChange={event => this.setSelectedTable(event.target.value)}>
                {Object.keys(content).map(key =>
                  <option key={key} value={key}>
                    {`${key} (${content[key].length})`}
                  </option>
                )}
              </Select>
              <span style={{fontWeight: 'bold', marginLeft: '10px'}}>
                {`${data.length} rows`}
              </span>
            </div>

            <Link onClick={() => this.setState({filterPanelVisible: true})}>
              {`${this.state.filters.filter(f => !!f.value).length} filters / `}
              {this.state.groupedField ? 'grouped' : 'not grouped'}
            </Link>

          </div>


          <Measure bounds onResize={contentRect => this.setState({dimensions: contentRect.bounds})}>
            {({ measureRef }) => (
              <div ref={measureRef} style={{flex: 1}}>
                <Table
                  rowHeight={35}
                  rowsCount={data.length}
                  width={this.state.dimensions.width}
                  height={this.state.dimensions.height}
                  headerHeight={50}
                  onColumnResizeEndCallback={(newColumnWidth, columnKey) => this.onColumnResizeEndCallback(newColumnWidth, columnKey)}
                  isColumnResizing={false}
                >
                  {columns.map((column, columnIndex) =>
                    <Column
                      key={column.name}
                      fixed={columnIndex === 0}
                      columnKey={column.name}
                      header={
                        <Cell onClick={() => this.setSortColumn(column.name)}>
                          {column.name}
                          {(column.name === sortColumn) && (this.state.sortReverse ? '▾' : '▴' )}
                          <HeaderInfo>
                            {column.type}
                          </HeaderInfo>
                        </Cell>
                      }
                      width={this.state.columnWidths[`${selectedTable}-${column.name}`] || 150}
                      isResizable={true}
                      cell={({rowIndex, width, height}) => {
                        const cellValue = data[rowIndex][column.name]
                        return (
                         <Cell
                           width={width}
                           height={height}
                           style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}
                           onClick={() => {
                             if(column.type === "GROUP BY") {
                               this.toggleVisibleGroupValue(cellValue)
                             } else if(column.name === 'Name') {
                               if(selectedTable === 'App' || selectedTable === 'Company' || selectedTable === 'Category') {
                                 this.onEditEntityClick(cellValue)
                               }
                             }
                           }}
                         >
                           {column.isArray
                             ? cellValue && cellValue.join(', ')
                             : ((cellValue === true || cellValue === false) ? `${cellValue}` : cellValue)
                           }
                          </Cell>
                       )
                      }}
                    />
                  )}
                </Table>
              </div>
            )}
          </Measure>

          <FilterPanel
            visible={this.state.filterPanelVisible}
            onClose={() => this.setState({filterPanelVisible: false})}
            synonyms={synonyms}
            schema={schema}
            selectedTable={selectedTable}
            filters={filters}
            onChangeFilters={filters => this.setState({filters})}
            groupedField={groupedField}
            onChangeGroupedField={groupedField => this.setState({groupedField})}
          />

        </Content>

        {this.state.entityNameForEditing &&
          <EditEntityDialog
            name={this.state.entityNameForEditing}
            entityType={this.state.selectedTable}
            onClose={({updatedEntity}) => this.onCloseEditEntityDialog({updatedEntity})}
            synonyms={synonyms}
            columns={columns}
            updateSynonyms={(newSynonyms) => this.setState({ synonyms: newSynonyms})}
          />
        }

      </div>
    )
  }

}
