import React, { useState, useEffect } from 'react'
import useDebounce from '../useDebounce'
import TextField from '@material-ui/core/TextField'
import styled from 'styled-components'

const Container = styled.div`
  width: 300px;
`

export default ({value, onValueChanged, placeholder, onFocus}) => {

  const [searchTerm, setSearchTerm] = useState(value)
  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  useEffect(() => {
    onValueChanged(debouncedSearchTerm)
  }, [debouncedSearchTerm, onValueChanged])

  useEffect(() => {
    setSearchTerm(value)
  },[value])

  return(
    <Container>
      <TextField
        type="text"
        variant="outlined"
        placeholder={placeholder}
        value={searchTerm}
        onChange={event => setSearchTerm(event.target.value)}
        onFocus={event => onFocus && onFocus()}
      />
    </Container>
  )
}
