import React from 'react'
import BucketsEditor from './BucketsEditor'
import CommentsEditor from './CommentsEditor'
import Grid from '@material-ui/core/Grid'
import SurveyHeader from './SurveyHeader'
import SectionsEditor from './SectionsEditor'
import QuestionsEditor from './QuestionsEditor'
import { receiveBuckets } from './actions/bucketActions'
import { receiveComments } from './actions/commentsActions'
import { addSection, receiveSections, setSectionsOrder } from './actions'
import { addQuestion, receiveQuestions } from './actions/questionActions'
import { receiveAnswers } from './actions/answerActions'
import { normalizedConfig } from './api'
import { arrayMove } from 'react-sortable-hoc'
import { connect } from 'react-redux'
import { Button } from '@material-ui/core'
import { getAppName } from '../../../AppConfig'

class ConfigEditor extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      sectionId: ''
    }
  }

  handleAddSection = () => {
    const { dispatch } = this.props
    dispatch(addSection())
  }

  handleAddQuestion = () => {
    const sectionId = this.state.sectionId
    const { dispatch } = this.props
    dispatch(addQuestion(sectionId))
  }

  handleSectionSort = ({ oldIndex, newIndex }) => {
    const { dispatch, sections } = this.props
    dispatch(setSectionsOrder(arrayMove(sections, oldIndex, newIndex)))
  }

  componentDidMount () {
    const { dispatch } = this.props
    const normalizedConf = normalizedConfig(this.props.config)
    const buckets = normalizedConf.entities.buckets
    const sections = normalizedConf.entities.sections
    const questions = normalizedConf.entities.questions
    const answers = normalizedConf.entities.answers
    dispatch(receiveBuckets(buckets))
    dispatch(receiveSections(sections))
    dispatch(receiveQuestions(questions))
    dispatch(receiveAnswers(answers))
    dispatch(receiveComments(this.props.config.comments))
  }

  render () {
    const { sectionId } = this.state
    const { sections } = this.props
    let body
    if (sectionId !== '') {
      body = (
        <QuestionsEditor
          sectionId={sectionId}
          key={sectionId}
        />
      )
    } else {
      body = (
        <SectionsEditor
          sections={sections}
          onSortEnd={this.handleSectionSort}
        />
      )
    }

    return (
      <div>
        <Grid container spacing={16} >
          <Grid item xs={12} md={9}>
            <SurveyHeader
              sectionId={sectionId}
              onSetSectionId={e => this.setState({ sectionId: e.target.value, questionId: '' })}
              onSetQuestionId={e => this.setState({ questionId: e.target.value })}
            />
          <div style={{margin: '20px 0'}}>
              {body}
            </div>
            { sectionId === '' && <Button variant='contained' color='primary' onClick={this.handleAddSection}>New Section</Button>}
            { sectionId !== '' && <Button variant='contained' color='primary' onClick={this.handleAddQuestion}>New Question</Button>}
          </Grid>
          <Grid item md={3} xs={12}>
            <BucketsEditor
              appName={getAppName(this.props.appId)}
            />
            <CommentsEditor />
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    sections: state.appConfig.sections.allIds
  }
}

export default connect(mapStateToProps)(ConfigEditor)
