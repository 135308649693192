import React from 'react'
import moment from 'moment'

const DATE_PATTERN = 'DD.MM.YYYY HH:mm'
const LINK_PATTERN = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g

export const formatUpdatedAt = (value) => {
  return moment(value).format(DATE_PATTERN)
}

export const formatUpdatedBy = (value) => {
  const name = value.split('::')[2]
  return name
}

export const formatContent = (key, value) => {
  const content = (
    <>
      <strong>{key}:&nbsp;</strong>
      {
        LINK_PATTERN.test(value)
        ? <a href={value} className="contentLink">{value}</a>
        : value != null && value.toString()
      }
    </>
  )
  return content
}
