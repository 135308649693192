import { combineReducers } from 'redux'
import appConfig from '../admin/appConfig/editor/reducers'
import auth from './authReducer'
import chatConversations from './chatConversationsReducer'
import chatConversation from './chatConversationReducer'
import sidePanel from './sidePanelReducer'
import notif from './notificationReducer'

const rootReducer = combineReducers({
  chatConversation,
  chatConversations,
  appConfig,
  auth,
  sidePanel,
  notif
})

export default rootReducer
