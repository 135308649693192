import React from 'react'
import styled from 'styled-components'
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/InfoOutlined'

// Descriptions from AWS Javascript SDK documentation:
// https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/CognitoIdentityServiceProvider.html#listUsers-property
// Some descriptions were changed to our needs.
// Descriptio for state DEACTIVATED was added by us.
const STATES = {
  'DEACTIVATED': 'User has been deactivated and therefore cannot sign in anymore.',
  'UNCONFIRMED': 'User has been created but not confirmed.',
  'CONFIRMED': 'User is activated and can sign in.',
  'ARCHIVED': 'User is no longer active and acrchived.',
  'COMPROMISED': 'User is disabled due to a potential security threat.',
  'UNKNOWN': 'User status is not known.',
  'RESET_REQUIRED': 'User is activated, but the user must request a code and reset his or her password before he or she can sign in.',
  'FORCE_CHANGE_PASSWORD': 'The user is created and the user can sign in using a temporary password, but on first sign-in, the user must change his or her password to a new value before doing anything else.'
}

const UserStatus = styled.div`
  display: flex;
  align-items: center;
`

export default ({enabled, status}) => {
  const myStatus = enabled ? status : 'DEACTIVATED'
  const description = STATES[myStatus]
  return(
    <UserStatus>
      <span>{myStatus}</span>
      <Tooltip placement="bottom" title={description}>
        <InfoIcon style={{fontSize: '16px', marginLeft: '5px'}} />
      </Tooltip>
    </UserStatus>
  )
}
