import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import TextField from '@material-ui/core/TextField'
import LoadingButton from '../components/LoadingButton'
import { FormContainer, FormSection, Form, FormTitle, ErrorMessage } from '../components/Basic'

export default class ResetPasswordPage extends Component {

  constructor(props) {
    super(props)
    this.state = {
      stage: 1,
      isLoading: false,
      errorMessage: null,
      email: '',
      code: '',
      password: ''
    }
  }

  isValidateFormStage1() {
    return (this.state.email.length > 0)
  }

  isValidateFormStage2() {
    return (this.state.code.length > 0 && this.state.password.length > 0)
  }

  handleSubmitStage1 = async (event) => {
    event.preventDefault()
    if(!this.isValidateFormStage1()) {
      return
    }

    this.setState({ isLoading: true })
    try {
      const email = this.state.email.trim()
      await Auth.forgotPassword(email)
      this.setState({
        stage: 2,
        isLoading: false,
        errorMessage: null
      })
    } catch (err) {
      this.setState({
        isLoading: false,
        errorMessage: (err && err.message) || err
      })
    }
  }

  handleSubmitStage2 = async (event) => {
    event.preventDefault()
    if(!this.isValidateFormStage2()) {
      return
    }

    this.setState({ isLoading: true })
    try {
      const email = this.state.email.trim()
      const code = this.state.code.trim()
      const password = this.state.password
      await Auth.forgotPasswordSubmit(email, code, password)
      this.setState({
        stage: 3,
        isLoading: false,
        errorMessage: null
      })
    } catch (err) {
      this.setState({
        isLoading: false,
        errorMessage: (err && err.message) || err
      })
    }
  }

  renderStage1() {
    return (
      <form onSubmit={this.handleSubmitStage1}>

        {this.state.errorMessage &&
          <ErrorMessage>
            {this.state.errorMessage}
          </ErrorMessage>
        }

        <FormSection>
          Please provide your email, that we can send you a code to reset your password.
        </FormSection>

        <FormSection>
          <TextField
            placeholder="Email"
            value={this.state.email}
            onChange={event => this.setState({email: event.target.value})}
            variant="outlined"
            fullWidth
          />
        </FormSection>

        <FormSection>
          <LoadingButton
            text="Submit"
            disabled={!this.isValidateFormStage1()}
            isLoading={this.state.isLoading}
            onClick={this.handleSubmitStage1}
          />
        </FormSection>

      </form>
    )
  }

  renderStage2() {
    return(
      <form onSubmit={this.handleSubmitStage2}>

        {this.state.errorMessage &&
          <ErrorMessage>
            {this.state.errorMessage}
          </ErrorMessage>
        }

        <FormSection>
          <TextField
            placeholder="Code"
            value={this.state.code}
            onChange={event => this.setState({code: event.target.value})}
            variant="outlined"
            fullWidth
          />
        </FormSection>

        <FormSection>
          <TextField
            autoComplete="new-password"
            placeholder="New password"
            value={this.state.password}
            onChange={event => this.setState({password: event.target.value})}
            variant="outlined"
            type="password"
            fullWidth
          />
        </FormSection>

        <FormSection>
          <LoadingButton
            text="Submit"
            disabled={!this.isValidateFormStage2()}
            isLoading={this.state.isLoading}
            onClick={this.handleSubmitStage2}
          />
        </FormSection>

      </form>
    )
  }

  renderStage3() {
    return(
      <div>
        <FormSection>
          Your password was changed. You can now login with your new password.
        </FormSection>
        <FormSection>
          <Link to={'/login'}>
            Login
          </Link>
        </FormSection>
      </div>
    )
  }

  render() {
    return (
      <FormContainer>
        <Form>
          <FormTitle>Reset Password</FormTitle>
          {(this.state.stage === 1) && this.renderStage1()}
          {(this.state.stage === 2) && this.renderStage2()}
          {(this.state.stage === 3) && this.renderStage3()}
        </Form>
      </FormContainer>
    )
  }

}
