import React from 'react'
import QuestionsPage from './app-advisor/QuestionsPage'
import GraphPage from './ved/GraphPage'
import {
  APP_ID_ARCHITECTURE_SELECTOR,
  APP_ID_INVENTORY_APP_SELECTOR,
  APP_ID_POS_APP_SELECTOR,
  APP_ID_APPSTACK_DESIGNER
} from './AppConfig'

export default class AppPage extends React.Component {

  render() {
    const { appId, clientId, savedSearchId } = this.props.match.params
    if(appId === APP_ID_ARCHITECTURE_SELECTOR) {
      return(
        <QuestionsPage
          appId={appId}
          appName='Architecture Selector	'
          clientId={clientId}
          savedSearchId={savedSearchId}
        />
      )
    } else if(appId === APP_ID_INVENTORY_APP_SELECTOR) {
      return(
        <QuestionsPage
          appId={appId}
          appName='Inventory App Selector	'
          clientId={clientId}
          savedSearchId={savedSearchId}
        />
      )
    } else if(appId === APP_ID_POS_APP_SELECTOR) {
      return(
        <QuestionsPage
          appId={appId}
          appName='POS App Selector	'
          clientId={clientId}
          savedSearchId={savedSearchId}
        />
      )
    } else if(appId === APP_ID_APPSTACK_DESIGNER) {
      return(
        <GraphPage
          clientId={clientId}
          savedSearchId={savedSearchId}
        />
      )
    } else {
      return <div>No app with this ID found.</div>
    }

  }
}
