import { API } from 'aws-amplify'

export const loadAppConfig = (appId) => async (dispatch) => {
  dispatch({ type: 'LOAD_APP_CONFIG' })
  try {
    const app = await API.get("dev-d1-apps-backend", '/appConfig/' + appId)
    const config = JSON.parse(app.config)
    dispatch({
      type: 'LOAD_APP_CONFIG_SUCCESS',
      payload: {
        appId,
        config
      }
    })
  } catch (err) {
    console.error(err)
    dispatch({
      type: 'LOAD_APP_CONFIG_ERROR',
      payload: {
        err
      }
    })
  }
}

export const loadAppConfigVersions = (appId) => async (dispatch) => {
  dispatch({ type: 'LOAD_APP_CONFIG_VERSIONS' })
  try {
    const versions = await API.get("dev-d1-apps-backend", '/appConfig?appId=' + appId)
    const versionsUsers = await API.get("dev-d1-apps-backend", '/coworkers')
    versions.sort((a, b) => (a.createdAt > b.createdAt) ? -1 : ((a.createdAt < b.createdAt) ? 1 : 0))
    dispatch({
      type: 'LOAD_APP_CONFIG_VERSIONS_SUCCESS',
      payload: {
        versions,
        versionsUsers
      }
    })
  } catch (err) {
    console.error(err)
    dispatch({
      type: 'LOAD_APP_CONFIG_VERSIONS_ERROR',
      payload: {
        err
      }
    })
  }
}

export const updateAppConfig = ({appId, createdAt, config, comment}) => async (dispatch) => {
  dispatch({ type: 'UPDATE_APP_CONFIG' })
  try {
    await API.put("dev-d1-apps-backend", '/appConfig/' + appId, {
      body: {
        createdAt,
        config: JSON.stringify(config),
        comment
      }
    })
    dispatch({
      type: 'UPDATE_APP_CONFIG_SUCCESS',
      payload: {
        config
      }
    })
  } catch (err) {
    console.error(err)
    dispatch({
      type: 'UPDATE_APP_CONFIG_ERROR',
      payload: {
        err
      }
    })
  }
}
