import {
  GET_CONVERSATION,
  GET_CONVERSATION_SUCCESS,
  GET_CONVERSATION_ERROR,
  CREATE_MESSAGE,
  CREATE_MESSAGE_SUCCESS,
  CREATE_MESSAGE_ERROR,
} from '../actions/chatConversationsActions'

const INITIAL_STATE = {
  data: null,
  getting: false,
  error: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case GET_CONVERSATION:
      return { ...state, data: null, getting: true, error: null }

    case GET_CONVERSATION_SUCCESS:
      return { ...state, data: action.payload, getting: false, error: null }

    case GET_CONVERSATION_ERROR:
      return { ...state, data: null, getting: false, error: action.payload }

    case CREATE_MESSAGE:
      return { ...state, getting: true, error: null }

    case CREATE_MESSAGE_SUCCESS:
      return { ...state, data: action.payload, getting: false, error: null }

    case CREATE_MESSAGE_ERROR:
      return { ...state, getting: false, error: action.payload }

    default:
      return state
  }
}
