import React from 'react'
import IntercomWidget from '../IntercomWidget'
import MenuBar from '../components/MenuBar'
import { MainContent } from '../components/Basic'
import UserList from './UserList'

export default () => {
  return (
    <div>
      <MenuBar title='Book of Apps' activeTab="Users" />
      <MainContent>
        <UserList />
      </MainContent>
      <IntercomWidget page='USERS_INDEX' />
    </div>
  )
}
