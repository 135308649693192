import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'

export default ({title, onClose, onSubmit}) =>
  <Dialog open={true} onClose={onClose}>
    <DialogTitle>
      <div style={{width: 400}}>
        {title}
      </div>
    </DialogTitle>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Cancel
      </Button>
      <Button onClick={onSubmit} color="primary" variant="contained">
        OK
      </Button>
    </DialogActions>
  </Dialog>
