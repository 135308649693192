import React from 'react'
import styled from 'styled-components'
import {Cell} from 'fixed-data-table-2'

const Select = styled.select`
  width: 100%;
`

const Info = styled.div`
  line-height: 16px;
  font-size: 10px;
  color: #777;
`

export default ({column, mappedFields, tableSchema, updateMappedField, isEditingMapping, onAppBulkImportClick}) => {

  const value = mappedFields
    .filter(f => f.sourceField === column.name)
    .map(f => f.targetField)
    .reduce((acc, cur) => cur, 'IGNORE')

  if(isEditingMapping) {

    return(
      <Cell>
        <div><b>{column.name}</b></div>
        <Select
          value={value}
          onChange={event => updateMappedField({sourceField: column.name, targetField: event.target.value})}
        >
          <option value='IGNORE'>IGNORE</option>
          {tableSchema.columns.map(column =>
            <option key={column.name} value={column.name}>
              {column.name}
            </option>
          )}
        </Select>
      </Cell>
    )

  } else {
    const col = tableSchema.columns.find(c => c.name === value)
    const type = col && col.type
    const isKey = col && col.isKey

    return(
      <Cell>
        {column.name}
        {(type === 'App' || type === 'Company') &&
          <span
            style={{cursor: 'pointer'}}
            onClick={() => onAppBulkImportClick(column.name)}
            role='img'
            aria-label='Import all'
          >⚡</span>
        }
        <Info>
          {value}
          {isKey && ' 🔑'}
        </Info>
      </Cell>
    )

  }
}
