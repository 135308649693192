import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Autocomplete from './Autocomplete'
import { withStyles,  createMuiTheme } from '@material-ui/core/styles'
import styled from 'styled-components'

export const theme = createMuiTheme({
  typography: {
    'fontFamily': '"Quicksand", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'
  }
});

export const DialogContainer = withStyles(theme => ({
  paper: {
    borderRadius: '20px',
    width: '100%',
    maxWidth: '100%',

    [theme.breakpoints.up('sm')]: {
      width: '530px',
      maxWidth: '530px'
    },

    [theme.breakpoints.up('md')]: {
      top: '-10%',
      width: '730px',
      maxWidth: '730px'
    },

    [theme.breakpoints.up('lg')]: {
      maxWidth: '930px',
      width: '930px'
    }
  }
}))(Dialog)

export const SmallDialog = withStyles(theme => ({
  root: {
    '& label': {
      display: 'block',
      marginBottom: '8px'
    }
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      top: '-10%',
      width: '530px',
      maxWidth: '530px'
    },

    [theme.breakpoints.up('lg')]: {
      maxWidth: '530px',
      width: '530px'
    }
  }
}))(DialogContainer)

export const CenteredDialog = withStyles(theme => ({
  paper: {
    [theme.breakpoints.up('md')]: {
      top: '0'
    }
  }
}))(DialogContainer)

export const SquareDialog = withStyles(theme => ({
  paper: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: '800px',
      width: '800px',
      height: '800px'
    }
  }
}))(CenteredDialog)

export const DialogContentContainer = withStyles(theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      paddingRight: '14px'
    }
  }
}))(DialogContent)

export const FormTitle = withStyles({
  root: {
    '& h2': {
      color: '#2e314e',
      fontWeight: '500',
      fontSize: '28px',
      '& > svg': {
        color: '#da9b31',
        width: '50px',
        height: '50px',
        position: 'relative',
        top: '14px',
        marginRight: '5px'
      }
    }
  }
})(DialogTitle)

export const TextfieldContainer = styled.div`
  margin-top: 10px;

  > label {
    display: block;
    margin-bottom: 8px;
  }

  @media (min-width: 960px) {
    margin-right: 10px;
  }
`

export const IndustryAutocomplete = withStyles(theme => ({
  input: {
    padding: '18.5px 14px',
    width: '100%'
  },
  paper: {
    '& > div': {
      height: '110px',
      [theme.breakpoints.up('lg')]: {
        height: '150px'
      }
    }
  }
}))(Autocomplete)

export const IconContainer = styled.span`
  width: 30px;
  height: 24px;
  position: relative;

  > svg {
    position: absolute;
    left: -2px;
    width: 30px;
    height: 30px;
  }
`

export const Actions = withStyles(theme => ({
  root: {
    margin: 0,
    [theme.breakpoints.up('md')]: {
      marginRight: '10px'
    }
  }
}))(DialogActions)

export const UnstyledActions = withStyles(theme => ({
  root: {
    margin: 0
  }
}))(DialogActions)

export const SimpleCancelButton = withStyles({
  root: {
    borderColor: '#e74c3c',
    color: '#e74c3c',
  }
})(Button)

export const SimpleSaveButton = withStyles({
  root: {
    borderColor: '#a9cf54',
    color: '#a9cf54',
    marginRight: 0
  }
})(SimpleCancelButton)

const addMarginTop = (button) => withStyles(theme => ({
  root: {
    marginTop: '60px !important',
    [theme.breakpoints.up('lg')]: {
      marginTop: '100px !important'
    }
  } 
}))(button)

export const CancelButton = addMarginTop(SimpleCancelButton)

export const SaveButton = addMarginTop(SimpleSaveButton)
