import { combineReducers } from 'redux'
import { omit } from '../../../../utils'

const sectionsById = (state = {}, action) => {
  switch (action.type) {
    case 'RECEIVE_SECTIONS':
      return action.payload.sections
    case 'UPDATE_SECTION':
      const old =  Object.assign({}, state[action.payload.id])
      return {
        ...state,
        [action.payload.id]: {
          ...old,
          id: action.payload.id,
          title: action.payload.title,
          intro: action.payload.intro,
          color: action.payload.color
        }
      }
    case 'DELETE_SECTION':
      return omit(state, [action.payload.id])
    case 'ADD_SECTION':
      return {
        ...state,
        [action.payload.id]: {
          id: action.payload.id,
          title: '',
          color: '',
          questions: []
        }
      }
    case 'ADD_QUESTION':
      return {
        ...state,
        [action.payload.sectionId]: {
          ...state[action.payload.sectionId],
          questions: [
            ...state[action.payload.sectionId].questions,
            action.payload.questionId
          ]
        }
      }
    case 'REMOVE_QUESTION_FROM_SECTION':
      return {
        ...state,
        [action.payload.sectionId]: {
          ...state[action.payload.sectionId],
          questions: state[action.payload.sectionId].questions.filter(qId => qId !== action.payload.questionId)
        }
      }
    case 'ADD_QUESTION_TO_SECTION':
      return {
        ...state,
        [action.payload.sectionId]: {
          ...state[action.payload.sectionId],
          questions: [
            ...state[action.payload.sectionId].questions,
            action.payload.questionId
          ]
        }
      }
    case 'SET_SECTION_QUESTIONS_ORDER':
      const copy = Object.assign({}, state[action.payload.id])
      return {
        ...state,
        [action.payload.id]: {
          ...copy,
          questions: action.payload.questions
        }
      }
    default:
      return state
  }
}

const allSections = (state = [], action) => {
  switch (action.type) {
    case 'RECEIVE_SECTIONS':
      return Object.keys(action.payload.sections)
    case 'SET_SECTIONS_ORDER':
      return action.payload.allSections
    case 'DELETE_SECTION':
      return state.filter(id => id !== action.payload.id)
    case 'ADD_SECTION':
      return [...state, action.payload.id]
    default:
      return state
  }
}

export default combineReducers({
  byId: sectionsById,
  allIds: allSections
})
