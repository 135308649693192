import React from 'react'
import styled from 'styled-components'
import PersonIcon from '@material-ui/icons/Person'
import StarIcon from '@material-ui/icons/Star'
import ChatIcon from '@material-ui/icons/Chat'
import BotPanel from './BotPanel'
import ThreadPanel from './ThreadPanel'

const Tabbar = styled.div`
  height: 35px;
  background-color: #F1F1F1;
  border-bottom: 1px solid #BBB;
  display: flex;
`

const TabbarItem = styled.div`
  cursor: pointer;
  flex: 1;
  text-align: center;
  border-right: 1px solid #BBB;
  padding-top: 5px;
  ${props => props.active && `
    border-bottom: 1px solid #1164A3;
  `}
`

export default class Sidepanel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0
    }
  }

  selectTab(index) {
    this.setState({activeTab: index})
  }

  render() {
    return(
      <>
        <Tabbar>
          <TabbarItem onClick={() => this.selectTab(0)} active={this.state.activeTab === 0}>
            <ChatIcon />
          </TabbarItem>
          <TabbarItem onClick={() => this.selectTab(1)} active={this.state.activeTab === 1}>
            <StarIcon />
          </TabbarItem>
          <TabbarItem onClick={() => this.selectTab(2)} active={this.state.activeTab === 2}>
            <PersonIcon />
          </TabbarItem>
        </Tabbar>

        <div style={{flex: 1, overflow: 'auto'}}>

          {this.state.activeTab === 0 &&
            <ThreadPanel />
          }

          {this.state.activeTab === 1 &&
            <BotPanel
              currentUser={{firstName: 'Mathias'}}
              saveLine={line => console.log(line)}
            />
          }

          {this.state.activeTab === 2 && this.props.client &&
            <div>
              <div style={{margin: '10px'}}>
                <b>Name:</b> {`${this.props.client.firstName} ${this.props.client.lastName}`}
              </div>
              <div style={{margin: '10px'}}>
                <b>Company:</b> {this.props.client.companyName}
              </div>
              <div style={{margin: '10px'}}>
                <b>Industry:</b> {this.props.client.industry}
              </div>
            </div>
          }

        </div>
      </>
    )
  }
}
