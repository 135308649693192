import React, { useState, useEffect } from 'react'
import { API } from 'aws-amplify'
import LoadingSpinner from '../../components/LoadingSpinner'
import TextField from '@material-ui/core/TextField'
import * as Dialog from '../../components/Dialog'

export default ({rule, onClose, onSave}) => {

  const [isLoading, setLoading] = useState(true)
  const [allCategories, setAllCategories] = useState()
  const [points, setPoints] = useState(rule.points)
  const [values, setValues] = useState((rule.values && rule.values.join(', ')) || null)
  const [categories, setCategories] = useState((rule.categories && rule.categories.map(v => ({label: v, value: v}))) || null)

  useEffect(() => {
    if(rule.categories) {
      API.get("d1-knowledgebase", `/content/categories`).then(allCategories => {
        setAllCategories(allCategories)
        setLoading(false)
      })
    } else {
      setLoading(false)
    }
  }, [rule])

  const handleSave = () => {
    const data = {
      points,
      values: values && values.split(',').map(v => v.trim()),
      categories: categories && categories.map(v => v.value),
    }
    onSave(data)
  }

  if(isLoading) {
    return(
      <Dialog.DialogContainer open={true} onClose={onClose}>
        <LoadingSpinner />
      </Dialog.DialogContainer>
    )
  }

  return(
    <Dialog.DialogContainer open={true} onClose={onClose}>
      <Dialog.FormTitle>
        Edit rule
      </Dialog.FormTitle>
      <Dialog.DialogContentContainer>

        <h4>
          {rule.description}
        </h4>
        {values != null &&
          <TextField
            type="text"
            variant="outlined"
            placeholder="comma-separated words"
            value={values}
            onChange={(e) => setValues(e.target.value)}
            fullWidth
          />
        }

        {categories != null &&
          <Dialog.IndustryAutocomplete
            value={categories}
            onChange={c => setCategories(c)}
            suggestions={allCategories.map(category => ({ value: category.Name, label: category.Name}))}
            placeholder='Categories'
            isMulti
          />
        }

        <h4>
          Points
        </h4>
        <TextField
          type="number"
          variant="outlined"
          placeholder="Points"
          value={points}
          onChange={(e) => setPoints(parseInt(e.target.value))}
          fullWidth
        />

        <Dialog.Actions>
          <Dialog.CancelButton variant="outlined" onClick={onClose}>
            Cancel
          </Dialog.CancelButton>
          <Dialog.SaveButton
            variant="outlined"
            onClick={() => handleSave()}
            disabled={(points < 0)}
          >
            Save
          </Dialog.SaveButton>
        </Dialog.Actions>

      </Dialog.DialogContentContainer>
    </Dialog.DialogContainer>
  )
}
