import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import * as StyledTable from '../../../components/Table'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {formatUpdatedAt, formatUpdatedBy, formatContent} from './util'

const EventsTableRow = withStyles({
  root: {
    '& td': {
      width: '20%',
      wordBreak: 'break-all',
      '&:last-of-type': {
        width: '40%'
      }
    },
    '&:last-of-type td': {
      borderBottom: 'none'
    }
  }
})(TableRow)

const UpdateEventsTableRow = ({ event }) =>
  <EventsTableRow>

    <StyledTable.TableStandardCell>
      {formatUpdatedAt(event.UpdatedAt)}
    </StyledTable.TableStandardCell>

    <StyledTable.TableStandardCell>
      {formatUpdatedBy(event.UpdatedBy)}
    </StyledTable.TableStandardCell>

    <StyledTable.TableStandardCell>
      {Object.entries(event.Content).map(([key, value]) =>
        <p>{formatContent(key, value)}</p>
      )}
    </StyledTable.TableStandardCell>

  </EventsTableRow>

const UpdateEventsTable = ({ events }) =>
  <StyledTable.TableContainer>
    <Table>
      <TableHead>
        <TableRow>
          <StyledTable.TableHeaderCell>Updated At</StyledTable.TableHeaderCell>
          <StyledTable.TableHeaderCell>Updated By</StyledTable.TableHeaderCell>
          <StyledTable.TableHeaderCell>Content</StyledTable.TableHeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>

      {events.map(event =>
        <UpdateEventsTableRow key={event.ID} event={event} />
      )}

      </TableBody>
    </Table>
  </StyledTable.TableContainer>

export default UpdateEventsTable
