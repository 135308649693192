import React from 'react'
import { connect } from 'react-redux'
import Question from './Question'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Grid from '@material-ui/core/Grid'
import { setSectionQuestionsOrder } from './actions'
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc'

const SortableItem = SortableElement((props) => <Question {...props} />)

const SortableList = SortableContainer(({ questions, sectionId, onClick, expanded, expandedAll }) => {
  return (
    <div>
      {questions.map((id, index) => (
        <SortableItem
          key={`item-${index}`}
          sectionId={sectionId}
          index={index}
          onClick={onClick}
          expanded={expanded}
          expandedAll={expandedAll}
          id={id}
        />
      ))}
    </div>
  )
})

class QuestionsEditor extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      expanded: null,
      expandedAll: true
    }
  }

  toggleExpandedAll = () => {
    const expandedAll = !this.state.expandedAll
    this.setState({ expandedAll })
  }

  handleClick = (sectionId) => {
    const newExpanded = this.state.expanded === sectionId ? null : sectionId
    this.setState({ expanded: newExpanded })
  }

  handleQuestionsSort = ({ oldIndex, newIndex }) => {
    const { sectionId, questions, dispatch } = this.props
    dispatch(setSectionQuestionsOrder(sectionId,
      arrayMove(questions, oldIndex, newIndex)))
  }

  render () {
    return (
      <Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={this.state.expandedAll}
                onChange={this.toggleExpandedAll}
                color='primary'
              />
            }
            label='Expand all'
          />
        </Grid>
        <Grid item xs={12}>
          <SortableList
            {...this.props}
            onSortEnd={this.handleQuestionsSort}
            distance={20}
            expanded={this.state.expanded}
            expandedAll={this.state.expandedAll}
            onClick={this.handleClick}
          />
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const sectionId = ownProps.sectionId
  const section = state.appConfig.sections.byId[sectionId]
  return { questions: section.questions }
}

export default connect(mapStateToProps)(QuestionsEditor)
