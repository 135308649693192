export const OTHER_DOMAIN_NAME = 'Other'
export const OTHER_CATEGORY_NAME = 'Other'

export const formatNumber = (value) => {
  if(typeof value === 'number') {
    return `$ ${Number(value).toLocaleString()}`
  } else {
    return ' - '
  }
}

export const roundAndFormatNumber = (value) => {
  if(typeof value === 'number') {
    return `$ ${Math.round(Number(value)).toLocaleString()}`
  } else {
    return ' - '
  }
}

const groupAppsByCategory = (apps) => {
  const categoryMap = apps.reduce((acc, cur) => {
    const category = cur.app.category || OTHER_CATEGORY_NAME

    if(!acc[category]) {
      acc[category] = {
        name: category,
        apps: []
      }
    }
    acc[category].apps.push({
      ...cur.app,
      transactions: cur.transactions,
      amount: cur.amount
    })
    return acc
  }, {})

  // sort alphabeticaly, but put `Other` at the end
  const result = Object.values(categoryMap)
    .sort((a, b) => {
      if(a.name === OTHER_CATEGORY_NAME) {
        return 1
      } else if(b.name === OTHER_CATEGORY_NAME) {
        return -1
      } else {
        return a.name.localeCompare(b.name)
      }
    })

  return result
}


export const getConfigFromFoundApps = ({foundAppsByCategory, allCategories}) => {
  const allCategoriesMap = allCategories.reduce((acc, cur) => {
    acc[cur.Name] = {
      Domain: cur.Domain || OTHER_DOMAIN_NAME,
      DomainPosition: cur.DomainPosition || null
    }
    return acc
  }, {})

  const domainsMap = foundAppsByCategory.reduce((acc, cur) => {
    const domain = (allCategoriesMap[cur.name] && allCategoriesMap[cur.name].Domain) || OTHER_DOMAIN_NAME
    if(acc[domain]) {
      acc[domain].primarySection.categories.push({
        ...cur,
        defaultApp: {
          name: cur.apps[0].name
        }
      })
    } else {
      acc[domain] = {
        primarySection: {
          name: domain,
          categories: [{
            ...cur,
            defaultApp: {
              name: cur.apps[0].name
            }
          }]
        }
      }
    }
    return acc
  }, {})

  const bands = Object.values(domainsMap)
    .sort((a, b) => {
      if(a.primarySection.name === OTHER_CATEGORY_NAME) {
        return 1
      } else if(b.primarySection.name === OTHER_CATEGORY_NAME) {
        return -1
      } else {
        const aCategory = a.primarySection.categories[0].name
        const bCategory = b.primarySection.categories[0].name

        return (
          allCategoriesMap[aCategory].DomainPosition
          -
          allCategoriesMap[bCategory].DomainPosition
        )
      }
    })

  return {
    bands
  }
}

// FIXME move this to a more global util/lib file
export const getQueryParameterByName = (name) => {
  const url = window.location.href
  const cleanName = name.replace(/[[\]]/g, '\\$&')
  const regex = new RegExp('[?&]' + cleanName + '(=([^&#]*)|&|#|$)')
  const results = regex.exec(url)
  if (!results) {
    return null
  } else if (!results[2]) {
    return ''
  } else {
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
  }
}

export const cleanUpConfig = (config) => {
  return {
    bands: config.bands.map(b => ({
      benchmarkPercentage: b.benchmarkPercentage,
      primarySection: {
        name: b.primarySection.name,
        categories: b.primarySection.categories.map(c => ({
          name: c.name,
          defaultApp: {
            name: c.defaultApp.name
          }
        }))
      },
      secondarySection: b.secondarySection && {
        name: b.secondarySection.name,
        categories: b.secondarySection.categories.map(c => ({
          name: c.name,
          defaultApp: {
            name: c.defaultApp.name
          }
        }))
      }
    }))
  }
}

export const findApps = ({allApps, transactionLines, selectedTransactionLines}) => {
  const result = {}
  transactionLines.forEach(line => {
    const detectedAppName = selectedTransactionLines[line.id]
    if(detectedAppName) {
      if(result[detectedAppName]) {
        result[detectedAppName].amount = result[detectedAppName].amount + line.amount
        result[detectedAppName].transactions = result[detectedAppName].transactions + 1 // FIXME this field should be named noOfTranasctions
      } else {
        result[detectedAppName] = {
          app: allApps.find(app => app.name === detectedAppName),
          amount: line.amount,
          transactions: 1 // FIXME this field should be named noOfTranasctions
        }
      }
    }
  })

  return groupAppsByCategory(Object.values(result))
}

export const filterAccountsByAppName = ({accounts, appName}) => {
  return accounts.reduce((acc, cur) => {
    if(cur.items) {
      const filteredItems = cur.items
        .filter(line => !appName || line.appNameOverridden === appName || (line.appNameOverridden !== null && line.appName === appName))
      if(filteredItems.length > 0) {
        acc.push({ ...cur, items: filteredItems })
      }
    }
    return acc
  }, [])
}

export const getSelectedTransactionLines = ({accounts, belowThreshold=false}) => {
  return accounts.reduce((acc, cur) => {
    if(cur.items) {
      cur.items.forEach(item => {
        if(belowThreshold) {
          if(item.appName && !item.includeInAppStack && !item.appNameOverridden) {
            acc[item.id] = item.appName
          }
        } else {
          if(item.appNameOverridden) {
            acc[item.id] = item.appNameOverridden
          } else if(item.appName && item.includeInAppStack && item.appNameOverridden !== null) {
            acc[item.id] = item.appName
          }    
        }
      })
    }
    return acc
  }, {})
}
