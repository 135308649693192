import React, { useState } from 'react'
import DeleteIcon from '@material-ui/icons/Delete'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save'
import InfoIcon from '@material-ui/icons/Info'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteDialog from './DeleteDialog'

const SectionForm = ({ section, onSectionSave, onDelete }) => {
  const [mode, setMode] = useState('view') // or 'edit'
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [title, setTitle] = useState(section.title)
  const [intro, setIntro] = useState(section.intro)
  const [color, setColor] = useState(section.color)

  const handleChange = (e) => {
    const value = e.target.value
    if (e.target.name === 'title') {
      setTitle(value)
    } else if (e.target.name === 'intro') {
      setIntro(value)
    } else if (e.target.name === 'color') {
      setColor(value)
    }
  }

  const handleSubmit = () => {
    toggleMode()
    onSectionSave(title, intro, color)
  }

  const toggleMode = () => {
    const newMode = mode === 'view' ? 'edit' : 'view'
    setMode(newMode)
  }

  return (
    <Grid container spacing={16}>

      {showDeleteDialog &&
        <DeleteDialog
          title='Delete section?'
          onClose={() => setShowDeleteDialog(false)}
          onSubmit={onDelete}
        />
      }

      <TextField
        label='Title'
        margin='normal'
        disabled={mode === 'view'}
        name='title'
        fullWidth
        value={title}
        onChange={handleChange}
        variant='outlined'
      />

      <Tooltip title='Intro structure: First sentence - definition of the section. Second sentence, three examples of questions in this section. Third sentence, what that information will help us do.'>
        <InfoIcon style={{height: '32px'}} />
      </Tooltip>
      <TextField
        label='Intro'
        margin='normal'
        disabled={mode === 'view'}
        name='intro'
        fullWidth
        value={intro}
        onChange={handleChange}
        variant='outlined'
        multiline
      />

      <TextField
        label='Color'
        margin='normal'
        disabled={mode === 'view'}
        name='color'
        fullWidth
        value={color}
        onChange={handleChange}
        variant='outlined'
      />

      { mode === 'view' &&
        <IconButton onClick={toggleMode} aria-label='Edit'> <EditIcon /> </IconButton>}
      { mode === 'view' &&
        <IconButton onClick={() => setShowDeleteDialog(true)} aria-label='Delete'> <DeleteIcon /> </IconButton>}
      { mode === 'edit' &&
        <IconButton onClick={handleSubmit} aria-label='Save'> <SaveIcon /> </IconButton>}
    </Grid>
  )
}

export default SectionForm
