import React from 'react'
import styled from 'styled-components'
import CloseIcon from '@material-ui/icons/Close'

const Container = styled.div`
  display: flex;
`

const Title = styled.h2`
  flex: 1;
  font-weight: normal;
  margin: 1rem 0;
  text-align: center;
  strong {
    text-transform: capitalize;
  }
`

export default ({title, onClose}) => {
  return(
    <Container>
      <Title>
        {title && <>App Stack for: <strong>{title}</strong></>}
        {!title && <>App Stack</>}
      </Title>
      {onClose &&
        <CloseIcon
          onClick={onClose}
          style={{padding: 5, cursor: 'pointer'}}
        />
      }
    </Container>
  )
}
