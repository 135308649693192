import React from 'react'
import styled from 'styled-components'
import { API } from 'aws-amplify'
import MenuBar from '../components/MenuBar'
import Autocomplete from '../components/Autocomplete'
import Button from '@material-ui/core/Button'

const IntentConfigPanel = styled.div`
  width: 300px;
  margin: 20px;
  padding: 20px;
  border: 1px solid #CCC;
`

const INTENTS = [
  { name: 'AppExpert',      slots: ['app'] },
  { name: 'AppFeature',     slots: ['app', 'feature'] },
  { name: 'AppForIndustry', slots: ['category', 'industry'] },
  { name: 'AppInfo',        slots: ['app', 'field'] },
  { name: 'AppIntegration', slots: ['app', 'otherApp'] },
  { name: 'CategoryExpert', slots: ['category'] },
  { name: 'IndustryExpert', slots: ['industry'] },
]

const SLOT_VALUES = {
  app: [
    'Xero',
    'DEAR',
    'Unleashed',
  ],
  otherApp: [
    'Xero',
    'DEAR',
    'Unleashed',
  ],
  feature: [
    'landing cost',
    'feature 1',
    'feature 2',
  ],
  field: [
    'website',
    'phone',
    'headquarter',
  ],
  category: [
    'Inventory',
    'POS',
    'Accounting',
  ],
  industry: [
    'destillery',
    'industry 1',
    'industry 2',
  ]
}


export default class BotDevPage extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      knowledge: {},
      intentName: INTENTS[0].name,
      slots: {}
    }
  }

  async fetchKnowledge() {
    const knowledge = await API.post("dev-d1-apps-backend", '/knowledge', {
      body: {
        intentName: this.state.intentName,
        slots: this.state.slots
      }
    })
    this.setState({knowledge})
  }

  render() {

    const slots = INTENTS
      .find(intent => intent.name === this.state.intentName)
      .slots

    const formValid = slots.reduce((acc, cur) => {
      return this.state.slots[cur] ? acc : false
    }, true)

    return(
      <div>

        <MenuBar title='Bot Dev' />


        <IntentConfigPanel>

          <div style={{marginBottom: '20px'}}>
            <Autocomplete
              label="Intent name"
              value={{ value: this.state.intentName, label: this.state.intentName}}
              onChange={intent => this.setState({intentName: intent.value})}
              suggestions={INTENTS.map(x => ({ value: x.name, label: x.name}))}
            />
          </div>

          {slots.map(slot =>
            <div key={slot} style={{marginBottom: '20px'}}>
              <Autocomplete
                label={slot}
                value={{ value: this.state.slots[slot], label: this.state.slots[slot]}}
                onChange={selected => this.setState({slots: {...this.state.slots, [slot]: selected.value}})}
                suggestions={SLOT_VALUES[slot].map(x => ({ value: x, label: x}))}
              />
            </div>
          )}

          <Button
            variant="contained"
            color="primary"
            onClick={() => this.fetchKnowledge()}
            disabled={!formValid}
          >
            Search
          </Button>

        </IntentConfigPanel>

        <IntentConfigPanel>
          <div>
            {this.state.knowledge.text}
          </div>
          {this.state.knowledge.link &&
            <div>
              <a href={this.state.knowledge.link}>
                {this.state.knowledge.link}
              </a>
            </div>
          }

        </IntentConfigPanel>

      </div>
    )
  }

}
