import {
  SET_ERROR_NOTIFICATION,
  SET_WARNING_NOTIFICATION,
  SET_INFO_NOTIFICATION,
  CLEAR_ERROR_NOTIFICATION,
  CLEAR_WARNING_NOTIFICATION,
  CLEAR_INFO_NOTIFICATION 
} from '../actions/notificationActions'

const INITIAL_STATE = {
  error: {
    title: '',
    message: '',
    timeout: null
  },
  warning: {
    title: '',
    message: '',
    timeout: null
  },
  info: {
    title: '',
    message: '',
    timeout: null
  }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ERROR_NOTIFICATION:
      return {
        ...state,
        error: {
          title: action.payload.title,
          message: action.payload.message,
          timeout: action.payload.timeout || null
        }
      }
    case SET_WARNING_NOTIFICATION:
      return {
        ...state,
        warning: {
          title: action.payload.title,
          message: action.payload.message,
          timeout: action.payload.timeout || null
        }
      }
    case SET_INFO_NOTIFICATION:
      return {
        ...state,
        info: {
          title: action.payload.title,
          message: action.payload.message,
          timeout: action.payload.timeout || null
        }
      }
    case CLEAR_ERROR_NOTIFICATION:
      return {
        ...state,
        error: {
          title: '',
          message: '',
          timeout: null
        }
      }
    case CLEAR_WARNING_NOTIFICATION:
      return {
        ...state,
        warning: {
          title: '',
          message: '',
          timeout: null
        }
      }
    case CLEAR_INFO_NOTIFICATION:
      return {
        ...state,
        info: {
          title: '',
          message: '',
          timeout: null
        }
      }
    default:
      return state
  }
}
