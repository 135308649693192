/*

  NOT USED ANYMORE!
  CAN BE REMOVED, ONCE WE FULLY TESTED THE NEW APP CONFIG EDITOR UI!

*/
import React, { Component } from 'react'
import { API } from 'aws-amplify'
import Button from '@material-ui/core/Button'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import AdminMenuBar from '../AdminMenuBar'
import {
  APP_ID_ARCHITECTURE_SELECTOR,
  APP_ID_INVENTORY_APP_SELECTOR,
  APP_ID_POS_APP_SELECTOR
} from '../../AppConfig'

export default class AdminAppConfigPage extends Component {

  constructor(props) {
    super(props)
    this.state = {
      config: null,
      selectedAppId: APP_ID_ARCHITECTURE_SELECTOR
    }
  }

  async componentDidMount() {
    const app = await API.get("dev-d1-apps-backend", '/appConfig/' + this.state.selectedAppId)
    const config = JSON.stringify(JSON.parse(app.config), null, 2)
    this.setState({config})
  }

  async handleSelectApp(selectedAppId) {
    this.setState({
      selectedAppId,
      config: null
    })
    const app = await API.get("dev-d1-apps-backend", '/appConfig/' + selectedAppId)
    const config = JSON.stringify(JSON.parse(app.config), null, 2)
    this.setState({config})
  }

  async save() {
    try {
      JSON.parse(this.state.config)
    } catch (err) {
      alert('JSON is not valid!\nNot saved!')
      return
    }

    try {
      await API.put("dev-d1-apps-backend", '/appConfig/' + this.state.selectedAppId, {
        body: {
          config: this.state.config
        }
      })
      alert('Saved!')
    } catch (err) {
      console.error(err)
      alert('Error, could not save!')
    }
  }

  render() {
    return (
      <div>
        <AdminMenuBar activeTab="App config" />

          <Tabs
            style={{backgroundColor: '#DDD'}}
            value={this.state.selectedAppId}
            onChange={(event, selectedAppId) => this.handleSelectApp(selectedAppId)}
          >
            <Tab value={APP_ID_ARCHITECTURE_SELECTOR} label='Architecture Selector' />
            <Tab value={APP_ID_INVENTORY_APP_SELECTOR} label='Inventory App Selector' />
            <Tab value={APP_ID_POS_APP_SELECTOR} label='POS App Selector' />
          </Tabs>



        {(this.state.config !== null) && <div style={{padding: '25px'}}>
          <textarea
            onChange={event => this.setState({config: event.target.value})}
            style={{fontSize: '16px', width: '100%', height: '400px'}}
            value={this.state.config}
          />
          <div style={{marginTop: '25px'}}>
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={() => this.save()}
            >
              Save
            </Button>
          </div>
        </div>}


      </div>
    )
  }
}
