import React, { Component } from 'react'
import styled from 'styled-components'
import { API } from 'aws-amplify'
import AdminMenuBar from '../AdminMenuBar'
import Button from '@material-ui/core/Button'
import LoadingSpinner from '../../components/LoadingSpinner'
import Tabs from './Tabs'
import {Table, Column, Cell} from 'fixed-data-table-2'
import Measure from 'react-measure'

const Content = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  top: 105px;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
`

const SynonymItem = styled.span`
  margin-right: 5px;
  background-color: #EEE;
  padding: 3px;
  border-radius: 3px;
`

const HoverContainer = styled.div`
  form {
    display: inline;
  }
  :hover {
    span {
      visibility: visible;
    }
  }
`

const Link = styled.span`
  color: blue;
  cursor: pointer;
  visibility: hidden;
  margin-left: 10px;
`

const Select = styled.select`
  font-size: 18px;
  margin-bottom: 20px;
  width: 250px;
`

const Input = styled.input`
  font-size: 14px;
  width: 150px;
  padding: 5px;
  margin-left: 10px;
  margin-right: 10px;
`

const TYPES = [
  'App',
  'Category',
  'Company',
  'Industry',
  'Region',
  'Tag',
  'Domain',
]

const DEFAULT_STATE = {
  searchTerm: '',
  newName: '',
  newSynonymName: null,
  newSynonymValue: '',
  updateExistingName: null,
  updateNewName: ''
}

const formatSynonyms = (synonyms) => {
  if(!synonyms) {
    return null
  }
  const result = synonyms.map(item => ({
    name: item.name,
    synonyms: item.synonyms.filter(s => s.toLowerCase() !== item.name.toLowerCase())
  }))
  result.sort((syn1, syn2) => syn1.name.localeCompare(syn2.name))
  return result
}

const filterSynonyms = (synonyms, searchTerm) => {
  if(!synonyms) {
    return null
  }
  const searchString = searchTerm.toLowerCase()
  const result = synonyms.filter(s => {
    if(s.name.toLowerCase().indexOf(searchString) >= 0) {
      return true
    }
    for (let i = 0; i < s.synonyms.length; i++) {
      if(s.synonyms[i].toLowerCase().indexOf(searchString) >= 0) {
        return true
      }
    }
    return false
  })
  return result
}

export default class KnowledgebaseSynonymsPage extends Component {

  constructor(props) {
    super(props)
    this.state = {
      ...DEFAULT_STATE,
      synonyms: null,
      selectedType: TYPES[0],
      dimensions: {
        width: -1,
        height: -1,
      }
    }
  }

  async componentDidMount() {
    await this.loadSynonyms(this.state.selectedType)
  }

  setSynonymsState(synonyms) {
    this.setState({
      ...DEFAULT_STATE,
      synonyms: formatSynonyms(synonyms)
    })
  }

  async loadSynonyms(selectedType) {
    this.setState({...DEFAULT_STATE, synonyms: null, selectedType})
    const synonyms = await API.get("d1-knowledgebase", `/content/synonyms?type=${selectedType}&showIgnore=true`)
    this.setSynonymsState(synonyms)
  }

  async createNewName() {
    API.post("d1-knowledgebase", '/synonyms', {
      body: {
        type: this.state.selectedType,
        synonym: this.state.newName.toLowerCase(),
        name: this.state.newName
      }
    })
    const synonyms = [
      ...this.state.synonyms,
      {
        name: this.state.newName,
        synonyms: [this.state.newName.toLowerCase()]
      }
    ]
    this.setSynonymsState(synonyms)
  }

  async createNewSynonym(event) {
    event.preventDefault()
    if(this.state.newSynonymName && this.state.newSynonymValue.length > 0) {
      API.post("d1-knowledgebase", '/synonyms', {
        body: {
          type: this.state.selectedType,
          synonym: this.state.newSynonymValue.toLowerCase(),
          name: this.state.newSynonymName
        }
      })
      const synonyms = this.state.synonyms.map(s => {
        if(s.name === this.state.newSynonymName) {
          s.synonyms.push(this.state.newSynonymValue.toLowerCase())
        }
        return s
      })
      this.setSynonymsState(synonyms)
    }
  }

  async updateExistingName(event) {
    event.preventDefault()
    if(this.state.updateNewName && this.state.updateNewName.length > 0) {
      API.put("d1-knowledgebase", `/synonyms/${this.state.updateExistingName}`, {
        body: {
          name: this.state.updateNewName,
          type: this.state.selectedType
        }
      })
      const synonyms = this.state.synonyms.map(s => {
        if(s.name === this.state.updateExistingName) {
          return {
            name: this.state.updateNewName,
            synonyms: [...s.synonyms, this.state.updateNewName.toLowerCase()]
          }
        } else {
          return s
        }
      })
      this.setSynonymsState(synonyms)
    }
  }

  render() {
    const synonyms = filterSynonyms(this.state.synonyms, this.state.searchTerm)
    return (
      <div>
        <AdminMenuBar activeTab="Knowledgebase" />
        <Tabs activeTab='Synonyms' />
        <Content>

          <div style={{display: 'flex', justifyContent: 'space-between', height: '50px'}}>

            <div>
              <Select value={this.state.selectedType} onChange={event => this.loadSynonyms(event.target.value)}>
                {TYPES.map(type =>
                  <option key={type} value={type}>{type}</option>
                )}
              </Select>
              <Input
                value={this.state.searchTerm}
                onChange={event => this.setState({searchTerm: event.target.value})}
                placeholder='Search'
              />
            </div>

            <div>
              <Input
                value={this.state.newName}
                onChange={event => this.setState({newName: event.target.value})}
                placeholder='New Name'
              />
              <Button
                onClick={() => this.createNewName()}
                disabled={this.state.newName.length === 0}
                size='small'
                variant='contained'
                color='primary'
              >
                Add
              </Button>
            </div>

          </div>

          {!synonyms && <LoadingSpinner />}
          {synonyms &&
            <Measure bounds onResize={contentRect => this.setState({dimensions: contentRect.bounds})}>
              {({ measureRef }) => (
                <div ref={measureRef} style={{flex: 1}}>
                  <Table
                    rowHeight={35}
                    rowsCount={synonyms.length}
                    width={this.state.dimensions.width}
                    height={this.state.dimensions.height}
                    headerHeight={50}
                  >
                    <Column
                      columnKey='name'
                      header={<Cell>Name</Cell>}
                      width={350}
                      cell={({rowIndex, width, height}) => (
                        <Cell
                          width={width}
                          height={height}
                        >
                          <HoverContainer>
                            {this.state.updateExistingName === synonyms[rowIndex].name &&
                              <form onSubmit={event => this.updateExistingName(event)}>
                                <input
                                  autoFocus
                                  value={this.state.updateNewName}
                                  onChange={event => this.setState({updateNewName: event.target.value})}
                                  placeholder='New Name'
                                />
                              </form>
                            }
                            {this.state.updateExistingName !== synonyms[rowIndex].name &&
                              <>
                                {synonyms[rowIndex].name}
                                <Link onClick={() => this.setState({...DEFAULT_STATE, updateNewName: synonyms[rowIndex].name, updateExistingName: synonyms[rowIndex].name})}>
                                  Edit
                                </Link>
                              </>
                            }
                          </HoverContainer>
                         </Cell>
                      )}
                    />
                    <Column
                      columnKey='synonyms'
                      header={<Cell>Synonyms</Cell>}
                      width={4000}
                      cell={({rowIndex, width, height}) => (
                        <Cell
                          width={width}
                          height={height}
                        >
                          <HoverContainer>
                            {synonyms[rowIndex].synonyms.map((synonym, index) =>
                              <SynonymItem key={synonym}>
                                {synonym}
                              </SynonymItem>
                            )}

                            {this.state.newSynonymName === synonyms[rowIndex].name &&
                              <form onSubmit={event => this.createNewSynonym(event)}>
                                <input
                                  value={this.state.newSynonymValue}
                                  onChange={event => this.setState({newSynonymValue: event.target.value})}
                                  placeholder='New Synonym'
                                />
                              </form>
                            }

                            {this.state.newSynonymName !== synonyms[rowIndex].name &&
                              <Link 
                                onClick={() => this.setState({
                                  ...DEFAULT_STATE, 
                                  searchTerm: this.state.searchTerm,
                                  newSynonymName: synonyms[rowIndex].name
                                })}
                              >
                                Add
                              </Link>
                            }

                          </HoverContainer>
                        </Cell>
                      )}
                    />
                  </Table>
                </div>
              )}
            </Measure>
          }

        </Content>
      </div>
    )
  }

}
