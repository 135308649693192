import React, { useState, useEffect} from 'react'
import styled from 'styled-components'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import AppStackReport from './AppStackReport'
import transformConfig from './transformConfig'
import { getConfigFromFoundApps } from './util'
import AddTemplateButton from './AddTemplateButton'

const TabPanel = styled.div`
  display: ${props => props.hidden ? 'none' : 'block'}
  padding: 10px;
  flex: 1;
`

const Content = ({isAdminArea, tabs, handleAddTemplateClick, selectedTemplate}) => {
  const [value, setValue] = useState(0)

  useEffect(() => {
    if(selectedTemplate) {
      setValue(1)
    }
  }, [selectedTemplate])

  return(
    <div style={{display: 'flex'}}>
      <Tabs
        orientation="vertical"
        value={value}
        onChange={(event, newValue) => setValue(newValue)}
        indicatorColor="primary"
        textColor="primary"
        style={{marginTop: '10px', marginRight: '20px', borderRight: '1px solid #CCC'}}
      >
        {tabs.map((tab, index) =>
          <Tab key={index} label={tab.name} />
        )}
      </Tabs>
      {tabs.map((tab, index) =>
        <TabPanel key={index} hidden={value !== index}>
          <div style={{display: 'flex'}}>
            {tab.content}
            {index === 0 &&
              <div>
                <AddTemplateButton onClick={handleAddTemplateClick} />
              </div>
            }
          </div>
        </TabPanel>
      )}
    </div>
  )
}

export default (props) => {

  const {
    isAdminArea,
    selectedTemplate,
    nextTemplate,
    foundAppsByCategory,
    totalIncome,
    allCategories,
    allApps,
    handleAppIconClick,
    handleAddTemplateClick,
    title
  } = props

  const tabs = []

  const allAppsConfig = transformConfig({
    config: getConfigFromFoundApps({foundAppsByCategory, allCategories}),
    foundAppsByCategory,
    totalIncome
  })
  tabs.push({
    name: 'All found apps',
    content: <AppStackReport
      config={allAppsConfig.config}
      isEditing={false}
      showDefaults={false}
      allApps={allApps}
      handleAppIconClick={handleAppIconClick}
      title={title}
    />
  })

  if(selectedTemplate) {
    const currentTemplateConfig = transformConfig({config: selectedTemplate.config, foundAppsByCategory, totalIncome})
    tabs.push({
      name: 'Template found apps',
      content: <AppStackReport
        config={currentTemplateConfig.config}
        isEditing={false}
        showDefaults={false}
        allApps={allApps}
        handleAppIconClick={handleAppIconClick}
        showBenchmarks={true}
        title={title}
      />
    })

    if(currentTemplateConfig.hasEmptyCategories) {
      tabs.push({
        name: 'Template suggestions',
        content: <AppStackReport
          config={currentTemplateConfig.config}
          isEditing={false}
          showDefaults={true}
          highlightDefaults={true}
          allApps={allApps}
          handleAppIconClick={handleAppIconClick}
          showBenchmarks={true}
          title={title}
        />
      })
    }
  }

  if(nextTemplate) {
    const nextTemplateConfig = transformConfig({config: nextTemplate.config, foundAppsByCategory, totalIncome})
    tabs.push({
      name: 'Next revenue band',
      content: <AppStackReport
        config={nextTemplateConfig.config}
        isEditing={false}
        showDefaults={true}
        highlightDefaults={true}
        allApps={allApps}
        handleAppIconClick={handleAppIconClick}
        showBenchmarks={true}
        title={title}
      />
    })
  }

  return (
    <Content
      isAdminArea={isAdminArea}
      tabs={tabs}
      handleAddTemplateClick={handleAddTemplateClick}
      selectedTemplate={selectedTemplate}
    />
  )
}
