import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import Paper from '@material-ui/core/Paper'
import HtmlField from './components/HtmlField'
import Autocomplete from './components/Autocomplete'
import QuestionMover from './QuestionMover'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import AnswersEditor from './AnswersEditor'
import AddAnswerButton from './AddAnswerButton'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import DeleteDialog from './DeleteDialog'

const QuestionForm = ({ question, topicsSuggestions, sectionId, onQuestionSave, onDelete }) => {
  const [mode, setMode] = useState('view') // or 'edit'
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const [form, setValues] = useState({
    title: question.title,
    tooltip: question.tooltip,
    questionType: question.questionType,
    topics: question.topics || [],
    answers: question.answers
  })

  const updateField = e => {
    setValues({
      ...form,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = () => {
    toggleMode()
    onQuestionSave(form)
  }

  const toggleMode = () => {
    const newMode = mode === 'view' ? 'edit' : 'view'
    setMode(newMode)
  }

  return (
    <Grid container spacing={16}>

      {showDeleteDialog &&
        <DeleteDialog
          title='Delete question?'
          onClose={() => setShowDeleteDialog(false)}
          onSubmit={onDelete}
        />
      }

      { mode === 'edit' &&
      <Paper style={{ minHeight: '100px', marginTop: '10px' }}>
        <HtmlField
          html={form.title || ''}
          disabled={mode === 'view'}
          onHTMLChange={html => setValues({ ...form, title: html })}
        />
      </Paper>
      }
      { mode === 'view' &&
      <TextField
        label='Title'
        margin='normal'
        disabled={mode === 'view'}
        name='title'
        fullWidth
        value={form.title}
        onChange={updateField}
        variant='outlined'
      />}

      <FormControl component='fieldset'>
        <FormLabel component='legend'>Question type</FormLabel>
        <RadioGroup
          aria-label='Question type'
          name='questionType'
          disabled={mode === 'view'}
          value={form.questionType}
          onChange={updateField}
        >
          <FormControlLabel
            disabled={mode === 'view'}
            value='checkbox'
            control={<Radio />}
            label='Multi select'
          />
          <FormControlLabel
            disabled={mode === 'view'}
            value='radiobutton'
            control={<Radio />}
            label='Single select'
          />
        </RadioGroup>
      </FormControl>

      { mode === 'view' && (
        <Grid container spacing={16} >
          <Grid item xs={12}>
            <Typography>Topics</Typography>
            {form.topics.map(topic => (<Chip color='primary' label={topic} />))}
          </Grid>
        </Grid>
      )}

      { mode === 'edit' &&
      <Grid container spacing={16} >
        <Grid item xs={12}>
          <Autocomplete
            value={form.topics.map(item => ({ value: item, label: item }))}
            onChange={(val) => {
              console.log('autocomplete', val)
              const res = val.map(item => item.value)
              setValues({ ...form, topics: res })
            }}
            suggestions={topicsSuggestions}
            label='Topics'
            creatable
            isMulti
          />
        </Grid>
      </Grid>}

      <TextField
        label='Tooltip'
        margin='normal'
        disabled={mode === 'view'}
        name='tooltip'
        multiline
        fullWidth
        rowsMax='4'
        value={form.tooltip}
        onChange={updateField}
        variant='outlined'
      />

      <Grid container spacing={16} >
        <Grid item xs={12}>
          { mode === 'view' && <AnswersEditor questionId={question.id} />}
          { mode === 'view' && <AddAnswerButton questionId={question.id} />}
        </Grid>
      </Grid>

      { mode === 'view' &&
        <IconButton onClick={toggleMode} aria-label='Edit'> <EditIcon /> </IconButton>}
      { mode === 'view' &&
        <IconButton onClick={() => setShowDeleteDialog(true)} aria-label='Delete'> <DeleteIcon /> </IconButton>}
      { mode === 'edit' &&
        <IconButton onClick={handleSubmit} aria-label='Save'> <SaveIcon /> </IconButton>}
      { mode === 'view' &&
        <QuestionMover questionId={question.id} sectionId={sectionId} />}
    </Grid>
  )
}

export default QuestionForm
