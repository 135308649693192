import React from 'react'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'

const Container = styled.div`
  font-size: 12px;
  font-style: italic;
`

const Loading = withStyles({
  root: {
    marginRight: '5px'
  }
})(CircularProgress)

export default ({isSaving}) => {
  if(isSaving) {
    return (
      <Container>
        <Loading size={12} color="inherit" />
        Saving ...
      </Container>
    )
  } else {
    return (
      <Container>
        All changes saved.
      </Container>
    )
  }
}
