import React from 'react'
import AdminMenuBar from '../AdminMenuBar'
import UserList from '../../users/UserList'

export default () =>  {
  return (
    <div>
      <AdminMenuBar activeTab="Users" />
      <div style={{margin: '20px'}}>
        <UserList isAdminArea />
      </div>
    </div>
  )
}
