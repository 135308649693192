import React from 'react'
import AdminMenuBar from './AdminMenuBar'

export default class AdminIndexPage extends React.Component {

  render() {
    return(
      <div>
        <AdminMenuBar />

      </div>
    )
  }

}
