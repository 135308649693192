import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import { API } from 'aws-amplify'
import { connect } from 'react-redux'
import moment from 'moment'
import styled from 'styled-components'
import { setSidePanelContent, setIsLoading as setSidePanelLoading } from '../../actions/sidePanelActions'
import { setErrorNotification } from '../../actions/notificationActions'
import Autocomplete from '../../components/Autocomplete'
import { SidePanelTab, SidePanelTabs } from '../../components/SidePanel'
import LoadingSpinner from '../../components/LoadingSpinner'
import AccountsList from './AccountsList'
import AssignAppToTransactionDialog from './AssignAppToTransactionDialog'
import UnassignAppFromTransactionDialog from './UnassignAppFromTransactionDialog'
import WaitForDownloadDialog from './WaitForDownloadDialog'
import AddTemplateDialog from './AddTemplateDialog'
import AddTemplateButton from './AddTemplateButton'
import AppSummary from './AppSummary'
import AppInfoTab from './AppInfoTab'
import ReportPages from './ReportPages'
import ReportInfoDataPanel from './ReportInfoDataPanel'
import Paper from '@material-ui/core/Paper'
import AppBar from '@material-ui/core/AppBar'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { getHeadersForUserRole } from '../../utils'
import SearchField from '../../components/SearchField'
import {
  findApps,
  formatNumber,
  getConfigFromFoundApps,
  cleanUpConfig,
  filterAccountsByAppName,
  getSelectedTransactionLines,
} from './util'
import { withStyles } from '@material-ui/core/styles'

const DATE_AND_TIME_FORMAT = 'DD.MM.YYYY - HH:mm'

const Container = styled.div`
  padding: 20px;
`

const LeftRightContent = styled.div`
  display: flex;
  flex-direction: row;
`

const Left = styled.div`
  flex: 1;
`

const Right = styled.div`
  flex: 1;
  padding-left: 30px;
`

const KeyValueItem = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  > label {
    font-weight: bold;
    width: 100px;
  }
  > div {
    flex: 1;
  }
`

const HeadingContainer = styled.div`
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const HeadingContainerAppSummary = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const BreadCrumbs = withStyles({
  root: {
    color: '#000'
  }
})(Breadcrumbs)

const BreadcrumbLink = withStyles({
  root: {
    '&:hover': {
      color: '#2e314e'
    }
  }
})(Link)

const AppStackReportsReportsContainer = ({
  isAdminArea, datasetId, history,
  setSidePanelContent, setSidePanelLoading,
  setErrorNotification, myCompanyId,
  isSidePanelOpen, activeSidePanelTab
}) => {

  const [isLoading, setIsLoading] = useState(true)
  const [content, setContent] = useState()
  const [allTemplates, setAllTemplates] = useState([])
  const [selectedTemplate, setSelectedTemplate] = useState()

  const [selectedDataset, setSelectedDataset] = useState()
  const [accounts, setAccounts] = useState([])   // TODO have accounts as {} called accountsMap

  const [lineItemsToBeAssigned, setLineItemsToBeAssigned] = useState()
  const [lineItemsToBeUnassigned, setLineItemsToBeUnassigned] = useState()
  const [appInfoForSidepanel, setAppInfoForSidepanel] = useState()
  const [isAddTemplateDialogOpen, setAddTemplateDialogOpen] = useState(false)
  const [isWaitForDownloadDialogOpen, setWaitForDownloadDialogOpen] = useState(false)
  const [topTabIndex, setTopTabIndex] = useState(0)
  const [searchTerm, setSearchTerm] = useState('')

  // Should be one of the following:
  const STATUS_NONE = 'NONE'
  const STATUS_LOADING = 'LOADING'
  const STATUS_LOADED = 'LOADED'
  const [allTransactionsLoadedStatus, setAllTransactionsLoadedStatus] = useState(STATUS_NONE)

  const lineItemsToBeUnassignedKeyword = useMemo(() => {
    if(lineItemsToBeUnassigned && (lineItemsToBeUnassigned.length === 1)) {
      const line = lineItemsToBeUnassigned[0]
      return line.detectedKeyword
    }
    return null
  }, [lineItemsToBeUnassigned])

  const loadAllData = useCallback(async () => {
    setIsLoading(true)
    const [apps, allCategories, templatesData, dataset, accounts, transactionLines] = await Promise.all([
      API.get("d1-knowledgebase", `/content/apps?type=minimalist`),
      API.get("d1-knowledgebase", `/content/categories`),
      API.get("dev-d1-apps-backend", '/appStackTemplates', { headers: getHeadersForUserRole() }),
      API.get("dev-d1-apps-backend", `/appStackReports/${datasetId}`),
      API.get("dev-d1-apps-backend", `/appStackReports/${datasetId}/accounts`),
      API.get("dev-d1-apps-backend", `/appStackReports/${datasetId}/transactionLines?hasAppName`),
    ])

    const allApps = apps
      .map(a => ({name: a.Name, category: a.MainCategory}))
      .sort((a, b) => a.name.localeCompare(b.name))
    const templates = templatesData
      .sort((a, b) => `${a.templateName} ${a.industry} ${a.maxRevenue}`.localeCompare(`${a.templateName} ${b.industry} ${b.maxRevenue}`))
    setContent({ allApps, allCategories })
    setAllTemplates(templates)
    setSelectedDataset(dataset)
    
    transactionLines.reduce((acc, line) => {
      const account = acc.find(a => a.name === line.accountName)
      if(account) {
        if(account.items) {
          account.items.push(line)
        } else {
          account.items = [ line ]
        }
      }
      return acc
    }, accounts)

    setAccounts(accounts.map(a => ({ ...a, loaded: false })))
    setIsLoading(false)
  }, [datasetId])

  const loadReport = useCallback(async () => {
    setIsLoading(true)
    setSelectedTemplate(null)
    setAccounts([])
    const [dataset, accounts, transactionLines] = await Promise.all([
      API.get("dev-d1-apps-backend", `/appStackReports/${datasetId}`),
      API.get("dev-d1-apps-backend", `/appStackReports/${datasetId}/accounts`),
      API.get("dev-d1-apps-backend", `/appStackReports/${datasetId}/transactionLines?hasAppName`),
    ])
    setSelectedDataset(dataset)

    transactionLines.reduce((acc, line) => {
      const account = acc.find(a => a.name === line.accountName)
      if(account) {
        if(account.items) {
          account.items.push(line)
        } else {
          account.items = [ line ]
        }
      }
      return acc
    }, accounts)

    setAccounts(accounts.map(a => ({ ...a, loaded: false })))
    setIsLoading(false)
  }, [datasetId])

  useEffect(() => {
    if(content) {
      loadReport()
    } else {
      loadAllData()
    }
  }, [datasetId, content, loadAllData, loadReport])

  const loadAllTransactions = async () => {
    setAllTransactionsLoadedStatus(STATUS_LOADING)
    const transactionLines = await API.get("dev-d1-apps-backend", `/appStackReports/${datasetId}/transactionLines`)
    const newAccounts = accounts.map(a => ({ ...a, items: [], loaded: true }))
    transactionLines.reduce((acc, line) => {
      const account = acc.find(a => a.name === line.accountName)
      if(account) {
        account.items.push(line)
      }
      return acc
    }, newAccounts)
    setAccounts(newAccounts)
    setAllTransactionsLoadedStatus(STATUS_LOADED)
  }

  const loadTransactionsForAccount = async (accountName) => {
    const items = await API.get("dev-d1-apps-backend", `/appStackReports/${datasetId}/transactionLines?accountName=${encodeURIComponent(accountName)}`)
    setAccounts(accounts.map(a => {
      if(a.name === accountName) {
        return { ...a, items, loaded: true }
      } else { 
        return a
      }
    }))
  }

  const [selectedTransactionLines, selectedTransactionLinesBelowThreshold] = useMemo(() => {
    return [
      getSelectedTransactionLines({accounts}),
      getSelectedTransactionLines({accounts, belowThreshold: true}),
    ]
  }, [accounts])

  const toggleLineItemSelection = ({lineItems, checked}) => {
    if(checked) {
      setLineItemsToBeAssigned(lineItems)
    } else {
      setLineItemsToBeUnassigned(lineItems)
    }
  }

  const assignAppToTransaction = ({appName, assignToSameContactNames, manualOverrideReason}) => {
    const effectedLines = lineItemsToBeAssigned.reduce((acc, lineItem) => {
      acc.push(lineItem)
      if(assignToSameContactNames) {
        // get all tx in the same account and compare contact name
        const account = accounts.find(a => a.name === lineItem.accountName)
        account.items.forEach(item => {
          if(item.contactName === lineItem.contactName) {
            acc.push(item)
          }
        })
      }
      return acc
    }, [])

    if(effectedLines.length > 0) {
      const effectedIds = effectedLines.map(l => l.id)
      API.patch("dev-d1-apps-backend", `/appStackReports/${datasetId}/transactionLines/${effectedIds.join(',')}`, {
        body: {
          manualOverrideAppName: appName,
          manualOverrideReason,
          exclude: false
        }
      })

      const newAccounts = accounts.map(account => {
        if(account.name === effectedLines[0].accountName) {
          const newItems = account.items.map(item => {
            if(effectedIds.indexOf(item.id) < 0) {
              return item
            } else {
              return { ...item, appNameOverridden: appName }
            }
              })
          return { ...account, items: newItems }
        } else {
          return account
        }
      })
      setAccounts(newAccounts)
    }

    setLineItemsToBeAssigned(null)
  }

  const unassignAppFromTransaction = async ({unassignFromSameDescription, addKeywordToIgnore, manualOverrideReason}) => {
    if(addKeywordToIgnore && lineItemsToBeUnassignedKeyword) {
      await API.post("dev-d1-apps-backend", `/appStackDetectionOverrides`, {
        body: {
          keyword: lineItemsToBeUnassignedKeyword
        }
      })
    }

    const effectedLines = lineItemsToBeUnassigned.reduce((acc, lineItem) => {
      acc.push(lineItem)

      if(unassignFromSameDescription) {
        // get all tx in the same account and compare description
        const account = accounts.find(a => a.name === lineItem.accountName)
        account.items.forEach(item => {
          if(item.description === lineItem.description) {
            acc.push(item)
          }
        })
      }
      return acc
    }, [])

    if(effectedLines.length > 0) {
      const effectedIds = effectedLines.map(l => l.id)
      API.patch("dev-d1-apps-backend", `/appStackReports/${datasetId}/transactionLines/${effectedIds.join(',')}`, {
        body: {
          manualOverrideAppName: null,
          manualOverrideReason,
          exclude: true
        }
      })

      const newAccounts = accounts.map(account => {
        if(account.name === effectedLines[0].accountName) {
          const newItems = account.items.map(item => {
            if(effectedIds.indexOf(item.id) < 0) {
              return item
            } else {
              const appNameOverridden = item.appNameOverridden ? undefined : null
              return { ...item, appNameOverridden }
            }
              })
          return { ...account, items: newItems }
        } else {
          return account
        }
      })
      setAccounts(newAccounts)
    }

    setLineItemsToBeUnassigned(null)
  }

  const createSidePanelContent = useCallback(({activeIndex, appInfo, filteredAccounts}) => {
    return [
      {
        active: (activeIndex === 0),
        title: 'App Info',
        content: <AppInfoTab appInfo={appInfo} />
      },
      {
        active: (activeIndex === 1),
        title: 'Transactions',
        content: <AccountsList
          accounts={filteredAccounts}
          selectedLineItems={selectedTransactionLines}
          toggleLineItemSelection={toggleLineItemSelection}
          isExpandable={false}
          showDescription
        />
      }
    ]
  }, [selectedTransactionLines])

  const showAppInfoInSidepanel = async ({appName, activeIndex}) => {
    setSidePanelLoading()
    const appInfo = await API.get("d1-knowledgebase", `/content/apps/${appName}`)
    setAppInfoForSidepanel(appInfo)
    const filteredAccounts = filterAccountsByAppName({ accounts, appName })
    setSidePanelContent(createSidePanelContent({
      activeIndex,
      appInfo,
      filteredAccounts,
    }))
  }

  useEffect(() => {
    if(isSidePanelOpen && accounts.length > 0) {
      const appName = appInfoForSidepanel && appInfoForSidepanel.Name
      if(appName) {
        const filteredAccounts = filterAccountsByAppName({ accounts, appName })
        setSidePanelContent(createSidePanelContent({
          activeIndex: activeSidePanelTab,
          appInfo: appInfoForSidepanel,
          filteredAccounts,
        }))
      }
    }
    return () => {
      setSidePanelContent()
    }
  }, [accounts, activeSidePanelTab, appInfoForSidepanel, createSidePanelContent, isSidePanelOpen, setSidePanelContent])

  const deleteSelectedDataset = async () => {
    await API.del("dev-d1-apps-backend", `/appStackReports/${selectedDataset.datasetId}`)
    window.location.href = '/'
  }

  const anonymiseSelectedDataset = async () => {
    await API.post("dev-d1-apps-backend", `/appStackReports/${selectedDataset.datasetId}/anonymise`)
    window.location.reload(false)
  }

  const downloadReport = async () => {
    setWaitForDownloadDialogOpen(true)
    const result = await API.get("dev-d1-apps-backend", `/appStackReports/${selectedDataset.datasetId}/pdf`)
    window.location.href = result.signedUrl
    setWaitForDownloadDialogOpen(false)
  }

  const rerunImport = async () => {
    await API.post("dev-d1-apps-backend", `/appStackReports/${selectedDataset.datasetId}/importFromS3`)
    history.push('/')
  }

  const rerunAnalysis = async () => {
    await API.post("dev-d1-apps-backend", `/appStackReports/${selectedDataset.datasetId}/analyze`)
    window.location.reload()
  }

  const updateSelectedDataset = async ({nickname, totalIncome, lineOfBusiness, visibility}) => {
    if(selectedDataset) {
      await API.put("dev-d1-apps-backend", `/appStackReports/${selectedDataset.datasetId}`, {
        body: {
          nickname,
          totalIncome,
          lineOfBusiness,
          visibility
        }
      })
      const updatedDataset = {
        ...selectedDataset,
        nickname,
        totalIncome,
        lineOfBusiness
      }
      setSelectedDataset(updatedDataset)
    }
  }

  const createNewTemplate = async (templateData) => {
    const { templateName, industry, maxRevenue } = templateData

    if(templateName.length === 0 ||
      ((industry && industry.length === 0) || industry === null) ||
      Number(maxRevenue) <= 0
    ) {
      setErrorNotification({
        title: 'Input Error',
        message: 'Please fill out all the fields at the top of the dialog. Thank you.'
      })
    } else {
      const foundAppsByCategory = Object.entries(templateData.config).map(([key, value]) => ({
        name: key,
        apps: [{name: value}]
      }))
      const newTemplate = await API.post("dev-d1-apps-backend", '/appStackTemplates', {
        body: {
          ...templateData,
          config: cleanUpConfig(getConfigFromFoundApps({
            foundAppsByCategory,
            allCategories: content.allCategories
          }))
        }
      })
      setAllTemplates([newTemplate, ...allTemplates])
      setAddTemplateDialogOpen(false)
    }
  }

  const foundAppsByCategory = useMemo(() => {
    return !!content && !isLoading && findApps({
      allApps: content.allApps,
      selectedTransactionLines,
      transactionLines: accounts.reduce((acc, cur) => {
        return acc.concat(cur.items || [])
      }, [])
    })
  }, [content, isLoading, selectedTransactionLines, accounts])

  const foundAppsBelowThresholdByCategory = useMemo(() => {
    return !!content && !isLoading && findApps({
      allApps: content.allApps,
      selectedTransactionLines: selectedTransactionLinesBelowThreshold,
      transactionLines: accounts.reduce((acc, cur) => {
        return acc.concat(cur.items || [])
      }, [])
    })
  }, [content, isLoading, selectedTransactionLinesBelowThreshold, accounts])


  if(!content || isLoading) {
    return <LoadingSpinner />
  }

  return(
    <Container>
      <LeftRightContent>
        <Left>
          <BreadCrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            <BreadcrumbLink color="inherit" href="#" onClick={() => history.push('/')}>
              <strong>All Clients</strong>
            </BreadcrumbLink>
            <Typography color="textPrimary">
              <b>{selectedDataset.nickname}</b> ({moment(selectedDataset.createdAt).format(DATE_AND_TIME_FORMAT)})
            </Typography>
          </BreadCrumbs>
          <KeyValueItem>
            <label>Template: </label>
            <div>
              <Autocomplete
                value={selectedTemplate && {label: `${selectedTemplate.industry} - Revenue up to ${formatNumber(selectedTemplate.maxRevenue)}`, value: selectedTemplate}}
                onChange={template => setSelectedTemplate(template && template.value)}
                suggestions={allTemplates.map(t => ({label: `${t.templateName} (${t.industry} - ${formatNumber(t.maxRevenue)})`, value: t}))}
                placeholder='Select a template'
              />
            </div>
          </KeyValueItem>
        </Left>
        <Right>
          <ReportInfoDataPanel
            isAdminArea={isAdminArea}
            dataset={selectedDataset}
            onUpdate={({nickname, totalIncome, lineOfBusiness, visibility}) => updateSelectedDataset({nickname, totalIncome, lineOfBusiness, visibility})}
            onDelete={() => deleteSelectedDataset()}
            onAnonymise={() => anonymiseSelectedDataset()}
            onDownload={() => downloadReport()}
            onRerunImport={() => rerunImport()}
            onRerunAnalysis={() => rerunAnalysis()}
            hasWritePermission={selectedDataset.companyId === myCompanyId}
          />
        </Right>
      </LeftRightContent>

      <Paper style={{backgroundColor: '#F5F5F5'}}>
        <AppBar position="static">
          <SidePanelTabs
            value={topTabIndex}
            onChange={(event, newValue) => setTopTabIndex(newValue)}
          >
            <SidePanelTab label="Appstack Report" />
            <SidePanelTab label="Accounting Data" />
          </SidePanelTabs>
        </AppBar>

        <div style={{padding: '10px', display: (topTabIndex === 0) ? 'block' : 'none'}}>
          <ReportPages
            isAdminArea={isAdminArea}
            selectedTemplate={selectedTemplate}
            nextTemplate={selectedTemplate && allTemplates.find(t => t.industry === selectedTemplate.industry && t.maxRevenue > selectedTemplate.maxRevenue)}
            foundAppsByCategory={foundAppsByCategory}
            totalIncome={selectedDataset.totalIncome}
            allApps={content.allApps}
            allCategories={content.allCategories}
            handleAppIconClick={appName => showAppInfoInSidepanel({appName})}
            handleAddTemplateClick={() => setAddTemplateDialogOpen(true)}
            title={selectedDataset.nickname}
          />
        </div>

        <div style={{padding: '10px', display: (topTabIndex === 1) ? 'block' : 'none'}}>
          <LeftRightContent>
            <Left>
              <HeadingContainer>
                <h2>
                  Accounts
                </h2>
                <div style={{display: 'flex'}}>
                  {(allTransactionsLoadedStatus === STATUS_LOADING)
                    && (searchTerm.length > 0)
                    && <LoadingSpinner size={24} padding={15} />
                  }
                  <SearchField
                    value={searchTerm}
                    onValueChanged={setSearchTerm}
                    placeholder='Search transactions'
                    onFocus={() => {
                      if(allTransactionsLoadedStatus === STATUS_NONE) {
                        loadAllTransactions()
                      }
                    }}
                  />
                </div>
              </HeadingContainer>
              <AccountsList
                accounts={accounts}
                selectedLineItems={selectedTransactionLines}
                toggleLineItemSelection={toggleLineItemSelection}
                isExpandable={true}
                showDescription
                searchTerm={searchTerm}
                loadTransactionsForAccount={(accountName) => loadTransactionsForAccount(accountName)}
              />
            </Left>
            <Right>
              <HeadingContainerAppSummary>
                <h2>
                  Summary of Apps
                </h2>
                <AddTemplateButton onClick={() => setAddTemplateDialogOpen(true)} />
              </HeadingContainerAppSummary>
              <AppSummary
                foundApps={foundAppsByCategory}
                handleAppNameClick={appName => showAppInfoInSidepanel({appName, activeIndex: 1})}
              />
              <HeadingContainerAppSummary>
                <h2>
                  Summary of Apps below Threshold
                </h2>
              </HeadingContainerAppSummary>
              <AppSummary
                foundApps={foundAppsBelowThresholdByCategory}
                handleAppNameClick={appName => showAppInfoInSidepanel({appName, activeIndex: 1})}
              />
            </Right>
          </LeftRightContent>
        </div>

      </Paper>

      <AssignAppToTransactionDialog
        isOpen={!!lineItemsToBeAssigned}
        onClose={() => setLineItemsToBeAssigned(null)}
        onSave={assignAppToTransaction}
        allApps={content.allApps}
      />

      <UnassignAppFromTransactionDialog
        isOpen={!!lineItemsToBeUnassigned}
        onClose={() => setLineItemsToBeUnassigned(null)}
        onSave={unassignAppFromTransaction}
        detectionKeyword={lineItemsToBeUnassignedKeyword}
      />

      <AddTemplateDialog
        isOpen={isAddTemplateDialogOpen}
        onClose={() => setAddTemplateDialogOpen(false)}
        onSave={createNewTemplate}
        foundAppsByCategory={foundAppsByCategory}
        totalIncome={selectedDataset.totalIncome}
      />

      <WaitForDownloadDialog
        isOpen={isWaitForDownloadDialogOpen}
      />

    </Container>
  )

}

const mapStateToProps = (state) => ({
  myCompanyId: state.auth.currentCompany.companyId,
  isSidePanelOpen: state.sidePanel.tabs.length > 0,
  activeSidePanelTab: state.sidePanel.activeTab
})

const mapDispatchToProps = (dispatch) => ({
  setSidePanelContent: tabs => dispatch(setSidePanelContent(tabs)),
  setSidePanelLoading: () => dispatch(setSidePanelLoading()),
  setErrorNotification: (error) => dispatch(setErrorNotification(error)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppStackReportsReportsContainer))
