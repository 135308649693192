import React, {useState, useMemo} from 'react'
import moment from 'moment'
import styled from 'styled-components'
import Tooltip from '@material-ui/core/Tooltip'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/InfoOutlined'
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles'
import Table from './Table'
import LoadingSpinner from '../../components/LoadingSpinner'
import { formatNumber } from './util'

const DATE_FORMAT = 'DD.MM.YYYY'

const NoTransactionMessage = styled.i`
  font-size: 12px;
`

const Indicator = styled.div`
  color: #777;
  font-size: 12px;
  width: 80px;
  display: flex;
  align-items: center;
`

const SimpleAccountInfoPanelHeading = styled.div`
  padding: 3px;
  font-weight: bold;
  input {
    margin-right: 10px;
  }
`

const FixedWidthTD = styled.td`
  width: 90px;
`

const IndicatorBar = withStyles({
  root: {
    width: '100px',
    marginLeft: '10px'
  }
})(LinearProgress)

export default (props) => {
  const C = props.isExpandable ? ExpandableAccountInfoPanel : SimpleAccountInfoPanel
  return(
    <C {...props} /> 
  )
}

const SimpleAccountInfoPanel = (props) => {
  const {
    account,
    selectedLineItems,
    toggleLineItemSelection,
    showDescription
  } = props
  return (
    <div>
      <SimpleAccountInfoPanelHeading>
        {toggleLineItemSelection &&
          <input
            type="checkbox"
            checked={true}
            onChange={() => toggleLineItemSelection({
              lineItems: account.items,
              checked: false
            })}
          />
        }
      {account.name}
      </SimpleAccountInfoPanelHeading>
      <TransactionsTable
        items={account.items}
        selectedLineItems={selectedLineItems}
        toggleLineItemSelection={toggleLineItemSelection}
        showDescription={showDescription}
      />
    </div>
  )
}

const ExpandableAccountInfoPanel = (props) => {
  const {
    account,
    selectedLineItems,
    toggleLineItemSelection,
    showDescription,
    searchTerm,
    loadTransactionsForAccount
  } = props

  const [expanded, setExpanded] = useState(false)

  const [accountItems, checkedLineItemsCount, percentage] = useMemo(() => {
    if(!account.loaded) {
      return [null , null, null]
    } else {
      let accountItems
      if(searchTerm.length > 0) {
        const searchTermLowerCase = searchTerm.toLowerCase()
        accountItems = account.items.filter(i => {
          return (i.description && i.description.toLowerCase().indexOf(searchTermLowerCase) >= 0)
            || (i.contactName && i.contactName.toLowerCase().indexOf(searchTermLowerCase) >= 0)
        })
      } else {
        accountItems = account.items
      }
    
      const checkedLineItemsCount = account.items.reduce((acc, cur) => {
        return !!selectedLineItems[cur.id] ? (acc + 1) : acc
      }, 0)
    
      const percentage = Math.round(checkedLineItemsCount * 100 / account.items.length)
  
      return [ accountItems, checkedLineItemsCount, percentage ]
     }
  }, [account, selectedLineItems, searchTerm])

  const handleChange = (event, isExpanded) => {
    if(isExpanded && !account.loaded) {
      loadTransactionsForAccount(account.name)
    }
    setExpanded(isExpanded)
  }

  // if searched and no items, then hide panel
  if(searchTerm.length > 0 && (!accountItems || accountItems.length === 0)) {
    return null
  }

  return (
    <ExpansionPanel
      expanded={expanded}
      onChange={handleChange}
    >
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography style={{flex: 1, fontSize: '16px', fontWeight: (!!checkedLineItemsCount) ? 'bold' : 'normal'}}>
          {account.name}
          {(checkedLineItemsCount > 0) && ` (${checkedLineItemsCount})`}
        </Typography>
        {(percentage > 0) &&
          <Indicator>
            <div>{percentage}%</div>
            <IndicatorBar variant="determinate" value={percentage} />
          </Indicator>
        }
      </ExpansionPanelSummary>
      {expanded &&
        <ExpansionPanelDetails>
          {account.loaded
            ? <TransactionsTable
                items={accountItems}
                selectedLineItems={selectedLineItems}
                toggleLineItemSelection={toggleLineItemSelection}
                showDescription={showDescription}
              />
            : <LoadingSpinner size={25} />
          }
        </ExpansionPanelDetails>
      }
    </ExpansionPanel>
  )
}

const TransactionsTable = ({items, selectedLineItems, toggleLineItemSelection, showDescription}) => {

  if(items.length === 0) {
    return(
      <NoTransactionMessage>
        There are not transactions in this account.
      </NoTransactionMessage>
     )
  }

  return(
    <Table>
      <tbody>
        {items.map(i =>
          <tr key={i.id}>
            {toggleLineItemSelection &&
              <td style={{width: '20px'}}>
                <input
                  type="checkbox"
                  checked={!!selectedLineItems[i.id]}
                  onChange={() => toggleLineItemSelection({lineItems: [i], checked: (!selectedLineItems[i.id])})}
                />
              </td>
            }
            <FixedWidthTD>{moment(i.transactionDate).format(DATE_FORMAT)}</FixedWidthTD>
            <FixedWidthTD>{formatNumber(i.amount)}</FixedWidthTD>
            <td>
              <span style={{fontWeight: 'bold'}}>
                {selectedLineItems[i.id]}
              </span>
            </td>
            <td>{i.contactName}</td>
            <td>
              {showDescription && i.description &&
                <Tooltip title={i.description} placement="right">
                  <InfoIcon style={{fontSize: '16px'}} />
                </Tooltip>
              }
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  )
}
