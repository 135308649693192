import React from 'react'
import ForceGraph2D from 'react-force-graph-2d'
import { API } from 'aws-amplify'
import AppList from './AppList'
import FilterPanel from './FilterPanel'
import Button from '@material-ui/core/Button'
import MenuBar from '../components/MenuBar'
import LoadingSpinner from '../components/LoadingSpinner'
import MakeCopyOfAnswersDialog from '../dialogs/MakeCopyOfAnswersDialog'
import { APP_ID_APPSTACK_DESIGNER } from '../AppConfig'
import MenuBarComponent from '../app-advisor/MenuBarComponent'
import IntercomWidget from '../IntercomWidget'
import { setSidePanelContent } from '../actions/sidePanelActions'
import styled from 'styled-components'
import { ProfileTab, CompanyTab } from './InfoPanel'
import { connect } from 'react-redux'

const Content = styled.div`
  position: relative;
  padding: 20px;

  canvas {
    width: 100% !important;
  }
`

const NODE_R = 10
const DEFAULT_ZOOM = 3

const COLOR_PINNED_APP = '#FFA500'
const COLOR_SELECTED_APP = '#F00'
const COLOR_INTEGRATED_APP = '#00F'
const COLOR_CATEGORY = '#0F0'


const createAppNode = (app, color) => ({
  id: app.id,
  name: app.Name,
  color
})

const createCategoryNode = (category) => ({
  id: category,
  name: category,
  color: COLOR_CATEGORY
})


const filterRawData = ({vedData, selectedApps, appFilter, pinnedAppIdsRaw, openedCategories}) => {

  const nodes = []
  const links = []

  const pinnedAppIds = pinnedAppIdsRaw.filter(x => selectedApps.indexOf(x) < 0)
  const pinnedApps = pinnedAppIds.map(pinnedAppId => vedData.apps.find(app => app.id === pinnedAppId))
  pinnedApps.forEach(app => nodes.push(createAppNode(app, COLOR_PINNED_APP)))

  const pinnedCategoriesSet = pinnedApps.reduce((acc, cur) => {
    cur.Categories.forEach(c => acc.add(c))
    return acc
  }, new Set())
  const pinnedCategories = [ ...pinnedCategoriesSet ]


  selectedApps.forEach(SELECTED_APP_ID => {
    const selectedApp = vedData.apps.find(app => app.id === SELECTED_APP_ID)

    // add selected app to nodes
    if(!nodes.find(node => node.id === selectedApp.id)) {
      nodes.push(createAppNode(selectedApp, COLOR_SELECTED_APP))
    }

    const integratedApps = vedData.integrations
      .filter(i => (i.App1 === SELECTED_APP_ID || i.App2 === SELECTED_APP_ID))
      .map(i => (i.App1 === SELECTED_APP_ID) ? ({id: i.App2, integration: i}) : ({id: i.App1, integration: i}))
      .map(item => ({app: vedData.apps.find(app => app.id === item.id), integration: item.integration }))
      .filter(item => {
        // filter integrations by dominance status
        if(!item.app) {
          return false
        }

/*
        const isInDominance = (appFilter.dominance.indexOf('1') >= 0 && item.app.d1Badge === 'Leader')
          || (appFilter.dominance.indexOf('2') >= 0 && item.app.d1Badge === 'Challenger')
          || (appFilter.dominance.indexOf('3') >= 0 && !item.app.d1Badge)
*/

        let isInIndustry = false
        if(appFilter.industries.length > 0) {
          appFilter.industries.forEach(industry => {
            if(item.app.Industries.indexOf(industry) >= 0) {
              isInIndustry = true
            }
          })
        } else {
          isInIndustry = true
        }


/*
        let isInRegion = false
        if((appFilter.regions.length > 0) && item.app.regions && (item.app.regions.length > 0)) {
          item.app.regions.forEach(region => {
            if(appFilter.regions.indexOf(region) >= 0) {
              isInRegion = true
            }
          })
        } else {
          isInRegion = true
        }
*/

        //return isInDominance && isInIndustry && isInRegion
        return isInIndustry

      })
      .filter(item => {
        // if app is pinned, then always show its integration link
        if(pinnedAppIds.indexOf(item.app.id) >= 0) {
          return true
        }
        // filter integrations if there is a pinned app in the same category
        let result = true
        item.app.Categories.forEach(category => {
          if(pinnedCategories.indexOf(category) >= 0) {
            result = false
          }
        })
        return result
      })


    integratedApps.forEach(item => {
      const app = item.app
      let isAppVisible = false

      app.Categories.forEach(category => {

        // add category to nodes, if not yet added
        if(!nodes.find(node => node.id === category)) {
          nodes.push(createCategoryNode(category))
        }

        // add link from selected app to category node
        links.push({
          source: SELECTED_APP_ID,
          target: category
        })


        const isPinnedApp = (pinnedAppIds.indexOf(app.id) >= 0)
        const isCategoryOpen = (openedCategories.indexOf(category) >= 0)

        if(isPinnedApp || isCategoryOpen) {
          isAppVisible = true
          // add link from category node to integrated app
          links.push({
            source: category,
            target: app.id,
            integration: item.integration
          })
        }

      })

      if(isAppVisible) {
        // add integrated app to nodes
        if(!nodes.find(node => node.id === app.id)) {
          nodes.push(createAppNode(app, COLOR_INTEGRATED_APP))
        }
      }

    })

  })

  const result = {
    nodes,
    links
  }
  return result
}


class GraphPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: null,
      selectedApps: [],
      pinnedApps: [],
      openedCategories: [],
      highlightNodes: [],
      highlightLink: null,
      appFilter: {
        dominance:['1'],
        industries: [],
        regions: []
      },
      actionPanelPosition: null,
      isMakeCopyOfAnswersDialogOpen: false,
      isSaving: false
    }
  }

  filterData() {
    this.setState({
      data: filterRawData({ ...this.state, pinnedAppIdsRaw: this.state.pinnedApps })
    })
  }

  _handleNodeClick = node => {
    if(node.color === COLOR_CATEGORY) {

      const openedCategories = this.state.openedCategories
      const index = openedCategories.indexOf(node.id)
      if(index < 0) {
        openedCategories.push(node.id)
      } else {
        openedCategories.splice(index, 1)
      }
      this.setState({openedCategories}, () => this.filterData())

    } else {
      this.setState({actionPanelPosition: {
        appId: node.id,
        x: this.mousePosition.x,
        y: this.mousePosition.y
      }})
      //this.handleToggleApp(node.id)
    }
  }

  _handleNodeHover = node => {
    this.setState({ highlightNodes: node ? [node] : [] });
  }

  _handleLinkHover = link => {
    this.setState({
      highlightLink: link,
      highlightNodes: link ? [link.source, link.target] : []
    });
  }

  _handleLinkClick = link => {
    if(link.integration) {
      // TODO show integration info in SidePanel
    }
  }

  async componentDidUpdate(prevProps) {
    if(prevProps.clientId !== this.props.clientId || prevProps.savedSearchId !== this.props.savedSearchId) {
      window.clearInterval(this.autoSaveIntervalId)
      await this.componentDidMount()
    }
  }

  async componentDidMount() {

    const [appResult, integrations] = await Promise.all([
      API.get("d1-knowledgebase", `/content/apps`),
      API.get("d1-knowledgebase", `/content/integrations`)
    ])

    // FIXME filter _IGNORE_ serverside
    const apps = appResult.map(app => {
      const categories = app.MainCategory ? [app.MainCategory, ...app.Categories] : app.Categories
      return {
        ...app,
        id: app.Name,
        Categories: categories.filter(c => c !== '_IGNORE_')
      }
    })

    const industriesSet = apps.reduce((acc, cur) => {
      cur.Industries.forEach(i => {
        if(i !== '_IGNORE_') {
          acc.add(i)
        }
      })
      return acc
    }, new Set())

    // FIXME once regions are implemented in knowledgebase
    const regions = []

    const vedData = {
      apps,
      industries: [ ...industriesSet ],
      regions,
      integrations
    }

    let savedData = null
    let clientId = this.props.clientId
    let savedSearch = null
    if(this.props.savedSearchId) {
      try {
        savedSearch = await API.get("dev-d1-apps-backend", '/savedSearches/' + this.props.savedSearchId)
        savedData = JSON.parse(savedSearch.content)
        clientId = savedSearch.clientId
      } catch (err) {
        console.error(err)
        // do nothing
      }
    }

    const client = await API.get("dev-d1-apps-backend", '/clients/' + clientId)

    if(savedData) {
      const selectedApps = savedData.selectedApps.filter(name => !!(vedData.apps.find(app => app.Name === name)))
      const pinnedApps = savedData.pinnedApps.filter(name => !!(vedData.apps.find(app => app.Name === name)))
      const openedCategories = (savedData.openedCategories || [])

      const appFilter = {
        dominance: (savedData.appFilter && savedData.appFilter.dominance) || ['1'],
        industries: (savedData.appFilter && savedData.appFilter.industries) || [],
        regions: (savedData.appFilter && savedData.appFilter.regions) || []
      }
      this.setState({
        selectedApps,
        appFilter,
        pinnedApps,
        openedCategories,
        vedData,
        client,
        savedSearch
      }, () => this.filterData())
    } else {
      this.setState({
        vedData,
        client,
        savedSearch
      })
    }

    const _this = this
    document.onmousemove = function (e) {
      _this.mousePosition = {
        x: e.pageX,
        y: e.pageY,
      }
    }

    this.autoSaveIntervalId = window.setInterval(() => this.autoSave(), 3000)
  }

  componentWillUnmount() {
    window.clearInterval(this.autoSaveIntervalId)
  }

  handleToggleApp(appName) {
    const selectedApps = this.state.selectedApps
    const index = selectedApps.indexOf(appName)
    if(index < 0) {
      selectedApps.push(appName)
    } else {
      selectedApps.splice(index, 1)
    }
    this.setState({selectedApps}, () => this.filterData())
  }

  handleSelectApp(appName) {
    const selectedApps = [ appName ]
    this.setState({actionPanelPosition: null, selectedApps}, () => this.filterData())
  }

  handleShowAppInfo(appId) {
    const infoPanelApp = this.state.vedData.apps.find(app => app.id === appId)
    this.setState({
      actionPanelPosition: null
    })
    this.props.setSidePanelContent([
      {
        title: "Profile",
        content: <ProfileTab app={infoPanelApp} />
      },
      {
        title: "Company",
        content: <CompanyTab app={infoPanelApp} />
      }
    ])
  }

  initGraphRef(el) {
    this.fg = el
    if(el) {
      el.zoom(DEFAULT_ZOOM)
    }
  }

  handleOnChangeAppFilter(appFilter) {
    this.setState({appFilter}, () => this.filterData())
  }

  pinApp() {
    const appId = this.state.actionPanelPosition.appId
    const pinnedApps = this.state.pinnedApps
    if(pinnedApps.indexOf(appId) < 0) {
      pinnedApps.push(appId)
    }
    this.setState({actionPanelPosition: null, pinnedApps}, () => this.filterData())
  }

  unpinApp() {
    const appId = this.state.actionPanelPosition.appId
    let pinnedApps = this.state.pinnedApps
    const index = pinnedApps.indexOf(appId)
    if(index >= 0) {
      pinnedApps = pinnedApps.filter(id => id !== appId)
    }
    this.setState({actionPanelPosition: null, pinnedApps}, () => this.filterData())
  }

  async autoSave() {
    const content = JSON.stringify({
      selectedApps: this.state.selectedApps,
      pinnedApps: this.state.pinnedApps,
      openedCategories: this.state.openedCategories,
      appFilter: this.state.appFilter
    })

    let doSave = true
    if(this.state.selectedApps.length === 0
      || (this.state.savedSearch && this.state.savedSearch.content === content) ) {
      doSave = false
    }

    if(doSave) {
      const name = (this.state.savedSearch && this.state.savedSearch.name) || 'Untitled'
      this.handleRename(name)
    }
  }

  async handleRename(name) {
    this.setState({isSaving: true})

    const content = JSON.stringify({
      selectedApps: this.state.selectedApps,
      pinnedApps: this.state.pinnedApps,
      openedCategories: this.state.openedCategories,
      appFilter: this.state.appFilter
    })

    let savedSearch = this.state.savedSearch
    if(savedSearch) {
      savedSearch = await API.put("dev-d1-apps-backend", '/savedSearches/' + this.state.savedSearch.savedSearchId, {
        body: {
          name,
          content
        }
      })
    } else {
      savedSearch = await API.post("dev-d1-apps-backend", '/savedSearches', {
        body: {
          clientId: this.state.client.clientId,
          appId: APP_ID_APPSTACK_DESIGNER,
          name,
          content
        }
      })
    }
    this.setState({savedSearch})
    window.setTimeout(() => this.setState({isSaving: false}), 500)
  }

  render() {
    const { vedData, data, highlightLink } = this.state;

    if(!vedData) {
      return <LoadingSpinner />
    }

    return(
      <div>

        <MenuBar
          title='Appstack Designer'
          component={
            <MenuBarComponent
              client={this.state.client}
              savedSearch={this.state.savedSearch}
              isSaving={this.state.isSaving}
              handleRename={name => this.handleRename(name)}
            />
          }
        />

        <Content>
          {(this.state.selectedApps.length === 0) && <AppList
            apps={this.state.vedData.apps}
            handleSelectApp={appName => this.handleSelectApp(appName)}
          />}

          {(this.state.selectedApps.length > 0) && <FilterPanel
            appFilter={this.state.appFilter}
            handleOnChangeAppFilter={value => this.handleOnChangeAppFilter(value)}
            handleOnStartAgain={() => this.setState({selectedApps: [], data: null, pinnedApps: []})}
            saveAnswers={() => this.setState({isMakeCopyOfAnswersDialogOpen: true})}
            industries={this.state.vedData.industries}
            regions={this.state.vedData.regions}
          />}

          {(this.state.actionPanelPosition) && <ActionPanel
            position={this.state.actionPanelPosition}
            handleOnClose={() => this.setState({actionPanelPosition: null})}
            handleOnSelect={() => this.handleSelectApp(this.state.actionPanelPosition.appId)}
            handleOnShowInfo={() => this.handleShowAppInfo(this.state.actionPanelPosition.appId)}
            handleOnPin={(this.state.pinnedApps.indexOf(this.state.actionPanelPosition.appId) < 0) && (() => this.pinApp())}
            handleOnUnpin={(this.state.pinnedApps.indexOf(this.state.actionPanelPosition.appId) >= 0) && (() => this.unpinApp())}
          />}

          {data && <ForceGraph2D
            ref={el => this.initGraphRef(el)}
            graphData={data}

            linkLabel={null}
            nodeLabel={null}

            linkDirectionalArrowLength={3}
            linkDirectionalArrowRelPos={1}
            nodeRelSize={NODE_R}
            linkWidth={link => link === highlightLink ? 5 : 1}

            onNodeClick={this._handleNodeClick}
            onNodeHover={this._handleNodeHover}
            onLinkClick={this._handleLinkClick}
            onLinkHover={this._handleLinkHover}


            nodeCanvasObject={(node, ctx, globalScale) => {
              const label = node.name;
              const fontSize = 16/globalScale;
              ctx.font = `${fontSize}px Sans-Serif`;
              const textWidth = ctx.measureText(label).width;
              const bckgDimensions = [textWidth, fontSize].map(n => n + fontSize * 0.2); // some padding
              ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';
              ctx.fillRect(node.x - bckgDimensions[0] / 2, node.y - bckgDimensions[1] / 2, ...bckgDimensions);
              ctx.textAlign = 'center';
              ctx.textBaseline = 'middle';
              ctx.fillStyle = node.color;
              ctx.fillText(label, node.x, node.y);
            }}

          />}

          {this.state.isMakeCopyOfAnswersDialogOpen && <MakeCopyOfAnswersDialog
            onClose={() => this.setState({isMakeCopyOfAnswersDialogOpen: false})}
            open={true}
            savedSearch={this.state.savedSearch}
            clientId={this.state.client.clientId}
            appId={APP_ID_APPSTACK_DESIGNER}
            content={{
              selectedApps: this.state.selectedApps,
              pinnedApps: this.state.pinnedApps,
              openedCategories: this.state.openedCategories,
              appFilter: this.state.appFilter
            }}
          />}

          <IntercomWidget page='APPSTACK_DESIGNER' />
        </Content>

      </div>
    )
  }

}

const mapDispatchToProps = (dispatch) => ({
  setSidePanelContent: (tabs) => dispatch(setSidePanelContent(tabs))
})

export default connect(null, mapDispatchToProps)(GraphPage)


const ActionPanel = ({position, handleOnClose, handleOnSelect, handleOnShowInfo, handleOnPin, handleOnUnpin}) => {

  const style = {
    position: 'absolute',
    zIndex: 100,
    top: position.y - 20,
    left: position.x - 20,
    backgroundColor: '#EEE',
    border: '1px solid #CCC',
    padding: '5px'
  }

  return (
    <div style={style} onMouseOut={console.log('HIDE ME')}>

      <Button color="primary" onClick={() => handleOnShowInfo()}>Info</Button>

      <Button color="primary" onClick={() => handleOnSelect()}>Explore</Button>

      {handleOnPin && <Button color="primary" onClick={() => handleOnPin()}>Pin</Button>}

      {handleOnUnpin && <Button color="primary" onClick={() => handleOnUnpin()}>Unpin</Button>}

      <Button color="primary" onClick={() => handleOnClose()}>X</Button>
    </div>
  )
}
