import React, { Component } from 'react'
import styled from 'styled-components'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField';
import MenuBar from '../components/MenuBar'
import LoadingButton from '../components/LoadingButton'

const Section = styled.div`
  margin: 20px 0;
`

const SuccessMessage = styled.div`
  background-color: green;
  color: white;
  padding 10px;
  border-radius: 4px;
`

export default class SettingsPage extends Component {

  constructor(props) {
    super(props)

    let companyName = 'KPMG'
    if(document.cookie) {
      const cookieCompanyName = document.cookie
        .split(';')
        .map(x => x.trim())
        .find(x => x.startsWith('botSettingsCompanyName='))
      if(cookieCompanyName) {
        companyName = cookieCompanyName.replace('botSettingsCompanyName=', '')
      }
    }

    this.state = {
      successMessage: null,
      companyName
    }
  }

  handleSubmit = (event) => {
    event.preventDefault()
    document.cookie = `botSettingsCompanyName=${this.state.companyName}`
    this.setState({successMessage: 'Saved'})
  }

  render() {
    return (
      <div>
        <MenuBar title='Settings' />
        <Paper style={{margin: '20px auto', padding: '20px 40px', width: '400px'}}>
          <h2>Bot Settings</h2>

            <form onSubmit={this.handleSubmit}>

              {this.state.successMessage &&
                <SuccessMessage>
                  {this.state.successMessage}
                </SuccessMessage>
              }

              <Section>
                <TextField
                  placeholder="Company name"
                  value={this.state.companyName}
                  onChange={event => this.setState({companyName: event.target.value})}
                  variant="outlined"
                  fullWidth
                />
              </Section>

              <Section>
                <LoadingButton
                  text="Save"
                  disabled={false}
                  isLoading={false}
                  onClick={this.handleSubmit}
                />
              </Section>

            </form>
        </Paper>
      </div>
    )
  }

}
