import React from 'react'
import { withRouter } from 'react-router-dom'
import { API } from 'aws-amplify'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import * as Dialog from '../components/Dialog'

export default withRouter(class MakeCopyOfAnswersDialog extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      name: '',
      clients: [],
      client: null
    }
  }

  async componentDidMount() {
    const allClients = await API.get("dev-d1-apps-backend", '/clients')
    const clients = allClients.map(c => ({value: c.clientId, label: c.firstName + ' ' + c.lastName}))
    const client = clients.find(client => client.value === this.props.clientId)
    this.setState({
      clients,
      client,
      name: this.props.savedSearch ? this.props.savedSearch.name : ''
    })
  }

  async save() {
    const result = await API.post("dev-d1-apps-backend", '/savedSearches', {
      body: {
        clientId: this.state.client.value,
        appId: this.props.appId,
        name: this.state.name,
        content: JSON.stringify(this.props.content)
      }
    })
    this.props.onClose()
    this.props.history.push('/apps/' + result.appId + '/savedSearches/' + result.savedSearchId)
  }

  render() {
    return(
      <Dialog.DialogContainer
        open={this.props.open}
        onClose={() => this.props.onClose()}
      >
        <Dialog.FormTitle>Make a Copy of Saved Search</Dialog.FormTitle>
        <Dialog.DialogContentContainer>

          <Grid container>

            <Grid item xs={12} md={6}>
              <Dialog.TextfieldContainer>
                <label htmlFor="client">Client</label>
                <Dialog.IndustryAutocomplete id="client"
                  value={this.state.client}
                  onChange={client => this.setState({client})}
                  suggestions={this.state.clients}
                  placeholder='Search client'
                />
              </Dialog.TextfieldContainer>
            </Grid>

            <Grid item xs={12} md={6}>
              <Dialog.TextfieldContainer>
                <label htmlFor="name">Name</label>
                <TextField id="name"
                  value={this.state.name}
                  variant="outlined"
                  onChange={(event) => this.setState({name: event.target.value})}
                  placeholder="Enter name" fullWidth 
                />
              </Dialog.TextfieldContainer>
            </Grid>

          </Grid>

          <Dialog.Actions>
            <Dialog.CancelButton variant="outlined" onClick={() => this.props.onClose()}>
              Cancel
            </Dialog.CancelButton>
            <Dialog.SaveButton variant="outlined"
              disabled={!this.state.client || this.state.name.length < 1}
              onClick={() => this.save()}
            >
              Save
            </Dialog.SaveButton>
          </Dialog.Actions>

        </Dialog.DialogContentContainer>
      </Dialog.DialogContainer>
    )
  }

})
