import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import MenuBar from './components/MenuBar'
import * as Dialog from './components/Dialog'
import DialogContentText from '@material-ui/core/DialogContentText';
import PersonIcon from '@material-ui/icons/Person'
import { ReactComponent as SaveIcon } from './assets/save.svg'
import Autocomplete from './components/Autocomplete'
import { setUserRole } from './actions/authActions'

const ROLES = [ 'USER', 'ADMIN' ]

const SelectRolePage = ({history, currentRole, setUserRole}) => {

  const closeDialog = () => history.push('/')

  return (
    <div>
      <MenuBar title='Settings' />

      <Dialog.DialogContainer
        open={true}
        onClose={closeDialog}
        aria-labelledby="form-dialog-title"
      >
        <Dialog.FormTitle id="form-dialog-title">
          <PersonIcon />
          Select a role
        </Dialog.FormTitle>

        <Dialog.DialogContentContainer>
          <DialogContentText>
          </DialogContentText>

          <div style={{width: '250px'}}>
            <Autocomplete
              value={{label: currentRole, value: currentRole}}
              onChange={role => role && setUserRole(role.value)}
              suggestions={ROLES.map(role => ({label: role, value: role}))}
              placeholder='Select role'
            />
          </div>

          <Dialog.Actions>
            <Dialog.SaveButton variant="outlined" onClick={closeDialog} >
              <Dialog.IconContainer>
                <SaveIcon />
              </Dialog.IconContainer>
              Close
            </Dialog.SaveButton>
          </Dialog.Actions>

        </Dialog.DialogContentContainer>
      </Dialog.DialogContainer>

    </div>
  )
}

const mapStateToProps = (state) => ({
  currentRole: state.auth.currentRole
})

const mapDispatchToProps = (dispatch) => ({
  setUserRole: (role) => dispatch(setUserRole(role)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SelectRolePage))
