import React from 'react';
import styled from 'styled-components';
import { SidePanelTabTitle } from '../../components/SidePanel';
import AmplifyConfig from '../../AmplifyConfig.js'

const endpoint = AmplifyConfig.API.endpoints.find(endpoint => {
  return endpoint.name === 'd1-knowledgebase'
}).endpoint

const AppInfoTabContainer = styled.div`
  .appTitle {
    display: flex;
    align-items: center;

    h5 {
      margin-bottom: 0
    }
  }

  .appImage {
    padding: 8px;
    border-radius: 8px;
    margin-right: 10px;

    img {
      display: block;
      max-height: 70px;
    }
  }

  p {
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
`

const formatValue = (value) => {
  let formattedValue = null

  if(Array.isArray(value)) {
    formattedValue = value.join(', ')
  } else if (value.startsWith('http://') || value.startsWith('https://')) {
    formattedValue = (<a href={value} target="_blank"  rel="noopener noreferrer">{value}</a>)
  } else {
    // converts boolean (true/false) to a string
    formattedValue = String(value)
  }
  
  return formattedValue
}

const whiteList = [
  {
    key: 'Tagline',
  },
  { 
    key: 'MainCategory',
    label: 'Main Category'
  },
  { 
    key: 'Categories',
    label: 'Other Categories'
  },
  { 
    key: 'Website',
    label: 'Website'
  },
  { 
    key: 'SocialFacebook',
    label: 'Facebook'
  },
  { 
    key: 'Social Twitter',
    label: 'Twitter'
  },
  { 
    key: 'Social LinkedIn',
    label: 'LinkedIn'
  },
  { 
    key: 'Tags',
    label: 'Tags'
  },
  { 
    key: 'Regions',
    label: 'Regions'
  }
]

export default ({ appInfo }) => {
  const { Name, ...app } = appInfo
  const encodedName = encodeURIComponent(Name.trim())
  const logoURL = `${endpoint}/content/appLogos/${encodedName}`

  const appEntries = whiteList.map((prop, i) => {
    let value = app[prop.key]

    if (typeof value === 'boolean') {
      value = String(value)
    }

    if(!value || value.length === 0) {
      return ''
    }

    let formattedValue = formatValue(value)

    return (
      <p key={`AppInfo-${i}`}>
        <strong>{prop.label ? `${prop.label}: ` : ''}</strong>
        {formattedValue}
      </p>
    )
  })

  return (
    <AppInfoTabContainer>
      <div className="appTitle">
        {logoURL !== null &&
          <div className="appImage"><img src={logoURL} alt={`${Name}'s Logo`} /></div>
        }
        <SidePanelTabTitle>{Name}</SidePanelTabTitle>
      </div>
      {appEntries}
    </AppInfoTabContainer>
  )
}
