import React from 'react'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import IconButton from '@material-ui/core/IconButton'
import { connect } from 'react-redux'
import { addAnswer } from './actions/answerActions'

const AddAnswerButton = ({ onAddAnswer }) => {
  const handleClick = e => {
    e.preventDefault()
    onAddAnswer()
  }

  return (
    <IconButton onClick={handleClick} aria-label='Add'> <NoteAddIcon /> </IconButton>
  )
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAddAnswer: () => {
    console.log('add Answer debug', ownProps.questionId)
    dispatch(addAnswer(ownProps.questionId))
  }
})

export default connect(null, mapDispatchToProps)(AddAnswerButton)
