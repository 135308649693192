import React from 'react';
import classNames from 'classnames';
import ReactSelect, { createFilter } from 'react-select';
import { FixedSizeList as List } from 'react-window';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { default as MaterialTextField } from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import styled from 'styled-components'

const FilterSelect = styled(ReactSelect)`
  background: ${props => props.isDisabled ? '#eee' : '#f3f3f3'};
  border-radius: 20px;
`

const TextField = withStyles({
  root: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '20px'
      },
      '& input': {
        borderRadius: '20px',
        background: '#f3f3f3'
      },
      '&:hover fieldset,&.Mui-focused fieldset': {
        borderColor: '#da9b31',
      }
    },
  }
})(MaterialTextField)

const styles = theme => ({
  input: {
    display: 'flex',
    fontSize: 16,
    padding: '12.1px 15px',
    paddingRight: '7px'
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    alignContent: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    fontSize: 16,
    position: 'absolute',
    left: '15.5px',
  },
  paper: {
    borderRadius: '20px',
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    '& > div': {
      padding: '0'
    }
  }
})

const FilterMenuItem = withStyles({
  root: {
    '&:first-child': {
      borderTopLeftRadius: '20px',
      borderTopRightRadius: '20px'
    },
    '&:last-child': {
      borderBottomLeftRadius: '20px',
      borderBottomRightRadius: '20px'
    },
    '&$selected, &$selected:hover': {
      backgroundColor: '#da9b31',
      fontWeight: '500',
      color: '#fff'
    },
  },
  selected: {},
})(MenuItem)

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField fullWidth
      variant="outlined"
      id="autocomplete"
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        }
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  const { onMouseMove, onMouseOver, ...newInnerProps } = props.innerProps
  return (
    <FilterMenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      {...newInnerProps}
    >
      {props.children}
    </FilterMenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu(props) {
  return (
    <Paper className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const MENU_LIST_ITEM_HEIGHT = 46;
const MenuList = ({options, children, maxHeight, getValue}) => {
  return (
    <List
      style={options.length < 4 ? {overflow: 'hidden'} : {}}
      height={options.length < 4 ? MENU_LIST_ITEM_HEIGHT * options.length : 200}
      itemCount={children.length}
      itemSize={MENU_LIST_ITEM_HEIGHT}
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  )
}

const components = {
  MenuList,
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

class Autocomplete extends React.Component {

  render() {
    const { classes } = this.props
    const selectStyles = {
      input: base => ({
        ...base,
        '& input': {
          font: 'inherit'
        },
      })
    }

    return (
      <FilterSelect
        ignoreAccents={false}
        filterOption={createFilter({ignoreAccents: false})}
        classes={classes}
        styles={selectStyles}
        isDisabled={this.props.disabled}
        options={this.props.suggestions}
        components={components}
        value={this.props.value}
        onChange={(item) => this.props.onChange(item)}
        placeholder={this.props.placeholder}
        isMulti={this.props.isMulti}
        isClearable={!this.props.isMulti}
      />
    )
  }
}
export default withStyles(styles, { withTheme: true })(Autocomplete)
