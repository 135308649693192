import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { API } from 'aws-amplify'
import Checkbox from '@material-ui/core/Checkbox'
import DataUsageIcon from '@material-ui/icons/DataUsage'
import DownloadIcon from '@material-ui/icons/GetApp'

import IntercomWidget from '../IntercomWidget'
import MenuBar from '../components/MenuBar'
import LoadingSpinner from '../components/LoadingSpinner'
import { MainContent } from '../components/Basic'
import WaitForDownloadDialog from './components/WaitForDownloadDialog'
import WaitForAnalysisDialog from './components/WaitForAnalysisDialog'
import { getHeadersForUserRole } from '../utils'
import VisibilityTooltip from './components/VisibilityTooltip'
import GenericTable from '../components/GenericTable/GenericTable'

const AppStackReportsIndexPage = ({history, currentRole}) => {

  const [datasets, setDatasets] = useState()
  const [initialDatasets, setInitialDatasets] = useState()
  const [selectedRows, setSelectedRows] = useState({})
  const [downloadDialogMessage, setDownloadDialogMessage] = useState()
  const [analyzeDialogMessage, setAnalyzeDialogMessage] = useState()
  const limit = 20

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    const initialDatasets = await API.get("dev-d1-apps-backend", `/appStackReports?limit=${limit}`, {
      headers: getHeadersForUserRole()
    })
    setInitialDatasets(initialDatasets)

    API.get("dev-d1-apps-backend", '/appStackReports', {
      headers: getHeadersForUserRole()
    }).then(datasets => {
      setDatasets(datasets)
    })
  }

  const handleToggleSelectedRow = (dataset) => {
    if(selectedRows[dataset.datasetId]) {
      const newSelectedRows = { ...selectedRows }
      delete newSelectedRows[dataset.datasetId]
      setSelectedRows(newSelectedRows)
    } else {
      const newSelectedRows = {
        ...selectedRows,
        [dataset.datasetId]: dataset.nickname
      }
      setSelectedRows(newSelectedRows)
    }
  }

  const downloadSelectedDatasets = async () => {
    const entries = Object.entries(selectedRows)
    for (let i = 0; i < entries.length; i++) {
      const [datasetId, nickname] = entries[i]
      setDownloadDialogMessage(nickname)
      const result = await API.get("dev-d1-apps-backend", `/appStackReports/${datasetId}/pdf`)
      window.location.href = result.signedUrl
    }
    setDownloadDialogMessage(null)
  }

  const analyzeSelectedDatasets = async () => {
    const entries = Object.entries(selectedRows)
    for (let i = 0; i < entries.length; i++) {
      const [datasetId, nickname] = entries[i]
      setAnalyzeDialogMessage(nickname)
      await API.post("dev-d1-apps-backend", `/appStackReports/${datasetId}/analyze`)
    }
    window.location.reload()
  }

  const isAllChecked = () => {
    return datasets && (datasets.length === Object.keys(selectedRows).length)
  }

  const onAllChecked = () => {
    if(isAllChecked()) {
      setSelectedRows({})
    } else {
      const newSelectedRows = datasets.reduce((acc, cur) => {
        acc[cur.datasetId] = cur.nickname
        return acc
      }, {})
      setSelectedRows(newSelectedRows)
    }
  }

  const formatDatasets = (datasets) => {
    return datasets.map((dataset, i) => {
      let newDataset = {
        ...dataset,
        isDatasetChecked: {
          value: !!selectedRows[dataset.datasetId],
          component: (
            <Checkbox
              checked={!!selectedRows[dataset.datasetId]}
              onClick={event => {
                event.stopPropagation()
                return handleToggleSelectedRow(dataset)
              }}
              value={`checked-${i}`}
              color="primary"
            />
          )
        },
        visibility: {
          value: dataset.visibility,
          component: <VisibilityTooltip dataset={dataset} />
        }
      }
      return newDataset
    })
  }

  if(!initialDatasets) {
    return(
      <div>
        <MenuBar title='Book of Apps' activeTab="Clients" />
        <MainContent>
          <LoadingSpinner />
        </MainContent>
      </div>
    )
  }

  const columns = [
    {
      title: '',
      key: 'isDatasetChecked',
      type: 'selector'
    },
    {
      title: 'Imported',
      key: 'createdAt',
      type: 'date'
    },
    {
      title: 'Dataset Nickname',
      key: 'nickname',
      type: 'string'
    },
    {
      title: 'YTD Revenue',
      key: 'totalIncome',
      type: 'currency',
      step: 1000000
    },
    {
      title: 'Line of Business',
      key: 'lineOfBusiness',
      type: 'string'
    },
    {
      title: 'Name',
      key: 'userName',
      type: 'string'
    },
    {
      title: 'Visibility',
      key: 'visibility',
      type: 'string'
    }
  ]

  const actionButtons = [
    {
      label: 'Import data',
      onClick: () => history.push('/appStackReports/import'),
      icon: <DataUsageIcon />
    },
    {
      label: 'Download',
      onClick: () => downloadSelectedDatasets(),
      disabled: !(Object.keys(selectedRows).length > 0),
      icon: <DownloadIcon />
    }
  ]

  if(currentRole === 'ADMIN') {
    columns.splice(2, 0, {
      title: 'Last analysed',
      key: 'lastAnalyzedAt',
      type: 'date'
    })
    columns.splice(-1, 0, {
      title: 'Company',
      key: 'companyName',
      type: 'string'
    })
    columns.push({
      title: 'Apps Over Threshold',
      key: 'appsOverThreshold',
      type: 'number'
    })
    actionButtons.push({
        label: 'Analyse',
        onClick: () => analyzeSelectedDatasets(),
        disabled: !(Object.keys(selectedRows).length > 0)
      }
    )
  }

  return (
    <div>
      <MenuBar title='Book of Apps' activeTab="Clients" />

      <MainContent>
        {(initialDatasets.length === 0) && <i>No datasets found.</i> }

        {initialDatasets.length > 0 &&
          <GenericTable
            title="My Clients"
            initialSortColumn="createdAt"
            initialSortOrder={true}
            columns={columns}
            initialData={formatDatasets(initialDatasets)}
            data={datasets && formatDatasets(datasets)}
            onItemClick={(row, columnKey) => {
              history.push(`/appStackReports/datasets/${row.datasetId}`)
            }}
            actionButtons={actionButtons}
            searchPlaceholder={'Search clients'}
            isAllChecked={isAllChecked()}
            onAllChecked={() => onAllChecked()}
            pageSize={20}
          />
        }
      </MainContent>

      <WaitForDownloadDialog
        isOpen={!!downloadDialogMessage}
        message={downloadDialogMessage}
      />

      <WaitForAnalysisDialog
        isOpen={!!analyzeDialogMessage}
        message={analyzeDialogMessage}
      />

      <IntercomWidget page='APPSTACK_REPORTS_INDEX' />
    </div>
  )
}

const mapStateToProps = (state) => ({
  currentRole: state.auth.currentRole
})

export default connect(mapStateToProps)(withRouter(AppStackReportsIndexPage))
