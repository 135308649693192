import React, { useState } from 'react'
import { addBucket } from './actions/bucketActions'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Bucket from './Bucket'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import IconButton from '@material-ui/core/IconButton'

const BucketsList = (props) => {
  return props.buckets.map((bucket, index) => (
    <Bucket
      {...props}
      bucket={bucket}
      id={bucket.id}
      key={bucket.id}
      bucketIndex={(index + 1)}
    />
  ))
}

const BucketsEditor = ({ buckets, onAddBucket, appName }) => {
  const [ expanded, setExpanded ] = useState(null)

  const handleToggleExpand = bucketId => {
    console.log('handleToggleExpand', bucketId)
    const newExpanded = expanded === bucketId ? null : bucketId
    setExpanded(newExpanded)
  }

  return (
    <div>
      <Typography variant='h5'>Buckets</Typography>
      <BucketsList
        buckets={buckets}
        expanded={expanded}
        onToggleExpand={handleToggleExpand}
        appName={appName}
      />
      <IconButton onClick={() => onAddBucket()} aria-label='Add'> <NoteAddIcon /> </IconButton>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  buckets: state.appConfig.buckets.allIds.map(bucketId => {
    return state.appConfig.buckets.byId[bucketId]
  })
})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onAddBucket: () => dispatch(addBucket())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BucketsEditor)
