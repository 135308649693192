
export const updateComments = (noInfoForSection) => ({
  type: 'UPDATE_COMMENTS',
  payload: {
    noInfoForSection
  }
})

export const receiveComments = comments => ({
  type: 'RECEIVE_COMMENTS',
  payload: comments
})
