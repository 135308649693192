import { combineReducers } from 'redux'
import { omit } from '../../../../utils'

const questionsById = (state = {}, action) => {
  switch (action.type) {
    case 'RECEIVE_QUESTIONS':
      return action.payload.questions
    case 'UPDATE_QUESTION':
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          id: action.payload.id,
          title: action.payload.title,
          tooltip: action.payload.tooltip,
          questionType: action.payload.questionType,
          answers: action.payload.answers,
          topics: action.payload.topics
        }
      }
    case 'DELETE_QUESTION':
      return omit(state, [action.payload.id])
    case 'REMOVE_ANSWER_FROM_QUESTION':
      return {
        ...state,
        [action.payload.questionId]: {
          ...state[action.payload.questionId],
          answers: state[action.payload.questionId].answers.filter(aId => aId !== action.payload.answerId)
        }
      }
    case 'ADD_ANSWER':
      return {
        ...state,
        [action.payload.questionId]: {
          ...state[action.payload.questionId],
          answers: [
            ...state[action.payload.questionId].answers,
            action.payload.answerId
          ]
        }
      }
    case 'SET_QUESTION_ANSWERS_ORDER':
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          answers: action.payload.answers
        }
      }
    case 'ADD_QUESTION':
      return {
        ...state,
        [action.payload.questionId]: {
          id: action.payload.questionId,
          title: '',
          tooltip: '',
          questionType: 'checkbox',
          answers: [],
          topics: []
        }
      }
    default:
      return state
  }
}

export const selectTopics = (state) => {
  let topicsSuggestions = []
  Object.keys(state.byId).forEach(questionId => {
    const questionTopics = state.byId[questionId].topics || []
    topicsSuggestions = [ ...topicsSuggestions, ...questionTopics ]
  })
  return [...new Set(topicsSuggestions)].sort()
}

export const selectTopicsSuggestions = (state) => {
  return selectTopics(state).map(topic => ({
    value: topic, label: topic
  }))
}

export default combineReducers({
  byId: questionsById
})
