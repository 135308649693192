import { normalize, schema } from 'normalizr'

export const normalizedConfig = (config) => {
  const bucket = new schema.Entity('buckets')
  const answer = new schema.Entity('answers', { bucket })
  const question = new schema.Entity('questions', { answers: [answer] })
  const section = new schema.Entity('sections', { questions: [question] })
  const surveySchemaEntity = new schema.Entity('surveys', {
    buckets: [bucket],
    sections: [section]
  })

  const normalizedConfig = normalize(config, surveySchemaEntity)

  return normalizedConfig
}
