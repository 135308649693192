import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { doLogout } from '../actions/authActions'
import AppBar from '@material-ui/core/AppBar'
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import HomeIcon from '@material-ui/icons/HomeOutlined'
import MenuBarDropDown from './MenuBarDropDown'
import styled from 'styled-components'
import { withStyles } from '@material-ui/core/styles'
import { NavButton } from './Basic'

const PageTitle = styled(Typography)`
  padding: 0 15px;
`

const BgIconButton = withStyles({
  root: {
    width: '80px',
    backgroundColor: '#292c46',
    borderRadius: 0,
    height: '100%',
    color: '#fff',
    '&:hover': {
      color: '#da9b31',
    }
  }
})(IconButton)

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;

  &.fullWidth {
    flex-grow: 1;
  }
`

const FlexUserDiv = styled(FlexDiv)`
  justify-content: flex-end;
  margin-right: 20px;
  button span {
    font-size: 18px;
  }
`

const TabButton = withStyles({
  root: {
    color: '#fff',
    marginLeft: '30px',
    position: 'relative',
    top: '2.5px',
    '&.active': {
      color: '#da9b31',
      fontWeight: 'bold'
    }
  }
})(NavButton)

export const NavToolbar = withStyles({
  root: {
    alignItems: 'stretch',
    backgroundColor: '#2e314e',
    color: '#fff'
  }
})(Toolbar)

const MenuBar = ({title, component, history, authState, doLogout, activeTab}) => {
  return (
    <AppBar position="static">
      <NavToolbar disableGutters={true} >
        <FlexDiv>
          <BgIconButton
            aria-label="Go to Home Page"
            color="inherit"
            onClick={() => history.push('/')}
          >
            <HomeIcon />
          </BgIconButton>
        </FlexDiv>

        <FlexDiv>
          <PageTitle variant="h5" color="inherit">
            {title}
          </PageTitle>
        </FlexDiv>

        <FlexDiv>
          <TabButton size="small" className={activeTab === 'Clients' ? 'active' : ''} onClick={() => history.push('/')}>
            Clients
          </TabButton>
        </FlexDiv>

        <FlexDiv>
          <TabButton size="small" className={activeTab === 'Templates' ? 'active' : ''} onClick={() => history.push('/appstackReports/templates')}>
            Templates
          </TabButton>
        </FlexDiv>

        <FlexDiv>
          <TabButton size="small" className={activeTab === 'Keywords' ? 'active' : ''} onClick={() => history.push('/appstackReports/keywords')}>
            Keywords
          </TabButton>
        </FlexDiv>

        {(authState.currentUser.isAdmin || authState.currentUser.isOrgAdmin) &&
          <FlexDiv>
            <TabButton size="small" className={activeTab === 'Users' ? 'active' : ''} onClick={() => history.push('/users')}>
              Users
            </TabButton>
          </FlexDiv>
        }

        <FlexDiv>
          <TabButton size="small" className={activeTab === 'Import' ? 'active' : ''} onClick={() => history.push('/appstackReports/import')}>
            Data Import
          </TabButton>
        </FlexDiv>

        <FlexDiv className="fullWidth">
          {component}
        </FlexDiv>

        <FlexUserDiv>
          <MenuBarDropDown
            authState={authState}
            doLogout={doLogout}
          />
        </FlexUserDiv>

      </NavToolbar>
    </AppBar>
  )
}

const mapStateToProps = (state) => ({
  authState: state.auth
})

const mapDispatchToProps = (dispatch) => ({
  doLogout: () => dispatch(doLogout())
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MenuBar))
