import React from 'react'
import { API } from 'aws-amplify'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import * as Dialog from '../components/Dialog'
import { ReactComponent as CancelIcon } from '../assets/cancel.svg'
import { ReactComponent as SaveIcon } from '../assets/save.svg'

export default class UpdateClientDialog extends React.Component {

  constructor(props) {
    super(props)

    const selectedIndustry = this.props.industries
      .filter(x => x.name === props.client.industry)
      .map(x => ({ value: x.id, label: x.name}))

    this.state = {
      firstName: props.client.firstName,
      lastName: props.client.lastName,
      companyName: props.client.companyName,
      industry: (selectedIndustry.length > 0 && selectedIndustry[0])
    }
  }

  isFormValid() {
    return (
      this.state.firstName.length > 0
      && this.state.lastName.length > 0
      && this.state.companyName.length > 0
      && this.state.industry
    )
  }

  async updateClient() {
    if(this.isFormValid()) {
      await API.put("dev-d1-apps-backend", '/clients/' + this.props.client.clientId, {
        body: {
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          companyName: this.state.companyName,
          industry: this.state.industry.label
        }
      })
      this.props.onClose(true)
    }
  }

  render() {
    return(
      <Dialog.DialogContainer
        open={this.props.open}
        onClose={() => this.props.onClose()}
        aria-labelledby="form-dialog-title"
      >
        <Dialog.FormTitle id="form-dialog-title">Update Client Details</Dialog.FormTitle>
        <Dialog.DialogContentContainer>

        <Grid container>
          <Grid item xs={12} md={6}>
            <Dialog.TextfieldContainer>
              <label htmlFor="firstName">First name</label>
              <TextField
                autoFocus
                variant="outlined"
                id="firstName" placeholder="Enter first name"
                value={this.state.firstName}
                onChange={(event) => this.setState({firstName: event.target.value})}
                fullWidth
              />
            </Dialog.TextfieldContainer>
          </Grid>

          <Grid item xs={12} md={6}>
            <Dialog.TextfieldContainer>
              <label htmlFor="lastName">Last name</label>
              <TextField
                variant="outlined"
                id="lastName" placeholder="Enter last name"
                value={this.state.lastName}
                onChange={(event) => this.setState({lastName: event.target.value})}
                fullWidth
              />
            </Dialog.TextfieldContainer>
          </Grid>

          <Grid item xs={12} md={6}>
            <Dialog.TextfieldContainer>
              <label htmlFor="companyName">Company name</label>
              <TextField
                variant="outlined"
                id="companyName" placeholder="Enter company name"
                value={this.state.companyName}
                onChange={(event) => this.setState({companyName: event.target.value})}
                fullWidth
              />
            </Dialog.TextfieldContainer>
          </Grid>

          <Grid item xs={12} md={6}>
            <Dialog.TextfieldContainer>
              <label htmlFor="industry">Industry</label>
                <Dialog.IndustryAutocomplete
                id="industry"
                  value={this.state.industry}
                  onChange={industry => this.setState({industry})}
                  suggestions={this.props.industries.map(x => ({ value: x.id, label: x.name}))}
                  placeholder='Search an industry'
                />
            </Dialog.TextfieldContainer>
          </Grid>

        </Grid>

          <Dialog.Actions>
            <Dialog.CancelButton variant="outlined" onClick={() => this.props.onClose()}>
              <Dialog.IconContainer><CancelIcon /></Dialog.IconContainer>
              Cancel
            </Dialog.CancelButton>
            <Dialog.SaveButton variant="outlined" onClick={() => this.updateClient()}>
              <Dialog.IconContainer><SaveIcon /></Dialog.IconContainer>
              Save
            </Dialog.SaveButton>
          </Dialog.Actions>

        </Dialog.DialogContentContainer>
        
      </Dialog.DialogContainer>
    )
  }

}
