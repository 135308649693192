import React, { useState } from 'react'
import BandDialog from './band/BandDialog'
import DeleteDialog from './band/DeleteDialog'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import EditIcon from '@material-ui/icons/EditOutlined'
import DialogAddIcon from '@material-ui/icons/LibraryAdd'
import IconButton from '@material-ui/core/IconButton'
import { transformedNameAt180Degree } from '../../components/Basic'
import Band from './band/Band'
import AppStackReportTitle from './AppStackReportTitle'
import styled, { css } from 'styled-components'
import { withStyles } from '@material-ui/core/styles'

const COLORS = [
  '#cfe2f3',
  '#ffe599',
  '#b6d7a8',
  '#f4cccd'
]

const AppStackReport = styled.div`
  flex: 1;
  border: 1px solid #CCC;
  padding-bottom: 10px;
  min-height: 200px;
`

const BandFigures = styled.table`
  border-collapse: collapse;
  width: 100%;

  td {
    outline: 0;
    border-bottom: 0;
    text-align: center;
  }

  .appStackFigures {
    width: 100px;
  }

  .appStackFiguresSpacer {
    width: 25px;
    border: 0;
  }
  `

const TableHead = styled.thead`
  th {
    border-bottom: 1px solid #ccc;

    &:first-of-type, &:nth-child(2), &.secondarySection {
      border-bottom: 0;
    }
  }
`

const IconBtnContainer = withStyles({
  root: {
    padding: '3px',
    color: '#000',
    marginTop: '15px'
  }
})(IconButton)

const axisLabels = css`
  font-size: 1.125rem;
  color: #292c46;
  text-transform: uppercase;
`

const Template = styled.div`
  display: flex;

  .yAxisLabel {
    ${transformedNameAt180Degree}
    ${axisLabels}
    text-align: center;

    p {
      margin-block-end: 6px;
    }
  }

  .xAxisLabel {
    ${axisLabels}
    padding: 16px 0 6px;
  }
`

export default ({
  config, onConfigChange,
  showDefaults, highlightDefaults,
  allApps, allCategories,
  handleAppIconClick, isEditing, showBenchmarks,
  title, hideTitle, onClose, hideRevenueNumbers,
}) => {

  const [isAddDialogOpen, toggleAddDialog] = useState(false)
  const [isEditDialogOpen, toggleEditDialog] = useState(false)
  const [isDeleteDialogOpen, toggleDeleteDialog] = useState(false)
  const [currentBand, setCurrentBand] = useState({})
  const [currentBandIndex, setCurrentBandIndex] = useState(0)

  const addBand = (band) => {
    const newConfig = {
      ...config,
      bands: [
        ...config.bands,
        band
      ]
    }

    onConfigChange(newConfig)
    toggleAddDialog(!isAddDialogOpen)
  }

  const deleteBand = (index) => {
    let newConfig = config
    newConfig.bands.splice(index, 1)
    onConfigChange(newConfig)
    toggleDeleteDialog(!isDeleteDialogOpen)
  }

  const editBand = (band) => {
    let newBands = [...config.bands]
    newBands[currentBandIndex] = band

    const newConfig = {
      ...config,
      bands: newBands
    }

    onConfigChange(newConfig)
    toggleEditDialog(!isEditDialogOpen)
  }

  const moveBand = ({bandIndex, bandConfig, direction}) => {
    let newBands = config.bands
    let newBandIndex = direction === 'up' ? bandIndex - 1 : bandIndex + 1
    let affectedBand = newBands[newBandIndex]
    newBands[newBandIndex] = bandConfig
    newBands[bandIndex] = affectedBand
    const newConfig = {
      ...config,
      bands: newBands
    }
    onConfigChange(newConfig)
  }

  const bandsWithSecondarySection = config.bands.reduce((total, band) => {
    if (band.secondarySection && band.secondarySection.name.length > 0) {
      total += 1
    }
    return total
  }, 0)

  return (
    <AppStackReport>
      <div className="template">
        {!hideTitle &&
          <AppStackReportTitle
            title={title}
            onClose={onClose}
          />
        }
        <Template>
          <div className="yAxisLabel"><p><strong>Domains</strong></p></div>
          <BandFigures border="1">
            <TableHead>
              <tr>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                {bandsWithSecondarySection > 0 &&
                  <th className="secondarySection">&nbsp;</th>
                }

                {hideRevenueNumbers
                  ? <th className="appStackFiguresSpacer"> </th>
                  : <>
                      <th className="appStackFigures">Annual</th>
                      <th className="appStackFigures">% Revenue</th>
                    </>
                }
                {showBenchmarks && <th className="appStackFigures">vs Bmk</th>}
              </tr>
            </TableHead>
            <tbody>

              {config.bands.map((band, i) =>
                <Band
                  key={i}
                  bandConfig={band}
                  allBands={config.bands.length}
                  color={COLORS[i % COLORS.length]}
                  toggleEditDialog={() => toggleEditDialog(!isEditDialogOpen)}
                  toggleDeleteDialog={() => toggleDeleteDialog(!isDeleteDialogOpen)}
                  setCurrentBand={band => setCurrentBand(band)}
                  setCurrentBandIndex={index => setCurrentBandIndex(index)}
                  showDefaults={showDefaults}
                  highlightDefaults={highlightDefaults}
                  bandIndex={i}
                  isEditing={isEditing}
                  allApps={allApps}
                  handleAppIconClick={handleAppIconClick}
                  moveBand={moveBand}
                  showBenchmarks={showBenchmarks}
                  hideRevenueNumbers={hideRevenueNumbers}
                  bandsWithSecondarySection={bandsWithSecondarySection}
                />
              )}

              <tr>
                <td>&nbsp;</td>
                <td className="xAxisLabel"><strong>Categories</strong></td>
              </tr>

              {isEditing &&
                <tr>
                  <td>&nbsp;</td>
                  <td style={{ textAlign: 'center' }}>
                    <IconBtnContainer onClick={() => toggleAddDialog(!isAddDialogOpen)}>
                      <AddIcon />
                    </IconBtnContainer>
                  </td>
                </tr>
              }
            </tbody>
          </BandFigures>
        </Template>

      </div>

      {isAddDialogOpen &&
        <BandDialog
          title={'Add Band'} icon={<DialogAddIcon />}
          isDialogOpen={isAddDialogOpen}
          allApps={allApps} allCategories={allCategories}
          toggleDialog={() => toggleAddDialog(!isAddDialogOpen)}
          addBand={addBand}
        />
      }

      {isEditDialogOpen &&
        <BandDialog
          title={'Edit Band'} icon={<EditIcon />}
          isDialogOpen={isEditDialogOpen}
          allApps={allApps} allCategories={allCategories}
          toggleDialog={() => toggleEditDialog(!isEditDialogOpen)}
          editBand={editBand} band={currentBand}
        />
      }

      {isDeleteDialogOpen &&
        <DeleteDialog
          isDialogOpen={isDeleteDialogOpen}
          toggleDialog={() => toggleDeleteDialog(!isDeleteDialogOpen)}
          deleteItem={deleteBand}
          idToBeDeleted={currentBandIndex}
        />
      }
    </AppStackReport>
  )
}
