import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { Auth } from 'aws-amplify'
import MenuBar from './components/MenuBar'
import * as Dialog from './components/Dialog'
import TextField from '@material-ui/core/TextField'
import DialogContentText from '@material-ui/core/DialogContentText';
import Grid from '@material-ui/core/Grid'
import SettingsIcon from '@material-ui/icons/Settings'
import { ReactComponent as CancelIcon } from './assets/cancel.svg'
import { ReactComponent as SaveIcon } from './assets/save.svg'

const ErrorMessage = styled.div`
  background-color: red;
  color: white;
  padding 10px;
  border-radius: 4px;
  margin: 0 20px;
`

const SettingsPage = ({history}) => {

  const [errorMessage, setErrorMessage] = useState()
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')

  const closeDialog = () => history.push('/')
  const isValidateForm = () => (oldPassword.length > 0 && newPassword.length > 0)

  const handleSubmit = async (event) => {
    event.preventDefault()
    if(isValidateForm()) {
      try {
        const currentUser = await Auth.currentAuthenticatedUser()
        await Auth.changePassword(currentUser, oldPassword, newPassword)
        closeDialog()
      } catch (err) {
        const msg = (err && err.message) || err
        setErrorMessage(msg)
      }
    }
  }

  return (
    <div>
      <MenuBar title='Settings' />

      <Dialog.DialogContainer
        open={true}
        onClose={closeDialog}
        aria-labelledby="form-dialog-title"
      >
        <Dialog.FormTitle id="form-dialog-title">
          <SettingsIcon />
          Settings
        </Dialog.FormTitle>

        {errorMessage &&
          <ErrorMessage>
            {errorMessage}
          </ErrorMessage>
        }

        <Dialog.DialogContentContainer>
          <DialogContentText>
          </DialogContentText>

          <form onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Dialog.TextfieldContainer>
                  <label htmlFor="oldPassword">Old Password</label>
                  <TextField
                    variant="outlined"
                    id="oldPassword"
                    placeholder="Enter your old password"
                    autoComplete="new-password"
                    value={oldPassword}
                    onChange={event => setOldPassword(event.target.value)}
                    type="password"
                  />
                </Dialog.TextfieldContainer>
              </Grid>

              <Grid item xs={12} md={6}>
                <Dialog.TextfieldContainer>
                  <label htmlFor="newPassword">New Password</label>
                  <TextField
                    variant="outlined"
                    id="newPassword"
                    placeholder="Enter your new password"
                    autoComplete="new-password"
                    value={newPassword}
                    onChange={event => setNewPassword(event.target.value)}
                    type="password"
                  />
                </Dialog.TextfieldContainer>
              </Grid>

            </Grid>
          </form>

          <Dialog.Actions>
            <Dialog.CancelButton variant="outlined" onClick={closeDialog}>
              <Dialog.IconContainer>
                <CancelIcon />
              </Dialog.IconContainer>
              Cancel
            </Dialog.CancelButton>
            <Dialog.SaveButton
              variant="outlined"
              onClick={handleSubmit}
              disabled={!isValidateForm()}
            >
              <Dialog.IconContainer>
                <SaveIcon />
              </Dialog.IconContainer>
              Save
            </Dialog.SaveButton>
          </Dialog.Actions>

        </Dialog.DialogContentContainer>
      </Dialog.DialogContainer>

    </div>
  )
}

export default withRouter(SettingsPage)
