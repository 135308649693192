import React, { Component } from 'react'
import { API } from 'aws-amplify'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import WikiPageEditor from './WikiPageEditor'
import AdminMenuBar from '../AdminMenuBar'
import { Container } from '../AdminHelpPage'

const PageList = withStyles({
  root: {
    border: '1px solid #CCC', 
    height: '400px', 
    overflow: 'scroll', 
    marginRight: '50px', 
    marginTop: '20px'
  }
})(List)

const EditorContainer = styled.div`
  width: 500px;
`

const pagesSortFunction = (a, b) => ('' + a.name).localeCompare(b.name)

export default class AdminWikiPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isCreateDialogOpen: false,
      newPageName: '',
      selectedWikiPageId: null,
      wikiPages: [],
      changedName: null,
      changedContent: null
    }
  }

  handleSelectPage(selectedWikiPageId) {
    const page = this.state.wikiPages.find(p => p.wikiPageId === selectedWikiPageId)
    this.setState({
      selectedWikiPageId,
      changedName: page.name,
      changedContent: page.content
    })
  }

  async componentDidMount() {
    const wikiPages = await API.get("dev-d1-apps-backend", '/wikiPages')
    wikiPages.sort(pagesSortFunction)
    this.setState({wikiPages})
  }

  async updatePage() {
    await API.put("dev-d1-apps-backend", '/wikiPages/' + this.state.selectedWikiPageId, {
      body: {
        name: this.state.changedName,
        content: this.state.changedContent
      }
    })
    const wikiPages = await API.get("dev-d1-apps-backend", '/wikiPages')
    wikiPages.sort(pagesSortFunction)
    this.setState({wikiPages})
  }

  async createPage() {
    const newPage = await API.post("dev-d1-apps-backend", '/wikiPages', {
      body: {
        name: this.state.newPageName,
        content: null
      }
    })

    const wikiPages = this.state.wikiPages
    wikiPages.push(newPage)
    wikiPages.sort(pagesSortFunction)

    this.setState({
      isCreateDialogOpen: false,
      newPageName: '',
      selectedWikiPageId: newPage.wikiPageId,
      wikiPages,
      changedName: newPage.name,
      changedContent: newPage.content
    })
  }

  render() {
    return (
      <div>
        <AdminMenuBar activeTab="Wiki pages" />

        <Container>

          <div style={{flex: 1}}>
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.setState({isCreateDialogOpen: true})}
              >
                New Page
              </Button>
            </div>

            <PageList>
              {this.state.wikiPages.map(page =>
                <ListItem
                  key={page.wikiPageId}
                  style={{borderBottom: '1px solid #CCC'}}
                  button
                  selected={page.wikiPageId === this.state.selectedWikiPageId}
                  onClick={event => this.handleSelectPage(page.wikiPageId)}
                >
                  <ListItemText primary={page.name} />
                </ListItem>
              )}
            </PageList>
          </div>

          <EditorContainer>
            {this.state.selectedWikiPageId && 
              <div>
                <div style={{textAlign: 'right'}}>
                  <Button variant="contained" color="primary" onClick={() => this.updatePage()}>
                    Save
                  </Button>
                </div>

                <TextField
                  label="Name"
                  value={this.state.changedName}
                  onChange={(event) => this.setState({changedName: event.target.value})}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />

                <Paper style={{minHeight: '100px', marginTop: '10px'}}>
                  <WikiPageEditor
                    html={this.state.changedContent || ''}
                    onHTMLChange={html => this.setState({changedContent: html})}
                    wikiPageId={this.state.selectedWikiPageId}
                    wikiPages={this.state.wikiPages}
                  />
                </Paper>

                <div style={{margin: '10px 0 0 5px', fontSize: '10px', fontVariant: 'italic'}}>
                  Page ID: {this.state.selectedWikiPageId}
                </div>

              </div>
            }
          </EditorContainer>

          <Dialog
            open={this.state.isCreateDialogOpen}
            onClose={() => this.setState({isCreateDialogOpen: false})}
          >
            <DialogTitle>Create a new page</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="Page name"
                value={this.state.newPageName}
                onChange={(event) => this.setState({newPageName: event.target.value})}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.createPage()} color="primary" variant="contained">
                Save
              </Button>
            </DialogActions>
          </Dialog>

        </Container>
      </div>
    )
  }
}
