import React from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import * as Dialog from '../components/Dialog'

const Container = styled.div`
  width: 230px;
  padding: 0 20px;
  text-align: center;
`

const Progress = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 15px;
`

const CircularFormProgress = withStyles({
  root: {
    color: '#a9cf54',
    position: 'relative',
    zIndex: 2
  }
})(CircularProgress)

const CircleProgressShadow  = withStyles({
  root: {
    color: '#333333',
    position: 'absolute',
    zIndex: 1
  }
})(CircularProgress)


const Percentage = styled.p`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  margin: 0;
  color: #fff;
  > sup {
    font-size: 25px;
  }
`

const TextInfo = styled.div`
  margin-top: 10px;
  color: #fff;
  font-size: 16px;
`

const OutlinedButton = withStyles({
  root: {
    color: '#a9cf54',
    fontSize: '14px',
    borderColor: '#a9cf54',
    borderRadius: '20px',
    width: '180px',
    textTransform: 'none',
    marginTop: '16px'
  }
})(Button)

const DownloadProgress = withStyles({
  root: {
    width: '16px',
    height: '16px',
    marginRight: '5px'
  }
})(CircularProgress)

const DownloadMessage = styled.div`
  font-size: 10px;
`

const DownloadMenu = withStyles({
  paper: {
    borderRadius: '20px',
    '& ul': {
      padding: '0' 
    }
  }
})(Menu)

const DownloadMenuItem = withStyles({
  root: {
    '&:hover, &:focus': {
      backgroundColor: '#a9cf54',
      color: '#fff'
    }
  }
})(MenuItem)

const DownloadingMessage = () =>
  <div>
    <div>
      <DownloadProgress color="primary" />
      Generating report
    </div>
    <DownloadMessage>
      This will take a few seconds.
    </DownloadMessage>
  </div>

export default class ProgressPanel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isDownloading: false,
      anchorEl: null,
      selectedBucket: null
    }
  }

  async save() {
    await this.props.saveAnswers()
  }

  async confirmDownload(bucketId) {
    if(this.props.questionsAnswered < this.props.questionsTotal) {
      this.setState({selectedBucket: bucketId})
    } else {
      await this.startDownload(bucketId)
    }
  }

  async startDownload(bucketId) {
    this.setState({isDownloading: true, selectedBucket: null})
    await this.props.downloadReport(bucketId)
    this.setState({isDownloading: false})
  }

  render() {
    return(
      <Container>

        <Progress>
          <CircularFormProgress size={160}
            variant='static'
            value={Math.round(this.props.questionsAnswered / this.props.questionsTotal * 100)}
          />
          <Percentage>{Math.round(this.props.questionsAnswered / this.props.questionsTotal * 100)}<sup>%</sup></Percentage>
          <CircleProgressShadow size={160}
            variant='static'
            value={100}
          />
        </Progress>

        <TextInfo>
          {this.props.questionsAnswered} of {this.props.questionsTotal} questions answered
        </TextInfo>

        <OutlinedButton
          fullWidth
          variant="outlined"
          aria-owns={this.state.anchorEl ? 'simple-menu' : undefined}
          aria-haspopup="true"
          disabled={this.state.isDownloading}
          onClick={event => this.setState({anchorEl: event.currentTarget})}
        >
          {this.state.isDownloading
            ? <DownloadingMessage />
            : 'Download Report'
          }
        </OutlinedButton>
        <DownloadMenu
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={() => this.setState({anchorEl: null})}
        >
        {this.props.buckets.map(bucket =>
          <DownloadMenuItem
            key={bucket.id}
            onClick={() => this.setState({anchorEl: null}, () => this.confirmDownload(bucket.id))}
          >
            {bucket.title}
          </DownloadMenuItem>
        )}
        </DownloadMenu>

        <OutlinedButton
          fullWidth
          variant="outlined"
          onClick={() => this.save()}
        >
          Make a Copy
        </OutlinedButton>

        <Dialog.DialogContainer
          open={!!this.state.selectedBucket}
          onClose={() => this.setState({selectedBucket: null})}
        >
          <Dialog.FormTitle>Unanswered questions</Dialog.FormTitle>
          <DialogContent>
            <DialogContentText>
              You did not answer {(this.props.questionsTotal - this.props.questionsAnswered)} out of {this.props.questionsTotal} questions. Questions you skipped will not be included in your report.
            </DialogContentText>
            {this.props.hasHiddenSections &&
              <DialogContentText>
                There are hidden sections that won't be included in the report.
              </DialogContentText>
            }
            <Dialog.UnstyledActions>
              <Dialog.CancelButton onClick={() => this.setState({selectedBucket: null})} variant="outlined">
                Cancel
              </Dialog.CancelButton>
              <Dialog.SaveButton onClick={() => this.startDownload(this.state.selectedBucket)} variant="outlined">
                OK
              </Dialog.SaveButton>
            </Dialog.UnstyledActions>
          </DialogContent>
        </Dialog.DialogContainer>


      </Container>
    )
  }
}
