import { withStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Autocomplete from './Autocomplete'
import styled, { css } from 'styled-components'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'

export const MainContent = styled.main`
  margin: 20px;
`

export const Heading1 = styled.h1`
  font-size: 40px;
  font-weight: normal;
  color: #2e314e;
`

export const Form = withStyles({
  root: {
    margin: '0 auto',
    padding: '20px 40px',
    width: '400px',
    borderRadius: '20px'
  }
})(Paper)

export const WideForm = withStyles({
  root: {
    margin: '0 auto',
    padding: '20px 40px',
    width: '800px',
    borderRadius: '20px'
  }
})(Paper)

export const FormTitle = styled.h2`
  color: #2e314e;
  font-weight: 500;
  font-size: 28px;
`

export const FormContainer = styled.div`
  padding-top: 70px;
`

export const FormSection = styled.div`
  margin: 20px 0;
  a {
    color: #069;
  }
`

export const ControlLabel = withStyles({
  root: {
    marginLeft: 0,
    alignItems: 'stretch',
    fontSize: '16px',
    padding: '15px 8px 0',
    '& input': {
      margin: '3px 12px 0 0'
    },
    '& > span': {
      display: 'inline'
    },
    '&.noTopPadding': {
      paddingTop: 0
    }
  }
})(FormControlLabel)

export const TallAutocomplete = withStyles({
  input: {
    padding: '18.5px 14px'
  }
})(Autocomplete)

export const NavButton = withStyles({
  root: {
    textTransform: 'uppercase',
    '&:hover': {
      color: '#da9b31'
    }
  }
})(Button)

export const ErrorMessage = styled.div`
  background-color: #e74c3c;
  color: white;
  padding: 14px;
  border-radius: 20px;
`

export const transformedNameAt180Degree = css`
  font-size: 14px;
  transform: rotate(180deg);
  writing-mode: vertical-rl;
`
