import React from 'react'
import { connect } from 'react-redux'

const SelectSection = ({ sectionId, onSetSectionId, sectionsList }) => {
  return (
    <div>
        Select section:
      <select value={sectionId} onChange={onSetSectionId}>
        <option value=''>All sections</option>
        {sectionsList.map(item => <option key={item.value} value={item.value}>{item.title}</option>)}
      </select>
    </div>
  )
}

const SurveyHeader = ({ sectionId, onSetSectionId, onSetQuestionId,
  sectionsList, questionsList, questionId }) => {
  const selectSection = (
    <SelectSection
      sectionId={sectionId}
      onSetSectionId={onSetSectionId}
      sectionsList={sectionsList}
    />)

  return (
    <div>
      {selectSection}
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  const sectionId = ownProps.sectionId
  const section = state.appConfig.sections.byId[sectionId]
  const sectionsList = state.appConfig.sections.allIds.map(id => {
    const sec = state.appConfig.sections.byId[id]
    return { value: sec.id, title: sec.title }
  })

  let questionsList
  if (section === undefined) {
    questionsList = []
  } else {
    questionsList = section.questions.map(questionId => {
      const question = state.appConfig.questions.byId[questionId]
      return { value: question.id, title: question.title }
    })
  }

  return { sectionsList, questionsList }
}

export default connect(mapStateToProps)(SurveyHeader)
