import React from 'react'
import { addQuestionToSection, removeQuestionFromSection } from './actions'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  }
})

class QuestionMover extends React.Component {
  state = {
    open: false,
    sectionId: ''
  };

  componentDidMount () {
    this.setState({ sectionId: this.props.sectionId })
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value })
  };

  handleClickOpen = () => {
    this.setState({ open: true })
  };

  handleClose = () => {
    this.setState({ open: false })
  };

  handleMove = () => {
    this.props.onQuestionMove(this.state.sectionId)
    this.setState({ open: false })
  }

  render () {
    const { classes, sectionsList } = this.props

    return (
      <div>
        <Button onClick={this.handleClickOpen}>Move</Button>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={this.state.open}
          onClose={this.handleClose}
        >
          <DialogTitle>Move to section</DialogTitle>
          <DialogContent>
            <form className={classes.container}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor='section-simple'>New Section</InputLabel>
                <Select
                  value={this.state.sectionId}
                  onChange={this.handleChange('sectionId')}
                  input={<Input id='section-simple' />}
                >
                  {sectionsList.map(section => <MenuItem
                    key={section.id} name='sectionId' value={section.id}>{section.title}</MenuItem>)}
                </Select>
              </FormControl>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color='primary'>
              Cancel
            </Button>
            <Button
              onClick={this.handleMove}
              disabled={this.props.sectionId === this.state.sectionId}
              color='primary'
            >
             Move
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

QuestionMover.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  sectionsList: state.appConfig.sections.allIds.map(sectionId => {
    const section = state.appConfig.sections.byId[sectionId]
    return { id: sectionId, title: section.title }
  })
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  onQuestionMove: sectionId => {
    const questionId = ownProps.questionId
    const initialSectionId = ownProps.sectionId
    dispatch(addQuestionToSection(questionId, sectionId))
    dispatch(removeQuestionFromSection(questionId, initialSectionId))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(QuestionMover))
