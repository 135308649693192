import React from 'react'
import styled from 'styled-components'
import Avatar from '@material-ui/core/Avatar'
import ReactMarkdown from 'react-markdown'

const BotMessage = styled.div`
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`

const Content = styled.div`
  color: black;
  background-color: #EEE;
  border-radius: 20px;
  padding: 10px 15px;
  p {
    margin: 0;
  }
  a {
    color: #0084ff;
  }
`

const TextLine = ({line}) =>
  <Content>
    <ReactMarkdown
      source={line.text}
      linkTarget='_blank'
    />
  </Content>

const LinkLine = ({line}) =>
  <Content>
    <a href={line.link.startsWith('http') ? line.link : 'http://' + line.link} target='_blank' rel="noopener noreferrer" style={{display: 'block'}}>
      {line.text}
    </a>
  </Content>

const CardLine = ({line}) =>
  <div style={{display: 'flex', flexDirection: 'row', overflow: 'auto'}}>
    {(line.cards.length > 2) && line.cards.map((card, index) => <Card key={index} {...card} /> )}
    {(line.cards.length <= 2) && line.cards.map((card, index) => <BigCard key={index} {...card} /> )}
  </div>

const Card = ({text, link, image}) =>
  <div style={{marginRight: '15px', backgroundColor: '#eee', borderRadius: '20px', border: '1px solid #eee'}}>
    <img alt='' src={image} style={{width: '120px', height: '100px', objectFit: 'cover', borderTopRightRadius: '20px', borderTopLeftRadius: '20px'}} />
    <div style={{textAlign: 'center', padding: '10px'}}>
      {text}
    </div>
  </div>

const BigCard = ({text, link, image}) =>
  <div style={{marginRight: '15px', backgroundColor: '#eee', borderRadius: '20px', border: '1px solid #eee'}}>
    <img alt='' src={image} style={{minWidth: '120px', maxWidth: '180px', minHeight: '100px', maxHeight: '180px', objectFit: 'cover', borderTopRightRadius: '20px', borderTopLeftRadius: '20px'}} />
    <div style={{textAlign: 'center', padding: '10px'}}>
      {text}
    </div>
  </div>


export default ({message, onClick}) =>
<div style={{margin: '15px', marginRight: '50px'}}>
  {message.lines.filter(line => line.text || line.cards).map((line, index, arr) =>
    <BotMessage key={index}>
      <Avatar
        src={((index === arr.length - 1) && "/bot-logo.png") || null}
        style={{marginRight: '10px', backgroundColor: 'white'}}
      > </Avatar>

      {line.cards && <CardLine line={line} />}
      {line.text && line.link && <LinkLine line={line} />}
      {line.text && !line.link && <TextLine line={line} />}

    </BotMessage>
  )}
</div>
