import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'

import BotMessage from './BotMessage'
import UserMessage from './UserMessage'
import SuggestionPanel from './SuggestionPanel'

import { createMessage } from '../../actions/chatConversationsActions'

const Chatbox = styled.div`
  height: 100%;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const ChatFooter = styled.div`
  display: flex;
  border-top: 1px solid #CCC;
  padding: 5px;

  input {
    flex: 1;
    font-size: 16px;
    padding: 10px;
    outline: none;
    border: none;
    ::placeholder {
      color: #AAA;
    }
  }
`

const MenuItem = styled.div`
  color: #0084ff;
  background-color: white;
  border-top: 1px solid #CCC;
  cursor: pointer;
  padding: 15px;
`

const MENU_ITEMS = [
  "Pin to client record",
  "Add company guidelines",
  "Forward chat to firm champion",
  "Forward chat to D1 expert",
  "Email conversation"
]

const getStartButtons = () => ({
  lines: [
    {
      buttons: [
        {text: 'Information about an app', value: 'Information about an app'},
        {text: 'Find an expert', value: 'Find an expert'},
        {text: 'Integration between apps', value: 'Integration between apps'},
        {text: 'Feature of an app', value: 'Feature of an app'},
      ]
    }
  ]
})

/*
const replacePlaceholder = (lines) => {
  let companyName = 'KPMG'
  if(document.cookie) {
    const cookieCompanyName = document.cookie
      .split(';')
      .map(x => x.trim())
      .find(x => x.startsWith('botSettingsCompanyName='))
    if(cookieCompanyName) {
      companyName = cookieCompanyName.replace('botSettingsCompanyName=', '')
    }
  }

  lines.forEach(line => {
    if(line.text) {
      line.text = line.text.replace('{{botSettingsCompanyName}}', companyName)
    }
  })
  return lines
}
*/

class ThreadPanel extends React.Component {

  constructor(props) {
    super(props)
    this.textInput = React.createRef()
    this.state = {
      isMenuOpen: false,
      currentText: '',
      lastText: ''
    }
  }

  scrollToEnd() {
    window.setTimeout(() => this.messagesEnd.scrollIntoView({ behavior: "smooth" }), 300)
  }

  async send({event, text}) {
    if(event) {
      event.preventDefault()
    }
    this.textInput.current.focus()

    const currentText = text || this.state.currentText
    this.props.createMessage(this.props.chatConversation.data.conversationId, currentText)

    this.setState({
      lastText: currentText,
      currentText: ''
    })
  }

  showInitialSuggestions() {
    const messages = [ ...this.props.chatConversation.messages ]
    messages.push(getStartButtons())
    this.setState({
      messages
    }, () => this.scrollToEnd())
  }

  openMenu() {
    this.setState({isMenuOpen: !this.state.isMenuOpen})
  }

  closeMenu() {
    this.setState({isMenuOpen: false})
  }

  showPreviousText() {
    this.setState({currentText: this.state.lastText})
  }

  render() {

    if(!this.props.chatConversation.data) {
      return null
    }


    const messages = this.props.chatConversation.data.messages
    messages.sort((a, b) => (a.createdAt < b.createdAt) ? -1 : 1)
    messages.push(getStartButtons())

    const lastMessage = messages[messages.length - 1]
    const lastLine = !lastMessage.userId && lastMessage.lines[lastMessage.lines.length - 1]
    const suggestButtons = lastLine && lastLine.buttons

    return(
      <Chatbox>
          <Content>
            <div style={{flex: 1, overflow: 'auto', padding: '10px 0'}}>

              {messages.map((message, index) => {
                if(message.userId) {
                  return(
                    <UserMessage key={index} message={message} />
                  )
                } else {
                  return(
                    <BotMessage
                      key={index}
                      message={message}
                      onClick={text => this.send({text})}
                      saveLine={line => this.props.saveLine(line)}
                    />
                  )
                }
              })}

              <div ref={(el) => this.messagesEnd = el }> </div>

            </div>

            <SuggestionPanel
              buttons={suggestButtons}
              onClick={text => this.send({text})}
            />


            <form onSubmit={event => this.send({event})}>
              <ChatFooter>

                <div style={{position: 'relative'}}>
                  <ClickAwayListener onClickAway={() => this.closeMenu()}>
                    <div>
                      <IconButton onClick={() => this.openMenu()}>
                        <MenuIcon />
                      </IconButton>
                      {this.state.isMenuOpen &&
                        <Paper elevation={10} style={{position: 'absolute', bottom: '50px', left: '5px', width: '300px', border: '1px solid #CCC'}}>
                          <div style={{padding: '15px', fontWeight: 'bold', textAlign: 'center'}}>Menu</div>
                          {MENU_ITEMS.map(item =>
                            <MenuItem onClick={() => this.closeMenu()}>
                              {item}
                            </MenuItem>
                          )}
                          <MenuItem onClick={() => {this.closeMenu(); this.showInitialSuggestions()}}>
                            Show questions you can ask
                          </MenuItem>
                        </Paper>
                      }
                    </div>
                  </ClickAwayListener>
                </div>

                <input
                  autoFocus
                  ref={this.textInput}
                  placeholder='Reply ...'
                  value={this.state.currentText}
                  onChange={event => this.setState({currentText: event.target.value})}
                  onKeyDown={event => event.keyCode === 38 && this.showPreviousText()}
                />
              </ChatFooter>
            </form>


        </Content>

      </Chatbox>
    )
  }

}



const mapStateToProps = (state) => ({
  chatConversation: state.chatConversation
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  createMessage: (conversationId, text) => dispatch(createMessage(conversationId, text))
})

export default connect(mapStateToProps, mapDispatchToProps)(ThreadPanel)
