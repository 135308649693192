import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import { TallAutocomplete } from '../../components/Basic'
import NumberTextField from './NumberTextField'
import styled from 'styled-components'

const AddTemplateForm = styled.form`
  display: flex;
  padding-top: 1px;
  > div {
    width: 32.33%;
    margin-right: 1%;
    &:last-of-type {
      width: 33.33%;
      margin-right: 0;
    }
    margin-bottom: 10px;
  }

  @media(max-width: 959px) {
    .responsive-templateForm {
      flex-direction: column;

      > div {
        width: 100%;
        margin-right: 0;
        &:last-of-type {
          width: 100%;
        }
      }
    }
  }
`

export default ({
  selectedTemplate, updateTemplate,
  allIndustries
}) => {
  const [autocompleteValue, setAutocompleteValue] = useState(
    selectedTemplate &&
    selectedTemplate.industry !== '' &&
    selectedTemplate.industry !== null ?
    {
      label: selectedTemplate.industry,
      value: selectedTemplate.industry
    } : null
  )

  const handleAutocompleteOnChange = (industry) => {
    let industryValue = industry && industry.value
    const newValue = industryValue === '' || industryValue === null ? null : {
      label: industry && industry.value,
      value: industry && industry.value
    }

    setAutocompleteValue(newValue)
    return updateTemplate({
      ...selectedTemplate,
      industry: industry && industry.value
    })
  }

  return (
    <AddTemplateForm>
      <TextField
        type="text"
        variant="outlined"
        placeholder="Template Name"
        value={selectedTemplate.templateName}
        onChange={(e) => updateTemplate({
          ...selectedTemplate,
          templateName: e.target.value
        })}
        fullWidth
      />

      <TallAutocomplete
        value={autocompleteValue}
        onChange={(industry) => handleAutocompleteOnChange(industry)}
        suggestions={allIndustries.map(i => ({label: i, value: i}))}
        placeholder="Select sub-industry"
        fullWidth
      />

      <NumberTextField
        placeholder="Max revenue"
        numberValue={selectedTemplate.maxRevenue}
        setNumberValue={(value) => updateTemplate({
          ...selectedTemplate,
          maxRevenue: value
        })}
      />

    </AddTemplateForm>
  )
}
