import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'

const Progress = withStyles({
  root: {
    width: '20px', 
    height: '20px', 
    marginRight: '10px',
    color: '#da9b31'
  }
})(CircularProgress)

export default ({text, isLoading, disabled, onClick}) =>
  <Button
    type="submit"
    disabled={disabled || isLoading}
    variant="contained"
    color="primary"
    onClick={onClick}
    fullWidth
  >
    {isLoading && <Progress />}
    {text}
  </Button>
