import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Button from '@material-ui/core/Button'
import Autocomplete from '../components/Autocomplete'
import styled from 'styled-components'
import { withStyles } from '@material-ui/core/styles'

const Container = styled.div`
  border: 1px solid #ccc; 
  background: #eee; 
  padding: 12px; 
  width: 345px; 
  z-index: 90; 
  font-size: 16px; 
  position: absolute;
  > button {
    margin-right: 10px;
  }
`

const InputGroup = styled.div`
  > p {
    font-weight: bold;
    margin: 20px 0 8px;
  }
`

const CheckboxLabel = withStyles({
  root: {
    marginLeft: 0,
    verticalAlign: 'baseline',
    '& span': {
      fontSize: '16px',
    }
  }
})(FormControlLabel)

export default class FilterPanel extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
    }
  }

  handleOnChangeDominanceFilter(id, checked) {
    let dominance = this.props.appFilter.dominance
    if(checked) {
      dominance.push(id)
    } else {
      dominance = dominance.filter(item => item !== id)
    }
    const appFilter = this.props.appFilter
    appFilter.dominance = dominance
    this.props.handleOnChangeAppFilter(appFilter)
  }

  handleOnChangeIndustryFilter(items) {
    const appFilter = this.props.appFilter
    appFilter.industries = items.map(item => item.value)
    this.props.handleOnChangeAppFilter(appFilter)
  }

  handleOnChangeRegionFilter(items) {
    const appFilter = this.props.appFilter
    appFilter.regions = items.map(item => item.value)
    this.props.handleOnChangeAppFilter(appFilter)
  }

  render() {

    const regionSuggestions = this.props.regions.map(region => ({value: region.id, label: region.code}))
    const regionValue = regionSuggestions.filter(item => this.props.appFilter.regions.indexOf(item.value) >= 0)

    return(
      <Container>
        <Button
          color="primary"
          variant="contained" size="small"
          onClick={this.props.handleOnStartAgain}
        >
          New
        </Button>

        <Button
          color="primary"
          variant="contained" size="small"
          onClick={this.props.saveAnswers}
        >
          Make a copy
        </Button>

        <InputGroup>
          <p>Market Dominance</p>
          <CheckboxLabel
            label="Leader"
            control={
              <input type="checkbox"
                checked={this.props.appFilter.dominance.indexOf('1') >= 0}
                onChange={event => this.handleOnChangeDominanceFilter('1', event.target.checked)}
                value="1"
              />
            }
          />

          <CheckboxLabel
            label="Challenger"
            control={
              <input type="checkbox"
                checked={this.props.appFilter.dominance.indexOf('2') >= 0}
                onChange={event => this.handleOnChangeDominanceFilter('2', event.target.checked)}
                value="2"
              />
            }
          />

          <CheckboxLabel
            label="Others"
            control={
              <input type="checkbox"
                checked={this.props.appFilter.dominance.indexOf('3') >= 0}
                onChange={event => this.handleOnChangeDominanceFilter('3', event.target.checked)}
                value="3"
              />
            }
          />

        </InputGroup>
        
        <InputGroup>
          <p>Industry</p>
          <Autocomplete
            value={this.props.appFilter.industries.map(i => ({value: i, label: i}))}
            onChange={items => this.handleOnChangeIndustryFilter(items)}
            suggestions={this.props.industries.map(x => ({ value: x, label: x}))}
            placeholder='Search industries'
            isMulti
          />
        </InputGroup>

        <InputGroup>
          <p>Region</p>
          <Autocomplete
            value={regionValue}
            onChange={items => this.handleOnChangeRegionFilter(items)}
            suggestions={regionSuggestions}
            placeholder='Search regions'
            isMulti
          />
        </InputGroup>
      </Container>
    )
  }
}
