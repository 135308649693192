import React from 'react'
import { connect } from 'react-redux'
import ReactIntercom from './ReactIntercom'

const IntercomWidget = ({currentUser, currentCompany, ...props}) => {

  if(!currentUser) {
    return null
  }

  const userInfo = {
    email: currentUser.email,
    name: `${currentUser.firstName} ${currentUser.lastName}`,
    first_name: currentUser.firstName,
    last_name: currentUser.lastName,
    company: {
      id: currentCompany.companyId,
      name: currentCompany.name
    }
  }

  return(
    <ReactIntercom
      appID="y83okxyn"
      {...props}
      {...userInfo}
    />
  )
}

const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
  currentCompany: state.auth.currentCompany,
})

export default connect(mapStateToProps)(IntercomWidget)
