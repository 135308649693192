import React from 'react'
import * as Dialog from '../../components/Dialog'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'

export default ({open, onClose, onAnonymise}) => {

  return(
    <Dialog.DialogContainer
      open={open}
      onClose={onClose}
    >
      <Dialog.FormTitle>
        <VisibilityOffIcon />Are you sure to anonymise this dataset?
      </Dialog.FormTitle>
      <Dialog.DialogContentContainer>

        <p>
          When you annonimise a dataset we will clean up the dataset so that it cannot identify the organisation it originally came from or any related party.
        </p>

        <b>What we will keep:</b>
        <ul>
          <li>only words that we identified as app names</li>
          <li>transaction dates and dollar amounts</li>
        </ul>

        <b>What we will remove:</b>
        <ul>
          <li>any text that is not identified as an app name (transaction line description, reference, contact name).</li>
        </ul>

        <p>
          This action is not reversible. Not even by us through the back-end.
        </p>

        <p>
          If you ever want to bring back the full descriptions, you will have to connect to Xero again and bring the data from there.
        </p>

        <Dialog.Actions>
          <Dialog.CancelButton variant="outlined" onClick={onClose}>
            Cancel
          </Dialog.CancelButton>
          <Dialog.SaveButton variant="outlined" onClick={onAnonymise}>
            Anonymise
          </Dialog.SaveButton>
        </Dialog.Actions>
      </Dialog.DialogContentContainer>
    </Dialog.DialogContainer>
  )
}
