import { createMuiTheme } from '@material-ui/core/styles'
import { palette } from './palette'
import { typography } from './typography'
import { MuiButton } from './button'
import { MuiTextField } from './textfield'

export const theme = createMuiTheme({
  palette,
  typography,
  overrides: {
    MuiButton,
    MuiTextField
  }
})
