import uuid from 'uuid/v4'

export const addSection = () => ({
  type: 'ADD_SECTION',
  payload: {
    id: uuid()
  }
})

export const receiveSections = sections => ({
  type: 'RECEIVE_SECTIONS',
  payload: {
    sections
  }
})

export const updateSection = (id, title, intro, color) => ({
  type: 'UPDATE_SECTION',
  payload: {
    id, title, intro, color
  }
})

export const deleteSection = id => ({
  type: 'DELETE_SECTION',
  payload: {
    id
  }
})

export const removeQuestionFromSection = (questionId, sectionId) => ({
  type: 'REMOVE_QUESTION_FROM_SECTION',
  payload: { questionId, sectionId }
})

export const addQuestionToSection = (questionId, sectionId) => ({
  type: 'ADD_QUESTION_TO_SECTION',
  payload: { questionId, sectionId }
})

export const setSectionQuestionsOrder = (id, questions) => ({
  type: 'SET_SECTION_QUESTIONS_ORDER',
  payload: {
    id, questions
  }
})

export const setSectionsOrder = allSections => ({
  type: 'SET_SECTIONS_ORDER',
  payload: {
    allSections
  }
})
