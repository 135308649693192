import React from 'react'
import styled from 'styled-components'
import GenericTable from '../components/GenericTable/GenericTable'
import VisibilityTooltip from './components/VisibilityTooltip'

const Container = styled.div`
  .genericTable {
    width: ${props => props.size};
  }
`

export default (props) => {
  if(props.small) {
    return <SmallListOfTemplates {...props} />
  } else {
    return <WideListOfTemplates {...props} />
  }
}

const SmallListOfTemplates = ({templates, actionButtons, onSelect, rightContentTemplate }) => {
  return(
    <Container size="300px">
      <GenericTable
        title="Templates"
        initialSortColumn="templateName"
        columns={[
          {
            title: 'Template Name',
            key: 'templateName',
            type: 'string'
          }
        ]}
        data={templates}
        onItemClick={(row, columnKey) => onSelect(row && row.templateId)}
        actionButtons={actionButtons}
        searchPlaceholder={'Search template'}
        sideContent={rightContentTemplate}
      />
    </Container>
  )
}

const WideListOfTemplates = ({currentRole, templates, onSelect, actionButtons }) => {

  let columns = [
    {
      title: 'Template Name',
      key: 'templateName',
      type: 'string'
    },
    {
      title: 'Industry',
      key: 'industry',
      type: 'string'
    },
    {
      title: 'Max Revenue',
      key: 'maxRevenue',
      type: 'currency',
      step: 1000000
    },
    {
      title: 'Name',
      key: 'userName',
      type: 'string'
    },
    {
      title: 'Company',
      key: 'companyName',
      type: 'string'
    },
    {
      title: 'Visibility',
      key: 'visibility',
      type: 'string'
    }
  ]

  const tableData = templates.map(template => {
    let newTemplate = {
      ...template,
      visibility: {
        value: template.visibility,
        component: <VisibilityTooltip template={template} />
      }
    }
    if(currentRole !== 'ADMIN') {
      delete newTemplate.companyName
      columns = columns.filter(column => column.title !== 'Company')
    }
    return newTemplate
  })

  return(
    <Container size="100%">
      <GenericTable
        title="Templates"
        initialSortColumn="industry"
        columns={columns}
        data={tableData}
        onItemClick={(row, columnKey) => onSelect(row && row.templateId)}
        actionButtons={actionButtons}
        searchPlaceholder={'Search template'}
      />
    </Container>
  )
}
