import React, { useState } from 'react'
import { API } from 'aws-amplify'
import DeleteIcon from '@material-ui/icons/Delete'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save'
import DocxUpload from './DocxUpload'
import DeleteDialog from './DeleteDialog'
import Link from './components/Link'

const downloadReportTemplate = async (filename) => {
  const result = await API.get("dev-d1-apps-backend", '/reportTemplates/' + filename)
  window.open(result.signedUrl, '_blank')
}

const BucketForm = ({ bucket, onSave, onDelete, appName, bucketIndex }) => {
  const [mode, setMode] = useState('view') // or 'edit'
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [title, setTitle] = useState(bucket.title)
  const [templateFile, setTemplateFile] = useState(bucket.templateFile)

  const handleChange = (e) => {
    const value = e.target.value
    if (e.target.name === 'title') {
      setTitle(value)
    }
  }

  const handleSubmit = () => {
    toggleMode()
    onSave(title, templateFile)
  }

  const toggleMode = () => {
    const newMode = mode === 'view' ? 'edit' : 'view'
    setMode(newMode)
  }

  return (
    <Grid container spacing={16}>

      {showDeleteDialog &&
        <DeleteDialog
          title='Delete bucket?'
          onClose={() => setShowDeleteDialog(false)}
          onSubmit={onDelete}
        />
      }

      <TextField
        label='Title'
        margin='normal'
        disabled={mode === 'view'}
        name='title'
        fullWidth
        value={title}
        onChange={handleChange}
        variant='outlined'
      />

      {mode === 'view' &&
        <Grid item xs={12}>
          <Link onClick={() => downloadReportTemplate(bucket.templateFile)}>
            Download report template
          </Link>
        </Grid>
      }

      {mode === 'edit' &&
        <Grid item xs={12}>
          <DocxUpload
            onUploaded={filename => setTemplateFile(filename)}
            appName={appName}
            bucketIndex={bucketIndex}
          />
        </Grid>
      }

      { mode === 'view' &&
        <IconButton onClick={toggleMode} aria-label='Edit'> <EditIcon /> </IconButton>}
      { mode === 'view' &&
        <IconButton onClick={() => setShowDeleteDialog(true)} aria-label='Delete'> <DeleteIcon /> </IconButton>}
      { mode === 'edit' &&
        <IconButton onClick={handleSubmit} aria-label='Save'> <SaveIcon /> </IconButton>}
    </Grid>
  )
}

export default BucketForm
