import React from 'react'
import { connect } from 'react-redux'
import LoadingSpinner from './LoadingSpinner'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import CloseIcon from '@material-ui/icons/Close'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { setSidePanelContent, setActiveTab } from '../actions/sidePanelActions'
import styled from 'styled-components'
import { withStyles } from '@material-ui/core/styles'

const PaperContainer = withStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: '#eee',
    top: 0,
    bottom: 0,
    zIndex: 999,
    fontSize: '16px',
    position: 'fixed',
    [theme.breakpoints.up('sm')]: {
      border: '1px solid #ccc',
      width: '465px'
    }
  }
}))(Paper)

export const Container = styled(PaperContainer)`
  transition: right 0.5s ease-in-out;
`

export const SidePanelTabs = withStyles({
  root: {
    backgroundColor: '#2e314e',
  },
  indicator: {
    backgroundColor: '#da9b31',
  },
  flexContainer: {
    height: '64px'
  }
})(Tabs)

export const SidePanelTab = withStyles({
  root: {
    color: '#fff',
    fontSize: '16px',
    padding: '6px 20px',
    opacity: '1',
    '&:hover': {
      color: '#da9b31'
    },
    '&$selected': {
      color: '#da9b31'
    }
  },
  selected: {}
})(Tab)

export const SidePanelTabTitle = styled.h5`
  font-size: 24px;
  font-weight: 700;
  margin: 0 0 10px;
`

export const Content = styled.div`
  width: 100%;
  overflow-y: auto;
  padding: 15px;
  box-sizing: border-box;
  top: 65px;
  bottom: 0;
  position: absolute;

  iframe {
    width: 100%;
  }

  a {
    color: #069;
  }

  p:first-child {
    margin-top: 0;
  }

  @media (min-width: 600px) {
    width: 465px;
  }
`

export const CloseBtnContainer = styled.div`
  position: relative;
`

export const CloseButton = withStyles({
  root: {
    right: '0',
    position: 'absolute',
    zIndex: '10',
    color: '#fff',
    padding: '20px',
    '&:hover': {
      color: '#da9b31',
    }
  }
})(IconButton)

const SidePanel = ({
  tabs, setSidePanelContent, activeTab,
  setActiveTab, isLoading
}) => {
  const positionRight = isLoading ? 0 : tabs.length > 0 ? 0 : -550
  const displayValue = (positionRight < 0) ? 'none' : 'block'
  const renderedContent = tabs.length > 0 && tabs[activeTab].content
  return (
    <Container elevation={4} style={{'right': `${positionRight}px`, display: displayValue}}>
      <CloseBtnContainer>
        <CloseButton onClick={() => setSidePanelContent()}>
          <CloseIcon />
        </CloseButton>
      </CloseBtnContainer>

      {isLoading &&
        <LoadingSpinner />
      }

      {!isLoading &&
        <>
          <AppBar position="static">
            <SidePanelTabs
              value={activeTab}
              onChange={(event, value) => setActiveTab(value)}
              scrollButtons="auto"
            >
              {tabs.map((tab, index) => (
                <SidePanelTab label={tab.title} key={index} />
              ))
              }
            </SidePanelTabs>
          </AppBar>

          <Content>
            {renderedContent}
          </Content>
        </>
      }
    </Container>
  )
}

const mapStateToProps = (state) => ({
  tabs: state.sidePanel.tabs,
  activeTab : state.sidePanel.activeTab,
  isLoading: state.sidePanel.isLoading
})

const mapDispatchToProps = (dispatch) => ({
  setSidePanelContent: () => dispatch(setSidePanelContent()),
  setActiveTab: (value) => dispatch(setActiveTab(value))
})

export default connect(mapStateToProps, mapDispatchToProps)(SidePanel)
