import React from 'react'
import { connect } from 'react-redux'
import { updateBucket, deleteBucket } from './actions/bucketActions'
import BucketForm from './BucketForm'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const Bucket = ({ id, bucket, bucketIndex, appName, onSave, onDelete, expanded, onToggleExpand }) => {
  return (
    <ExpansionPanel expanded={expanded === bucket.id}
      onChange={() => onToggleExpand(bucket.id)}
    >
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography >{bucket.title}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <BucketForm
          onSave={onSave}
          key={bucket.id}
          onDelete={onDelete}
          bucket={bucket}
          appName={appName}
          bucketIndex={bucketIndex}
        />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

const mapStateToProps = (state, ownProps) => ({
  bucket: state.appConfig.buckets.byId[ownProps.id]
})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSave: (title, templateFile) => dispatch(updateBucket(ownProps.id, title, templateFile)),
    onDelete: () => dispatch(deleteBucket(ownProps.id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Bucket)
