import React from 'react'
import styled from 'styled-components'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'

const Ball = styled.div`
  @keyframes ballanimation {
    0%   { opacity: 0; }
    100% { opacity: 1; }
  }
  animation: ballanimation 1s ease forwards;
  width: 34px;
  height: 34px;
  margin: 5px;
  border: 3px solid #171820;
  border-radius: 50%;
  background: ${props => props.color};
  box-shadow: 0px 0px 0px 3px #fff;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
  z-index: 10;
/*  :hover {
    background: orange;
  }
*/
`

const Highlight = styled.div`
  width: 22px;
  height: 12px;
  border-radius: 50%;
  opacity: 0.3;
  background-color: #ffffff;
  position: absolute;
  left: 5.5px;
  top: 2.5px;
  z-index: 15;
`

const BallTooltip = withStyles({
  tooltip: {
    fontSize: '14px'
  }
})(Tooltip)

export default ({tooltip, color, onClick}) =>
  <BallTooltip title={tooltip} placement="bottom-start">
    <Ball color={color} onClick={onClick}>
      <Highlight />
    </Ball>
  </BallTooltip>
