import React from 'react'
import { API } from 'aws-amplify'
import styled from 'styled-components'
import Sidepanel from './Sidepanel'
import ConversationsList from './ConversationsList'

const LeftList = styled.div`
  background-color: #3f0e40;
  width: 250px;
  font-size: 16px;
  h4 {
    margin: 0;
    color: white;
    font-size: 18px;
    padding: 15px;
  }
`

const SectionTitle = styled.div`
  color: #C5B7C6;
  cursor: pointer;
  display: block;
  padding: 5px 15px;
`

const SectionItem = styled.div`
  color: ${props => props.selected ? 'white' : '#C5B7C6'};
  background-color: ${props => props.selected ? '#1164A3' : 'inherit'};
  cursor: pointer;
  display: block;
  padding: 5px 15px;
  :hover {
    background-color: ${props => props.selected ? '#1164A3' : '#350D36'};
  }
`

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  height: 55px;
  border-bottom: 1px solid #BBB;
  display: flex;
  justify-content: space-between;

  h4 {
    margin: 15px;
    color: black;
    font-size: 18px;
    font-weight: bold;
  }

  input {
    width: 300px;
    margin: 10px;
    font-size: 14px;
    padding: 8px;
    outline: none;
    border: 1px solid #BBB;
    border-radius: 5px;
    ::placeholder {
      color: #AAA;
    }
  }

`

const Main = styled.div`
  display: flex;
  flex: 1;
`

const MainCenter = styled.div`
  flex: 1;
  height: 100%;
`

const MainRight = styled.div`
  flex: 1;
  height: 100%;
  border-left: 1px solid #BBB;
  display: flex;
  flex-direction: column;
`


export default class BotPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      clients: [],
      selectedClient: null
    }
  }

  async componentDidMount() {
    const clients = await API.get("dev-d1-apps-backend", '/clients')
    this.setState({
      clients,
      selectedClient: (clients.length > 0) && clients[0]
    })
  }

  render() {

    return(
      <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0}}>

        <div style={{height: '100%', display: 'flex'}}>

          <LeftList>
            <h4>Mathias Schaeffner</h4>
            <SectionTitle>
              Clients
            </SectionTitle>
            {this.state.clients.map(client =>
              <SectionItem
                key={client.clientId}
                selected={this.state.selectedClient.clientId === client.clientId}
                onClick={() => this.setState({selectedClient: client})}
              >
                # {`${client.firstName} ${client.lastName}`}
              </SectionItem>
            )}
          </LeftList>

          <Content>

            <Header>
              <div>
                {this.state.selectedClient &&
                  <h4>
                    # {`${this.state.selectedClient.firstName} ${this.state.selectedClient.lastName}`}
                  </h4>
                }
              </div>
              <div>
                <input
                  placeholder='Search'
                  value={''}
                  onChange={event => this.setState({searchText: event.target.value})}
                />
              </div>
            </Header>


            <Main>
              <MainCenter>
                <ConversationsList />
              </MainCenter>

              <MainRight>
                <Sidepanel
                  client={this.state.selectedClient}
                />
              </MainRight>

            </Main>

          </Content>

        </div>


      </div>
    )
  }

}
